import React from "react";
import UserAnalyticsController from "./UserAnalyticsController.web";
import "./../userFeed.css";
import {
  Card,
  Row,
  Typography,
  Avatar,
  Tabs,
  Spin,
  Empty
} from "antd";

const { Text } = Typography;
const { TabPane } = Tabs;

// Customizable Area Start
interface UserDataType {
  attributes: {
  id: string | number,
  role: string,
  photo: string,
  first_name: string,
  last_name: string,
  full_name: string,
  company_name: string,
  profile_pic: string,
  following: boolean
}
}
// Customizable Area End

// Customizable Area Start
export default class UserAnalytics extends UserAnalyticsController {
    renderUserList = (users:any, dataTestIdPrefix:any) => (
        this.state.isLoading.isUserLoad ? <Spin /> : (
          users.map((item: UserDataType) => (
            <div className="follow_main" key={item?.attributes.id}>
              <div className="follow_content">
                <div
                  className="follow_profile"
                  style={{ cursor: 'pointer' }}
                  data-test-id={`${dataTestIdPrefix}-tab${item?.attributes.id}`}
                  onClick={() => this.handleClickOnUser(item?.attributes)}
                >
                  <Avatar src={item?.attributes?.photo} size={50} />
                  <Typography>{item?.attributes?.first_name ? `${item?.attributes?.first_name} ${item?.attributes?.last_name}` : item?.attributes?.full_name}</Typography>
                </div>
                <div className={item?.attributes?.following ? "follow_button" : "following_button"} >
                  <button
                    data-test-id={`follow-unfollow-Button${item?.attributes?.id}`}
                    onClick={() => this.onHandleFollow(item?.attributes?.id)}
                    style={{width: "100px"}}
                  >
                    {item?.attributes?.following ? "Unfollow" : "Follow"}
                  </button>
                </div>
              </div>
            </div>
          ))
        )
      );
    
      render() {
        const { userAnalytics, keyValue } = this.state;
        return (
          <div className="background" style={{ backgroundColor: "rgb(246, 246, 246)" }}>
            {this.redirectUser()}
            <Row style={{ paddingTop: "15px", marginBottom: 7, marginLeft: 20 }}>
              <Text className="textlabel_subheadingText">My Profile /</Text>
              <Text className="textlabel_subheadingText2"> User analytics</Text>
            </Row>
            <Card style={{ width: "100%", backgroundColor: "rgb(246, 246, 246)", border: 'none' }}>
              <Tabs
                activeKey={keyValue}
                defaultActiveKey="1"
                size="large"
                tabBarStyle={{ color: "#919191", backgroundColor: "#dee5eb", height: "3rem" }}
                style={{ borderRadius: "3px", backgroundColor: "#f1f0f5" }}
                data-test-id="tab-change"
                onChange={this.handleChangeTab}
              >
                <TabPane
                  tab={`Resume Downloads (${userAnalytics?.cv_downloaded_users_count || 0})`}
                  data-test-id="followers-tab"
                  key="1"
                  style={{ width: "96%", height: "100%", margin: "auto" }}
                >
                  {userAnalytics?.cv_downloaded_users?.data?.length ? this.renderUserList(userAnalytics.cv_downloaded_users?.data, "followers") : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                </TabPane>
                <TabPane
                  tab={`Rating Users (${userAnalytics?.rated_users_count || 0})`}
                  data-test-id="following-tab"
                  key="2"
                  style={{ width: "96%", height: "100%", margin: "auto" }}
                >
                  {userAnalytics?.rated_users?.data?.length ? this.renderUserList(userAnalytics.rated_users?.data, "following") : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                </TabPane>
                <TabPane
                  tab={`Review Given (${userAnalytics?.reviewers_count || 0})`}
                  data-test-id="followers-tab"
                  key="3"
                  style={{ width: "96%", height: "100%", margin: "auto" }}
                >
                  {userAnalytics?.reviewers?.data?.length ? this.renderUserList(userAnalytics.reviewers?.data, "followers") : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                </TabPane>
                <TabPane
                  tab={`Profile Visit (${userAnalytics?.visited_users_count || 0})`}
                  data-test-id="following-tab"
                  key="4"
                  style={{ width: "96%", height: "100%", margin: "auto" }}
                >
                  {userAnalytics?.visited_users?.data?.length ? this.renderUserList(userAnalytics.visited_users?.data, "following") : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                </TabPane>
              </Tabs>
            </Card>
          </div>
        );
      }
}
// Customizable Area End
