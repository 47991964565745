import React from 'react';
import { PieChart, Pie, Cell } from 'recharts';
import { Card, Typography, Row, Col, Space } from 'antd';


const { Text, Title } = Typography;

const NPSChartView = (props: any) => {
    // Customizable Area Start
    const colorData = [
        { color: "#b24040" },
        { color: "#3a87a8" },
        { color: "#3c9b83" },
        { color: "#d9d9d9" }
    ]
    const { npsData } = props;
    // console.log(npsData);
    const data = [
        { score: props.npsData ? props.npsData.detractors : 0 },
        { score: props.npsData ? props.npsData.passives : 0 },
        { score: props.npsData ? props.npsData.promoters : 0 },
        { score: props.npsData.detractors || props.npsData.passives || props.npsData.promoters ? 0 : props.npsData.noValue },
    ];

    const listOfDesc = [
        {
            id: 1,
            color: "#3c9b83",
            desc: "Promoters 80 to 100%"
        },
        {
            id: 2,
            color: "#3a87a8",
            desc: "Passives 60 to 79%"
        },
        {
            id: 3,
            color: "#b24040",
            desc: "Detractors 0 to 59%"
        }
    ]
    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({
        cx,
        cy,
        midAngle,
        innerRadius,
        outerRadius,
        percent,
        index
    }: any) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);
        console.log()
        if ((percent * 100) > 0) {
            return (
                <text
                    x={x}
                    y={y}
                    style={{ fontSize: 10 }}
                    fill="white"
                    width={20}
                    textAnchor={"middle"}
                    dominantBaseline="middle"
                >

                    {!props.npsData.noValue && `${(percent * 100).toFixed(0)}%`}
                </text>
            );
        }
    };

    return (
        <div
            style={{
                width: "95%",
                height: '91%',
                borderRadius: "5px",
                marginLeft: "20px"
            }}
        >
            <h4 style={{ color: "rgba(0, 0, 0, 0.85)", fontSize: "15px" }}>NPS Chart</h4>
            <div className='npsChart'>
                <div
                    className='npsChart_position'
                    style={{

                    }}>

                    {props?.npsData.score ?
                        <PieChart width={300} height={300} style={{
                            // transform: 'rotate(90deg)'
                        }}>
                            <Pie
                                data={data}
                                dataKey="score"
                                outerRadius={90}
                                innerRadius={71}
                                paddingAngle={0}
                                startAngle={-90}
                                // rotate={90}
                                label={renderCustomizedLabel}
                                labelLine={false}
                            // endAngle={0}
                            >
                                {
                                    colorData.map((entry, index) => <Cell key={index} fill={entry.color} />)
                                }
                            </Pie>
                        </PieChart> :
                        <div style={{ textAlign: 'center' }}>Score is  not Available</div>
                    }
                </div>
                <div
                    className='nps_Chart_Analytics'
                    style={{}}
                >
                    {
                        listOfDesc.map((item: any) => {
                            return <div
                                key={item.id}
                                // style={{
                                //     margin: "5px 0px",
                                //     display: "flex",
                                //     flexDirection: "row"
                                // }}
                                className="npsChart_analytics_responsive"
                            >
                                <Card style={{ width: "15px", height: "15px", backgroundColor: item.color, borderRadius: "2px" }}></Card>
                                <Text style={{ fontSize: "11px", marginLeft: "5px" }}>{item.desc}</Text>
                            </div>
                        })
                    }
                </div>
            </div>
        </div>

    )
    // Customizable Area End
}

export default NPSChartView
// Customizable Area Start
// Customizable Area End
