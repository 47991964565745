import React, { Component } from "react";
// Customizable Area Start
import {
  PageHeader,
  Card,
  Row,
  Col,
  Form,
  Input,
  Typography,
  Button,
  Spin,
  DatePicker,
  Steps,
  Popover,
  Space,
  Select,
  Avatar,
  Upload,
  message,
  Divider,
} from "antd";
import { logo, style, NETWORK_PROFILE_ING } from "../../assets";
const { Text, Link, Title } = Typography;
import {
  UserOutlined,
  EnvironmentOutlined,
  CloseCircleOutlined,
  CheckCircleTwoTone,
  PlusCircleFilled,
} from "@ant-design/icons";
import ContactusController from "../../../../contactus/src/ContactusController";
// Customizable Area End

const ViewInvite = (props: any) => {
  // Customizable Area Start
  console.log("get all props", props);
  const ViewInviteEnvironmentOutlinedicon = () => {
    return <EnvironmentOutlined />
  }
  // Customizable Area End

  // Customizable Area Start
  return (
    <div>
      <Col xs={0} sm={0} md={3} lg={4} xl={4}>
        <Card hoverable className="padding-card-network">
          <Row justify="center">
            <Avatar size={64} icon={<UserOutlined />} />
          </Row>
          <Row justify="center" style={{ paddingTop: "12px" }}>
            <Text style={{ fontSize: 14, fontWeight: "bold" }}>
              Anje Keizer
            </Text>
            <br />
            <p
              style={{
                width: "100%",
                marginBottom: 0,
                fontSize: 12,
                textAlign: "center",
              }}
            >
              Sr. Python Developer <Text type="secondary"> at </Text> Google
            </p>
            <br />
            <Text
              style={{
                width: "100%",
                marginBottom: 0,
                fontSize: 12,
                textAlign: "center",
              }}
              type="secondary"
            >
              {ViewInviteEnvironmentOutlinedicon()}
              San Diego, Callifornia, US
            </Text>
          </Row>
          <Divider />
          <Row
            gutter={5}
            style={{ alignItems: "center" }}
            justify="space-around"
          >
            <Col>
              <Button
                className={style.skip_add_btn}
                type="primary"
                shape="round"
                size={"small"}
              >
                View Profile
              </Button>
            </Col>
            <Col style={{ marginLeft: 8 }}>
              <Button
                className={style.skip_add_btn}
                type="primary"
                shape="round"
                size={"small"}
              >
                Invite
              </Button>
            </Col>
          </Row>
        </Card>
      </Col>
    </div>
  );
  // Customizable Area End

  // Customizable Area Start
  // Customizable Area End
};
// Customizable Area Start
export default ViewInvite;
// Customizable Area End
