import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { message } from "antd";
import { getStorageData, isEmpty } from "../../../../framework/src/Utilities";
import axios from "axios";
const googleAPIkey = "AIzaSyCBH92YX2oCbI7PChqFNrgzw-PolHAiKZc";
import { RouterProps } from "react-router";
// @ts-ignore
import { baseURL } from '../../../../framework/src/config.js';
import React, { createRef } from "react";
import { Redirect, useHistory, useLocation } from "react-router-dom";
export const configJSON = require("./../config");
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { cloneDeep } from "lodash";
export interface Props extends RouterProps {
  navigation: any;
  id: string;
  match: any;
  // Customizable Area Start
  location: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isCreatePostEnable: boolean;
  isMenuVisible: boolean;
  isBusinessUser: boolean;
  createPostDescription: any;
  postList: any[];
  videoArrayList: any;
  isloader: boolean;
  token: string | null;
  isCreatePostVisible: boolean;
  fullAddress: string;
  createPostImages: any;
  loading: boolean;
  likedItems: number[];
  followedItems: number[];
  userProfile: any;
  loginUser: any;
  accountDetails: any
  isReportIssueVisible: boolean;
  reportPostID: any;
  selectedFile: any;
  value: any;
  mentionInputValue: string;
  GetConnectionArrayAcc: any;
  tagged_connection_ids: any;
  isEmojiPickerEnable: boolean;
  postComments: any;
  AllMedia: any
  knownPeople: any
  redirect: boolean;
  shiftRatings: any;
  drawerVisible: boolean;
  isModalInviteFriends: boolean;
  isModalNewsletter: boolean;
  isModalNewsletterConfirm: boolean;
  myReviews: any;
  ratingSends: any;
  ratingReceives: any;
  followersAndFollowingOpen: boolean;
  createPostOpen: boolean;
  keyValue: string;
  upcomingWorkAnnivers: any;
  defaultActiveKey: any;
  pledgeTracking: any
  followers: any;
  tagsOpen: boolean;
  editData: any;
  editPostOpen: boolean;
  personalProfile: any,
  businessProfile: any,
  newsubscribe: any;
  isModalInviteFrdsVisible: boolean;
  getPostBYIdForShare: any;
  postId: any;
  profileData: any;
  suggestions: any;
  open: boolean;
  address: any;
  editortext: any;
  location: boolean;
  loadComments: any;
  updatedDetails: any;
  commentslikeditems: any;
  data: any;
  deleteExistingMedias: any;
  bodyText: any
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class ViewPersonalPostController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  labelLogOut: string;
  labelProfile: string;
  labelSetting: string;
  labelApplied: string;
  labelHome: string;
  labelMyNetwork: string;
  labelCommunity: string;
  labelChat: string;
  labelAt: string;
  labelCommunityForum: string;
  labelWorkAnniversaries: string;
  labelBirthAnniversaries: string;
  labelKnownPeople: string;
  labelInvitations: string;
  placeHolderSearchContact: string;
  placeHolderWhatOnMind: string;
  btnLearnMore: string;

  isMenuVisible: boolean;

  PostID: any;
  editPostID: any;
  getHomeFeedPostsApiCallId: any;
  geteditFeedPostApiCallId: any;
  getPendingInvitationsApiCallId: any;
  getKnownPeopleApiCallId: any;
  getNetworkConnectionAppliedId: any;
  addCommentInPost: any;
  addRplyCommentInPost: any;
  postNewsSubscribeCallID: any;
  postNewsUnSubscribeCallID: any;
  getPostByIdForshareMethodTypeCallID: any;
  shareCountMethodTypeCallId : any;
  getCommentInPostApiCallID: any;
  unsubscribe: any;
  getReviewsApiCallId: any;
  getRatingSendApiCallId: any;
  getRatingReceivedApiCallId: any;
  upcomingWorkAnniversariesApiCallId: any;
  GetProfielInformationId: any;

  getConnectionApiCallId: any;
  deletePostApiCallId: any;
  addReplyCommentInPost: any;
  newsubscribe: any;
  getAccountDataEndPoint: any
  // Customizable Area End
  contentRef = createRef<any>();
    constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      myReviews: [],
      isCreatePostEnable: false,
      isMenuVisible: false,
      isBusinessUser: false,
      createPostDescription: EditorState.createEmpty(),
      postList: [],
      videoArrayList: [],
      isloader: false,
      token: localStorage.getItem("token") ? localStorage.getItem("token") : "",
      isCreatePostVisible: false,
      fullAddress: "",
      createPostImages: [],
      loading: false,
      likedItems: [],
      followedItems: [],
      loginUser: {},
      userProfile: {},
      isReportIssueVisible: false,
      reportPostID: "",
      selectedFile: null,
      value: 1,
      GetConnectionArrayAcc: [],
      mentionInputValue: "",
      tagged_connection_ids: [],
      isEmojiPickerEnable: false,
      postComments: [],
      AllMedia: [],
      accountDetails: {},
      knownPeople: [],
      redirect: false,
      shiftRatings: 1,
      drawerVisible: false,
      isModalInviteFriends: false,
      isModalNewsletter: false,
      isModalNewsletterConfirm: false,
      ratingSends: {},
      ratingReceives: [],
      followersAndFollowingOpen: false,
      createPostOpen: false,
      keyValue: "",
      upcomingWorkAnnivers: {},
      defaultActiveKey: 1,
      pledgeTracking: {},
      followers: [],
      tagsOpen: false,
      editData: {},
      editPostOpen: false,
      personalProfile: {},
      businessProfile: {},
      newsubscribe: false,
      isModalInviteFrdsVisible: false,
      getPostBYIdForShare: [],
      postId: null,
      profileData: [],
      suggestions: [],
      open: false,
      address: null,
      editortext: "",
      location: false,
      loadComments: false,
      updatedDetails: {},
      commentslikeditems: [],
      data: {},
      deleteExistingMedias: [],
      bodyText: false,
    };
    this.btnLearnMore = configJSON.btnLearnMore;
    this.placeHolderWhatOnMind = configJSON.placeHolderWhatOnMind;
    this.placeHolderSearchContact = configJSON.placeHolderSearchContact;
    this.labelLogOut = configJSON.labelLogOut;
    this.labelProfile = configJSON.labelProfile;
    this.labelSetting = configJSON.labelSetting;
    this.labelApplied = configJSON.labelApplied;
    this.labelHome = configJSON.labelApplied;
    this.labelMyNetwork = configJSON.labelMyNetwork;
    this.labelCommunity = configJSON.labelCommunity;
    this.labelChat = configJSON.labelChat;
    this.labelAt = configJSON.labelAt;
    this.labelCommunityForum = configJSON.labelCommunityForum;
    this.labelWorkAnniversaries = configJSON.labelWorkAnniversaries;
    this.labelBirthAnniversaries = configJSON.labelBirthAnniversaries;
    this.labelKnownPeople = configJSON.labelKnownPeople;
    this.labelInvitations = configJSON.labelInvitations;

    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }
  async componentWillMount() {
    await this.GetProfileInformation();
  }
  async componentDidMount() {
    super.componentDidMount();
    // let id = this.props.location.search;
    // const urlId = new URLSearchParams(id).get('id');
    let id = this.props.match.params.id;
    localStorage.setItem("sharePost", id)
    this.setState({
      postId: id
      // postId: urlId
    });
    let token = await localStorage.getItem("token");
    console.log(token, "tttttt")
    if (token && token !== null && token !== undefined) {
      this.setState({ redirect: false });
    } else {
      this.setState({ redirect: true });
    }
    this.getHomeFeedPosts();
    this.handleInvitations();
    this.getKnownPeople();
    let data: any = await getStorageData("loginUser").then((res) => {
      if (res) {
        this.setState({
          loginUser: res,
          isBusinessUser: res.role === "business",
        });
        console.log("loginUser", res)
      }
    });
    await getStorageData("userProfile").then((res) => {
      const response = res?.attributes?.profile?.data?.attributes;
      console.log("res-->", response);
    });
    await getStorageData("accountData").then((res) => {
      console.log("accountDetails-->", res);
      this.setState({ accountDetails: res });
    });
    await getStorageData('updatedProfile').then((res) => {
      console.log("checking_userProfile res-->", res);
      if (res) {
        this.setState({ updatedDetails: res });
      }
    });
    this.getPostByIDForShare(id)
    this.updateShareCount(id)
  }
  getProfileAccount = (id: any) => {
    console.log(id, "dddddd22..")
    const header = {
      "Content-Type": configJSON.getPostsApiContentType,
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAccountDataEndPoint = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getAccountDetailsEndPoint}/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAccountDetailsApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async GetProfileInformation() {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.GetProfielInformationId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.GetProfileInfoEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.SearchAPIMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // console.log("GetProfileInfo--->" + JSON.stringify(requestMessage));
    return true;
  }

  redirectUser() {
    if (this.state.redirect) {
      // return <Redirect to="/" />;
      this.props.history.push({
        //@ts-ignore
        pathname: '/',
        state: this.state.postId
      })
    }
  }

  handleFollowersAndFollowingCancel = () => {
    this.setState({ followersAndFollowingOpen: false })
  }
  handlecreatePostOpenCancel = () => {
    this.setState({ createPostOpen: false, bodyText: false })
  }
  handlecreatePostOpen = () => {
    this.setState({ createPostOpen: true, createPostDescription: EditorState.createEmpty() })
  }
  handleEditPostOpen = (id: any) => {
    this.setState({ editPostOpen: true })
    this.getFeedPost(id);
    console.log(id, "editidconole")
  }

  handleEditPostCancel = () => {
    this.setState({
      editPostOpen: !this.state.editPostOpen,
      address: null,
      location: false,
      AllMedia: [], createPostImages: [], videoArrayList: [], isCreatePostEnable: false, isCreatePostVisible: false, tagged_connection_ids: [],
      // postList: responseJson.data,
      tagsOpen: false,
      createPostDescription: EditorState.createEmpty(),
      isloader: false,
    })
  }

  handleFollowersAndFollowingOpen = (data: string) => {
    if (data.match(/followers/gi)) {
      this.setState({ followersAndFollowingOpen: true, keyValue: "1" })
    } else if (data.match(/Following/gi)) {
      this.setState({ followersAndFollowingOpen: true, keyValue: "2" })
    }
  }

  async receive(from: String, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.getHomeFeedPostsApiCallId) {
          this.setState({
            deleteExistingMedias: [],
            postList: responseJson.data,
            // likedItems:,
            isloader: false,
            AllMedia: [], createPostImages: [], videoArrayList: [], createPostDescription: EditorState.createEmpty(), isCreatePostEnable: false, isCreatePostVisible: false, tagged_connection_ids: [],
          });
          if (responseJson?.data) {
            let value = responseJson.data.map((c: any) => c.attributes.liked && this.setState((prevState) => ({
              likedItems: [...prevState.likedItems, c.id],
            })));
            let valueFollowed = responseJson.data.map((c: any) => c.attributes.account.data.attributes.following && this.setState((prevState) => ({
              followedItems: [...prevState.followedItems, c?.attributes?.account?.data?.id],
            })));
          }
        } else if (apiRequestCallId === this.GetProfielInformationId) {
          const res = responseJson?.data?.attributes?.profile?.data?.attributes;
          if (responseJson.data) {
            const finalData = {
              profile: {
                date_of_birth: responseJson.data?.attributes?.profile?.data?.attributes?.date_of_birth,
                domain_skills: responseJson.data?.attributes?.profile?.data?.attributes?.domain_skills,
                employment_type: responseJson.data?.attributes?.profile?.data?.attributes?.employment_type,
                full_name: responseJson.data?.attributes?.profile?.data?.attributes?.full_name,
                personal_skills: responseJson.data?.attributes?.profile?.data?.attributes?.personal_skills,
                primary_email: responseJson.data?.attributes?.profile?.data?.attributes?.email,
                profileUrl: responseJson.data?.attributes?.profile?.data?.attributes?.photo,
                secondary_email: responseJson.data?.attributes?.profile?.data?.attributes?.secondary_email
              },

            }
            this.getProfileAccount(responseJson?.data?.attributes?.profile?.data?.attributes?.account_id);
            this.setState({
              pledgeTracking: responseJson.data?.attributes?.profile?.data?.attributes?.pledge_tracking,
              profileData: responseJson?.data,
              userProfile: responseJson?.data?.attributes?.profile?.data?.attributes
            });
          }
        } else if (apiRequestCallId === this.PostID) {
          this.setState({
            deleteExistingMedias: [],
            AllMedia: [], createPostImages: [], videoArrayList: [], isCreatePostEnable: false, isCreatePostVisible: false, tagged_connection_ids: [],
            // postList: responseJson.data,
            tagsOpen: false,
            address: null,
            location: false,
            createPostDescription: EditorState.createEmpty(),
            isloader: false,
          });
          this.getHomeFeedPosts();
        } else if (apiRequestCallId === this.editPostID) {
          if (responseJson.data) {
            this.setState({
              AllMedia: [], createPostImages: [], videoArrayList: [], isCreatePostEnable: false, isCreatePostVisible: false, tagged_connection_ids: [],
              // postList: responseJson.data,
              tagsOpen: false,
              location: false,
              address: null,
              createPostDescription: EditorState.createEmpty(),
              isloader: false,
            });
            this.getHomeFeedPosts();
          } else {
            this.setState({
              AllMedia: [], createPostImages: [], videoArrayList: [], isCreatePostEnable: false, isCreatePostVisible: false, tagged_connection_ids: [],
              // postList: responseJson.data,
              tagsOpen: false,
              location: false,
              address: null,
              createPostDescription: EditorState.createEmpty(),
              isloader: false,
            });
          }
        } else if (apiRequestCallId === this.addCommentInPost) {
          this.setState({
            isloader: false,
            loadComments: false
          });
          // this.getHomeFeedPosts();
          this.getComments(responseJson.data.attributes.commentable_id);
        } else if (apiRequestCallId === this.addReplyCommentInPost) {
          this.setState({
            isloader: false,
            loadComments: false
          });
          // this.getHomeFeedPosts();
          // window.location.reload();
        } else if (apiRequestCallId === this.getAccountDataEndPoint) {
          if (responseJson) {
            this.setState({
              data: responseJson?.data?.attributes?.account?.data?.attributes
            });
            console.log("resss--->", this.state.data)
          }
        } else if (apiRequestCallId === this.getCommentInPostApiCallID) {
          console.log("get comments: ", responseJson);
          let list = this.state.postList.map((value) =>
            value.id == responseJson.data[0]?.attributes?.commentable_id
              ? { ...value, attributes: { ...value.attributes, comments: { data: responseJson.data } } }
              : value
          );
          this.setState({
            loadComments: false,
            postComments: responseJson.data,
            postList: list
          });
        } else if (apiRequestCallId === this.getPendingInvitationsApiCallId) {
          // console.log("network request: ", JSON.stringify(responseJson));
          this.setState({
            // postList: responseJson.data,
            isloader: false,
          });
        } else if (apiRequestCallId === this.getKnownPeopleApiCallId) {
          // console.log("konown people: ", JSON.stringify(responseJson));
          this.setState({
            knownPeople: responseJson.data,
            isloader: false,
          });
        } else if (apiRequestCallId === this.getNetworkConnectionAppliedId) {
          let receivedData = responseJson.data;
          let finalFriendsData: any = [];
          receivedData &&
            receivedData.forEach((item: any, index: any) => {
              if (item && item.attributes && item.attributes.full_name) {
                let finalItem = item;
                finalItem["name"] = item.attributes.full_name;
                finalFriendsData.push(finalItem);
              }
            });
          console.log("finalFriendsData - ", finalFriendsData);
          this.setState(
            {
              GetConnectionArrayAcc: finalFriendsData,
            },
            () => {
              console.log("responseJson.data rcvd = ", responseJson.data);
              if (
                this.state.GetConnectionArrayAcc &&
                this.state.GetConnectionArrayAcc.length > 0
              ) {
                // console.log("categories: ", this.state.GetConnectionArrayAcc);
                const connectedFriends = this.state.GetConnectionArrayAcc.map(
                  (item: any) => item.attributes.connected_as
                )
                  .filter((v: any, i: any, a: any) => a.indexOf(v) == i)
                  .filter((x: any) => x !== null)
                  .map((x: any) => {
                    return { value: x };
                  });
                console.log("connectedFriends: ", connectedFriends);
                // this.setState({
                //   categoryFilterArray: connectedFriends,
                // });
                // console.log("@@@ connectedFriends: ", connectedFriends);
              }
            }
          );
          console.log(
            JSON.stringify(
              "GetConnectionArray Applied" + this.state.GetConnectionArrayAcc
            )
          );

          // this.setState({GetCategoriesArray:responseJson.data})
        }
      } else {
        this.parseApiErrorResponse(responseJson);
      }
      //get followers
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.getConnectionApiCallId) {
          this.setState({ followers: responseJson.data });
          console.log(this.state.followers, "folllllll")
        }
      } else {
        this.parseApiErrorResponse(responseJson);
      }

      if (apiRequestCallId === this.deletePostApiCallId) {
        this.getHomeFeedPosts();
      }

      //newsSubscribe
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.postNewsSubscribeCallID) {
          this.setState({ newsubscribe: true });
          console.log(responseJson.message, "newsdata")
        }
      } else {
        this.parseApiErrorResponse(responseJson);
      }

      //newsUnSubscribe
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.postNewsUnSubscribeCallID) {
          this.setState({ newsubscribe: false });
          console.log(responseJson.message, "newsdata")
        }
      } else {
        this.parseApiErrorResponse(responseJson);
      }

      //getsinglePostforshare


      //getPostByIdForShare
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.getPostByIdForshareMethodTypeCallID) {
          this.setState({
            getPostBYIdForShare: responseJson.data
          })
          console.log(responseJson.data, "getpostbyId")
        }
      } else {
        this.parseApiErrorResponse(responseJson);
      }

      //get Edit data 
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.geteditFeedPostApiCallId) {
          this.setState({
            editData: responseJson.data, isloader: false,
            address: responseJson.data?.attributes?.location
          })
          this.setState({
            createPostDescription:
              EditorState.createWithContent(ContentState.createFromText(responseJson.data?.attributes?.body))
          });
          this.setState({ tagged_connection_ids: [] });
          this.setState({ AllMedia: responseJson.data?.attributes?.media });
        }
      } else {
        this.parseApiErrorResponse(responseJson);
      }

      //    console.log(this.state.tagged_connection_ids,"tagged_connection_ids")

      // get ratingReceived res
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.getRatingReceivedApiCallId) {
          this.setState({ ratingReceives: responseJson.data });
          console.log(this.state.ratingReceives, "ratingggggg")
        }
      } else {
        this.parseApiErrorResponse(responseJson);
      }

      // get ratingSend res
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.getRatingSendApiCallId) {
          this.setState({ ratingSends: responseJson.data });
        }
      } else {
        this.parseApiErrorResponse(responseJson);
      }

      // get work anniversies
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.upcomingWorkAnniversariesApiCallId) {
          this.setState({ upcomingWorkAnnivers: responseJson });
          console.log(this.state.upcomingWorkAnnivers, "anniverserissss")
        }
      } else {
        this.parseApiErrorResponse(responseJson);
      }

    } else if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let requesterId = message.getData(
        getName(MessageEnum.SessionRequestedBy)
      );
    }

    // Customizable Area End
  }

  // Customizable Area Start

  getDomainSkills(userDataHere: any) {
    if (
      isEmpty(userDataHere) ||
      isEmpty(userDataHere.attributes) ||
      isEmpty(userDataHere.attributes.domain_skills)
    )
      return "";
    const domainSkillsPresent = userDataHere.attributes.domain_skills;
    const domainSkillsStr = domainSkillsPresent.join(", ");
    console.log("domainSkillsStr = ", domainSkillsStr);
    return domainSkillsStr;
  }

  getHomeFeedPosts = async () => {
    this.setState({ isloader: true });
    // const token = await getStorageData("token");
    // token= localStorage.getItem("token") ? localStorage.getItem("token"):"",
    const header = {
      "Content-Type": configJSON.getPostsApiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getHomeFeedPostsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getUserPostsApiEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getPostsApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  // edit api
  getFeedPost = async (id: number) => {
    this.setState({ isloader: true });
    this.setState({
      AllMedia: [], createPostImages: [], videoArrayList: [],
      createPostDescription: EditorState.createEmpty(),
      isCreatePostEnable: false,
      isCreatePostVisible: false, tagged_connection_ids: [],
      // isloadEditPost: true,
      deleteExistingMedias: []
    });
    // const token = await getStorageData("token");
    // token= localStorage.getItem("token") ? localStorage.getItem("token"):"",
    const header = {
      "Content-Type": configJSON.getPostsApiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.geteditFeedPostApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      // configJSON.getUserPostsApiEndpoint
      `${configJSON.getPostsApiEndpoint}/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getPostsApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  createPost = async () => {
    this.setState({ isloader: true });
    // const token = await getStorageData("token");
    // token= localStorage.getItem("token") ? localStorage.getItem("token"):"",
    const header = {
      "Content-Type": configJSON.getPostsApiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getHomeFeedPostsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPostsApiEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getPostsApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getBase64(img: any, callback: any) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }
  beforeUpload(file: any) {
    console.log("filetype", file);
    const isJpgOrPng =
      file.type.slice(0, 5) == "image" ||
      file.type.slice(0, 5) == "video";

    let isLt2M = false;
    if (file.type.slice(0, 5) == "image") {
      isLt2M = file.size / 1024 / 1024 < 5;
      if (!isLt2M) {
        message.error('Image must smaller than 5MB!');
      }
    } else if (file.type.slice(0, 5) == "video") {
      isLt2M = file.size / 1024 / 1024 < 10;
      if (!isLt2M) {
        message.error('Video must smaller than 10MB!');
      }
    }

    this.getBase64(file, (imageUrl: string) => {
      let ImageArrayList = [...this.state.createPostImages];
      let VideoArray = [...this.state.videoArrayList];
      let AllMedia = [...this.state.AllMedia]
      if (isLt2M && (file.type.slice(0, 5) == "video" || file.type.slice(0, 5) == "image")) {

        AllMedia.push({
          filePath: imageUrl,
          // filePath: "data:image/jpeg;base64," + info.file.originFileObj,
          uri: imageUrl,
          type: file.type,
          name: file.name,
          data: file,
        });
      }
      if (file.type.slice(0, 5) == "video" && isLt2M) {
        VideoArray.push({
          filePath: imageUrl,
          // filePath: "data:image/jpeg;base64," + info.file.originFileObj,
          uri: imageUrl,
          type: file.type,
          name: file.name,
          data: file,
        });
      } else if (file.type.slice(0, 5) == "image" && isLt2M) {
        ImageArrayList.push({
          filePath: imageUrl,
          // filePath: "data:image/jpeg;base64," + info.file.originFileObj,
          uri: imageUrl,
          type: file.type,
          name: file.name,
          data: file,
        });
      } else {
        message.error("invalid file type!!!");
      }

      this.setState({
        createPostImages: ImageArrayList,
        videoArrayList: VideoArray,
        AllMedia,
        loading: false,
      });
      console.log(this.state.createPostImages);
    });

    return true;
  }

  handleImageChange = (info: any) => {
    console.log(info);
  };
  deleteItem = (index: any) => {
    let deleteExistingMedias: any = cloneDeep(this.state.deleteExistingMedias);
    this.state.AllMedia.forEach((item: any, indexHere: any) => {
      if (index === indexHere && item.blob_id) {
        deleteExistingMedias.push(item);
      }
    });

    console.log("Deleting: ", index);
    let clonedArr = [...this.state.AllMedia].filter(
      (el: any, i: number) => index !== i
    );
    this.setState({ AllMedia: clonedArr, deleteExistingMedias });
  };

  onRemoveImage = (index: number) => {
    let imageData = this.state.createPostImages.filter((item: any, id: number) => {
      return id !== index;
    })
    let videoData = this.state.videoArrayList.filter((item: any, id: number) => {
      return id !== index;
    })
    let data = this.state.AllMedia;
    data.splice(index, 1);
    this.setState({
      AllMedia: data,
      createPostImages: imageData,
      videoArrayList: videoData
    });
  };
  // edit post function
  handleEditSubmit = async (id: any) => {
    console.log("value", id)
    this.setState({ editPostOpen: false })
    const header = {
      // "Content-Type": configJSON.createPostApiContentType,
      // "Content-Type": 'multipart/form-data; boundary=----WebKitFormBoundaryqTqJIxvkWFYqvP5s',
      token: this.state.token,
    };

    let data = {
      status: "pending",
    };
    console.log("header: ", header);
    console.log("description: ", this.state.createPostDescription);
    let trimmedStr = this.state.editortext.trim();

    var form = new FormData();
    console.log("trim", typeof trimmedStr);

    await form.append("body", trimmedStr?.toString());
    console.log("FormData2-->", form);

    // formdata.append("name", 'ABC');
    console.log("FormData3-->", form.get("body"));
    if (this.state.fullAddress && this.state.fullAddress !== "") {
      await form.append("location", this.state.fullAddress);
    }
    if (this.state.tagged_connection_ids.length > 0) {
      const uniqueTaggedIds = Array.from(
        new Set(this.state.tagged_connection_ids)
      );
      uniqueTaggedIds.forEach((taggedId: any) => {
        form.append("taggings_attributes[]account_id", taggedId);
      });
      console.log(uniqueTaggedIds, "uniqueTaggedIds")
    }

    if (this.state.AllMedia.length > 0) {
      this.state.AllMedia.forEach((item: any) => {
        if (item.hasOwnProperty("blob_id")) delete item.blob_id;
        if (item.data) form.append("media[]", item.data);
        if (item.content_type && item.uri) form.append("media[]", item);
      });
    }

    if (this.state.deleteExistingMedias.length > 0) {
      this.state.deleteExistingMedias.forEach((item: any) => {
        if (item.blob_id) form.append("blob_ids[]", item.blob_id);
      });
    }

    // const httpBody = {
    //   data: data,
    // };
    var options = { content: form };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.editPostID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.postendpoint}/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      form
      // {"_parts": [["body", "Parth"]]}
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PUTMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    console.log("Getconnection--->" + JSON.stringify(requestMessage));
    // return true;
  };

  handleSubmit = async () => {
    // console.log("value",value)

    const header = {
      token: this.state.token,
    };

    let data = {
      status: "pending",
    };
    console.log("header: ", header);
    console.log("description", this.state.editortext);
    let trimmedStr = this.state.editortext.trim();

    var form = new FormData();
    console.log("trim", typeof trimmedStr);
    if (trimmedStr.length > 0) {
      this.handlecreatePostOpenCancel();
      await form.append("body", trimmedStr.toString());
      console.log("FormData2-->", form);

      console.log("FormData3-->", form.get("body"));
      if (this.state.address && this.state.address !== "") {
        await form.append("location", this.state.address);
      }
      if (this.state.tagged_connection_ids.length > 0) {
        const uniqueTaggedIds = Array.from(
          new Set(this.state.tagged_connection_ids)
        );
        uniqueTaggedIds.forEach((taggedId: any) => {
          form.append("taggings_attributes[]account_id", taggedId);
        });
        console.log(uniqueTaggedIds, "uniqueTaggedIds")
      }

      if (this.state.createPostImages.length > 0) {
        this.state.createPostImages.forEach((item: any) => {
          form.append("media[]", item.data);
        });
      }
      if (this.state.videoArrayList.length > 0) {
        this.state.videoArrayList.forEach((item: any) => {
          form.append("media[]", item.data);
        });
      }

      // const httpBody = {
      //   data: data,
      // };
      var options = { content: form };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.PostID = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.postendpoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        header
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        form
        // {"_parts": [["body", "Parth"]]}
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.exampleAPiMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      console.log("Getconnection--->" + JSON.stringify(requestMessage));
      // return true;
    } else {
      this.setState({
        bodyText: true
      })
    }
  };

  //NewsletterSubscribe
  NewsletterSubscribe = async (value: String) => {
    console.log(value, "value")
    this.setState({ isloader: true });
    const token = this.state.token;
    const header = {
      "Content-Type": configJSON.getPostsApiContentType,
      token: token,
    };
    let body = {
      data: {
        email: value
      },
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.postNewsSubscribeCallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.postNewsSubscribe}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postNewsSubscribeMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  NewsletterUnSubscribe = async () => {
    this.setState({ isloader: true });
    const token = this.state.token;
    const header = {
      "Content-Type": configJSON.getPostsApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.postNewsUnSubscribeCallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.postNewsUnSubscribe}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postNewsUnSubscribeMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  //get post by id for share
  getPostByIDForShare = async (id: any) => {
    this.setState({ isloader: true });
    const token = this.state.token;
    const header = {
      "Content-Type": configJSON.getPostsApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getPostByIdForshareMethodTypeCallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getUserPostsApiEndpoint}/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getPostsApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  updateShareCount = async (id:number) => {
    this.setState({ isloader: true });
    const token = this.state.token;
    const header = {
      "Content-Type": configJSON.getPostsApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.shareCountMethodTypeCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateShareCountApiEndPoint}${id}/share_post`
      
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  likePost = async (id: number) => {
    this.setState({ isloader: true });
    const token = this.state.token;
    const header = {
      "Content-Type": configJSON.getPostsApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getPostsApiEndpoint}/${id}/like`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.likeDislikeApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  dislikePost = async (id: number) => {
    this.setState({ isloader: true });
    const token = await this.state.token;
    const header = {
      "Content-Type": configJSON.getPostsApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getPostsApiEndpoint}/${id}/dislike`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.likeDislikeApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  toggleLike = (id: number, liked: boolean) => {
    if (liked) {
      console.log("Unlike");
      this.setState({
        likedItems: this.state.likedItems.filter(function (el) {
          return el !== id;
        }),
      });
      this.dislikePost(id);
    } else {
      this.setState((prevState) => ({
        likedItems: [...prevState.likedItems, id],
      }));
      this.likePost(id);
    }
  };

  toggleCommentLike = (id: number, liked: boolean, itemId?: any) => {
    if (liked) {
      this.dislikedComment(id, itemId);
    } else {
      this.likedComment(id, itemId);
    }
  };
  onClickFollow = async (id: number, isFollowed: boolean) => {
    // console.log(id,isFollowed,'follow fun')
    try {
      const response = await axios({
        url:
          `${baseURL}/bx_block_follow/follows?account_id=${id}`,
        method: "POST",
        headers: {
          token: await localStorage.getItem('token'),
          "Content-Type": "application/json"
        }
      });
      if (isFollowed) {
        this.setState({
          // postLike: false
          followedItems: this.state.followedItems.filter(function (el) {
            return el !== id;
          }),
        });
      } else {
        this.setState((prevState) => ({
          followedItems: [...prevState.followedItems, id],
        }));
      }
      // setFollow(!isFollow);
      // window.location.reload();
      console.log(response, "following post");
    } catch (error) {
      console.error(error, 'error while calling api follow REQUEST');
    }
  };

  addComment = (id: number, comment: any) => {
    this.setState({ loadComments: true });
    const token = this.state.token;
    const header = {
      "Content-Type": configJSON.getPostsApiContentType,
      token: token,
    };
    let body = {
      data: {
        attributes: {
          post_id: id,
          comment: comment,
          // "taggings_attributes": [
          //     {
          //         "account_id": 2
          //     }
          // ]
        },
      },
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addCommentInPost = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createComment}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  addReplyComment = (id: number, comment: any) => {
    this.setState({ loadComments: true });
    const token = this.state.token;
    const header = {
      "Content-Type": configJSON.getPostsApiContentType,
      token: token,
    };
    let body = {
      data: {
        attributes: {
          comment_id: id,
          comment: comment,
        },
      },
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addReplyCommentInPost = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createComment}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getComments = (id: number) => {
    this.setState({ loadComments: true });
    const token = this.state.token;
    const header = {
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCommentInPostApiCallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createComment}?post_id=${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getPostsApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  likedComment = async (id: number, itemId?: any) => {
    // this.setState({ isloader: true });
    const token = await this.state.token;
    const header = {
      // "Content-Type": configJSON.getPostsApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createComment}/${id}/like`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.likeDislikeApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.getComments(itemId);
    return true;
  };
  dislikedComment = (id: number, itemId?: any) => {
    // this.setState({ isloader: true });
    const token = this.state.token;
    const header = {
      // "Content-Type": configJSON.getPostsApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createComment}/${id}/dislike`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.likeDislikeApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.getComments(itemId);
    return true;
  };
  handleInvitations = () => {
    const header = {
      "Content-Type": "application/json",
      token: this.state.token,
    };
    const json_data = {
      status: "pending",
      page: "1",
      per_page: "10",
    };
    console.log("json_data....");
    const httpBody = json_data;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getPendingInvitationsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getNetworkEndPoint + "?status=pending"
      // configJSON.getNetworkEndPoint+ "?status=pending"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      // JSON.stringify(header)
      header
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getNetworkApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // console.log(
    //   "Getconnection Invitations--->" + JSON.stringify(requestMessage)
    // );
    return true;
  };

  getKnownPeople = () => {
    const header = {
      token: this.state.token,
    };

    console.log("getKnownPeople");
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getKnownPeopleApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getKnownPeopleEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getNetworkApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // console.log(
    //   "Getconnection Invitations--->" + JSON.stringify(requestMessage)
    // );
  };

  handleMenuClick(e: any, id: any) {
    console.log("click", e);
    if (e.key === "save") {
      // setDropDownVisible(true);
      this.savePost(id, "save");
    }
    if (e.key === "hide") {
      this.savePost(id, "hide");
    }
    if (e.key === "report") {
      // setDropDownVisible(true);
      this.setState({ isReportIssueVisible: true, reportPostID: id });
    }
  }

  handlePostList = (data: any, status: string) => {
    const postData = this.state.postList;
    if (status === "update") {
      console.log(status, data);
      const updatePostList = postData?.map((item: any) => {
        if (item.id === data.id) {
          return data
        } else {
          return item
        }
      })
      this.setState({ postList: updatePostList });
    } else if (status === "delete") {
      const updatePostList = postData?.filter((item: any) => {
        return item.id != data?.id
      })
      this.setState({ postList: updatePostList });
    }
  }

  handleOk = async (e: any) => {
    console.log(e);
    await this.savePost(this.state.reportPostID, "report");
    this.setState({ isReportIssueVisible: false, reportPostID: "" });
    // setDropDownVisible(false);
  };

  handleCancel = (e: any) => {
    console.log(e);
    this.setState({ isReportIssueVisible: false, reportPostID: "" });
  };
  onChangeValue = (e: any) => {
    console.log("radio checked", e.target.value);
    this.setState({ value: e.target.value });
  };
  savePost = async (id: number, endString: string) => {
    // this.setState({ isloader: true });
    const token = this.state.token;
    const header = {
      "Content-Type": configJSON.getPostsApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getPostsApiEndpoint}/${id}/${endString}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  ratingOnchange = (e: any) => {
    console.log('radio checked', e.target.value);
    this.setState({
      shiftRatings: e.target.value,
    });
  };

  goToUpgrade = () => {
    if (this.state.data?.account_plan === "premium") {
      //  useHistory().
      this.props.history.push({ pathname: '/UpgradeAccount' })
    } else {
      this.props.history.push({ pathname: '/Premium/Account' })
    }
  }

  // delete post 
  deletePost = async (id: any) => {
    const header = {
      "Content-Type": configJSON.getPostsApiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deletePostApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deletePostEndPoint}/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.DELETEMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  // Customizable Area End
}