Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start

exports.GETMethod = "GET";
exports.POSTMethod = "POST";
exports.PUTMethod = "PUT";
exports.DELETEMethod = "DELETE";
exports.PATCHMethod = "PATCH"
exports.APPLICATIONJSONContentType = "application/json";
exports.MULTIPARTFormData = "multipart/form-data";

exports.getSettingsApiEndpoint = "account_block/settings";
exports.updateSettingsApiEndpoint = "account_block/settings";
exports.createContactUsApiEndpoint = "account_block/issues";
exports.getPoliciesApiEndpoint = "account_block/policies";
exports.removeAccountApiEndpoint = "account_block/accounts/remove_account";
exports.suspendDeleteConfirmApiEndpoint = "account_block/accounts/validate";
exports.signOutAPIEndPoint = "account_block/accounts/signout";
exports.blockedAccountsApiEndpoint = "account_block/accounts/blocked_list";
exports.unblockUserApiEndpoint = "account_block/accounts/";
exports.getSettingsApiContentType = "application/json";
exports.purchaseHistoryApiEndpoint= "purchase_histroy"
// Customizable Area End
