import React from "react";
import PostRatingController, { Props } from "./PostRatingController.web";
import { uparrow, free_ac, bluepoint, bgblue, orengepoint, pre_ac } from "./../assets";
import CommonListItem from "./../commonListItem.web";
import "./../userFeed.css";
import moment from "moment";
import {
  Card,
  Row,
  Col,
  List,
  Input,
  Typography,
  Avatar,
  Button,
  Upload,
  Image,
  Badge,
  Radio,
  Tabs,
  Space,
  Modal,
} from "antd";
// import CustomHeader from "../CustomHeader.web";
const { Title, Text } = Typography;

export default class PremiunAccount extends PostRatingController {
  render() {
    const { navigation } = this.props;
    const personalDescription = [
      [
        {description: "Posted pictures and videos will appear on the user page for a period of 30 days."},
        {description: "All Ratings and reviews will be viewable for a period of 1 year."},
        {description: "Basic analytics on the ratings."},
        {description: "Share your posts and other users' posts on external social media."},
        {description: "Connect with another user and rate them."},
        {description: "Connect with business professionals and businesses and message them privately."},
        {description: "Create Communities and manage them."}
      ],
      [
        {description: "Includes all features of Basic Plan."},
        {description: "All ratings and reviews shall be made available beyond 365 days."},
        {description: "Posted pictures and videos will appear on the user page for a period of 365 days."},
        {description: "Create Personalized Questionnaire for each category."},
        {description: "Advanced analytics on the ratings."}
      ]
    ]
    const businessDescription = [
      [
        {description : "Posted pictures and videos will appear on the user page for a period of 30 days."},
        {description : "All Ratings and reviews will be viewable for a period 1 year."},
        {description : "Basic analytics on the ratings."},
        {description : "Connect with different business and personnel."}
        ],
        [
        {description : "Includes all features of Basic Plan Posted pictures and videos will appear on the user page for a period of 365 days."},
        {description : "All ratings and reviews shall be made available beyond 365 days."},
        {description : "Create Personalized Questionnaire for each category."},
        {description : "Advanced analytics on the ratings."},
        {description : "Connect with different business and personnel."},
        {description : "Create Job Postings/Long to job postings on user’s business career page."}
        ]
    ]
    const RenderDescriptions = ({ mainItem,descriptions }: any) => (
      <Col style={{ padding: 18 }}>
        {descriptions.map((item: any, idx: any) => (
          <div className="pre_row" key={idx}>
            <Avatar
              // size={14}
              src={mainItem.attributes.subscription_type === "free" ? bluepoint : orengepoint}
              style={{ marginTop: 2, width:"14px", height:"14px" }}
            />
            <Text className="pre_point">
              {item.description}
            </Text>
          </div>
        ))}
      </Col>
    );
    return (
      <div className="backgroun" style={{ backgroundColor: "#f6f6f6" }}>
        {this.redirectUser()}
        {/* <CustomHeader {...this.props} dotVisible={false} /> */}
        <div>
          <Row style={{ paddingTop: "15px", marginBottom: 7, marginLeft: 20 }}>
            <Text
              className="textlabel_subheadingText"
            >
              My Profile /
            </Text>
            <Text
              className="textlabel_subheadingText2"
            >
              {" "}
              Premium Account
            </Text>
          </Row>
          <Col style={{ marginLeft: "20px", marginTop: "10px" }}>
            <Text
              className="textlabel_headingText"
            >
              Premium Account
            </Text>
            <br />
            <Text
              className="pre_cap"
            >
              Select a plan that works for you
            </Text>
          </Col>
          <div
            className="pre_main"
            style={{
              flexDirection:"row",
             }}
          >
          
          {this.state.getSubscribealldata.map((item:any)=>(
          
            <>
            {item.attributes.subscription_type === "free" && item.attributes.name.toLowerCase().includes(this.state.accountDetails?.attributes?.account?.data?.attributes?.role) &&
            <div
              className="pre_consept"
            >
              <Col
                className="Pre_top"
              >
                <Avatar
                  size={60}
                  src={free_ac}
                />
                <Text className="textlabel_UpgradetoPremiumText3">{item.attributes.name}</Text>
                <Text
                  className="textlabel_UpgradetoPremiumText4"
                >
                  {item.attributes.subscription_type}
                </Text>
              </Col>
              <RenderDescriptions mainItem={item} descriptions={this.state.accountDetails?.attributes?.account?.data?.attributes?.role === "personal" ? personalDescription[0] : businessDescription[0]} />
            </div>}
            {/*  */}
            {item.attributes.subscription_type === "premium" && item.attributes.name.toLowerCase().includes(this.state.accountDetails?.attributes?.account?.data?.attributes?.role) &&
            <div
              className="pre_consept"
              style={{
                marginLeft: "20px",
                backgroundColor: "#fff",
              }}
            >
              <Col
                className="Pre_top"
              >
                <Avatar
                  size={60}
                  src={pre_ac}
                />
                <Text className="textlabel_UpgradetoPremiumText3">{item.attributes.name}</Text>
                <Text
                  className="attrbuted_textlabel_UpgradetoPremiumText3"
                >
                  ${item.attributes.yearly_plan} <span className="attrbuted_textlabel_UpgradetoPremiumText4">/ Year</span>
                </Text>
                {/* <Text
                  className="attrbuted_textlabel_UpgradetoPremiumText4"
                >
                  /Year
                </Text> */}
              </Col>
              <RenderDescriptions mainItem={item} descriptions={this.state.accountDetails?.attributes?.account?.data?.attributes?.role === "personal" ? personalDescription[1]:businessDescription[1]} />
              <Col style={{ marginTop: 'auto', marginBottom: 15 }}>
                <Button
                  data-testid="select-plan-btn"
                  className="pre_plan_btn login_btn_colors__kpBfq"
                  onClick={() => {
                    const selectedPlan = {
                      name: item.attributes.name,
                      yearlyPlan: item.attributes.yearly_plan,
                      id : item.id,
                      description : this.state.accountDetails?.attributes?.account?.data?.attributes?.role === "personal" ? personalDescription[1]:businessDescription[1]
                    };
                    this.props.history.push({
                      pathname: '/checkout',
                      state: { plan: selectedPlan } 
                    });
                  }}
                >
                  Select Plan
                </Button>
              </Col>
            </div>}</>
          ))}
          </div>
        </div>
      </div>

    );
  }
}