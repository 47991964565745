import mixpanel from 'mixpanel-browser';

mixpanel.init('a750651207a35123ddc2e1fa371ace6e');

const Mixpanel = {
    track: (name: string, props?: Record<string, any>) => {
        mixpanel.track(name, props);
    },
    identify: (id: string) => {
        mixpanel.identify(id);
    },
    people: {
        set: (props: Record<string, any>) => {
            mixpanel.people.set(props);
        },
    },
};

export default Mixpanel;
