import React from "react";
import "../communityManage.css";
import {
    Row,
    Col,
    Typography,
    List,
    Breadcrumb,
    Button, Avatar,
    Empty,
    Spin, Divider,
    Menu
} from "antd";
import CommunitySettingsController, { Props } from "./CommunitySettingsController.web";
import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';
import DeleteCommunity from "../../../Groups/src/DeleteCommunity.web";
import CommunityAlert from "../CommunityAlert.web";
const { Text } = Typography;

const PurpleSwitch = withStyles({
    switchBase: {
        '&$checked': {
            color: '#4080c0',
        },
        '&$checked + $track': {
            backgroundColor: '#4080c0',
        },
    },
    checked: {},
    track: {},
})(Switch);

export default class CommunitySettings extends CommunitySettingsController {
    // Customizable Area Start
    // Customizable Area End
    render() {
        return (
            <div
                className="searchStyles"
                style={{ background: "#f6f6f6", minHeight: "100vh" }}
            >
                {this.redirectUser()}
                <div style={{ padding: '1rem' }}>
                    <Breadcrumb data-testid="breadcrumb">
                        <Breadcrumb.Item>Community</Breadcrumb.Item>
                        <Breadcrumb.Item data-testid="community-name" className="anchor_element" onClick={this.handleCommunity}>
                            <a>{this.state.communityData?.name}</a>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item className="follow-label">Community Settings</Breadcrumb.Item>
                    </Breadcrumb>
                    <Row>
                        <Col style={{ padding: '16px' }} span={6}
                            xs={24}
                            sm={24}
                            md={10}
                            lg={8}
                            xl={6}
                            xxl={6}>
                            <h2 style={{ color: '#434343' }}>Community Settings </h2>
                            <Menu
                                theme="light"
                                style={{
                                    width: "100%",
                                    color: "rgba(151, 151, 151, 1)",
                                    borderRadius: "5px",
                                    overflow: "hidden"
                                }}
                                data-testid="menu-test"
                                mode="inline"
                            >
                                {
                                    this.data.map((item: any, id: number) => {
                                        return (
                                            <Menu.Item
                                                key={item.id}
                                                className="customclass"
                                                style={{
                                                    width: "100%",
                                                    height: "3rem",
                                                    borderBottom: id + 1 < this.data.length ? "1px solid #ededed" : "none", display: "flex",
                                                    flexDirection: "row",
                                                    alignItems: "center"
                                                }}
                                                onClick={() => this.onItemPress(item.id)}
                                            >
                                                <img
                                                    src={item.icon}
                                                    style={{
                                                        width: "20px",
                                                        height: '20px',
                                                        marginRight: "10px"
                                                    }}
                                                />
                                                <span style={{ fontWeight: 500, fontSize: 15 }}>{item.title}</span>
                                            </Menu.Item>
                                        )
                                    })
                                }
                            </Menu>
                        </Col>
                        <Col
                            style={{ padding: "16px", }}
                            xs={24}
                            sm={24}
                            md={24}
                            lg={14}
                            xl={14}
                            xxl={14}>
                            {this.state.initialActiveNav === 1 && (
                                <div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between'
                                        }}
                                        data-testid="save-button"
                                    >
                                        <h2 style={{ color: '#434343' }}>Manage Requests</h2>
                                        <Button
                                            shape="round"
                                            style={{
                                                border: "1px solid #00698f",
                                                color: "#00698f",
                                                marginBottom: "5px",
                                                width: "5rem",
                                                height: "1.8rem",
                                                padding: "1px"
                                            }}
                                            onClick={() => this.editManageRequestFlags(this.state.communityData.id)}
                                        >
                                            Save
                                        </Button>
                                    </div>
                                    <div style={{ background: '#fff' }}>
                                        {this.state.isloading ? (
                                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                <Spin />
                                            </div>
                                        ) : (
                                            <div data-testid="list-item-test">
                                                <List.Item
                                                    data-testid="post-swicth-listItem"
                                                    actions={[<div style={{ position: 'relative' }}>
                                                        <PurpleSwitch
                                                            data-testid="post-switch"
                                                            defaultChecked={this.state.manageRequests.post_request}
                                                            onChange={(e) => this.handleSwitchChange(e.target.checked, "post_request")}
                                                        />
                                                    </div>]
                                                    }
                                                >
                                                    <List.Item.Meta
                                                        style={{ paddingLeft: '1rem', color: '# ' }}
                                                        title={<span style={{ color: '#6a6a6a' }}>Post Requests</span>}
                                                        description={'You can decide what post will get posted or rejected'}
                                                    />
                                                </List.Item>
                                                <Divider style={{ margin: '5px' }} />
                                                <List.Item
                                                    actions={[<div style={{ position: 'relative' }}>
                                                        <PurpleSwitch
                                                            data-testid="join-switch-test"
                                                            defaultChecked={this.state.manageRequests.join_request}
                                                            onChange={(e) => this.handleSwitchChange(e.target.checked, "join_request")}
                                                        />
                                                    </div>]
                                                    }
                                                >
                                                    <List.Item.Meta
                                                        style={{ paddingLeft: '1rem', color: '#6a6a6a' }}
                                                        title={<span style={{ color: '#6a6a6a' }}>Joining Request</span>}
                                                        description={'You can decide who can join community'}
                                                    />
                                                </List.Item>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}

                            {this.state.initialActiveNav === 2 && (
                                <div>
                                    <div><h2 style={{ color: '#434343' }}>Blocked Acounts</h2></div>
                                    <div style={{ background: '#fff' }}>
                                        {this.state.blockedAccounts?.length > 0 ? (
                                            <List
                                                itemLayout="horizontal"
                                                style={{ background: '#fff', cursor: 'pointer' }}
                                                dataSource={this.state.blockedAccounts}
                                                renderItem={(item: any, index: number) => (
                                                    <List.Item
                                                        data-testid={`ListItem${index + 1}`}
                                                        actions={[<Button
                                                            className="settings-button"
                                                            data-testid="unblock-item"
                                                            key="report"
                                                            onClick={() => this.setState({
                                                                updateBlockUser: item.id,
                                                                isUnblockVisible: true,
                                                                allCommunity: item.attributes.full_name
                                                            })}
                                                        >
                                                            Unblock
                                                        </Button>]}
                                                    >
                                                        <List.Item.Meta
                                                            style={{ paddingLeft: '1rem', color: '#6a6a6a' }}
                                                            avatar={
                                                                <Avatar
                                                                    src={item?.attributes?.photo}
                                                                    style={{ backgroundColor: "lightgrey" }}
                                                                />
                                                            }
                                                            title={<span style={{ color: '#6a6a6a' }}>{item.attributes.full_name}</span>}
                                                        />
                                                    </List.Item>
                                                )}
                                            />
                                        ) : (
                                            <Empty
                                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                                                data-testid="blocked-empty"
                                                style={{
                                                    backgroundColor: "white",
                                                    padding: "100px",
                                                    textAlign: "center",
                                                    marginTop: 0
                                                }}
                                                description={"No Blocked Accounts on this community page"}
                                            />
                                        )}
                                    </div>
                                </div>
                            )}

                            {this.state.initialActiveNav === 3 && (
                                <div>
                                    <div><h2 style={{ color: '#434343' }}>Hidden Posts </h2></div>
                                    <div style={{ background: '#fff' }}>
                                        {this.state.hiddenPosts?.length > 0 ? (
                                            <List
                                                itemLayout="horizontal"
                                                style={{ background: '#fff', cursor: 'pointer' }}
                                                dataSource={this.state.hiddenPosts}
                                                renderItem={(item: any, index: number) => (
                                                    <List.Item
                                                        data-testid={`hiddenListItem${index + 1}`}
                                                        actions={[<Button
                                                            size="large"
                                                            shape="round"
                                                            data-testid="addInCommunityButton"
                                                            className="follow-outline"
                                                            key="report"
                                                            onClick={() => this.add_to_community(item.id)}
                                                            style={{ width: "100%" }}
                                                        >
                                                            <Text style={{ fontSize: 16, color: "#005574" }}>Add to Community</Text>
                                                        </Button>]}
                                                    >
                                                        <List.Item.Meta
                                                            style={{ paddingLeft: '1rem', color: '#6a6a6a' }}
                                                            avatar={
                                                                <Avatar
                                                                    src={item?.attributes?.account?.data?.attributes?.photo}
                                                                    style={{ backgroundColor: "lightgrey" }}
                                                                />}
                                                            title={<span style={{ color: '#6a6a6a' }}>{item?.attributes?.account?.data?.attributes?.first_name}
                                                                &nbsp;<span style={{ color: 'rgba(0, 0, 0, 0.45)' }}> posted, "{item?.attributes?.body}"</span></span>}
                                                            description={this.getDifference(item?.attributes?.updated_at)}
                                                        />
                                                    </List.Item>
                                                )}
                                            />
                                        ) : (
                                            <Empty
                                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                                                data-testid="hidden-empty"
                                                style={{
                                                    backgroundColor: "white",
                                                    padding: "100px",
                                                    textAlign: "center",
                                                    marginTop: 0
                                                }}
                                                description={"No Hidden Posts on this community page"}
                                            />
                                        )}
                                    </div>
                                </div>
                            )}

                            {this.state.initialActiveNav === 4 && (

                                <div data-testid="delete-menu-tab">
                                    <DeleteCommunity
                                        communityId={this.state.communityData.id}
                                        history={this.props.history}
                                    />
                                </div>
                            )}

                        </Col>
                    </Row>
                </div>
                {this.renderUnBlockModal()}
                <CommunityAlert
                  modal={this.state.isModel}
                  modalStatus={this.state.isModel}
                  handleCancel={()=>{this.setState({isModel:false})}}
                  heading={"Manage request updated successfully"}
                  desc={"Your request for updating the Manage request updated successfully"}
                />
            </div>
        );
    }
}
// Customizable Area Start

// Customizable Area End
