import React from "react";
import {
    Typography,
    Select,
    Modal,
    Spin,
    Row,
    Button,
    Empty
} from "antd";
import MyNetworkController, { Props } from "./myNetworkController.web";
import "./network.css";
// import CustomHeader from "../../../CommunityForum/src/CustomHeader.web";
import { Grid } from "@material-ui/core";
import NetworkComponent from "./componant/NetworkComponent.web";
import { successModel } from "../../../user-profile-basic/src/personal/OtherPersonalProfile.web";
import CommunityAlert from "../../../CommunityForum/src/CommunityAlert.web";
const { Text } = Typography;

export default class MyNetworkDetail extends MyNetworkController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start   
        this.getNetworkDetails();
        // Customizable Area End
    }

    // Customizable Area Start
    loadMorehandle = (prevState: number) => {
        this.setState({ pageNumber: prevState + 1 })
    }

    onCancleModel = () => {
        return this.setState({ modelStatus: false })
    }

    shortConditon = (condition: boolean, truStatement: string, falseStatement: string) => {
        return condition ? truStatement : falseStatement
    }

    pageHeader = (pageName: string, type: string) => {
        if (pageName == "invitation") {
            return "Invitations"
        } else if (pageName == "network") {
            return "My Network "
        } else if (type === "business") {
            return "Business You May Know"
        } else {
            return "People You May Know";
        }
    }

    render() {
        const pageName = this.props.history.location.pathname.split("/")[2];
        const pageHeader = this.pageHeader(pageName, this.state.type);
        const componentType = pageName == "invitation" ? "invitation" : pageName == "network" ? "My Network " : "you_may_know";
        return (
            <div style={{ background: "#f6f6f6" }}>
                {this.redirectUser()}
                <Grid container style={{ padding: "10px 15px" }}>
                    <Grid item style={{ fontSize: "20px", fontWeight: "bold", padding: "10px 0px" }} xs={12}>{pageHeader}</Grid>
                    {console.log(this.state.netWorkDetailsData, "this.state.netWorkDetailsData")}
                    {this.state.pageLoader ? <Row style={{ alignItems: "center", padding: "25px" }}>
                        <Spin />

                    </Row> : this.state.netWorkDetailsData.length > 0 ? <Grid item xs={12}>
                        <Grid item xs={12}>
                            <Row gutter={[16, 16]}>

                                {this.state.netWorkDetailsData.length > 0 &&
                                    this.state.netWorkDetailsData.map((item: any, index: number) => <NetworkComponent
                                        key={`${index}you_may_know`}
                                        others={{ type: componentType }}
                                        showAcceptRequestModal={this.showAcceptRequestModal}
                                        data={item}
                                        acceptRequest={this.acceptRequest}
                                        acceptModel={this.state.sendRequestLoadData}
                                        cardid={this.state.cardid}
                                    />
                                    )}
                            </Row>
                            <Grid item style={{ fontSize: "16px", fontWeight: 600, textAlign: "end", padding: "10px 0px", display: "true" }} xs={6}>
                                {this.state.netWorkDetailsData.length >= 12 &&
                                    this.state.nextPage !== null && this.state.showLoadMoreVisible &&
                                    <span style={{ fontSize: "16px", color: "#005574", cursor: "pointer" }}
                                        onClick={() => this.loadMorehandle(this.state.pageNumber)}>{this.state.nextPageLoader ? <Spin /> : "Load More"}
                                    </span>}
                                {this.state.netWorkDetailsData.length >= 12 && this.state.nextPage == "" &&
                                    <span style={{ fontSize: "16px", color: "#005574", cursor: "pointer" }}
                                        onClick={() => this.loadMorehandle(this.state.pageNumber)}>
                                        {this.state.nextPageLoader ? <Spin /> : "Load More"}
                                        {console.log(this.state.netWorkDetailsData)}
                                    </span>}
                            </Grid>
                        </Grid>
                    </Grid> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}

                    <Modal
                        width={450}
                        style={{ borderWidth: "0px", borderRadius: "4px", overflow: "hidden" }}
                        bodyStyle={{ paddingTop: "5px" }}
                        centered
                        title={<div style={{ fontWeight: "bold", borderWidth: "0px", fontSize: "18px", paddingRight: '24px' }}>
                            {this.state.acceptRequestmodal?.title}
                        </div>}
                        visible={this.state.acceptRequestmodal?.show}
                        onCancel={this.closeAcceptRequestModal}
                        footer={null}
                    >
                        <Grid container style={{ padding: "0px" }}>
                            {(this.state.acceptRequestmodal?.type == "send" || this.state.acceptRequestmodal?.type == "people") ? "" : <Grid item xs={12} style={{ paddingBottom: "10px" }}>
                                <Text>Change Relationship to</Text>
                            </Grid>}
                            <Grid item xs={12}>
                                <Select
                                    style={{
                                        padding: "0 !important",
                                        border: "2px solid #a9bac8",
                                        borderRadius: '27px',
                                        overflow: "hidden",
                                        width: "100%"
                                    }}
                                    // placeholder="Select Relationship"
                                    bordered={true}
                                    value={this.state.relationinDropdown ? this.state.relationinDropdown : "Select Relationship"}
                                    onChange={this.changeRelation}
                                    dropdownStyle={{
                                        border: "2px solid #a9bac8",
                                        borderRadius: "10px",
                                        paddingTop: "0px",
                                        paddingBottom: "0px",
                                        width: "100%"
                                    }}
                                >
                                    {this.state.acceptRequestmodal?.selectDrop &&
                                        this.state.acceptRequestmodal?.selectDrop?.length === 0 ?
                                        <h4>Loading...</h4> :
                                        this.state.acceptRequestmodal?.selectDrop?.map((item: any, id: number) => {
                                            // console.log("item32232332====", item);
                                            return (
                                                <option key={item}
                                                    style={{
                                                        borderBottom: this.state.acceptRequestmodal?.selectDrop?.length - 1 > id ? "2px solid #a9bac8" : "none",
                                                        padding: "10px 20px 10px 20px",
                                                        color: "#a9bac8",
                                                    }}
                                                    value={item?.props?.value}
                                                >
                                                    {item?.props?.value}
                                                </option>
                                            );
                                        })}
                                    {/* {this.state.acceptRequestmodal?.selectDrop && this.state.acceptRequestmodal?.selectDrop} */}
                                </Select>
                                <p style={{ color: "red", marginLeft: 4 }}>{this.state.addConnectionError}</p>
                            </Grid>
                            <Grid item xs={12} style={{ paddingTop: "15px" }}>
                                {this.state.acceptRequestmodal?.type == "accept" ? <Grid container>
                                    <Grid xs={6}>
                                        <Button
                                            type="primary"
                                            shape="round"
                                            className="btn-accept-req-modal"
                                            onClick={() => this.acceptRequest({ type: "accepted", data: this.state.acceptRequestmodal?.data })}
                                        >
                                            Accept Request
                                        </Button>
                                    </Grid>
                                    <Grid xs={6} style={{ textAlign: "end" }}>
                                        <Button
                                            type="primary"
                                            shape="round"
                                            className="btn-reject-req-modal"
                                            danger
                                            onClick={() => this.acceptRequest({ type: "rejected", data: this.state.acceptRequestmodal?.data })}
                                        >
                                            Reject Request
                                        </Button>
                                    </Grid>
                                </Grid> : <Grid container>
                                    <Grid xs={6}>
                                        <Button
                                            type="primary"
                                            shape="round"
                                            className="btn-accept-req-modal"
                                            onClick={() => this.sendRequest()}
                                        >
                                            Send Request
                                        </Button>
                                    </Grid>
                                    <Grid xs={6} style={{ textAlign: 'end' }}>
                                        <Button
                                            type="primary"
                                            shape="round"
                                            className="btn-reject-req-modal"
                                            danger
                                            onClick={() => this.closeAcceptRequestModal()}
                                        >
                                            Cancel
                                        </Button>
                                    </Grid>
                                </Grid>}
                            </Grid>
                        </Grid>
                    </Modal>
                </Grid>
                {successModel(this.state.modelStatus, this.onCancleModel, this.state.modelData?.relation, this.state.modelData?.name)}
                <CommunityAlert
                    heading={this.shortConditon(this.state.responseHandler.status == "accepted", "Connection established successfully", "Connection rejected successfully")}
                    modalStatus={this.state.acceptModel}
                    desc={this.shortConditon(this.state.responseHandler.status == "accepted", `Your connection with ${this.state.responseHandler.full_name} as ${this.state.responseHandler.sender_relation} is established successfully`, `Your connection with ${this.state.responseHandler.full_name} as ${this.state.responseHandler.sender_relation} is rejected successfully`)}
                    modal={this.state.acceptModel}
                    handleCancel={() => { this.setState({ acceptModel: false }) }}
                />
            </div>
        );
    }
    // Customizable Area End
}