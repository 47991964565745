import React, { Component } from "react";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { message } from "antd";
export const configJSON = require("./config");
import { RouterProps } from "react-router";
export interface Props extends RouterProps {
  navigation: any;
  id: string;
}
interface S {
  isFromEmail: boolean;
  isErrorModalShow: boolean
  errorContent: any;
  verifyErrorBannerImageId: string | null;
  verifyErrorPageBannerImage: string;
  time:any
}
interface SS {
  id: any;
}

export default class ErrorController extends BlockComponent<Props, S, SS> {
  createAccountApiCallId: any;
  otpResendApiCallId: any;
  formref = React.createRef();
  timer: any;
  getVerifyErrorPageBannerImageApiCallId = '';
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.receive = this.receive.bind(this);
    this.state = {
      isFromEmail: false,
      isErrorModalShow: false,
      errorContent: {},
      verifyErrorBannerImageId: '',
      verifyErrorPageBannerImage: '',
      time:0
    };
  }
  async componentDidMount() {
    this.getVerifyErrorPageBannerImage()
    let history: any = (await this.props.history)
      ? this.props.history.location.state
      : "";
    if (history) {
      this.setState({ isFromEmail: history?.isFromMail });
    }
    this.startTimer();
  }

  async componentWillUnmount() {
    let time :any = await localStorage.getItem('timming');
    time = time && JSON.parse(time);     
    if (time?.s !== undefined) {
      time.s = this.state.time;
    } 
    localStorage.setItem('timming', JSON.stringify(time));
    clearInterval(this.timer);
  }


  onContinueClick = async () => {
    // if (this.state.verificationData?.role_id == 2) {
    //   this.props.history.push("/select/industry");
    // } else {
    //   this.props.history.push("/add_personal_profile");
    // }
    message.info("Coming Soon");
  };
  async receive(from: string, message: Message) {
    console.log("receive");
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId == this.getVerifyErrorPageBannerImageApiCallId) {
          this.updateVerifyErrorPageBannerImageState(responseJson);
        }
      }
      else {
        console.log("hello");
      }
    }
  }
  getVerifyErrorPageBannerImage = () => {
    const header = {
      'Content-Type': 'application/json',
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getVerifyErrorPageBannerImageApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getLandingPageImageAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    this.setState({
      verifyErrorBannerImageId: requestMessage.id,
    });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  updateVerifyErrorPageBannerImageState = (verifyErrorPageBannerImageData: any) => {
    if (verifyErrorPageBannerImageData.data) {
      this.setState({
        verifyErrorPageBannerImage: verifyErrorPageBannerImageData.data[0].attributes.image,
      });
    } else {
      this.setState({
        isErrorModalShow: true,
        errorContent: {
          title: "Error",
          isError: true,
          body: "Unable to load verify Error banner image!"
        },
      });
    }
  };

  startTimer = () => {
      let time: any = localStorage.getItem('timming');
      time = time && JSON.parse(time);
      this.timer = setInterval(() => {
        this.updateTimer(time);
      }, 1000);
  };
  updateTimer = async (time:any) => {  
    if (time?.s !== undefined) {
      time.s = time.s - 1;
      
      if (time.s < 0) {
        time.s = 0;
      }
            
      this.setState({
        time: time.s,
      });

      if (time.s === 0) {
        clearInterval(this.timer);
      }
    }
  };

}
