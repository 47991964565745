Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.ACCOUNT_TYPE_EMAIL = "EmailAccount";
exports.ACCOUNT_TYPE_SOCIAL = "SocialAccount";
exports.ACCOUNT_TYPE_PHONE = "SmsAccount";

exports.contentTypeApiUpdateUser = "application/json";
exports.apiEndPointUpdateUser = "profile/profile";
exports.apiUpdateUserType = "PUT";

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";

exports.contenttypeApiValidateMobileNo = "application/json";
exports.endPointApiValidateMobileNo = "profile/change_phone_validation";
exports.callTypeApiValidateMobileNo = "POST";

exports.endPointApiGetUserProfile = "profile/profile";
exports.contentTypeApiGetUserProfile = "application/json";
exports.methodTypeApiGetUserProfile = "GET";

// Customizable Area Start
exports.endPointApiUploadProfilePicture = "bx_block_profile/profiles/upload_profile_pic";
exports.contentTypeApiUploadProfilePicture = "multipart/form-data";
exports.methodTypeApiUploadProfilePicture = "POST";

exports.blockedAccountPostApiEndpoint = "account_block/accounts"
exports.blockedAccountPostApiMethod = "Post"
exports.reportPostApiEndpoint = "account_block/accounts/"
exports.reportPostApiMethod = "POST"
exports.followMethodType= "POST"
exports.GETMethod = "GET";
exports.POSTMethod = "POST";
exports.PUTMethod = "PUT";
exports.DELETEMethod = "DELETE";
exports.APPLICATIONJSONContentType = "application/json";
exports.MULTIPARTFormData = "multipart/form-data";
exports.uploadImageApi = "bx_block_profile/profiles/upload_profile_pic";
exports.initialDetailsApi = "bx_block_categories/categories";
exports.personalProfileApi = "bx_block_profile/personal_profiles";
exports.businessProfileApi = "bx_block_profile/business_profiles";
exports.businessCatAPI = "bx_block_categories/sub_categories?category_name=";
exports.addProfileAPIEndPoint = "bx_block_profile/business_profiles";
exports.getCategoryAPIEndPoint = "bx_block_categories/categories";
exports.addPersonalProfileAPIEndPoint = "bx_block_profile/personal_profiles";
exports.getAnyAccountEndPoint = "account_block/accounts/";
exports.postsApiEndpoint = "bx_block_posts/posts";
exports.getUsersPostApiEndPoint = "bx_block_posts/posts/other_user_posts"
exports.getProfileInfoEndPoint = "bx_block_profile/profiles";
exports.getFollowersEndPoint = "account_block/accounts/followers_list";
exports.getFollowingsEndPoint = "account_block/accounts/following_list";
exports.followUnFollowEndPoint = "bx_block_follow/follows";
exports.getRatingsSentEndPoint = "account_block/accounts/ratings_sent";
exports.getRatingsReceivedEndPoint = "account_block/accounts/ratings_received";
exports.downloadResumeEndPoint = "bx_block_cvresumecandidatemanagement/resume"
exports.placeHolderEmail = "Email";
exports.getReviewsEndPoint = "bx_block_reviews/reviews";
exports.getRatingsEndPoint = "bx_block_accountscoreranking/ratings/get_rating"
exports.getReviewsEndPointgGet= "bx_block_reviews/reviews/reviews_sent";
exports.subscribeUnsubscribeEndPoint = "bx_block_contact_us/news_letter_unsubscribe";
exports.labelHeader =
  "This is your profile, Here you can see and update your personal information.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelArea = "Area";
exports.labelMobile = "Mobile";
exports.labelEmail = "Email";
exports.labelCurrentPassword = "Current password";
exports.labelNewPassword = "New Password";
exports.labelRePassword = "Re-Type Password";
exports.btnTextCancelPasswordChange = "Cancel";
exports.btnTextSaveChanges = "Save Changes";
exports.btnTextChangePassword = "Change Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorMobileNoNotValid = "Phone number is not valid.";
exports.errorTitle = "Error";
exports.errorBothPasswordsNotSame = "Passwords must match.";
exports.errorCurrentNewPasswordMatch =
  "New password cannot match current password.";
exports.errorCurrentPasswordNotValid = "Current password not valid.";
exports.errorNewPasswordNotValid = "New password not valid.";
exports.errorReTypePasswordNotValid = "Re-type password not valid.";
exports.hintCountryCode = "Select Country";
exports.errorBlankField = "can't be blank";
exports.errorEmailNotValid = "Email not valid.";
exports.saveContactsEndPoint = "bx_block_contactsintegration/account_contacts";

// report User
exports.removeConnectionApiContentType = "application/json";
exports.removeConnectionApiEndpoint = "bx_block_request_management/requests";
exports.removeConnectionApiMethod = "DELETE";
exports.getOtherBusinessProfileInfoEndPoint = "bx_block_profile/business_profiles"
exports.getOtherPersonalProfileInfoEndPoint = "bx_block_profile/personal_profiles"
exports.addReviewContentType = "application/json"
exports.addRatingContentType = "application/json"
// Customizable Area End

