import React, { useEffect } from "react";
import {
  Card,
  Row,
  Col,
  Typography,
  Button,
  Spin,
  Avatar,
  Tabs,
} from "antd";
// @ts-ignore
import { baseURL } from '../../../../framework/src/config.js';
import axios from "axios";
const { Text, Title } = Typography;
const { TabPane } = Tabs;
import { useHistory } from "react-router-dom"
import moment from "moment"
import {
  UserOutlined,
} from "@ant-design/icons";
import { chatIconImage } from "../assets";

const UpcomingWorkAnniversaries = (props: any) => {
  const history = useHistory()
  const [loading, setLoading] = React.useState(false);
  const [todayAnniversarie, setTodayAnniversarie] = React.useState([]);
  const [thisMonth, setthisMonth] = React.useState([]);
  const [nextmonth, setnextMonth] = React.useState([]);
  const [wtoday, setWToday] = React.useState([]);
  const [wthismonth, setWthisMonth] = React.useState([]);
  const [wnextmonth, setWnextMonth] = React.useState([]);
  const [tabkey, setTabKey] = React.useState('')

  useEffect(() => {
    if (props?.history?.location?.state?.key !== undefined) {
      setTabKey(props?.history?.location?.state?.key)
    } else {
      setTabKey('1')
    }
    if (props?.history?.location?.state?.aniversaryData) {
      const aniversaryData = props?.history?.location?.state?.aniversaryData;
      setTodayAnniversarie(aniversaryData?.birth?.today?.data)
      setthisMonth(aniversaryData?.birth?.this_month?.data)
      setnextMonth(aniversaryData?.birth?.next_month?.data)
      setWToday(aniversaryData?.work?.today?.data)
      setWthisMonth(aniversaryData?.work?.this_month?.data)
      setWnextMonth(aniversaryData?.work?.next_month?.data)
    } else {
      anniversariesData();
    }
    const token = localStorage.getItem("token");
    if (!token) {
      history.push({ pathname: '/' });
    }

  }, []);

  const handleOpenOtherBusinessView = (account_id: number) => history.push({ pathname: "/other/business/profile", state: account_id });

  const handleOpenOtherPersonalView = (account_id: number) => history.push({ pathname: "/other/personal/profile", state: account_id });

  const handleOpenChatView = (account_id: number) => history.push({ pathname: "/chat", state: { userId: account_id } });
  const removeAnni = () => console.log("hiii");

  function callback(key: any) {
    setTabKey(key)
  }

  const anniversariesData = async () => {
    setLoading(true)
    try {
      const response = await axios({
        url: `${baseURL}/account_block/accounts/anniversaries`,
        method: 'get',
        headers: {
          'token': localStorage.getItem("token"),
          'Content-Type': 'application/json'
        },

      })
      if (response) {
        setTodayAnniversarie(response.data.birth?.today?.data)
        setthisMonth(response.data.birth?.this_month?.data)
        setnextMonth(response.data.birth?.next_month?.data)
        setWToday(response.data.work?.today?.data)
        setWthisMonth(response.data.work?.this_month?.data)
        setWnextMonth(response.data.work?.next_month?.data)
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      console.error(error);
    }
  }

  return (
    <div style={{ backgroundColor: "white", padding: "18px" }}>
      {
        loading ? <Spin style={{ color: '#fff' }} /> :
          <div>
            <Text
              className="textlabel_subheadingText"
            >
              My Profile / <span className="textlabel_subheadingText2">Anniversaries{" "}</span>
            </Text>
            <div
              style={{
                height: "100%",
              }}
            >
              <Title level={4} style={{ marginTop: "10px" }}>Anniversaries</Title>
              <Tabs
                defaultActiveKey="1"
                activeKey={tabkey}
                onChange={callback}
              >
                <TabPane
                  tab="Work Anniversaries"
                  key="1"
                  style={{ padding: "5px 20px 20px 20px", height: "100%", minHeight: "60vh", backgroundColor: "#f1f0f5" }}
                >
                  {
                    wtoday &&
                    wtoday.length > 0 && <div style={{ marginTop: "10px", display: "flex", flexDirection: "column", justifyContent: "flex-start", gap: "5px" }}>
                      <Text type="secondary">Today</Text>
                      <Row gutter={[10, 10]}>
                        {
                          wtoday?.map((n: any, index: any) => {
                            return <Col xxl={4} xl={4} lg={6} md={8} sm={12} xs={24} key={index}>
                              <AnniCol
                                n={n}
                                type="work"
                                comingOn="Today"
                                removeAnni={() => removeAnni()}
                                handleOpenOtherBusinessView={(n_id: any) => handleOpenOtherBusinessView(n_id)}
                                handleOpenOtherPersonalView={(n_id: any) => handleOpenOtherPersonalView(n_id)}
                                handleOpenChatView={(n_id: any) => handleOpenChatView(n_id)}
                              />
                            </Col>
                          })
                        }
                      </Row>
                    </div>
                  }
                  {
                    wthismonth &&
                    wthismonth.length > 0 && <div style={{ marginTop: "10px", display: "flex", flexDirection: "column", justifyContent: "flex-start", gap: "5px" }}>
                      <Text type="secondary">This Week</Text>
                      <Row gutter={[10, 10]}>
                        {
                          wthismonth?.map((n: any, index: any) => {
                            return <Col style={{ height: "auto" }} xxl={4} xl={4} lg={6} md={8} sm={12} xs={24} key={index}>
                              <AnniCol
                                n={n}
                                type="work"
                                comingOn="Weekly"
                                removeAnni={() => removeAnni()}
                                handleOpenOtherBusinessView={(n_id: any) => handleOpenOtherBusinessView(n_id)}
                                handleOpenOtherPersonalView={(n_id: any) => handleOpenOtherPersonalView(n_id)}
                                handleOpenChatView={(n_id: any) => handleOpenChatView(n_id)}
                              />
                            </Col>
                          })
                        }
                      </Row>
                    </div>
                  }
                  {
                    wnextmonth &&
                    wnextmonth.length > 0 && <div style={{ marginTop: "10px", display: "flex", flexDirection: "column", justifyContent: "flex-start", gap: "5px" }}>
                      <Text type="secondary">Next Month</Text>
                      <Row gutter={[10, 10]}>

                        {
                          wnextmonth?.map((n: any, index: any) => {
                            return <Col style={{ height: "auto" }} xxl={4} xl={4} lg={6} md={8} sm={12} xs={24} key={index}>
                              <AnniCol
                                n={n}
                                type="work"
                                comingOn="month"
                                removeAnni={() => removeAnni()}
                                handleOpenOtherBusinessView={(n_id: any) => handleOpenOtherBusinessView(n_id)}
                                handleOpenOtherPersonalView={(n_id: any) => handleOpenOtherPersonalView(n_id)}
                                handleOpenChatView={(n_id: any) => handleOpenChatView(n_id)}
                              />
                            </Col>

                          })
                        }
                      </Row>
                    </div>
                  }
                </TabPane>
                <TabPane
                  tab="Birth Anniversaries"
                  key="2"
                  style={{ padding: "5px 20px 20px 20px", height: "100%", minHeight: "60vh", backgroundColor: "#f1f0f5" }}
                >
                  {
                    todayAnniversarie &&
                    todayAnniversarie.length > 0 &&
                    <div style={{ marginTop: "10px", display: "flex", flexDirection: "column", justifyContent: "flex-start", gap: "5px" }}>
                      <Text type="secondary">Today</Text>
                      <Row gutter={[10, 10]}>
                        {
                          todayAnniversarie?.map((n: any, index: any) => {

                            return <Col style={{ height: "auto" }} xxl={4} xl={4} lg={6} md={8} sm={12} xs={24} key={index}>
                              <AnniCol
                                n={n}
                                type="Birth"
                                comingOn="Today"
                                removeAnni={() => removeAnni()}
                                handleOpenOtherBusinessView={(n_id: any) => handleOpenOtherBusinessView(n_id)}
                                handleOpenOtherPersonalView={(n_id: any) => handleOpenOtherPersonalView(n_id)}
                                handleOpenChatView={(n_id: any) => handleOpenChatView(n_id)}
                              />
                            </Col>
                          })
                        }
                      </Row>
                    </div>
                  }
                  {
                    thisMonth &&
                    thisMonth?.length > 0 &&
                    <div style={{ marginTop: "10px", display: "flex", flexDirection: "column", justifyContent: "flex-start", gap: "5px" }}>
                      <Text type="secondary">This Week</Text>
                      <Row gutter={[10, 10]}>
                        {
                          thisMonth?.map((n: any, index: any) => {
                            return <Col style={{ height: "auto" }} xxl={4} xl={4} lg={6} md={8} sm={12} xs={24} key={index}>
                              <AnniCol
                                n={n}
                                type="Birth"
                                comingOn="Weekly"
                                removeAnni={() => removeAnni()}
                                handleOpenOtherBusinessView={(n_id: any) => handleOpenOtherBusinessView(n_id)}
                                handleOpenOtherPersonalView={(n_id: any) => handleOpenOtherPersonalView(n_id)}
                                handleOpenChatView={(n_id: any) => handleOpenChatView(n_id)}
                              />
                            </Col>
                          })
                        }
                      </Row>
                    </div>
                  }
                  {
                    nextmonth &&
                    nextmonth.length > 0 &&
                    <div style={{ marginTop: "10px", display: "flex", flexDirection: "column", justifyContent: "flex-start", gap: "5px" }}>
                      <Text type="secondary">Next Month</Text>
                      <Row gutter={[10, 10]}>
                        {
                          nextmonth.map((n: any, index: any) => {
                            return <Col style={{ height: "auto" }} xxl={4} xl={4} lg={6} md={8} sm={12} xs={24} key={index}>
                              <AnniCol
                                n={n}
                                type="Birth"
                                comingOn="month"
                                handleOpenOtherBusinessView={(n_id: any) => handleOpenOtherBusinessView(n_id)}
                                handleOpenOtherPersonalView={(n_id: any) => handleOpenOtherPersonalView(n_id)}
                                handleOpenChatView={(n_id: any) => handleOpenChatView(n_id)}
                                removeAnni={() => removeAnni()}
                              />
                            </Col>

                          })
                        }
                      </Row>
                    </div>
                  }

                </TabPane>
              </Tabs>
            </div>
          </div>
      }

    </div>
  );
};

interface ANNIProps {
  // Customizable Area Start
  openNPS?: {
    name: string,
    npsData: any
  }
  comingOn: string
  n: any
  type: string
  handleCancel?: () => void
  handleOpenOtherBusinessView: any
  handleOpenOtherPersonalView: any
  handleOpenChatView: any
  removeAnni: any
  // Customizable Area End
}

class AnniCol extends React.Component<ANNIProps> {
  // Customizable Area Start
  constructor(props: any) {
    super(props);
    // Customizable Area Start
    // const { } = this.state;
    // console.log(this.state,"state")
    // Customizable Area End
  }
  render() {
    const { n, type, comingOn } = this.props;
    console.log(n);
    return <Card
      className="Birth_Anniversari_card"
      style={{ height: "100%" }}
      size="small"
      actions={
        comingOn === "Today" ? [
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '2px 12px' }}>
            <Button
              type="default"
              shape="round"
              size="middle"
              style={{ color: "rgb(64, 130, 185)", borderColor: "rgb(64, 130, 185)" }}
              onClick={n?.account?.data?.attributes?.role === "business" ? () => this.props.handleOpenOtherBusinessView(n?.account?.data?.attributes?.account_id) : () => this.props.handleOpenOtherPersonalView(n?.account?.data?.attributes?.account_id)}
            >
              View Profile
            </Button>

            <Button
              type="text"
              shape="circle"
              size="large"
              icon={
                <img src={chatIconImage} width={28} />
              }
              onClick={() => {
                this.props.handleOpenChatView(n?.account?.data?.attributes?.account_id)
                // console.log(item?.account?.data?.id, "item")
                // history.push({ pathname: "/chat", state: { userId: item?.account?.data?.id } })
              }}
            />
          </div>
        ] :
          [
            <Button
              type="default"
              shape="round"
              size="middle"
              style={{ color: "rgb(64, 130, 185)", borderColor: "rgb(64, 130, 185)" }}
              onClick={n?.account?.data?.attributes?.role === "business" ? () => this.props.handleOpenOtherBusinessView(n?.account?.data?.attributes?.account_id) : () => this.props.handleOpenOtherPersonalView(n?.account?.data?.attributes?.account_id)}
            >
              View Profile
            </Button>
          ]
      }
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: "2px",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{ color: '#d0d0d0' }}
          >
            {`${n?.days_left > 0 ? `${n?.days_left} ${n?.days_left > 1 ? 'days' : 'day'} left ` : 'TODAY'} `}
          </div>
          <div>
            <a
              style={{ color: '#d0d0d0' }}
              onClick={() => this.props.removeAnni()}
            >
              <svg
                viewBox="64 64 896 896"
                focusable="false"
                data-icon="close"
                width="1em"
                height="1em"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"
                >
                </path>
              </svg>
            </a>
          </div>
        </div>
        <Avatar
          size={64}
          icon={
            n?.account?.data?.attributes?.photo ?
              <img src={n?.account?.data?.attributes?.photo} /> :
              <UserOutlined />}
        />
        <Text style={{ fontSize: 14, fontWeight: "bold", textAlign: 'center' }}>
          {n?.account?.data?.attributes?.full_name}
        </Text>
        <Text
          style={{
            width: "100%",
            marginTop: 0,
            fontSize: 12,
            textAlign: "center",
          }}
        >
          {n?.account?.data?.attributes?.profile?.data?.attributes?.designation?.title}
          <Text type="secondary"> {n?.account.data.attributes.profile.data.attributes?.designation?.company &&
            `at ${n.account.data.attributes.profile.data.attributes.designation.company}`}
          </Text>
        </Text>
        <Text
          style={{
            width: "100%",
            marginTop: 0,
            fontSize: 12,
            textAlign: "center",
          }}
          type="secondary"
        >
          {type === 'Birth' ?
            `${moment(n?.account?.data?.attributes?.profile?.data?.attributes?.date_of_birth).format('DD MMM YYYY')}` :
            `${n?.title}`}
        </Text>
      </div>
    </Card>
  }
  // Customizable Area End
}

export default UpcomingWorkAnniversaries;
