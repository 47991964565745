import React from "react";
// Customizable Area Start
import {
  PageHeader,
  Card,
  Row,
  Col,
  Form,
  Input,
  Typography,
  Button,
  Steps,
  Modal
} from "antd";
const { Title, Text } = Typography;
const style = require(`../../../social-media-account/src/login.module.css`);
import "../profile.css";
import EditCertificateController from "./EditCertificateControllers.web";
import CloseOutlined from "@ant-design/icons/lib/icons/CloseOutlined";
import moment from "moment";
const { TextArea } = Input;
const { Step } = Steps;
// const AlphaSomeSpecialChar = /^[ A-Za-z0-9_@./#&+-]*$/;
// Customizable Area End
export default class EditCertificate extends EditCertificateController {
  // Customizable Area Start
  renderCloseIcon = () => {
    return <CloseOutlined />
  }
  // Customizable Area End
  renderCloseModal = () => {
    return (
      //Customizable Area Start
      <Modal
        className="delete_popup"
        title="Personal Profile"
        visible={this.state.isModalVisible}
        onOk={() => {
          this.deleteData(this.state.modalIndex);
        }}
        footer={null}
        onCancel={() =>
          this.setState({
            isModalVisible: false
          })
        }
      >
        <p>
          Want to Delete {this.state.modalString} {this.state.modalIndex + 1} ?
        </p>
        <Row
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            paddingTop: "0.5rem",
            paddingBottom: "1rem"
          }}
        >
          <Col lg={12} md={12} sm={12} xs={12}>
            <Button
              className={style.btn_colors2}
              type="primary"
              shape="round"
              size={"large"}
              style={{ width: "200px" }}
              onClick={() => {
                this.setState({
                  isModalVisible: false
                });
              }}
            >
              Cancel
            </Button>
          </Col>
          <Col lg={12} md={12} sm={12} xs={12}>
            <Button
              className={style.btn_danger}
              type="primary"
              shape="round"
              size={"large"}
              danger
              style={{ width: "200px" }}
              onClick={() => {
                this.deleteData(this.state.modalIndex);
              }}
            >
              Delete
            </Button>
          </Col>
        </Row>
      </Modal>
      // Customizable Area End
    );
  };

  render() {
    // Customizable Area Start
    const { all_data } = this.state;
    console.log(this.state.employment_type, "test type");
    return (
      <div
        style={{
          backgroundColor: "#eff8fe",
          height: "100%"
        }}
      >
        {this.renderCloseModal()}
        {this.redirectUser()}

        <Row
          style={{
            // minHeight: "90vh",
            alignItems: "center",
            padding: "60px 0",
            backgroundColor: "#eff8fe"
          }}
        >
          <Col span={7} xs={2} sm={2} md={3} lg={7} xl={7} xxl={7} />
          <Col span={10} xs={20} sm={20} md={18} lg={10} xl={10} xxl={10}>
            <Title level={2} style={{ textAlign: "center" }}>
              Edit Your Personal Profile
            </Title>

            <Steps
              current={this.state.employment_type == "Student" ? 0 : 1}
              style={{ padding: "15px" }}
            >
              <Step />
              <Step />
              <Step />
              <Step />

              {this.state.employment_type != "Student" && <Step />}
            </Steps>
          </Col>
          <Col span={7} xs={2} sm={2} md={3} lg={7} xl={7} xxl={7} />
          <Col span={6} xs={2} sm={2} md={3} lg={3} xl={6} xxl={6} />
          <Col
            span={12}
            xs={20}
            sm={20}
            md={18}
            lg={18}
            xl={12}
            xxl={12}
            style={{ height: "100%" }}
            className="basic_profile_card"
          >
            <Card className="card-body-certificate">
              {all_data.certificate && all_data.certificate.length > 0
                ? all_data.certificate.map((item: any, index: any) => {
                  return (
                    <div key={index}>
                      {/* <Row className="experince-card-heading"> */}
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          backgroundColor: "#e0eaf4",
                          minHeight: "35px"
                        }}
                      >
                        <Title
                          level={4}
                          style={{
                            marginLeft: "15px",
                            marginTop: "10px",
                            color: "steelblue",
                            height: "100%",
                            display: "flex",
                            alignItems: "center"
                          }}
                        >
                          Certificate {index + 1}
                        </Title>
                        <div
                          style={{
                            display: index === 0 ? "none" : "block",
                            cursor: "pointer",
                            padding: "1rem"
                          }}
                          onClick={() => this.showModal(index, "Certificate")}
                        >
                            {this.renderCloseIcon()}

                        </div>
                      </div>
                      {/* </Row> */}
                      <Form
                        className="form-with-padding"
                        // initialValues={{
                        //   name: "",
                        // }}
                        onFinish={this.handleCertificateData}
                        ref={this.certificate_formref}
                      >
                        <Row gutter={15}>
                          <Col
                            span={12}
                            xs={24}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            xxl={12}
                          >
                            <Form.Item
                              rules={[
                                { required: true, message: "Required field" },
                                {
                                  min: 1,
                                  message: "min character limit to 1"
                                },
                                {
                                  max: 40,
                                  message: "max character limit to 40"
                                },
                                //   {
                                //     pattern: new RegExp(/^[a-zA-Z ]+$/i),
                                //     message: "input accept only alphabets"
                                // }
                              ]}
                            // name="name"
                            >
                              <Input
                                className={style.input_fields}
                                name="name"
                                placeholder="Name"
                                value={item?.name ? item?.name : ''}
                                style={{ borderWidth: 1}}
                                // onChange={e =>
                                //   this.handleInputChange(
                                //     e,
                                //     index,
                                //     "Certificate"
                                //   )
                                // }
                                onChange={(e) => {
                                  let pattern = /^[a-zA-z][a-zA-z\s]*$/
                                  this.handleInputChange(e, index, "Certificate")
                                  if (item?.name.trim().length > 0 && !item.description) {
                                    let error: any = [...this.state.acheivements]
                                    error[index] = { patent: "Certificate description should not contain any type of number", desc: error[index]?.desc }
                                    this.setState({
                                      awards: error
                                    })
                                  } else {
                                    let error: any = [...this.state.acheivements]
                                    error[index] = { patent: "", desc: error[index]?.desc }
                                    this.setState({
                                      acheivements: error
                                    })
                                  }
                                  let result = e.target.value.length == 0 ? true : pattern.test(e.target.value.trim())
                                  if (!result || item.description && !item.name) {
                                    let error: any = [...this.state.awards]
                                    error[index] = { patent: "Enter only alphabeats", desc: error[index]?.desc }
                                    this.setState({
                                      awards: error
                                    })

                                  } else {
                                    let error: any = [...this.state.awards]
                                    error[index] = { patent: "", desc: error[index]?.desc }
                                    this.setState({
                                      awards: error
                                    })
                                  }
                                  console.log(this.state.awards, e.target.value)
                                }
                                }
                              />
                              <Text style={{ color: 'red', fontSize: '12px',paddingLeft:"16px" }}>{this.state.awards[index]?.patent}</Text>
                            </Form.Item>
                          </Col>
                          <Col
                            span={12}
                            xs={24}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            xxl={12}
                          >
                            <Form.Item
                              rules={[
                                { required: true, message: "Required field" },
                                {
                                  min: 15,
                                  message: "min character limit to 15"
                                }
                              ]}
                            // name="description"
                            >
                              <Input
                                className={style.input_fields}
                                name="description"
                                placeholder="Description"
                                value={item?.description ? item?.description : ''}
                                style={{ borderWidth: 1 }}
                                onChange={(e) => {
                                  let pattern = /^[a-zA-z][a-zA-z\s]*$/
                                  this.handleInputChange(e, index, "Certificate")
                                  if (item?.description.trim().length > 0 && !item.name) {
                                    let error: any = [...this.state.awards]
                                    error[index] = { patent: "Certificate name should not contain any type of number", desc: error[index]?.desc }
                                    this.setState({
                                      acheivements: error
                                    })
                                  } else {
                                    let error: any = [...this.state.awards]
                                    error[index] = { patent: "", desc: error[index]?.desc }
                                    this.setState({
                                      awards: error
                                    })
                                  }
                                  let result = e.target.value.length === 0 ? true : pattern.test(e.target.value.trim())
                                  if (!result || !item.description && item.name) {
                                    let error: any = [...this.state.acheivements]
                                    error[index] = { patent: "Enter only alphabeats", desc: error[index]?.desc }
                                    this.setState({
                                      acheivements: error
                                    })

                                  } else {
                                    let error: any = [...this.state.acheivements]
                                    error[index] = { patent: "", desc: error[index]?.desc }
                                    this.setState({
                                      acheivements: error
                                    })
                                  }
                                  console.log(this.state.acheivements)
                                }
                                }
                              />
                              <Text style={{ color: 'red', fontSize: '12px',paddingLeft:"16px" }}>{this.state.acheivements[index]?.patent}</Text>
                            </Form.Item>
                          </Col>
                          <Row style={{ width: "100%" }}>
                            <Col
                              span={12}
                              xs={24}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                              xxl={12}
                              style={{ paddingRight: 10, paddingLeft: 10 }}
                            >
                              <Button
                                className={style.experience_add_btn}
                                type="primary"
                                htmlType="submit"
                                shape="round"
                                size={"large"}
                                style={{
                                  display:
                                    index === all_data.certificate.length - 1
                                      ? "block"
                                      : "none",
                                  width: "100%",
                                  backgroundColor: "white",
                                  borderColor: "#d9d9d9"
                                }}
                              >
                                Add Certification
                              </Button>
                            </Col>
                            <Col
                              span={12}
                              xs={0}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                              xxl={12}
                            />
                          </Row>
                        </Row>
                      </Form>
                    </div>
                  );
                })
                : ""}

              {all_data.award && all_data.award.length > 0
                ? all_data.award.map((item: any, index: any) => {
                  return (
                    <div key={index}>
                      {/* <Row className="experince-card-heading"> */}
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          backgroundColor: "#e0eaf4",
                          minHeight: "35px"
                        }}
                      >
                        <Title
                          level={4}
                          style={{
                            marginLeft: "15px",
                            marginTop: "10px",
                            color: "steelblue",
                            height: "100%",
                            display: "flex",
                            alignItems: "center"
                          }}
                        >
                          Awards and Recognition {index + 1}
                        </Title>
                        <div
                          style={{
                            display: index === 0 ? "none" : "block",
                            cursor: "pointer",
                            padding: "1rem"
                          }}
                          onClick={() =>
                            this.showModal(index, "Awards and Recognition")
                          }
                        >
                           {this.renderCloseIcon()}
                        </div>
                      </div>

                      {/* </Row> */}
                      <Form
                        className="form-with-padding-2"
                        // initialValues={{
                        //   title: "",
                        //   description: "",
                        // }}
                        onFinish={this.handleAwardData}
                        ref={this.award_formref}
                      >
                        <Row gutter={15}>
                          <Col
                            span={12}
                            xs={24}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            xxl={12}
                          >
                            <Form.Item
                              rules={[
                                { required: true, message: "Required field" },
                                {
                                  min: 1,
                                  message: "min character limit to 1"
                                },
                                {
                                  max: 40,
                                  message: "max character limit to 40"
                                }
                              ]}
                            // name="title"
                            >
                              <Input
                                className={style.input_fields}
                                name="title"
                                placeholder="Title"
                                style={{ borderWidth: 1 }}
                                value={item.title}
                                onChange={e =>
                                  this.handleInputChange(e, index, "Award")
                                }
                              />
                            </Form.Item>
                          </Col>
                          <Col
                            span={12}
                            xs={24}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            xxl={12}
                          >
                            <Form.Item
                              rules={[
                                { required: true, message: "Required field" },
                                {
                                  min: 15,
                                  message: "min character limit to 15"
                                }
                              ]}
                            // name="description"
                            >
                              <Input
                                className={style.input_fields}
                                name="description"
                                placeholder="Description"
                                value={item.description}
                                style={{ borderWidth: 1 }}
                                onChange={e =>
                                  this.handleInputChange(e, index, "Award")
                                }
                              />
                            </Form.Item>
                          </Col>
                          <Row style={{ width: "100%" }}>
                            <Col
                              span={12}
                              xs={24}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                              xxl={12}
                              style={{ paddingRight: 10, paddingLeft: 10 }}
                            >
                              <Button
                                className={style.experience_add_btn}
                                type="primary"
                                htmlType="submit"
                                shape="round"
                                size={"large"}
                                style={{
                                  display:
                                    index === all_data.award.length - 1
                                      ? "block"
                                      : "none",
                                  width: "100%",
                                  backgroundColor: "white",
                                  borderColor: "#d9d9d9",
                                  overflow: "hidden"
                                }}
                              >
                                Add Awards and Recognition
                              </Button>
                            </Col>
                            <Col
                              span={12}
                              xs={0}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                              xxl={12}
                            />
                          </Row>
                        </Row>
                      </Form>
                    </div>
                  );
                })
                : ""}
              {all_data.acheivement && all_data.acheivement.length > 0
                ? all_data.acheivement.map((item: any, index: any) => {
                  return (
                    <div key={index}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          backgroundColor: "#e0eaf4",
                          minHeight: "35px"
                        }}
                      >
                        <Title
                          level={4}
                          style={{
                            marginLeft: "15px",
                            marginTop: "10px",
                            color: "steelblue",
                            height: "100%",
                            display: "flex",
                            alignItems: "center"
                          }}
                        >
                          Achievements {index + 1}
                        </Title>
                        <div
                          style={{
                            display: index === 0 ? "none" : "block",
                            cursor: "pointer",
                            padding: "1rem"
                          }}
                          onClick={() =>
                            this.showModal(index, "Achievements")
                          }
                        >
                           {this.renderCloseIcon()}
                        </div>
                      </div>
                      <Form
                        className="form-with-padding-2"
                        // initialValues={{
                        //   company: "",
                        //   start_date: "",
                        //   end_date: "",
                        //   title: "",
                        //   description: "",
                        // }}
                        onFinish={this.handleAchievementsData}
                        ref={this.achievement_formref}
                      >
                        <Row gutter={15}>
                          <Col
                            xs={24}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            xxl={12}
                          >
                            <Form.Item
                              rules={[
                                { required: true, message: "Required field" },
                                {
                                  min: 15,
                                  message: "min character limit to 15"
                                }
                              ]}
                            // name="description"
                            >
                              <TextArea
                                autoSize={{ minRows: 4, maxRows: 4 }}
                                className={style.input_fields_TextArea}
                                name="description"
                                placeholder="Description"
                                value={item.description}
                                style={{ borderWidth: 1 }}
                                onChange={e =>
                                  this.handleInputChange(
                                    e,
                                    index,
                                    "Acheivements"
                                  )
                                }
                              />
                            </Form.Item>
                            <Button
                              className={style.experience_add_btn}
                              type="primary"
                              htmlType="submit"
                              shape="round"
                              size={"large"}
                              style={{
                                display:
                                  index === all_data.acheivement.length - 1
                                    ? "block"
                                    : "none",
                                width: "100%",
                                backgroundColor: "white",
                                borderColor: "#d9d9d9"
                              }}
                            >
                              Add Achievements
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    </div>
                  );
                })
                : ""}
            </Card>
            <Row style={{ background: "#f8f8f8" }}>
              <Col lg={12} xl={12} md={12} sm={12} xs={24}
                className={style.responsive_bottom_action_button} style={{ padding: "15px", }}>
                <Button
                  onClick={this.skipProfile}
                  className={style.skip_add_btn}
                  type="primary"
                  htmlType="submit"
                  disabled={this.state.disableNextButton}
                  shape="round"
                  size={"large"}
                  style={{ width: "100%" }}
                >
                  Skip Profile Setup
                </Button>
              </Col>
              <Col
                lg={12} xl={12} md={12} sm={12} xs={24}
                className={style.responsive_bottom_action_button}
                style={{
                  padding: "15px 0px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "end"
                }}
              >
                <Button
                  className={style.previous_btn}
                  type="primary"
                  shape="round"
                  onClick={() => this.handlePrevious()}
                  size={"large"}
                >
                  Previous
                </Button>
                <Button
                  className={style.btn_colors}
                  type="primary"
                  style={{}}
                  disabled={this.state.disableNextButton}
                  onClick={this.handleNext}
                  shape="round"
                  size={"large"}
                >
                  Next
                </Button>
              </Col>
            </Row>
          </Col>
          <Col span={6} xs={2} sm={2} md={3} lg={3} xl={6} xxl={6} />
        </Row>
      </div>
    );
    // Customizable Area End
  }
}
