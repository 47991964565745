import React from "react";
import {
  Typography,
  Button,
  Spin,
  Select,
  Row,
  Empty,
  Modal
} from "antd";
import MyNetworkController from "./myNetworkController.web";
import "./network.css";
import { Grid } from "@material-ui/core";
import NetworkComponent from "./componant/NetworkComponent.web";
import { successModel } from "../../../user-profile-basic/src/personal/OtherPersonalProfile.web";
import CommunityAlert from "../../../CommunityForum/src/CommunityAlert.web";
const { Text } = Typography;

export default class MyNetwork extends MyNetworkController {
  //Customizable Area Start
  onCanclepopup =()=>{
    return this.setState({modelStatus:false})
  }

  shortingConditon =(condition:boolean,truStatement:string,falseStatement:string) =>{
    return condition?truStatement:falseStatement
  }
  render() {
    return (
      <div style={{ background: "#f6f6f6" }}>
        {this.redirectUser()}
        {this.state.pageLoader ? <Row gutter={[16, 16]} style={{ alignItems: "center", padding: "25px" }}>
          <Spin />
        </Row> : (this.state.pendingConnectionList.length > 0 || this.state.acceptedConnectionList.length > 0 || this.state.suggestedList.length > 0) ? <Grid xs={12} style={{ padding: "10px 15px" }}>
          {this.state.pendingConnectionList.length > 0 && <Grid container >
            <Grid item style={{ fontSize: "20px", fontWeight: "bold", padding: "10px 0px" }} xs={6}>Invitations</Grid>
            <Grid item style={{ fontSize: "16px", fontWeight: 600, textAlign: "end", padding: "10px 0px" }} xs={6}>
              <span style={{ fontSize: "16px", color: "#005574", cursor: "pointer" }} onClick={() => this.props.history.push({ pathname: "/myNetwork/invitation" })}>View All</span>
            </Grid>
            <Grid item xs={12}>
              <Row gutter={[16, 16]}>
                {this.state.pendingConnectionList.map((item: any, index: number) => <NetworkComponent
                  key={`${index}invitation`}
                  data={item}
                  others={{ type: "invitation" }}
                  showAcceptRequestModal={this.showAcceptRequestModal}
                // acceptRequest={this.acceptRequest}
                />
                )}
              </Row>
            </Grid>
          </Grid>}

          {this.state.acceptedConnectionList.length > 0 && <Grid container>
            <Grid item style={{ fontSize: "20px", fontWeight: "bold", padding: "10px 0px" }} xs={6}>My Network</Grid>
            <Grid item style={{ fontSize: "16px", fontWeight: 600, textAlign: "end", padding: "10px 0px" }} xs={6}>
              {this.state.acceptedConnectionList.length >= 4 &&
                <span style={{ fontSize: "16px", color: "#005574", cursor: "pointer" }} onClick={() => this.props.history.push({ pathname: "/myNetwork/network" })}>View All</span>
              }</Grid>
            <Grid item xs={12}>
              <Row gutter={[16, 16]}>
                {this.state.acceptedConnectionList.map((item: any, index: number) => <NetworkComponent
                  key={`${index}my_network`}
                  data={item}
                />
                )}
              </Row>
            </Grid>
          </Grid>}

          {this.state.suggestedList.length > 0 && <Grid container>
            <Grid item style={{ fontSize: "20px", fontWeight: "bold", padding: "10px 0px" }} xs={6}>People You May Know</Grid>
            <Grid item style={{ fontSize: "16px", fontWeight: 600, textAlign: "end", padding: "10px 0px" }} xs={6}>
              {this.state.suggestedList.length >= 4 &&
                <span style={{ color: "#005574", fontSize: "16px", cursor: "pointer" }} onClick={this.onViewAllInvitePersonal}>View All</span>}</Grid>
            <Grid item xs={12}>
              <Row gutter={[16, 16]}>
                {this.state.suggestedList.map((item: any, index: number) => <NetworkComponent
                  others={{ type: "you_may_know" }}
                  showAcceptRequestModal={this.showAcceptRequestModal}
                  data={item}
                  key={`${index}you_may_know`}
                  acceptModel={this.state.nextPageLoader}
                  cardid={this.state.cardid}
                />
                )}
              </Row>
            </Grid>
          </Grid>}

          {this.state.suggestedBusList.length > 0 && <Grid container>
            <Grid item style={{ fontWeight: "bold", fontSize: "20px", padding: "10px 0px" }} xs={6}>Business You May Know</Grid>
            <Grid item style={{ padding: "10px 0px", fontSize: "16px", fontWeight: 600, textAlign: "end" }} xs={6}>
              {this.state.suggestedBusList.length >= 4 &&
                <span style={{ fontSize: "16px", cursor: "pointer", color: "#005574" }} onClick={this.onViewAllInviteFromBusiness}>View All</span>}</Grid>
            <Grid item xs={12}>
              <Row gutter={[16, 16]}>
                {this.state.suggestedBusList.map((item: any, index: number) => <NetworkComponent
                  others={{ type: "you_may_know" }}
                  showAcceptRequestModal={this.showAcceptRequestModal}
                  key={`${index}you_may_know`}
                  data={item}
                  acceptModel={this.state.nextPageLoader}
                  cardid={this.state.cardid}
                />
                )}
              </Row>
            </Grid>
          </Grid>}


        </Grid> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        }
        <CommunityAlert
          modalStatus={this.state.acceptModel}
          handleCancel={()=>{this.setState({acceptModel:false})}}
          modal={this.state.acceptModel}
          heading={this.shortingConditon(this.state.responseHandler.status == "accepted", "Connection established successfully", "Connection rejected successfully")}
          desc={this.shortingConditon(this.state.responseHandler.status == "accepted", `Your connection with ${this.state.responseHandler.full_name} as ${this.state.responseHandler.sender_relation} is established successfully`, `Your connection with ${this.state.responseHandler.full_name} as ${this.state.responseHandler.sender_relation} is rejected successfully`)}
        />
        {successModel(this.state.modelStatus, this.onCanclepopup, this.state.modelData?.relation, this.state.modelData?.name)}
        <Modal
          centered
          width={450}
          style={{ borderWidth: "0px", borderRadius: "4px", overflow: "hidden" }}
          bodyStyle={{ paddingTop: "5px" }}
          title={<div style={{ fontWeight: "bold", borderWidth: "0px", fontSize: "18px", paddingRight: '24px' }}>
            {this.state.acceptRequestmodal?.title}
          </div>}
          visible={this.state.acceptRequestmodal?.show}
          footer={null}
          onCancel={this.closeAcceptRequestModal}
        >
          <Grid container style={{ padding: "0px" }}>
            {(this.state.acceptRequestmodal?.type == "send" || this.state.acceptRequestmodal?.type == "people") ? "" : <Grid item xs={12} style={{ paddingBottom: "10px" }}>
              <Text>Change Relationship to</Text>
            </Grid>}
            <Grid item xs={12}>
              <Select
                style={{
                  borderRadius: '27px',
                  overflow: "hidden",
                  padding: "0 !important",
                  border: "2px solid #a9bac8",
                  width: "100%"
                }}
                // placeholder="Select Relationship"
                bordered={true}
                value={this.state.relationinDropdown ? this.state.relationinDropdown : "Select Relationship"}
                onChange={this.changeRelation}
                dropdownStyle={{
                  paddingTop: "0px",
                  border: "2px solid #a9bac8",
                  borderRadius: "10px",
                  paddingBottom: "0px",
                  width: "100%"
                }}
              >
                {this.state.acceptRequestmodal?.selectDrop &&
                  this.state.acceptRequestmodal?.selectDrop?.length === 0 ?
                  <h4>Loading...</h4> :
                  this.state.acceptRequestmodal?.selectDrop?.map((item: any, id: number) => {
                    return (
                      <option key={item}
                        style={{
                          borderBottom: this.state.acceptRequestmodal?.selectDrop?.length - 1 > id ? "2px solid #a9bac8" : "none",
                          padding: "10px 20px 10px 20px",
                        }}
                        value={item?.props?.value}
                      >
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div className="capital_options" style={{ color: "#a9bac8" }}>{item?.props?.value}</div>
                        </div>
                      </option>
                    );
                  })}
              </Select>
              <p style={{ color: "red", marginLeft: 4 }}>{this.state.addConnectionError}</p>
            </Grid>
            <Grid item xs={12} style={{ paddingTop: "15px" }}>
              {this.state.acceptRequestmodal?.type == "accept" ? <Grid container>
                <Grid xs={6}>
                  <Button
                    className="btn-accept-req-modal"
                    type="primary"
                    shape="round"
                    onClick={() => this.acceptRequest({ type: "accepted", data: this.state.acceptRequestmodal?.data })}
                  >
                    Accept Request
                  </Button>
                </Grid>
                <Grid xs={6} style={{ textAlign: "end" }}>
                  <Button
                    type="primary"
                    className="btn-reject-req-modal"
                    shape="round"
                    danger
                    onClick={() => this.acceptRequest({ type: "rejected", data: this.state.acceptRequestmodal?.data })}
                  >
                    Reject Request
                  </Button>
                </Grid>
              </Grid> : <Grid container>
                <Grid xs={6}>
                  <Button
                    type="primary"
                    shape="round"
                    className="btn-accept-req-modal"
                    onClick={() => this.sendRequest()}
                  >
                    Send Request
                  </Button>
                </Grid>
                <Grid xs={6} style={{ textAlign: 'end' }}>
                  <Button
                    type="primary"
                    shape="round"
                    className="btn-reject-req-modal"
                    danger
                    onClick={() => this.closeAcceptRequestModal()}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>}
            </Grid>
          </Grid>
        </Modal>
      </div>
    );
  }
  //Customizable Area End
}
