import React from 'react';
import { Form, Input, Checkbox, Button } from 'antd';
import Mixpanel from '../../../../components/src/Mixpanel';
const { TextArea } = Input;

const AddReviewView = (props: any) => {
    // Customizable Area Start
    Mixpanel.track("Reviews")
    const [form] = Form.useForm();
    const { data, onFinish } = props;
    const [anonymousState, setAnonymous] = React.useState<boolean>(false)
    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };
    React.useEffect(() => {
        setAnonymous(props.disableAnonymous)
    }, [props.disableAnonymous])

    const validation = (rule: any, value: any, callback: (error?: string) => void) => {
        if (form.getFieldValue('anonymous') === false && props.disableAnonymous) {
            return callback("please do Review anonymously")
        } else if (form.getFieldValue('anonymous') === true && props.hideAnonymous) {
            return callback("already reviewed anonymously")
        }
        return callback()
    };
    return (
        <Form
            form={form}
            name="basic"
            initialValues={{ anonymous: false }}
            onFinish={(value) => {
                onFinish(value)
                form.resetFields();
            }}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
            <Form.Item
                name="title"
                rules={[{ required: true, message: 'please add title!' }]}
            >
                <Input
                    placeholder="Add Title"
                    style={{ borderRadius: "25px", color: "rgb(0, 85, 116)" }}
                />
            </Form.Item>

            <Form.Item
                name="description"
                rules={[{ required: true, message: 'please add description!' }]}
            >
                <TextArea
                    placeholder="Add Review"
                    autoSize={{ minRows: 4, maxRows: 4 }}
                    style={{
                        borderRadius: "0.4rem",
                        color: "rgb(0, 85, 116)"
                    }}
                />
            </Form.Item>
            <Form.Item
                name="anonymous"
                valuePropName="checked"
                rules={
                    // props.disableAnonymous ? [{
                    //     message: "please do Review anonymously",

                    // }] : props.hideAnonymous && [{
                    //     message: "already reviewed anonymously"
                    // }]
                    [{ validator: validation }]
                }
            >
                <Checkbox
                    // defaultChecked={props.disableAnonymous}
                    // disabled={props.disableAnonymous}
                    style={{ color: "rgb(117, 117, 117)" }}
                >
                    Post review anonymously
                </Checkbox>
            </Form.Item>
            <Form.Item>
                <Button
                    htmlType="submit"
                    style={{
                        width: "100%",
                        height: "2.5rem",
                        borderRadius: "25px",
                        backgroundColor: "rgb(0, 85, 116)",
                        color: "#ffffff"
                    }}
                >
                    Post
                </Button>
            </Form.Item>
        </Form >
    )
    // Customizable Area End
}

export default AddReviewView
