import React, { useEffect, useState } from 'react';
import { Space, Typography, Row, Col, Spin, Empty,Menu } from 'antd';
const { Title, Text } = Typography;
const AppliedPolicy = (props: any) => {
    // Customizable Area Start
    const { policiesData, isloader } = props;

    return (
        <Space direction='vertical' style={{ width: "100%" }}>
            {isloader ? <Spin />
                : <Row gutter={[16, 16]}>
                    <Title level={5} style={{ fontWeight: 500, fontSize: 16 }}>
                        Applied Policy
                    </Title>
                    <div style={{ width: "100%", backgroundColor: "#ffffff" }}>
                    {
                        
                        policiesData?.map((item: any, id: number) => { 
                            return (
                                <Col xs={{ span: 24 }} lg={{ span: 24 }} style={{ width: "100%", display: "flex", flexDirection: "column",padding:"0px" }} key={item?.id}>
                                    <a href={item?.attributes?.link} target="_blank" >
                                    <span key={item.id} className="customclass" style={{ width: "100%", height: "3rem", borderBottom: id + 1 < policiesData?.length ? "1px solid #ededed" : "none", display: "flex", flexDirection: "row", alignItems: "center" }} >
                     
                      <span style={{fontWeight:500,fontSize:15,paddingLeft:"10px"}}>{id + 1}. {item?.attributes?.title}</span>
                    </span>
                                    </a>
                                </Col>
                            )
                        })
                    }
                    </div>
                </Row>
            }
        </Space>
    )
    // Customizable Area End
}

export default AppliedPolicy;
