import React, { useState } from 'react';
import { Space, Checkbox, Button, Typography, Card, Row, Col, Switch, Form, Input, Select, DatePicker } from 'antd';
import CustomRadioButton from './CustomRadioButton';
import axios from 'axios';
// @ts-ignore
import { baseURL } from '../../../../framework/src/config.js';
import AlertMessage from './AlertMessage.web';
const { Title, Text, Paragraph } = Typography;
const { TextArea } = Input;
import { EyeFilled, EyeInvisibleFilled } from '@ant-design/icons';


const DeleteAccount = (props: any) => {
    // Customizable Area Start
    const { onFinish, suspendMessage, handleOk } = props;
    const [form] = Form.useForm();
    const [ratingButtonValue, setRatingButtonValue] = useState<number | null>(null);
    const [isDisabled, setIsDisabled] = useState(false);
    const [passwordVerify, setPasswordVerify] = useState<boolean | null>(null);
    const [ratingShow, setRatingShow] = useState(false);
    const [validateToken, setValidateToken] = useState<string | null>(null);
    const [passwordValue, setPasswordValue] = useState<string>('');
    const [selectedReason, setSelectedReason] = useState<string>('');

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    const handleSelectChange = (value:any) => {
        setSelectedReason(value);
    }

    const onBlur = async (e: any) => {
        if (e.target.value.trim().length > 0) {
            try {
                const responce = await axios({
                    method: 'post',
                    url: `${baseURL}/account_block/accounts/validate`,
                    headers: {
                        "Content-Type": "application/json",
                        "token": localStorage.getItem("token")
                    },
                    data: {
                        password: e.target.value,
                        token_type: 'delete'
                    }
                })
                setPasswordVerify(true);
                setValidateToken(responce?.data?.token)
            } catch (error) {
                console.log(error);
                setPasswordVerify(false);
            }
        }
    }

    const onChange = (e: any) => {
        setPasswordValue(e.target.value);
    }

    const handleFinish = (value: any,) => {
        if (ratingButtonValue === null) {
            setRatingShow(true);
        } else if (validateToken) {
            let formData = {
                token: validateToken,
                reason:selectedReason ==="custom"?value?.costumReason:value?.reason,
                description: value.thoughts,
                app_rating: ratingButtonValue
            }
            setRatingShow(false);
            onFinish(formData);
        }
    }

    const handleOnClick = (value: string) => {
        if (value === "Yes, Delete Account") {
            setIsDisabled(false);
            if (isDisabled) {
                setPasswordVerify(null);
                setPasswordValue('');
                form.resetFields();
                setRatingButtonValue(null);
            }
        } else if (value === "No, Don't Delete") {
            setIsDisabled(true);
            setPasswordVerify(null);
            setPasswordValue('');
            form.resetFields();
            setRatingButtonValue(null);
        }
    }

    const optionList = [
        {
            id: 1,
            name: "No, Don't Delete",
            value: "no"
        },
        {
            id: 2,
            name: "Yes, Delete Account",
            value: "yes"
        }
    ]

    const ratingNumber = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

    const config = {
        rules: [{ type: 'object' as const, required: true, message: 'Please select date!' }],
    };

    return (
        <Space direction='vertical' style={{ width: "100%" }}>
            <Row gutter={[1, 1]}>
                <Col xs={{ span: 24 }} lg={{ span: 20 }} style={{ display: "flex", flexDirection: "column" }}>
                    <Title
                        style={{}}
                        level={5}
                    >
                        Delete Account
                    </Title>
                    <div style={{ width: "100%", backgroundColor: "#ffffff", padding: "1rem", display: "flex", flexDirection: "column" }}>
                        <Paragraph
                            style={{ fontWeight: 500, fontSize: "12px", color: "rgba(151, 151, 151, 0.6)", marginBottom: "0px" }}
                        >
                            Are you sure you want to delete your account?
                        </Paragraph>
                        <CustomRadioButton data={optionList} handleOnClick={handleOnClick} />
                        <Row>
                            <Col xs={{ span: 24 }} lg={{ span: 10 }}>
                                <div style={{ display: "flex", flexDirection: "column", marginBottom: "15px" }}>
                                    <Paragraph
                                        style={{
                                            fontWeight: 500,
                                            fontSize: "12px",
                                            color: "rgba(151, 151, 151, 0.6)",
                                            marginBottom: "5px"
                                        }}
                                    >
                                        To delete account, you need to confirm your password.
                                    </Paragraph>
                                    <Input.Password
                                        autoComplete="new-password"
                                        placeholder="Confirm Password"
                                        onInput={onBlur}
                                        data-test-id="password"
                                        onChange={onChange}
                                        visibilityToggle={true}
                                        disabled={isDisabled}
                                        autoFocus={false}
                                        value={passwordValue}
                                        iconRender={(visible) => (visible ? <EyeFilled style={{ color: "#CFD9E2" }} /> : <EyeInvisibleFilled style={{ color: "#CFD9E2" }} />)}
                                        style={{
                                            borderRadius: "25px",
                                            border: "1px solid rgb(0, 105, 143,0.5)",
                                            padding:"8px"
                                        }}
                                    />
                                    {
                                        passwordVerify !== null && <Text type='secondary' style={{ fontSize: "14px", color: passwordVerify ? "#52c41a" : "#f5222d" }}>{passwordVerify ? "Verified" : "Invalid password"}</Text>
                                    }
                                </div>
                            </Col>
                        </Row>
                        <Form
                            form={form}
                            name="basic"
                            labelCol={{ span: 5 }}
                            wrapperCol={{ span: 10 }}
                            onFinish={handleFinish}
                            onFinishFailed={onFinishFailed}
                        >
                            <Paragraph
                                style={{ fontWeight: 500, fontSize: "12px", color: "rgba(151, 151, 151, 0.6)", marginBottom: "5px" }}
                            >
                                Provide Feedback
                            </Paragraph>
                            <Form.Item
                                name="reason"
                                rules={[{ required: true, message: 'Please add your reason!' }]}
                                style={{ marginBottom: "15px" }}
                            >
                               
                                 <Select
                                    placeholder="Reason for leaving applied"
                                    onChange={handleSelectChange}
                                    data-test-id="Reason"
                                    style={{ borderRadius: "25px", border: "1px solid rgb(0, 105, 143,0.5)" }}
                                    disabled={isDisabled === false ? !passwordVerify === true : true}
                                >
                                    <Select.Option value="I have a duplicate account">I have a duplicate account</Select.Option>
                                    <Select.Option value="I'm getting to manny emails">I'm getting to manny emails</Select.Option>
                                    <Select.Option value="I'm not getting any value from my membership">I'm not getting any value from my membership</Select.Option>
                                    <Select.Option value="I have a privacy concern">I have a privacy concern</Select.Option>
                                    <Select.Option value="I'm receiving unwanted contact">I'm receiving unwanted contact</Select.Option>
                                    <Select.Option value="custom">Other, please explain further</Select.Option>
                                </Select>                                
                            </Form.Item>   
                           {selectedReason ==="custom" && <Form.Item
                                name="costumReason"
                                rules={[{ required: true, message: 'Please add your reason!' }]}
                                style={{ marginBottom: "15px" }}
                            >
                                <Input
                                    disabled={isDisabled === false ? !passwordVerify === true : true}
                                    style={{ borderRadius: "25px", border: "1px solid rgb(0, 105, 143,0.5)",padding:"8px" }}
                                    placeholder="Reason for leaving applied"
                                    
                                />
                            </Form.Item> }                                              
                            <Form.Item
                                name="thoughts"
                                rules={[{ required: true, message: 'Please add your thoughts' }]}
                                style={{ marginBottom: "15px" }}
                                data-test-id="thoughts"
                            >
                                <TextArea
                                    disabled={isDisabled === false ? !passwordVerify === true : true}
                                    style={{ borderRadius: "5px", border: "1px solid rgb(0, 105, 143,0.5)", }}
                                    placeholder="Add your thoughts about applied"
                                    autoSize={{ minRows: 3, maxRows: 3 }}
                                />
                            </Form.Item>

                            <Paragraph style={{ fontWeight: 500, fontSize: "12px", color: "rgba(151, 151, 151, 0.6)", marginBottom: "5px" }}>How likely would you recommend this app to your friend?</Paragraph>
                            <div style={{ marginBottom: "15px" }}>
                                {
                                    ratingNumber.map((item: number) => {
                                        return (
                                            <Button
                                                key={item}
                                                disabled={isDisabled === false ? !passwordVerify === true : true}
                                                style={{
                                                    marginRight: "5px",
                                                    borderRadius: "5px",
                                                    width: "2rem",
                                                    backgroundColor: ratingButtonValue === item ? "#00698f" : "#ffffff",
                                                    color: ratingButtonValue === item ? "#ffffff" : "rgb(0, 105, 143,0.5)",
                                                    fontWeight: "bold",
                                                    fontSize: "14px",
                                                    border: "1px solid rgb(0, 105, 143,0.5)",
                                                    padding: "0px"
                                                }}
                                                onClick={() => setRatingButtonValue(item)}
                                            >
                                                {item}
                                            </Button>
                                        )
                                    })
                                }
                                {
                                    ratingShow ? <Paragraph
                                        style={{
                                            fontWeight: 400,
                                            fontSize: "14px",
                                            color: "#f5222d",
                                            marginBottom: "5px"
                                        }}
                                    >
                                        Please add your rating
                                    </Paragraph>
                                        : null
                                }
                            </div>
                            <Form.Item style={{ marginBottom: "15px" }}>
                                <Button  disabled={isDisabled === false ? !passwordVerify === true : true} style={{ width: "100%", height: "2.5rem", fontSize: "14px", backgroundColor: "#00698f", borderRadius: "18px", color: "#ffffff" }} type="primary" htmlType="submit">
                                    Delete Account
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </Col>
            </Row>
            <AlertMessage
                isModalVisible={suspendMessage}
                subBody="We will get back to you"
                body={suspendMessage}
                failureModal={suspendMessage}
                onOk={handleOk}
            />
        </Space>
    )
    // Customizable Area End
}

export default DeleteAccount;
