import React, { Component } from "react";
// Customizable Area Start
import {
  PageHeader,
  Card,
  Row,
  Col,
  Form,
  Input,
  Typography,
  Button,
  Spin,
  Space,
  Select,
  Avatar,
  Upload,
  Image
} from "antd";

const { Title } = Typography;
import { AimOutlined } from "@ant-design/icons";
import { logo, NETWORK_PROFILE_ING, editLogo } from "../assets";
const style = require(`../../../social-media-account/src/login.module.css`);
import "../profile.css";
import EditBusinessBasicController from "../EditBusinessBasicController.web";
import CropModal from "../CommonUI/CropModal.web"
const { Option } = Select;
import PlacesAutocomplete from "react-places-autocomplete";

interface PlacesAutocompleteProps {
  getInputProps?: any;
  suggestions?: any;
  getSuggestionItemProps?: any;
  loading: any;
}
// Customizable Area End
export default class EditBusinessProfileBasic extends EditBusinessBasicController {
  // Customizable Area Start
  handleShowOtherBusinessIndustry = (val: any) => {
    if (val == "Other") {
      this.setState({ otherBusinessIndustryvisible: true });
    } else {
      this.setState({ otherBusinessIndustryvisible: false });
    }
  }
  getUserLocattion = () => {
    return (
      <div>
        <PlacesAutocomplete
          value={this.state.address}
          onChange={(address) => this.setState({ address })}
          onSelect={this.handleSelect}
        >
          {({ getInputProps, suggestions, getSuggestionItemProps, loading }: PlacesAutocompleteProps) => (
            <div>
              <div className="autocomplete-dropdown-container">
                {<div style={{
                  boxShadow: "0px 2px 7px 0px #888888",
                  maxHeight: '120px',
                  overflowY: "auto"
                }}>
                  {
                    suggestions.map((suggestion: {
                      terms: any;
                      formattedSuggestion: any;
                      active: any;
                      description: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined;
                    }) => {
                      const style = {
                        backgroundColor: suggestion.active ? "#41b6e6" : "#fff",
                        padding: '14px',
                        borderBottom: '1px solid rgb(0, 0, 0, 0.05)'
                      };
                      return (
                        <div className="input-suggestion"
                          {...getSuggestionItemProps(suggestion, { style })}>
                          <span style={{ paddingLeft: "1rem" }}>
                            {
                              this.handleval(suggestion)
                            }
                          </span>
                        </div>
                      );
                    })
                  }
                </div>}
              </div>
              <div className="input-location">
                {/* <img src={locationIcon} alt="" width={14} /> */}
                <Input {...getInputProps({})}
                  className={style.input_fields}
                  placeholder="Location"
                  // style={webstyle.locationSearchInput}
                  suffix={<AimOutlined
                    style={{ color: "#005574" }}
                  // onClick={() => this.getLocation()}
                  />}
                />
              </div>
            </div>
          )
          }
        </PlacesAutocomplete>
      </div >
    );
  }
  renderSubCategory = () => {
    return (
      <Form.Item
        rules={[
          {
            required: true,
            message: "Required field"
          }
        ]}
        style={{ borderWidth: 1 }}
        name="business_industry"
      >
        <Select
          data-testid="businessIndustry"
          id={"select"}
          className={style.input_fields_select}
          placeholder="Business Industry / Category"
          bordered={true}
          dropdownStyle={{
            borderRadius: "15px",
            border: "1px solid #a9bac8",
            paddingTop: "0px",
            paddingBottom: "0px",
          }}
          notFoundContent={
            this.state.loadSubCategory ? (
              <Spin size="small" />
            ) : (
              "No Data Found"
            )
          }
          onSelect={(val) => this.handleShowOtherBusinessIndustry(val)}
          onChange={this.handleChange}
        >
          {this.state.sub_category &&
            this.state.sub_category.map((item: any) => {
              return (
                <option
                  style={{
                    borderBottom: "1px solid #7aacd6",
                    padding: "10px 20px 10px 20px"
                  }}
                  value={item.name}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%"
                    }}
                  >
                    <div>{item.name}</div>
                  </div>
                </option>
              );
            })}
        </Select>
      </Form.Item>
    )
  }
  renderBusinessOwnership = () => {
    return (
      this.state.businessOwnership.map(
        (item: any, index: any) => {
          return (
            <option
              style={{
                borderBottom: "1px solid #7aacd6",
                padding: "10px 20px 10px 20px"
              }}
              key={index}
              value={item.name}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%"
                }}
              >
                <div>{item.name}</div>
              </div>
            </option>
          );
        }
      )
    )
  }
  renderYearList = () => {
    return (
      this.state.yearList.map((item: number) => {
        return (
          <option
            style={{
              borderBottom: "1px solid #7aacd6",
              padding: "10px 20px 10px 20px"
            }}
            value={item}
            key={item}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%"
              }}
            >
              <div>{item}</div>
            </div>
          </option>
        );
      })
    )
  }
  renderNoOfEmployeeList = () => {
    return (
      this.state.noOfEmployee.map((item: any, id: number) => {
        return (
          <option
            style={{
              borderBottom: "1px solid #7aacd6",
              padding: "10px 20px 10px 20px"
            }}
            value={item.name}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%"
              }}
            >
              <div>{item.name}</div>
            </div>
          </option>
        );
      })
    )
  }
  renderLocationField = () => {
    return (
      <Form.Item shouldUpdate>
        {() => {
          return (
            <Form.Item rules={[
              { required: true, message: "Required field" },
            ]}
              name="location">
              <Input
                className={style.input_fields}
                placeholder="Location"
                onChange={e => {
                  const Value = e.target.value;
                  setTimeout(() => {
                    this.profile_formref?.current?.setFieldsValue({
                      location: Value
                    });
                  }, 0);
                }}
                size="large"
                style={{ borderWidth: 1, color: "#a9bac8" }}
                suffix={
                  <AimOutlined
                    style={{ color: "#005574" }}
                    onClick={() => this.getLocation()}
                  />
                }
              />
            </Form.Item>
          );
        }}
      </Form.Item>
    )
  }
  renderOtherBusinessIndustry = () => {
    return (
      <Form.Item
      >
        <Input
          data-testid="inputBusinessIndustry"
          placeholder="Other Business Industry / Category"
          className={style.input_fields}
          style={{ color: "#a9bac8", borderWidth: 1 }}
          onChange={(e: any) => {
            this.setState(
              {
                Other_Business_industry: e.target.value
              }
            )
          }}
          size="large"
          autoFocus
        />
      </Form.Item>
    )
  }
  handleBackgroundImage = () => {
    return (
      this.state.backgroundImage ? (
        <Image
          style={{ borderTopRightRadius: "5px", borderTopLeftRadius: "5px", objectFit: "cover" }}
          width="100%"
          height="270px"
          src={this.state.backgroundImage}
        />
      ) : (
        <Image
          style={{ borderTopRightRadius: "5px", borderTopLeftRadius: "5px", objectFit: "cover" }}
          width="100%"
          height="270px"
          src={this.state.updateProfile?.cover_photo}
        />
      )
    )
  }
  renderForm = (initialvaluesdata: any) => {

    return (
      <Form
        data-testid="addFormData"
        initialValues={initialvaluesdata}
        ref={this.profile_formref}
        onFinish={this.handleAddProfileData}
      >
        <div>
          <Card>
            <Form.Item
              name="cover_pic"
              rules={[{ required: true, message: "Required field" }]}
              className="Cover1"
            >
              <Col>
                <div className="cover-img" style={{
                  marginBottom: '40px', width: "100%",
                  height: "270px",
                }}>
                  {
                    this.state.backgroundImage || this.state.updateProfile?.cover_photo ? (
                      this.handleBackgroundImage()
                    )
                      :
                      <Upload
                        data-testid="backgroundImageEdit"
                        style={{ margin: '5px' }}
                        name="backgroundImage"
                        className={style.background_Image}
                        listType="picture-card"
                        multiple={false}
                        showUploadList={false}
                        beforeUpload={(file) => this.uploadBackgroundImage(file)}
                        onChange={(info) => {
                          this.setState({
                            backgroundImageFile: info.file.originFileObj
                          })
                        }
                        }
                      >
                        <div
                          style={{
                            width: "100%",
                            height: "100%",
                            display: 'flex',
                            alignItems: "center",
                            justifyContent: 'center',
                            border: '2px solid #a9bac8 ',
                            borderRadius: '7px'
                          }}
                        >
                          <p style={{ fontSize: 15, color: "#a9bac8" }}>
                            + Add Background Image
                          </p>
                        </div>
                      </Upload>
                  }
                  <Upload
                    data-testid='UploadImg'
                    name="file"
                    className="avatar-position-upload"
                    showUploadList={false}
                    multiple={false}
                    beforeUpload={file => this.beforeUpload(file)}
                    onChange={this.handleImageChange}
                    onRemove={() => {
                      this.setState({ profileUrl: "" });
                    }}
                  >
                    <div
                      style={{
                        position: "relative"
                      }}
                    >
                      {this.state.profileUrl ? <Avatar size={64} src={this.state.profileUrl} style={{}} /> : <Avatar size={64} src={this.state?.updateProfile?.photo} />}
                      <img
                        src={editLogo}
                        width={22}
                        style={{
                          position: "absolute",
                          top: "41px",
                          left: "45px"
                        }}
                      />
                    </div>
                  </Upload>
                  <Upload
                    data-testid='UploadImg'
                    name="file"
                    className="edit-cover-icon"
                    showUploadList={false}
                    multiple={false}
                    beforeUpload={(file) => this.uploadBackgroundImage(file)}
                    onChange={(info) => {
                      this.setState({
                        backgroundImageFile: info.file.originFileObj
                      })
                    }
                    }
                  >
                    <img
                      src={editLogo}
                      width={22}
                      style={{
                        position: "absolute",
                        bottom: "0px",
                        right: "0px"
                      }}
                    />
                  </Upload>
                </div>
              </Col>
            </Form.Item>
            <Row gutter={12}>
              <Col
                span={12}
                xs={24}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                xxl={12}
              >
                <Form.Item
                  rules={[
                    { required: true, message: "Required field" },
                    {
                      pattern: new RegExp(/^[a-zA-Z ]+$/i),
                      message: "input accept only alphabets"
                    }
                  ]}
                  name="company_name"
                >
                  <Input
                    className={style.input_fields}
                    placeholder="Company Name"
                    style={{ borderWidth: 1, color: "#a9bac8" }}
                    size="large"
                  />
                </Form.Item>
              </Col>
              <Col
                span={12}
                xs={24}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                xxl={12}
              >
                <Form.Item
                  rules={[
                    { required: true, message: "Required field" }
                  ]}
                  name="business"
                >
                  <Select
                    data-testid="selectBusiness"
                    id={"select"}
                    className={style.input_fields_select}
                    placeholder="Business"
                    bordered={true}
                    dropdownStyle={{
                      borderRadius: "15px",
                      border: "1px solid #a9bac8",
                      paddingTop: "0px",
                      paddingBottom: "0px",
                    }}
                    notFoundContent={
                      this.state.loading ? (
                        <Spin size="small" />
                      ) : (
                        "No Data Found "
                      )
                    }
                    onChange={this.handleSubCategory}
                  >
                    {this.state.businessIndustryList.map(
                      (item: any, index: any) => {
                        return (
                          <option
                            style={{
                              borderBottom: "1px solid #7aacd6",
                              padding: "10px 20px 10px 20px"
                            }}
                            key={item.name}
                            value={item.name}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "100%"
                              }}
                            >
                              <div>{item.name}</div>
                            </div>
                          </option>
                        );
                      }
                    )}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col
                span={12}
                xs={24}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                xxl={12}
              >
                {this.state.loadSubCategory ? (
                  <Spin />
                ) : (
                  this.renderSubCategory()
                )}
                {this.state.otherBusinessIndustryvisible && this.renderOtherBusinessIndustry()}
              </Col>
              <Col
                span={12}
                xs={24}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                xxl={12}
              >
                <Form.Item
                  rules={[
                    { required: true, message: "Required field" }
                  ]}
                  name="ownership_type"
                >
                  <Select
                    placeholder="Ownership Type"
                    className={style.input_fields_select}
                    allowClear
                    bordered={true}
                    dropdownStyle={{
                      borderRadius: "15px",
                      border: "1px solid #a9bac8",
                      paddingTop: "0px",
                      paddingBottom: "0px",
                    }}
                    onChange={this.handleChange}
                  >
                    {this.renderBusinessOwnership()}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col
                span={12}
                xs={24}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                xxl={12}
              >
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "Required field"
                    }
                  ]}
                  style={{ borderWidth: 1 }}
                  name="established_year"
                >
                  <Select
                    id={"select"}
                    className={style.input_fields_select}
                    placeholder="Established Year"
                    bordered={true}
                    dropdownStyle={{
                      borderRadius: "15px",
                      border: "1px solid #a9bac8",
                      paddingTop: "0px",
                      paddingBottom: "0px",
                    }}
                  >
                    {this.renderYearList()}
                  </Select>
                </Form.Item>
              </Col>
              <Col
                span={12}
                xs={24}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                xxl={12}
              >
                <Form.Item
                  rules={[
                    { required: true, message: "Required field" },
                    {
                      pattern: new RegExp(/^[a-zA-Z ]+$/i),
                      message: "input accept only alphabets"
                    }
                  ]}
                  name="founder"
                >
                  <Input
                    className={style.input_fields}
                    placeholder="Founder"
                    style={{ borderWidth: 1, color: "#a9bac8" }}
                    size="large"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col
                span={12}
                xs={24}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                xxl={12}
              >
                <Form.Item
                  data-testid='contactPerson'
                  rules={[
                    {
                      required: true,
                      message: "Required field"
                    }
                  ]}
                  name="contact_person"
                >
                  <Input
                    className={style.input_fields}
                    placeholder="Contact Person"
                    style={{ borderWidth: 1, color: "#a9bac8" }}
                    size="large"
                  />
                </Form.Item>
              </Col>
              <Col
                span={12}
                xs={24}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                xxl={12}
              >
                <Form.Item
                  rules={[
                    { required: true, message: "Required field" }
                  ]}
                  name="num_of_employees"
                  style={{ borderWidth: 1 }}
                >
                  <Select
                    id={"select"}
                    bordered={true}
                    dropdownStyle={{
                      borderRadius: "15px",
                      border: "1px solid #a9bac8",
                      paddingTop: "0px",
                      paddingBottom: "0px",
                    }}
                    className={style.input_fields_select}
                    placeholder="Number of Employees"
                    onChange={this.handleChange}
                    value={this.state.selectedNoOfEmployee}
                  >
                    {this.renderNoOfEmployeeList()}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col
                span={12}
                xs={24}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                xxl={12}
              >
                {this.state.loadLocation ? (
                  <Spin />
                ) : this.getUserLocattion()}
              </Col>
              <Col
                span={12}
                xs={24}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                xxl={12}
              >
                <Form.Item
                  rules={[
                    {
                      message: "eg: http://www.sample.com or https://www.sample.com/xyz",
                      pattern: new RegExp(/^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/)
                    }
                  ]}
                  name="website"
                >
                  <Input
                    className={style.input_fields}
                    placeholder="Website"
                    style={{ borderWidth: 1, color: "#a9bac8" }}
                    size="large"
                    type={"url"}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "15px",
            background: "#f8f8f8"
          }}
        >
          <Button
            className={style.btn_colors}
            type="primary"
            htmlType="submit"
            shape="round"
            size={"large"}
          >
            Next
          </Button>
        </div>
      </Form>
    )
  }
  renderBusnissProfileV2 = () => {
    const Industrytype = this.state.updateProfile?.industry_type
      ? this.state.updateProfile?.industry_type
      : this.state.prev_data?.industry_type
    const Businessindustry = this.state.prev_data?.business_industry ? this.state.prev_data?.business_industry : ''
    const initialvaluesdata = {
      company_name: this.state.updateProfile.company_name
        ? this.state.updateProfile.company_name
        : this.state.prev_data?.company_name,
      business: this.state.updateProfile.business
        ? this.state.updateProfile.business
        : Industrytype,
      business_industry: this.state.updateProfile.business_industry
        ? this.state.updateProfile.business_industry
        : Businessindustry,
      ownership_type: this.state.updateProfile.ownership_type
        ? this.state.updateProfile.ownership_type
        : this.state.prev_data?.ownership_type,
      established_year:
        this.state.updateProfile?.established_year
          ? this.state.updateProfile?.established_year
          : this.state.prev_data?.established_year,
      founder: this.state.updateProfile.founder
        ? this.state.updateProfile.founder
        : this.state.prev_data?.founder,
      contact_person: this.state.updateProfile.contact_person
        ? this.state.updateProfile.contact_person
        : this.state.prev_data?.contact_person,
      num_of_employees: this.state.updateProfile.num_of_employees
        ? this.state.updateProfile.num_of_employees
        : this.state?.prev_data?.num_of_employees,
      location: this.state.updateProfile?.location
        ? this.state.updateProfile?.location
        : this.state.prev_data?.location,
      website: this.state.updateProfile.website
        ? this.state.updateProfile.website
        : this.state.prev_data?.website,
      profile_pic: this.state.profileUrl
        ? this.state.profileUrl : this.state?.updateProfile?.photo,
      cover_pic: this.state.backgroundImage
        ? this.state.backgroundImage : this.state.updateProfile?.cover_photo
    }
    return (
      <div>
        <Row
          style={{
            minHeight: "90vh",
            backgroundColor: "#eff8fe",
            alignItems: "center"
          }}
        >
          <Col xs={0} sm={0} md={3} lg={6} xl={4} />
          <Col xs={23} sm={22} md={18} lg={15} xl={17}>
            <Title
              level={2}
              style={{
                textAlign: "center",
                marginTop: "50px",
                marginBottom: "15px"
              }}
            >
              Edit Your Business Profile
            </Title>
            <Col span={24} offset={1} className="basic_profile_card">
              {this.state.profileLoading ? (
                <Spin />
              ) : (this.renderForm(initialvaluesdata))}
            </Col>
            <Col xs={0} md={3} sm={0} lg={7} xl={8} />
          </Col>
        </Row>
      </div>
    );
  };

  renderCropModal = () => {
    return (
      <>
        <CropModal
          openCropModal={this.state.openCropModal}
          history={this.props.history}
          setCropModal={() => {
            this.setState({
              openCropModal: false
            })
          }}
          setCroppedImageUrl={(file) => {
            this.setState({
              profileUrl: file.url,
              openCropModal: false,
              file: file.pic
            })
          }}
          data-testid="CropModal"
          src={this.state.copyProfileUrl}
        />
      </>
    )
  }
  // Customizable Area End
  render() {
    // Customizable Area Start
    return <div>
      {this.renderBusnissProfileV2()}
      {this.renderCropModal()}
    </div>;
    // Customizable Area End
  }

}
// Customizable Area Start
const webstyle = {
  locationSearchInput: {
    fontFamily: "Lato sans-serif",
    width: "90%",
    minHeight: "30px",
    padding: "4px 11px",
    background: "none",
    border: "none",
    borderRadius: "5px",
    fontSize: "14px",
    color: "#757575",
    fontWeight: "bold",
  }
}
//Customizable Area End
