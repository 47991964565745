import React from "react";
// Customizable Area Start
import "../CommonUI/otherProfile.css";
import { horizentalTripleDot } from "../assets"
import OtherProfileController from "../OtherProfileController.web";
import SliderView from "../CommonUI/SliderView.web";
import NPSChartView from "../CommonUI/NPS_Chart.web";
import ReviewDataView from "../CommonUI/ReviewDataView.web";
import AddReviewView from "../CommonUI/AddReviewView.web";
import BlockUnblockAlert from "../CommonUI/BlockUnblockAlert.web";
import { Layout, Space, Row, Col, Image, Card, Avatar, List, Typography, Button, Tabs, Divider, Modal, Spin, Progress, Empty, Select, Menu, Dropdown } from 'antd';
const { Text, Title, Paragraph } = Typography;
const { TabPane } = Tabs;
import {
    EnvironmentOutlined,
    UserOutlined,
    ZoomOutOutlined,
    CloseOutlined,
    ZoomInOutlined,
    RotateLeftOutlined,
    RotateRightOutlined,
    EyeOutlined,
} from '@ant-design/icons';
// import SelectCategory from "../CommonUI/SelectCategory.web";
import SendRequestModal from "../CommonUI/SendRequestModal.web";
import UserPost from "../CommonUI/UserPost.web";
import { CommonDialog } from "../CommonUI/CommonDialog.web";
import { FollowUnfollowAlert } from "../CommonUI/FollowUnfollowAlert";
import { ViewAllDialog } from "../CommonUI/ViewAllDialog.web";
import RatingView from "../CommonUI/RatingView.web";
import AboutAndStats from "../CommonUI/AboutAndStats.web";
import ReportUser from "../CommonUI/ReportUser.web";
import { StopOutlined } from '@ant-design/icons';
import CommunityAlert from "../../../CommunityForum/src/CommunityAlert.web";
import moment from "moment";
// Customizable Area End

export default class OtherBusinessProfile extends OtherProfileController {
    // Customizable Area Start
    render() {
        const { profileData, ratingOption, isModal, isAddReview, isAddAppliedViewRating, isAddRatingRateAsCustomer, isRatingViewShow, ratingView, ratingViewName, ratingViewName_2, reviewData, isLoading, isSelectCategoryModalVisible, errorMessageOnOk, nextPageLoader, cover_image, ratingData } = this.state;
        const account_id = profileData?.account_id;
        const { navigation } = this.props;
        const onCanclepopup = () => {
            return this.setState({ isSuccessfullModal: false })
        }

        return (
            <Layout>
                {this.redirectUser()}
                <div style={{ backgroundColor: "rgba(245, 245, 245, 1)", height: '100%', minHeight: '90vh' }}>
                    {isLoading ?
                        <div style={{ margin: 12 }}>
                            <Spin />
                        </div> : <>
                            {!this.state.suspendedAccount ?
                                <Row>
                                    <Col xs={{ span: 24 }} lg={{ span: 18 }}>
                                        <Profile
                                            data-testid='Profile'
                                            data={profileData}

                                            handleClick={this.handleOpenModal}
                                            handleReviewClick={this.handleOpenAddReviewModal}
                                            handleFollow={this.onHandleFollow}
                                            handleFollowing={this.handleFollowUnfollowOpen}
                                            handleConnect={this.onClickConnect}
                                            handleAddAppliedViewRatingOpen={this.handleAddAppliedViewRatingOpen}
                                            isLoading={isLoading}
                                            handleChat={this.handleChatPush}
                                            acceptRequest={this.acceptRequest}
                                            handleBlock={this.handleBlockOpen}
                                            handleReportOpen={this.handleReportOpen}
                                            handleRemoveConnection={this.handleRemoveConnection}
                                            handleHideCoverImage={this.handleHideCoverImage}
                                            handleSentConnectionsList={this.handleCancelConnectionRequest}
                                            downloadResumeWithAxios={this.downloadResumeWithAxios}
                                            downloadResumeLoading={this.state.downloadResumeLoading}
                                            nextPageLoader={nextPageLoader}
                                            handleShowPreview={this.handleShowPreview}
                                            cover_image={cover_image}
                                            handleDecreaseWidth={this.handleDecreaseWidth}
                                            preview={this.state.preview}
                                            handleHidePreview={this.handleHidePreview}
                                            handleRotateImageLeft={this.handleRotateImageLeft}
                                            handleRotateImageRight={this.handleRotateImageRight}
                                            imageRotationDegree={this.state.imageRotationDegree}
                                            handleShowCoverImage={this.handleShowCoverImage}
                                            displayCoverImage={this.state.displayCoverImage}
                                            handleIncreaseWidth={this.handleIncreaseWidth}
                                            accountPlan={this.state.accountPlan}
                                            widthhchange={this.state.widthhchange}
                                            RatingOption={ratingOption}
                                            lastSeen={this.state.lastSeen}
                                        />
                                        {!isLoading ? <UserPostRatingReview
                                            data-testid='UserPostRatingReview'
                                            isPostView={this.state.isPostView}
                                            data={profileData}
                                            handleFollow={this.onHandleFollow}
                                            handleFollowing={this.handleFollowUnfollowOpen}
                                            activeTab={this.state.activeTabForPost}
                                            getPosts={this.getBusinessPosts}
                                            postDataArray={this.state.postsDataArray}
                                            isPrivate={this.state.isPrivate}
                                            isRatingVisible={this.state.isRatingVisible}
                                            accountId={this.state.account_id}
                                            handleRatingView={this.handleRatingView}
                                            reviewData={reviewData}
                                            ratingData={ratingData}
                                            isLoading={this.state.isPostLoading}
                                            noOfPostData={this.state.noOfPostData}
                                            handlePostList={this.handlePostList}
                                        /> : <Spin />}
                                    </Col>
                                    {!this.state.isPrivate &&
                                        <Col xs={{ span: 24 }} lg={{ span: 6 }}>
                                            <ContactDetails
                                                data={profileData}
                                                isLoading={isLoading}
                                            />
                                            <BasicInfo
                                                data={profileData}
                                                isLoading={isLoading}
                                            />
                                            <History
                                                data-testid='History'
                                                data={profileData}
                                                isLoading={isLoading}
                                                handleOpen={this.handleViewAllOpen}
                                            />
                                            <Expansion
                                                data={profileData}
                                                isLoading={isLoading}
                                                handleOpen={this.handleViewAllOpen}
                                            />
                                            <CoreTeam
                                                data={profileData}
                                                isLoading={isLoading}
                                                handleOpen={this.handleViewAllOpen}
                                            />
                                        </Col>
                                    }
                                </Row>
                                :
                                <Row style={{ marginTop: 24, marginLeft: 18, marginRight: 18 }} gutter={[6, 6]}>
                                    <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                                        <div style={{
                                            background: "#fff",
                                            height: '100%',
                                            padding: 20,
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}>
                                            <Avatar style={{ backgroundColor: "#919191" }}
                                                size={64}
                                                src={this.state.suspendedAccountData?.photo ? this.state.suspendedAccountData?.photo :
                                                    <UserOutlined />} />
                                            <div style={{ margin: "10px" }}>
                                                <Text style={{
                                                    fontSize: 18,
                                                    fontWeight: 500,
                                                }}>{this.state.suspendedAccountData?.full_name}</Text>
                                                <div>
                                                    <span style={{ color: "#da0e2a" }}> <StopOutlined /> this account is suspended by user</span>
                                                </div>

                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            }

                        </>}
                </div>

                <Modal
                    width={450}
                    data-testid='Acceptrequestmodal'
                    style={{ borderWidth: "0px", borderRadius: "4px", overflow: "hidden" }}
                    centered
                    className="Accept-request-modal"
                    title={
                        <div style={{ width: "100%", cursor: "move", fontWeight: "bold", borderWidth: "0px", paddingRight: '24px' }}>
                            {profileData?.full_name} wants to connect with you as {' '}
                            {profileData?.received_request?.sender_relation}
                        </div>}
                    visible={this.state.isShowInvitationModal}
                    bodyStyle={{ paddingTop: "5px" }}
                    onCancel={() => this.setState({ isShowInvitationModal: false })}
                    footer={[
                        <div style={{ display: 'flex', justifyContent: 'space-between', margin: '0 10px' }}>
                            <Button data-testid="reportbutton" size="large" className="report-button" style={{ width: "45%" }} key="accept"
                                onClick={() => {
                                    if (this.state.changeRelationShipAccept) {
                                        this.setState({ isShowInvitationModal: false });
                                        this.acceptRequest({ type: "accepted", ...profileData.received_request })
                                    } else {
                                        this.setState({
                                            checkModalForAccept: true
                                        })
                                    }
                                }}
                            >Accept Request</Button>
                            <Button data-testid="rejectbutton" size="large" className="reject-button" style={{ width: "45%" }} key="reject"
                                onClick={() => {
                                    this.setState({ isShowInvitationModal: false });
                                    this.acceptRequest({ type: "rejected", ...profileData.received_request })
                                }}
                            >Reject Request</Button>
                        </div>
                    ]}
                >
                    <Row gutter={12}>
                        <Col xs={24} >
                            <p>Change relationship to</p>
                            <Select
                                id={"select"}
                                style={{
                                    padding: "0 !important",
                                    border: "2px solid #a9bac8",
                                    borderRadius: '27px',
                                    overflow: "hidden",
                                    width: "100%"
                                }}
                                placeholder="Select Relationship"
                                bordered={true}
                                dropdownStyle={{
                                    border: "2px solid #a9bac8",
                                    borderRadius: "10px",
                                    paddingTop: "0px",
                                    paddingBottom: "0px",
                                    width: "100%"
                                }}
                                value={profileData?.received_request?.sender_relation}
                                onChange={this.changeInviteRelationship}
                            >
                                {this.state.loadInvitationData && this.state.loadInvitationData.map((item: any, id: number) => <option key={item.id}
                                    style={{
                                        borderBottom: this.state.loadInvitationData?.length - 1 > id ? "1px solid #a9bac8" : "none",
                                        padding: "10px 20px 10px 20px"
                                    }}
                                    value={item.name}>
                                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                        <div className="capital_options" style={{ color: "#a9bac8" }}>{item.name}</div>
                                    </div>
                                </option>)}
                            </Select>
                            {this.state.checkModalForAccept
                                && !this.state.changeRelationShipAccept &&
                                <Text style={{ color: 'red', fontSize: 14 }}>please change relationship </Text>
                            }
                        </Col>
                    </Row>
                </Modal>
                {isAddReview && <ModalAddReview
                    data-testid="ModalAddReview"
                    open={isAddReview}
                    handleCancel={this.handleAddReviewCancel}
                    data={account_id}
                    disableAnonymous={this.state.disableAnonymous}
                    hideAnonymous={this.state.hideAnonymous}
                    onFinish={this.onFinishAddReview}
                />}
                <ModalAbout
                    data-testid='ModalAbout'
                    data={profileData}
                    open={isModal}
                    handleCancel={this.handleCancel}
                />
                <ModalAddAppliedViewRating
                    data-testid='ModalAddAppliedViewRating'
                    open={isAddAppliedViewRating}
                    handleCancel={this.handleAddAppliedViewRatingCancel}
                    handleSave={this.handleSaveAndNext}
                    generalRating={this.state.generalRating}
                    generalRatingChange={this.generalRatingChange}
                />
                <ModalAddRatingRateAsCustomer
                    data-testid='ModalAddRatingRateAsCustomer'
                    open={isAddRatingRateAsCustomer}
                    handleCancel={this.handleAddRatingRateAsCustomerCancel}
                    handleBack={this.handleAddRatingBack}
                    userRateItems={this.state.userRateItems}
                    userRatingChange={this.userRatingChange}
                    submitUsersRating={this.submitUsersRating}
                />
                <ModalRatingView
                    date-testid='ModalRatingView'
                    open={isRatingViewShow}
                    ratingViewData={ratingView}
                    ratingViewName={ratingViewName}
                    handleRatingNPSChart={this.handleRatingNPSChart}
                    handleCancel={this.handleRatingViewCancel}
                />
                <ModalNPSChart
                    data-testid='ModalNPSChart'
                    openNPS={ratingViewName_2}
                    handleCancel={this.handleNPSChartCancel}
                />
                <SendRequestModal
                    data-testid='SendRequestModal'
                    sendRequestRelations={this.state.sendRequestRelations}
                    handleChange={this.handleSelectChange}
                    isModalVisible={isSelectCategoryModalVisible}
                    handleOk={this.handleOnClickConnect}
                    handleCancel={this.handleSelectCategoryCancel}
                    errorMessage={errorMessageOnOk}
                    userName={profileData?.profile?.data?.company_name}
                />
                <CommonDialog
                    date-testid='CommonDialog'
                    body={"Already Reviewed!"}
                    isModalVisible={this.state.alertReview}
                    onOk={this.handleAlertReviewOk}
                />
                <CommonDialog
                    body={"Review Submitted Successfully"}
                    isModalVisible={this.state.isCompleteReview}
                    onOk={this.handleAlertReviewOk}
                />
                <FollowUnfollowAlert
                    data-testid='FollowUnfollowAlert'
                    handleOk={this.onHandleFollow}
                    handleCancel={this.handleFollowUnfollowCancel}
                    visible={this.state.followUnfollowAlert}
                />
                <ViewAllDialog
                    data-testid='ViewAllDialog'
                    body={this.state.viewAllBody}
                    title={this.state.viewAllTitle}
                    visible={this.state.viewAllModal}
                    handleCancel={this.handleViewAllCancel}
                />
                <BlockUnblockAlert
                    data-testid='BlockUnblockAlert'
                    visible={this.state.blockAlertOpen}
                    handleUnblock={this.handleUnblock}
                    handleCancel={this.handleBlockCancel}
                    fullName={profileData?.company_name}
                    accountId={account_id}
                    blocked={profileData?.block_accounts?.[0]?.journal_id === account_id}
                />
                <ReportUser
                    data-testid='ReportUser'
                    visible={this.state.reportOpen}
                    handleCancel={this.handleReportClose}
                    onFinish={this.onFinishReport}
                />
                <CommunityAlert
                    modal={this.state.isSuccessModal}
                    modalStatus={this.state.isSuccessModal}
                    handleCancel={() => { this.setState({ isSuccessModal: false }) }}
                    heading={""}
                    desc={this.state.getMessage}
                />
                <CommunityAlert
                    modal={this.state.isModalOpen}
                    modalStatus={this.state.isModalOpen}
                    handleCancel={() => { this.setState({ isModalOpen: false }) }}
                    heading={"Request Sent Successfully"}
                    desc={`Your Connection Request to ${this.state.modelData?.name} as ${this.state.category_id} Sent Successfully`}
                />

            </Layout >
        );
    }
    // Customizable Area End
}
// Customizable Area Start


interface MyNPSChartProps {

    openNPS?: {
        name: string,
        npsData: any
    }
    handleCancel: () => void

}

export class ModalNPSChart extends React.Component<MyNPSChartProps> {

    render() {
        return <Modal
            title={
                <Space
                    style={{
                        width: "100%",
                        cursor: "move",
                        fontWeight: "bold",
                        color: "rgba(0, 0, 0, 0.85)"
                    }}
                >
                    {this.props.openNPS?.name}
                </Space>
            }
            visible={this.props?.openNPS?.name !== ""}
            footer={null}
            bodyStyle={{
                width: "100%",
                height: "100%",
                borderRadius: "5px",
                overflowY: "auto",
                backgroundColor: "#f1f0f5"
            }}
            centered
            onCancel={() => this.props.handleCancel()}
        >
            <NPSChartView npsData={this.props.openNPS?.npsData} />
        </Modal>
    }

}


interface AddRatingRateAsCustomerProps {

    open?: boolean
    handleCancel: () => void
    handleBack: () => void
    userRateItems: any
    userRatingChange: any
    submitUsersRating: any

}

export class ModalAddRatingRateAsCustomer extends React.Component<AddRatingRateAsCustomerProps> {

    render() {

        return <Modal
            footer={null}
            centered
            bodyStyle={{
                overflowX: "hidden",
                width: "100%",
                paddingTop: "0px",
                maxHeight: "80vh",
                overflowY: "scroll",
                borderRadius: "5px",
            }}
            title={
                <Space
                    style={{
                        width: "100%",
                        cursor: "move",
                        fontWeight: "bold",
                        color: "rgba(0, 0, 0, 0.85)"
                    }}
                >
                    Add Rating
                </Space>
            }
            visible={this.props.open}
            onCancel={() => this.props.handleCancel()}
        >
            <div
                style={{
                    paddingRight: "20px",
                    width: "100%",
                    flexDirection: "column",
                    display: "flex",
                }}
            >
                <Title
                    style={{
                        marginBottom: "1rem",
                        color: "rgba(0, 0, 0, 0.85)",
                        fontWeight: "normal"
                    }}
                    level={5}
                >
                    Rate as {this.props.userRateItems?.name}
                </Title>
                {
                    this.props.userRateItems?.array?.map((item: any, index: Number) => item.name && <Space
                        direction="vertical"
                        key={item.name}
                    >
                        <SliderView
                            description={item.description}
                            name={item.name}
                            changeHandler={(val: any) => this.props.userRatingChange({ name: item.name, value: val, index: index })}
                            value={item.value}
                        />
                    </Space>)
                }
            </div>
            <div
                style={{
                    width: "100%",
                    marginTop: "1rem",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between"
                }}
            >
                <Button
                    type="default"
                    style={{
                        width: "45%",
                        height: "2.5rem",
                        borderRadius: "25px",
                        border: "1px solid #4080c0",
                        color: "#006285"
                    }}
                    onClick={() => this.props.handleBack()}
                >
                    Back
                </Button>
                <Button
                    type="primary"
                    style={{
                        width: "45%",
                        height: "2.5rem",
                        borderRadius: "25px",
                        backgroundColor: "#006285",
                        color: "#ffffff"
                    }}
                    onClick={this.props.submitUsersRating}
                >
                    Submit
                </Button>
            </div>
        </Modal>
    }

}

interface AddRatingAppliedViewProps {

    open?: boolean
    handleCancel: () => void
    handleSave: () => void
    generalRating: any
    generalRatingChange: any

}

export class ModalAddAppliedViewRating extends React.Component<AddRatingAppliedViewProps> {


    render() {

        return <Modal
            footer={null}
            centered
            bodyStyle={{
                paddingTop: "0px",
                width: "100%",
                borderRadius: "5px",
                overflowY: "scroll",
                overflowX: "hidden",
                maxHeight: "80vh",

            }}
            title={
                <Space
                    style={{
                        width: "100%",
                        cursor: "move",
                        fontWeight: "bold",
                        color: "rgba(0, 0, 0, 0.85)"
                    }}
                >
                    Add Rating
                </Space>
            }
            visible={this.props.open}
            onCancel={() => this.props.handleCancel()}
        >
            <div
                style={{
                    width: "100%",
                    display: "flex",
                    paddingRight: "20px",
                    flexDirection: "column",

                }}
            >
                <Title
                    style={{
                        color: "rgba(0, 0, 0, 0.85)",
                        fontWeight: "normal",
                        marginBottom: "1rem",

                    }}
                    level={5}
                >
                    Applied View Rating
                </Title>
                {
                    this.props.generalRating?.array?.map((item: any, index: any) => item.name && <Space
                        direction="vertical"
                        key={item.name}
                    >
                        <SliderView
                            description={item?.description}
                            name={item?.name}
                            changeHandler={(val: any) => this.props.generalRatingChange({ name: item?.name, value: val, index: index })}
                            value={item?.value}
                        />
                    </Space>
                    )
                }
            </div>
            <Button
                style={{
                    width: "100%",
                    height: "2.5rem",
                    borderRadius: "25px",
                    backgroundColor: "#006285",
                    color: "#ffffff",
                    marginTop: "1rem"
                }}
                onClick={() => this.props.handleSave()}
            >
                Save & Next
            </Button>
        </Modal>
    }

}

interface MyProps {

    data?: any
    accId?: number
    isLoading: boolean

}
interface MyModalProps {

    open?: boolean
    data?: any
    handleCancel: () => void

}
interface MyAddReviewProps {

    open?: boolean
    handleCancel: () => void
    data?: string
    disableAnonymous: boolean
    hideAnonymous: boolean
    onFinish: (value: any) => void

}

export class ModalAddReview extends React.Component<MyAddReviewProps> {


    render() {
        return <Modal
            footer={null}
            bodyStyle={{
                width: "100%",
                maxHeight: "80vh",
                borderRadius: "5px",
            }}
            title={
                <Space
                    style={{
                        width: "100%",
                        cursor: "move",
                        fontWeight: "bold",
                        color: "rgba(0, 0, 0, 0.85)"
                    }}
                >
                    Add Review
                </Space>
            }
            visible={this.props.open}
            onCancel={() => this.props.handleCancel()}
        >
            <AddReviewView
                disableAnonymous={this.props.disableAnonymous}
                hideAnonymous={this.props.hideAnonymous}
                data={this.props.data}
                onFinish={this.props.onFinish}
            />
        </Modal>
    }

}

interface RatingViewProps {

    open?: boolean
    ratingViewData?: Array<any>
    ratingViewName?: string
    handleRatingNPSChart: (name: string, data: any) => void
    handleCancel: () => void

}
export class ModalRatingView extends React.Component<RatingViewProps> {


    render() {
        return <Modal
            title={
                <Space
                    style={{
                        width: "100%",
                        cursor: "move",
                        fontWeight: "bold",
                        color: "rgba(0, 0, 0, 0.85)"
                    }}
                >
                    {this.props.ratingViewName}
                </Space>
            }
            visible={this.props.open}
            footer={null}
            bodyStyle={{
                width: "100%",
                maxHeight: "80vh",
                borderRadius: "5px",
                overflowY: "auto",
                backgroundColor: "#f1f0f5"
            }}
            centered
            onCancel={() => this.props.handleCancel()}
        >
            {
                this.props?.ratingViewData?.map((item, id) => {
                    if (id !== 7) {
                        const score = 0
                        if (item.score === null) {
                            return <div
                                key={id}
                            >
                                <Card
                                    style={{ width: "100%", cursor: "pointer", marginTop: "1rem" }}
                                    onClick={() => this.props.handleRatingNPSChart(item.name, item.nps_rating)}
                                >
                                    <Progress type="circle" width={110} percent={score} />
                                    <Text style={{ marginLeft: "2rem", color: "rgba(0, 0, 0, 0.85)" }}>{item.name}</Text>
                                </Card>
                            </div>
                        } else {
                            return (
                                <div
                                    key={id}
                                >
                                    <Card
                                        style={{ width: "100%", cursor: "pointer", marginTop: "1rem" }}
                                        onClick={() => this.props.handleRatingNPSChart(item.name, item.nps_rating)}
                                    >
                                        <Progress type="circle" width={110} percent={item.score} />
                                        <Text style={{ marginLeft: "2rem" }}>{item.name}</Text>
                                    </Card>
                                </div>
                            )
                        }
                    }
                })
            }
        </Modal>
    }

}

export class ModalAbout extends React.Component<MyModalProps> {


    render() {
        const aboutList = [
            {
                id: 1,
                heading_name: "Overview",
                desc: this.props?.data?.company_overview
            },
            {
                id: 2,
                heading_name: "Mission",
                desc: this.props?.data?.company_mission
            },
            {
                id: 3,
                heading_name: "Vision",
                desc: this.props?.data?.company_vision
            },
            {
                id: 4,
                heading_name: "Core Values",
                desc: this.props?.data?.company_core_values
            },
            {
                id: 5,
                heading_name: "Health, Safety and Environment",
                desc: this.props?.data?.health_safety_environment
            },
            {
                id: 6,
                heading_name: "Products & Services",
                desc: this.props?.data?.company_overview
            },
        ]
        return <Modal
            title={
                <Space
                    style={{
                        width: "100%",
                        cursor: "move",
                        fontWeight: "bold",
                    }}
                >
                    About {this.props?.data?.company_name}
                </Space>
            }
            visible={this.props?.open}
            footer={null}
            bodyStyle={{
                width: "100%",
                maxHeight: "80vh",
                borderRadius: "5px",
                overflowY: "auto"
            }}
            centered
            onCancel={() => this.props.handleCancel()}
        >
            {
                aboutList.map((item) => {
                    return (
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                            }}
                            key={item.id}
                        >
                            <Title level={5} style={{ color: "rgba(0, 0, 0, 0.85)" }}>{item.heading_name}</Title>
                            <Paragraph style={{ color: "#919191" }}>{item.desc}</Paragraph>
                        </div>
                    )
                })
            }
        </Modal>
    }

}

interface MyProfileProps {
    lastSeen: any
    data?: any
    handleClick: () => void
    handleReviewClick: () => void
    handleFollow: () => void
    handleFollowing: () => void
    handleConnect: () => void
    handleAddAppliedViewRatingOpen: () => void
    isLoading: boolean
    handleChat: () => void
    acceptRequest: any
    handleBlock: () => void
    handleReportOpen: () => void
    handleRemoveConnection: (id: any) => void
    handleSentConnectionsList: () => void
    downloadResumeWithAxios: () => void
    downloadResumeLoading: boolean
    nextPageLoader?: boolean
    widthhchange: any
    handleIncreaseWidth: () => void
    handleDecreaseWidth: () => void
    displayCoverImage: any
    handleHideCoverImage: () => void
    handleShowCoverImage: () => void
    imageRotationDegree: any
    handleRotateImageRight: () => void
    handleRotateImageLeft: () => void
    handleShowPreview: () => void
    preview: boolean
    handleHidePreview: () => void
    cover_image: any;
    accountPlan: string;
    RatingOption: boolean
}

export class Profile extends React.Component<MyProfileProps> {
    handleInvited = (isInvited: any) => {
        if (isInvited) {
            return "Requested"
        } else {
            return "Connect"
        }
    }
    renderButton = (isInvited: any) => {
        return (
            this.props?.data?.is_blocked ?
                <Button
                    type="default"
                    data-testid='Unblock'
                    shape="round"
                    style={{ border: "1px solid #4080c0", width: isInvited ? "7rem" : "6.5rem", color: "#4080c0" }}
                    onClick={() => this.props.handleBlock()}
                    disabled={isInvited ? true : false}
                >
                    Unblock
                </Button>
                : <Button
                    type="default"
                    data-testid='Connect'
                    shape="round"
                    style={{ border: "1px solid #4080c0", width: isInvited ? "7rem" : "6.5rem", color: "#4080c0" }}
                    onClick={() => this.props.handleConnect()}
                    disabled={this.props?.data?.block_accounts?.[0]?.journal_id === this.props.data.account_id}
                >

                    {this.handleInvited(isInvited)}
                </Button>

        )
    }
    renderFollowButton = (isFollowing: boolean, isBlocked: boolean) => {
        return (
            <Button
                shape="round"
                data-testid="Following"
                style={{ border: "1px solid #4080c0", width: isFollowing ? "7rem" : "6rem", color: isFollowing ? "#ffffff" : "#4080c0", backgroundColor: isFollowing ? "#4080c0" : "#ffffff" }}
                onClick={() => { this.handleFollowingdata(isFollowing) }}
                disabled={isBlocked}
            >
                {
                    isFollowing ? "Following" : "Follow"
                }
            </Button>
        )
    }
    handleFollowingdata = (isFollowing: any) => {
        if (isFollowing) {
            this.props.handleFollowing()
        } else {
            this.props.handleFollow()
        }
    }
    renderUi = (props: any) => {
        const { isFollowing, isConnected, rating_option, isInvited, isBlocked, isRequested, requestedObj, menu } = props
        return (
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", flexWrap: "wrap" }}>
                <div style={{ marginBottom: "5px", }}>
                    {this.renderFollowButton(isFollowing, isBlocked)}
                </div>
                <div style={{ marginLeft: "10px", marginBottom: "5px", display: (isConnected || isRequested) ? "none" : "block", pointerEvents: isBlocked ? "none" : "auto" }}>
                    {
                        this.renderButton(isInvited)
                    }
                </div>
                <div style={{ marginLeft: "10px", display: (isRequested) ? "block" : "none" }}>

                    <Button
                        style={{ border: "1px solid #6ccc3e", color: "#6ccc3e" }}
                        data-testid='openAcceptModal'
                        shape="round"
                        onClick={() => this.props.acceptRequest({ type: "openAcceptModal" })}
                    >
                        Accept
                    </Button>
                </div>
                <div style={{ marginLeft: "10px", display: (isRequested) ? "block" : "none" }}>
                    <Button
                        shape="round"
                        data-testid="RequestRejected"
                        danger
                        onClick={() => this.props.acceptRequest({ type: "rejected", ...requestedObj })}
                    >
                        Reject
                    </Button>
                </div>
                <div style={{ marginLeft: "10px", marginBottom: "5px", display: isConnected || rating_option ? "block" : "none" }}>
                    <Button
                        style={{ backgroundColor: "#4080c0", width: "5rem", color: "#ffffff" }}
                        data-testid='AddAppliedViewRating'
                        shape="round"
                        onClick={() => this.props.handleAddAppliedViewRatingOpen()}
                    // disabled={true}
                    >
                        Rate
                    </Button>
                </div>
                <div style={{ marginLeft: "10px", marginBottom: "5px", display: isConnected ? "block" : "none" }}>
                    <Button
                        style={{ backgroundColor: "#4080c0", width: "5.5rem", color: "#ffffff" }}
                        data-testid='Review'
                        shape="round"
                        onClick={() => this.props.handleReviewClick()}
                    >
                        Review
                    </Button>
                </div>
                {/* <div style={{ marginLeft: "10px", marginBottom: "5px", display: isConnected ? "block" : "none" }}>
                    <Button
                        data-testid='downloadresume'
                        style={{
                            backgroundColor: "#4080c0", width: "9rem", color: "#ffffff"
                        }}
                        onClick={() => {
                            // message.info('Coming soon')
                            if (!this.props.downloadResumeLoading) {
                                this.props.downloadResumeWithAxios()
                            }
                        }}
                        shape="round"
                    >
                        {this.props.downloadResumeLoading ? <Spin /> : "Download Profileee"}
                    </Button>
                </div> */}
                <div style={{ display: isConnected ? "block" : "none", marginLeft: "10px", marginBottom: "5px" }}>
                    <Button
                        onClick={this.props.handleChat}
                        style={{ backgroundColor: "#4080c0", width: "5rem", color: "#ffffff" }}
                        shape="round"
                    >
                        Chat
                    </Button>
                </div>
                <div style={{ marginLeft: "10px", marginBottom: "5px", }}>
                    <Dropdown overlay={menu} placement="bottomRight" trigger={["click"]}>
                        <img src={horizentalTripleDot} width="32px" />
                    </Dropdown>
                </div>
            </div>
        )
    }
    handleImage() {
        if (this.props.cover_image) {
            return this.props.cover_image
        } else {
            return "https://timelinecovers.pro/facebook-cover/download/stunning-little-flowers-facebook-cover.jpg"
        }
    }
    handleBlockUnblock(isBlocked: boolean) {
        return (<>{!this.props?.data?.is_blocked && <Menu.Item
            onClick={() => this.props.handleBlock()}
            key="block"
        >
            {isBlocked ? "Unblock" : "Block"}
        </Menu.Item>
        }</>)
    }
    formatLastSeen(userLastSeen: any, accountPlan: string) {
        if (moment(userLastSeen, moment.ISO_8601, true).isValid()) {
            return (
                <>
                    {accountPlan !== "free" && <>
                        <span style={{ width: "6px", height: "6px", backgroundColor: "yellow", borderRadius: "50%", margin: "0px 4px 0px 7px", position: "relative", bottom: "1px" }}></span>
                        <span
                            style={{ fontSize: "12px", fontWeight: 500, textTransform: "capitalize" }}
                        >
                            Last Active On:{moment(userLastSeen).format('DD/MM/YYYY hh:mm A')}
                        </span>
                    </>
                    }
                </>
            )

        } else {
            return (
                <>
                    {accountPlan !== "free" && <>
                        <span style={{ width: "6px", height: "6px", backgroundColor: "green", borderRadius: "50%", margin: "0px 4px 0px 7px", position: "relative", bottom: "1px" }}></span>

                        <span
                            style={{ fontSize: "12px", fontWeight: 500, textTransform: "capitalize" }}
                        >
                            {userLastSeen}
                        </span>
                    </>
                    }
                </>
            )
        }
    }
    render() {

        // const isFollowing = this.props?.data?.following;
        // const isConnected = this.props?.data?.friend;
        // const isInvited = this.props?.data?.invited;
        const isFollowing = this.props?.data?.following;
        const isConnected = this.props?.data?.friend
        const rating_option = this.props.RatingOption;
        const userLastSeen = this.props?.lastSeen
        const isInvited = this.props?.data?.invited;
        const isBlocked = this.props?.data?.block_accounts?.[0]?.journal_id === this.props.data.account_id;
        const isRequested = Object.entries(this.props?.data?.received_request || {}).length > 0;
        const company_overview = this.props?.data?.profile?.data?.attributes?.company_overview;
        const requestedObj = this.props?.data?.received_request || {};

        // received_request

        const menu = (
            <Menu>
                {/* {
                    isConnected ?
                        <Menu.Item onClick={() => message.info("Comming Soon!")} key="remove">Remove Connection</Menu.Item>
                        : null
                } */}
                {this.handleBlockUnblock(isBlocked)}
                <Menu.Item
                    onClick={() => this.props.handleReportOpen()}
                    key="report"
                >
                    Report
                </Menu.Item>
                {this.props?.data?.friend && <Menu.Item
                    onClick={() => this.props.handleRemoveConnection(this.props?.data?.request?.id)}
                    key="remove_connection"
                >
                    Remove Connection
                </Menu.Item>}
                {isInvited && this.props?.data?.connected_as === null && <Menu.Item
                    onClick={() => this.props.handleSentConnectionsList()}
                    key="cancel Request"
                >
                    Cancel Connection Request
                </Menu.Item>}
            </Menu>
        );


        return (
            <div id="profile" style={{ width: "100%", display: "flex", justifyContent: "end", paddingTop: "1rem" }}>
                <div style={{ width: "96%", backgroundColor: "#ffffff", margin: "auto" }}>


                    <div
                        data-testid="handlePreview"
                        onMouseEnter={() => this.props.handleShowPreview()}
                        onMouseLeave={() => this.props.handleHidePreview()}
                        className="cover-img">
                        <img
                            id="myImg"
                            style={{ borderTopRightRadius: "5px", borderTopLeftRadius: "5px", objectFit: "cover" }}
                            width="100%"
                            height="250px"
                            src={this.handleImage()}
                        />
                        {this.props.preview && <div
                            data-testid="handleShowCoverImage"
                            onClick={() => { this.props.handleShowCoverImage() }}
                            className="cover"
                            style={{
                                cursor: 'pointer',
                                position: 'absolute',
                                display: "flex",
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundColor: 'black',
                                width: '96%',
                                height: '250px',
                                opacity: 0.8,
                            }}
                        >
                            <p style={{ color: 'white' }}>
                                {<EyeOutlined />} Preview
                            </p>
                        </div>}
                        <Avatar className="avatar-position" style={{ backgroundColor: "#919191" }} size={64} src={this.props?.data?.photo ? this.props?.data?.photo : <UserOutlined />} />
                    </div>
                    <div className='block'>
                        <div id="myModal" className="modal" style={{ display: `${this.props.displayCoverImage}`, height: 'unset' }}>
                            <div
                                style={{
                                    minHeight: '100vh',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    display: 'flex',
                                }}>
                                <div style={{
                                    width: '100%',
                                    maxHeight: '100vh',
                                    overflow: 'auto',
                                }}>
                                    <div
                                        style={{
                                            margin: 'auto',
                                            overflow: 'hidden',
                                            position: 'relative',
                                            transition: 'transform 0.2s, width 0.2s, height 0.2s',
                                            width: `${(window.innerWidth < 700 ? 100 : 40) + this.props.widthhchange}%`,
                                        }}
                                        className='reviewedCoverImage'
                                    >
                                        <ul className="ImageHandleBar">
                                            <li data-testid="RotateImageLeft" onClick={() => this.props.handleRotateImageLeft()}><RotateLeftOutlined style={{ fontSize: '12px !important' }} /></li>
                                            <li data-testid="RotateImageRight" onClick={() => this.props.handleRotateImageRight()}><RotateRightOutlined style={{ fontSize: '12px !important' }} /></li>
                                            <li data-testid="DecreaseWidth" onClick={() => this.props.handleDecreaseWidth()}><ZoomOutOutlined style={{ fontSize: '12px' }} /></li>
                                            <li data-testid="IncreaseWidth" onClick={() => this.props.handleIncreaseWidth()}><ZoomInOutlined style={{ fontSize: '12px' }} /></li>
                                            <li data-testid="HideCoverImage" onClick={() => this.props.handleHideCoverImage()}><CloseOutlined style={{ fontSize: '12px' }} /></li>
                                        </ul>

                                        <img
                                            onBlur={() => { }}
                                            className="zoomable-image-container modal-content"
                                            style={{
                                                transform: `rotate(${this.props.imageRotationDegree}deg)`,
                                                transition: 'transform 0.2s, width 0.2s',
                                                width: `${100}%`,
                                            }}
                                            src={this.handleImage()}
                                            id="img01" />
                                    </div>
                                </div>
                            </div>
                            <div id="caption"></div>
                        </div>
                    </div>

                    <div className="user-details">
                        <Row>
                            <Col xs={{ span: 24 }} lg={{ span: 9 }} style={{ padding: "0px 0px 0px 16px" }}>
                                {
                                    this.props.isLoading ? <Spin />
                                        : <Text style={{ display: "flex", flexWrap: "wrap", flexDirection: 'row', alignItems: 'baseline', width: "700px" }}>
                                            <span style={{ fontSize: "18px", fontWeight: 500, textTransform: "capitalize" }}>{this.props?.data?.company_name}</span>
                                            {
                                                this.props?.data?.business_industry ?
                                                    <>
                                                        <span style={{ width: "6px", height: "6px", backgroundColor: "#dfe5eb", borderRadius: "50%", margin: "0px 4px 0px 7px", position: "relative", bottom: "1px" }}></span>
                                                        <span
                                                            style={{ fontSize: "12px", fontWeight: 500, textTransform: "capitalize" }}
                                                        >
                                                            {this.props?.data?.business_industry}
                                                        </span>
                                                        <span style={{ fontSize: "12px", color: "rgb(117, 117, 117)" }}>
                                                            {
                                                                this.props?.data?.location ?
                                                                    <EnvironmentOutlined style={{ margin: "0px 4px" }} />
                                                                    : null
                                                            }
                                                            {this.props?.data?.location}
                                                        </span>
                                                        {this.formatLastSeen(userLastSeen, this.props.accountPlan)}

                                                    </>
                                                    : null
                                            }
                                        </Text>
                                }
                            </Col>

                            <Col xs={{ span: 24 }} lg={{ span: 15 }} style={{ padding: "10px 16px 0px 0px" }}>
                                {
                                    this.props.isLoading ? <Spin />
                                        : this.renderUi({ isFollowing, isConnected, rating_option, isInvited, isBlocked, isRequested, requestedObj, menu })
                                }
                            </Col>
                            <Col span={24} >
                                <AboutAndStats
                                    isLoading={this.props.isLoading}
                                    data={this.props.data}
                                    handleClick={this.props.handleClick}
                                />
                            </Col>
                        </Row>

                    </div>
                </div>
            </div >
        );
    }

}

interface MyPostRatingReviewProps {

    data?: any
    getPosts: (id: number) => any
    postDataArray?: any
    accountId: number
    isPrivate: boolean
    isPostView: boolean
    isRatingVisible: boolean
    handleRatingView: (data: any, name: string) => void
    reviewData: Array<any>
    ratingData: Array<any>
    isLoading: boolean
    activeTab: any
    handleFollow: () => void
    handleFollowing: () => void
    noOfPostData: boolean
    handlePostList: any

}
interface Istate {

    activeTab: any

}

export class UserPostRatingReview extends React.Component<MyPostRatingReviewProps, Istate> {


    constructor(props: any) {
        super(props)
        this.state = {
            activeTab: '1'
        }
    }
    componentDidMount = () => {
        // console.log(this.props.activeTab, this.props.isPostView)
        this.setState({
            activeTab: "1"
        })
    }



    changeTab = (activeKey: any) => {
        this.setState({
            activeTab: activeKey
        });
    }
    RatingDataViewTab = () => {
        return (
            <TabPane
                tab="Ratings"

                key={this.props.isPrivate ? "2" : "3"}
                style={{
                    width: "96%",
                    height: "100%",
                    margin: "1rem auto"
                }}
            >
                {/* <div onClick={()=>this.changeTab(2)}> */}
                {
                    this.props.isLoading ? <Spin /> :
                        <RatingView isPrivate={this.props.isRatingVisible} data={this.props?.ratingData} />
                }
                {/* </div> */}
            </TabPane>
        )
    }
    ReviewDataViewTab = () => {
        return (
            <TabPane
                tab="Reviews"
                key={this.props.isPrivate ? "3" : "4"}
                style={{ width: "96%", height: "100%", margin: "1rem auto" }}
            >
                {/* <div onClick={()=>this.changeTab(3)}> */}
                {
                    // this.props.isLoading ? <Spin />
                    this.props?.reviewData?.length ?
                        <ReviewDataView reviewData={this.props.reviewData} />
                        : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                }
                {/* </div> */}
            </TabPane>
        )
    }
    render() {
        const isFollowing = this.props?.data?.following;
        const isConnected = this.props?.data?.friend;

        return (
            <div style={{ width: "100%", paddingTop: "1rem" }}>
                <Tabs
                    activeKey={this.state.activeTab}
                    defaultActiveKey={this.state.activeTab}
                    onChange={this.changeTab}
                    centered={true}
                    size="large"
                    tabBarStyle={{
                        color: "rgb(117, 117, 117)",
                        backgroundColor: "#dfe5eb",
                        height: "3rem"

                    }}
                    style={{
                        //    fontFamily:'Helvetica',
                        width: "96%",
                        margin: "auto",
                        borderRadius: "3px",
                        backgroundColor: "#f1f0f5"
                    }}
                >
                    {/* {this.props.isPostView && */}
                    <TabPane
                        tab="Posts"
                        key="1"
                        style={{ width: "96%", height: "100%", margin: "1rem auto" }}
                    >
                        {/* <div onClick={()=>this.changeTab(1)}> */}
                        {
                            <div>
                                {
                                    this.props.isLoading ? <Spin /> :
                                        this.props.postDataArray?.map((item: any, index: number) => {
                                            return (
                                                <>

                                                    <UserPost item={item}
                                                        following={this.props.data?.following}
                                                        onHandleFollow={this.props.data?.following ? () => this.props.handleFollowing() : () => this.props.handleFollow()}
                                                    />
                                                    {
                                                        (this.props.postDataArray.length - 1 === index) &&
                                                        <>
                                                            {this.props.isLoading ? <Spin /> :
                                                                this.props.noOfPostData &&
                                                                <Text style={{ fontSize: 16, color: "#0e76a8", cursor: 'pointer' }}
                                                                    onClick={() => this.props.getPosts(this.props.accountId)}
                                                                >

                                                                    Load More ...
                                                                </Text>
                                                            }
                                                        </>
                                                    }
                                                </>
                                            )
                                        })
                                }
                            </div>
                        }
                        {/* </div> */}

                    </TabPane>
                    {/* } */}
                    {this.RatingDataViewTab()}
                    {this.ReviewDataViewTab()}
                </Tabs>
            </div>
        );
    }

}

export class BasicInfo extends React.Component<MyProps> {

    render() {
        const basicInfoList = [
            {
                id: 1,
                name: "Business",
                info: this.props?.data?.business
            },
            {
                id: 2,
                name: "Business Industry/Category",
                info: this.props?.data?.business_industry
            },
            {
                id: 3,
                name: "Ownership",
                info: this.props?.data?.ownership_type
            },
            {
                id: 4,
                name: "Founder",
                info: this.props?.data?.founder
            },
            {
                id: 5,
                name: "No. of Employees",
                info: this.props?.data?.num_of_employees
            },
            {
                id: 6,
                name: "Location",
                info: this.props?.data?.location
            },
            {
                id: 7,
                name: "Website",
                info: this.props?.data?.website
            },
        ]
        return <div style={{ margin: "1rem" }}>
            <Title level={5} style={{ color: "rgba(0, 0, 0, 0.85)" }}>Basic Information</Title>
            <List
                style={{ borderRadius: "5px", overflow: "hidden" }}
                data-testid='BasicInformation'
                itemLayout="horizontal"
                dataSource={basicInfoList}
                renderItem={item => (
                    <List.Item className="listInfo" style={{ display: item.info ? "block" : "none" }}>
                        <List.Item.Meta
                            title={item.name}
                            description={<div className="ant-list-item-meta-description">
                                {
                                    item.name === 'Website' ?
                                        <a href={item.info} target="_blank" style={{ color: "@40a9ff !important" }}>{item.info}</a>
                                        : item.info
                                }
                            </div>}
                        />
                    </List.Item>
                )}
            />
        </div>
    }

}

export class ContactDetails extends React.Component<MyProps> {

    render() {
        const contactDetails = [
            {
                contact_person: this.props?.data?.contact_person,
                contact_email: this.props?.data?.contact_email,
                contact_phon: this.props?.data?.contact_phon
            }
        ]
        const isConnected = this.props?.data?.friend;
        return <>
            {
                isConnected ?
                    <div style={{ margin: "1rem" }}>
                        <Title level={5} style={{ color: "rgba(0, 0, 0, 0.85)" }}>Contact Person</Title>
                        <List
                            style={{ borderRadius: "5px", overflow: "hidden" }}
                            itemLayout="horizontal"
                            dataSource={contactDetails}
                            data-testid='isConnected'
                            renderItem={item => (
                                <List.Item className="listInfo">
                                    <List.Item.Meta
                                        title={item.contact_person}
                                        description={
                                            <div style={{ display: "flex", flexDirection: "column" }}>
                                                <Text style={{ fontWeight: 400, fontSize: "14px", color: "rgb(117, 117, 117)", display: item?.contact_email ? "block" : "none" }}>
                                                    <span style={{ color: "rgba(0, 0, 0, 0.45)" }}>Email:</span> {item?.contact_email}
                                                </Text>
                                                <Text style={{ fontWeight: 400, fontSize: "14px", color: "rgb(117, 117, 117)", display: item?.contact_phon ? "block" : "none" }}>
                                                    <span style={{ color: "rgba(0, 0, 0, 0.45)" }}>Phone:</span> {item?.contact_phon}
                                                </Text>
                                            </div>
                                        }
                                    />
                                </List.Item>
                            )}
                        />
                    </div>
                    : null
            }
        </>
    }

}

interface ViewAllProps {

    data?: any
    isLoading: boolean
    handleOpen: (title: string, data: any) => void

}

export class History extends React.Component<ViewAllProps> {


    render() {
        const historyData: Array<any> = this.props?.data?.profile?.data?.attributes?.history;

        return <>
            {
                historyData?.length && historyData[0]?.year ?
                    <div style={{ margin: "1rem" }}>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                            <Title level={5} style={{ color: "rgba(0, 0, 0, 0.85)" }}>{this.props?.data?.company_name} History</Title>
                            <Text
                                style={{ fontSize: "14px", color: "#005574", cursor: "pointer" }}
                                data-testid='History'
                                onClick={() => this.props.handleOpen("Google History", historyData)}
                            >
                                View All
                            </Text>
                        </div>
                        <List
                            style={{ borderRadius: "5px", overflow: "hidden" }}
                            itemLayout="horizontal"
                            data-testid='Historylist'
                            dataSource={historyData.slice(0, 4)}
                            renderItem={item => (
                                <List.Item className="listInfo">
                                    <List.Item.Meta
                                        title={item.year}
                                        description={item.description}
                                    />
                                </List.Item>
                            )}
                        />
                    </div>
                    : null
            }
        </>
    }

}

export class CoreTeam extends React.Component<ViewAllProps> {


    render() {
        const coreTeamData: Array<any> = this.props?.data?.profile?.data?.attributes?.core_team;
        return <>
            {
                coreTeamData?.length && coreTeamData[0]?.name ?
                    <div style={{ margin: "1rem" }}>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                            <Title level={5} style={{ color: "rgba(0, 0, 0, 0.85)" }}>Core Team</Title>
                            <Text
                                style={{ fontSize: "14px", color: "#005574", cursor: "pointer" }}
                                data-testid='CoreTeam'
                                onClick={() => this.props.handleOpen("Core Team", coreTeamData)}
                            >
                                View All
                            </Text>
                        </div>
                        <List
                            style={{ borderRadius: "5px", overflow: "hidden" }}
                            itemLayout="horizontal"
                            data-testid='CoreTeamlist'
                            dataSource={coreTeamData.slice(0, 3)}
                            renderItem={item => (
                                <List.Item className="listInfo">
                                    <List.Item.Meta
                                        title={item.name}
                                        description={item.designation}
                                    />
                                </List.Item>
                            )}
                        />
                    </div>
                    : null
            }
        </>
    }

}

export class Expansion extends React.Component<ViewAllProps> {


    render() {
        const expansionData: Array<any> = this.props?.data?.profile?.data?.attributes?.expansion;
        return <>
            {
                expansionData?.length && expansionData[0]?.year ?
                    <div style={{ margin: "1rem" }}>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                            <Title level={5} style={{ color: "rgba(0, 0, 0, 0.85)" }}>Expansion/Mergers & More</Title>
                            <Text
                                style={{ fontSize: "14px", color: "#005574", cursor: "pointer" }}
                                data-testid='Expansion'
                                onClick={() => this.props.handleOpen("Expansion/Mergers & More", expansionData)}
                            >
                                View All
                            </Text>
                        </div>
                        <List
                            style={{ borderRadius: "5px", overflow: "hidden" }}
                            itemLayout="horizontal"
                            data-testid='Expansionlist'
                            dataSource={expansionData.slice(0, 4)}
                            renderItem={item => (
                                <List.Item className="listInfo">
                                    <List.Item.Meta
                                        title={item.year}
                                        description={item.description}
                                    />
                                </List.Item>
                            )}
                        />
                    </div>
                    : null
            }
        </>

    }

}


// Customizable Area End