import React, { Component } from "react";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import confirm from "../../alert/src/alert.web";
import AlertError from "../../alert/src/alertError.web";
export const configJSON = require("./config");
import { RouterProps } from "react-router";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
export interface Props extends RouterProps {
  navigation: any;
  id: string;
}
interface S {
  loading: boolean;
  isRegistration: boolean;
  bussiness_profile: boolean;
  data: any;
  signUpUser: any,
  seconds: number;
  isResendOtp: boolean;
  isFromLogin: boolean
  token: string
  userEmail: string
  time: any;
  isErrorModalShow: boolean
  errorContent: any
  emailVerifyPageBannerImageId: string | null;
  emailVerifyPageBannerImage: string;
}
interface SS {
  id: any;
}

export default class VerifyEmailController extends BlockComponent<
  Props,
  S,
  SS
> {
  createAccountApiCallId: any;
  otpResendApiCallId: any;
  getEmailVerifyPageBannerImageApiCallId = '';
  formref = React.createRef();
  timer: any;
  constructor(props: Props) {
    super(props);
    this.timer = 0;
    this.startTimer = this.startTimer.bind(this);
    this.countDown = this.countDown.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.receive = this.receive.bind(this);
    this.state = {
      bussiness_profile: true,
      loading: false,
      isRegistration: true,
      data: this.props.history ? this.props.history.location.state : "",
      signUpUser: getStorageData("signUpUser") as any ? getStorageData("signUpUser") : {},
      seconds: 60,
      isResendOtp: false,
      isFromLogin: false,
      token: '',
      userEmail: '',
      time: {},
      isErrorModalShow: false,
      errorContent: {},
      emailVerifyPageBannerImageId: '',
      emailVerifyPageBannerImage: ''
    };
  }
  async componentDidMount() {
    this.getEmailVerifyPageBannerImage();
    let history: any = await this.props.history ? this.props.history.location.state : "";
    console.log("history", history, this.state.data)
    if (history && history.isFromLogin) {
      await this.setState({ isFromLogin: true })
      this.onResendOTP()
    } else {
      this.setState({ isFromLogin: false })
      this.getTimer();
    }
    let result: any = {}
    await getStorageData("signUpUser").then((res) => {
      this.setState({ signUpUser: res })
      result = res
    })
    if (this.state.isFromLogin) {
      this.setState({ userEmail: this.state.data?.email ? this.state.data?.email : result.email })
    } else {
      this.setState({ userEmail: this.state.data?.responseJson.data?.attributes?.email ? this.state.data.responseJson.data.attributes.email : result.email })
    }
  }
  getTimer=async()=> {
    let time :any= localStorage.getItem("timming")
    let timming :any = await time&&JSON.parse(time);
      
    let timeLeftVar :{}
    if(timming?.s||timming?.s==0){
      this.setState({seconds:(timming.s)})
      timeLeftVar = this.secondsToTime((timming.s));
    }else{
      timeLeftVar = this.secondsToTime(this.state.seconds);
    }
    this.setState({ time: timeLeftVar });
    this.startTimer();
    localStorage.setItem("timming","")
  }
  startTimer() {
    if (this.timer == 0 && this.state.seconds > 0) {
      this.timer = setInterval(this.countDown, 1000);
    }
  }

  async countDown() {
    // Remove one second, set state so a re-render happens.
    let seconds = this.state.seconds - 1;
    this.setState({
      time: this.secondsToTime(seconds),
      seconds: seconds,
    });
    // Check if we're at zero.
    if (seconds == 0) {
      await clearInterval(this.timer);
      this.timer = 0;
    }
  }
  secondsToTime(secs: any) {
    let hours = Math.floor(secs / (60 * 60));

    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);

    let obj = {
      h: hours,
      m: minutes,
      s: seconds,
    };
    return obj;
  }
  getTime() {
    if (this.state.seconds > 0) {
      setTimeout(async () => {
        let data = this.state.seconds - 1;
        this.setState({ seconds: data });
        // console.log("seconds", this.state.seconds);
        // return this.state.seconds;
      }, 1000);
    } else {
      this.setState({ seconds: 0 });
    }
    if (this.state.seconds == 60) {
      return "01:00";
    } else if (this.state.seconds > 0) {
      return `00:${this.state.seconds.toString().length == 1
        ? "0" + this.state.seconds
        : this.state.seconds
        }`;
    } else {
      return this.state.seconds;
    }
  }
  handleProfile = () => {
    this.setState({
      bussiness_profile: !this.state.bussiness_profile,
    });
  };
  handleLogin = async (data: {} | any) => {
    if (data.otp_code.length == 4) {
      this.setState({ loading: true })
      const header = {
        "Content-Type": "application/json",
      };
      let signuptoken = ''
      await getStorageData("signUpUserResponse").then(async (res) => {
        console.log("signuptoken", res)
        signuptoken = await res?.email_otp?.meta.token
      });
      console.log("signuptoken", signuptoken)
      console.log("token", signuptoken)

      let token = this.state.isFromLogin || this.state.token ? this.state.token : signuptoken
      // let token = this.state.data?.email_otp?.meta.token? this.state.data.email_otp.meta.token :this.state.token
      Object.assign(data, { token: token });
      const json_data = {
        data: data,
      };
      const httpBody = json_data;
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.createAccountApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.matchEmailOTPApiEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "POST"
      );
      setTimeout(runEngine.sendMessage(requestMessage.id, requestMessage), 3000);
      // runEngine.sendMessage(requestMessage.id, requestMessage);
    } else {
      this.setState({ isErrorModalShow: true, errorContent: { title: "Error", isError: true, body: "Please Enter valid OTP!" } })
    }
  };
  async receive(from: string, message: Message) {
    console.log("receive");
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId == this.otpResendApiCallId) {
          this.setState({ isResendOtp: false });
          if (responseJson.email_otp) {
            this.setState({ token: responseJson?.email_otp?.meta.token });
            this.setState({
              isResendOtp: false,
              seconds: 60,
            });
            this.getTimer();
          } else {
            this.setState({
              isResendOtp: false,
              seconds: 0,
            });
          }
          console.log("otpResendApiCallId", responseJson);
        } else if (apiRequestCallId == this.createAccountApiCallId) {
          await this.updateEmailVerifyPageCreateAccountState(responseJson);
        } else if (apiRequestCallId == this.getEmailVerifyPageBannerImageApiCallId) {
          this.updateEmailVerifyPageBannerImageState(responseJson);
        }
      } else {
        console.log("something went wrong");
      }
    }
  }
  handleSignup = () => {
    this.props.navigation.navigate("CreateAccount");
  };
  async onResendOTP() {
    localStorage.setItem("timming","")
    this.setState({ isResendOtp: true, seconds: 0 });
    const res = await getStorageData("signUpUser");
    const isPN = isNaN(res);
    let email = ''
    if (this.state.isFromLogin) {
      // console.log("from login", this.state.data)
      email = this.state.data?.email ? this.state.data?.email : res.email
    } else {
      email = this.state.data?.responseJson.data?.attributes?.email ? this.state.data.responseJson.data.attributes.email : res.email
      // console.log("from signup", this.state.data.responseJson.data.attributes.email)
    }
    console.log("isPN: ", res, isPN);
    const header = {
      "Content-Type": configJSON.apiResendOtpContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    console.log("requestMessage = ", JSON.stringify(requestMessage));

    console.log(
      "requestMessage messageId = ",
      JSON.stringify(requestMessage.messageId)
    );

    this.otpResendApiCallId = requestMessage.messageId.toString();

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiResendOtpEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const otpData = {
      email: email,
    };

    const httpBody = otpData;
    console.log("httpBody", httpBody);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiResendOtpMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getEmailVerifyPageBannerImage = () => {
    const header = {
      'Content-Type': 'application/json',
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getEmailVerifyPageBannerImageApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getLandingPageImageAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    this.setState({
      emailVerifyPageBannerImageId: requestMessage.id,
    });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  updateEmailVerifyPageBannerImageState = (emailVerifyPageBannerImageData: any) => {
    if (emailVerifyPageBannerImageData.data) {
      this.setState({
        emailVerifyPageBannerImage: emailVerifyPageBannerImageData.data[0].attributes.image,
      });
    } else {
      this.setState({
        isErrorModalShow: true,
        errorContent: {
          title: "Error",
          isError: true,
          body: "Unable to load verify email banner image!",
        },
      });
    }
  };
  updateEmailVerifyPageCreateAccountState = async (emailVerifyCreateAccountResponse: any) => {
    this.setState({ loading: false })
    if (emailVerifyCreateAccountResponse.messages) {
      if (this.state?.data?.sms_otp_verified) {
        localStorage.setItem("token", emailVerifyCreateAccountResponse.messages[0]?.otp?.token);
        await setStorageData("verificationData", emailVerifyCreateAccountResponse.messages[0]?.otp)
        this.props.history.push({
          pathname: "/varification/success",
          state: emailVerifyCreateAccountResponse.messages[0]?.otp
        });
      } else {
        this.props.history.push({
          pathname: "/phone/varification",
          state: { ...this.state.data, isFromLogin: this.state.isFromLogin },
        });
      }
    } else if (emailVerifyCreateAccountResponse.errors) {
      this.setState({ isErrorModalShow: true, errorContent: { title: "Error", isError: true, body: emailVerifyCreateAccountResponse.errors[0]?.otp } })
      if (emailVerifyCreateAccountResponse.errors && emailVerifyCreateAccountResponse.errors[0]?.pin == "OTP has been expired, please request a new one.") {
        this.setState({ isErrorModalShow: true, errorContent: { title: "error", isError: true, body: emailVerifyCreateAccountResponse.errors[0]?.pin } })
      } else {               
        let time = JSON.stringify(this.state.time)
        localStorage.setItem("timming",time)
        this.props.history.push({
          pathname: "/varification/error",
          state: { isFromMail: true },          
        });
      }
    } else {
      let time = JSON.stringify(this.state.time)
      localStorage.setItem("timming",time)
      this.props.history.push({
        pathname: "/varification/error",
        state: { isFromMail: true },
      });
      console.log("something went wrong");
    }
  };
}
