import React from "react";
import CommonSettingController from "./CommonSettingsController.web";
import "./settings.css";
import { List, Row, Col, Typography, Menu, Space, Divider } from 'antd';
import AccountSettings from "./AccountSettings.web";
import DeleteAccount from "./DeleteAccount.web";
import AppliedPolicy from "./AppliedPolicy.web";
import ContactUs from "./ContactUs.web";
import SuspendAccount from "./SuspendAccount.web";
import AboutApplied from "./AboutApplied.web";
import Logout from "./Logout.web";
import { deleteIcon, suspendIcon, logoutIcon, aboutIcon, appliedIcon, contactUsIcon, settingIcon } from '../assets';
import { isEmpty } from "../../../../framework/src/Utilities";
import './pdfs.css';
import {
  SettingOutlined, ContactsOutlined, ScheduleOutlined, InfoCircleOutlined, UsergroupAddOutlined, PhoneFilled,
  VideoCameraOutlined, AlertOutlined, TrophyOutlined, ReadOutlined, ProfileOutlined, SecurityScanOutlined
} from "@ant-design/icons";
import { CircularProgress, Box, LinearProgress } from "@material-ui/core";
import LanguageOutlinedIcon from '@material-ui/icons/LanguageOutlined';
import SchoolIcon from '@material-ui/icons/School';
import SportsSoccerIcon from '@material-ui/icons/SportsSoccer';
import VpnLockIcon from '@material-ui/icons/VpnLock';

const { Title, Text } = Typography;


const addRatingList = [
  {
    id: 1,
    value: 78,
    color: "Attitude",
  },
  {
    id: 2,
    value: 90,
    color: "performance",
  },
  {
    id: 3,
    value: 95,
    color: "productivity",
  },
  {
    id: 4,
    value: 78,
    color: "leadership",
  },
  {
    id: 5,
    value: 97,
    color: "integrity",
  },
  {
    id: 6,
    value: 77,
    color: "expertise",
  },
  {
    id: 7,
    value: 92,
    color: "diversity",
  },
];
const v = [
  {
    id: 1,
    value: 78,
    color: "Asset to the Organization",
  },
  {
    id: 2,
    value: 90,
    color: "Depandabulity",
  },
  {
    id: 3,
    value: 95,
    color: "Customer Centric",
  },
  {
    id: 4,
    value: 78,
    color: "Communication",
  },
  {
    id: 5,
    value: 97,
    color: "Adapt & Evolves",
  },
  {
    id: 6,
    value: 77,
    color: "Goal Oriented",
  },
  {
    id: 7,
    value: 92,
    color: "Profile Endorsement",
  },
];
const vv = [
  {
    id: 1,
    value: 78,
    color: "Asset to the Team",
  },
  {
    id: 2,
    value: 90,
    color: "Depandabulity",
  },
  {
    id: 3,
    value: 95,
    color: "Team Player",
  },
  {
    id: 4,
    value: 78,
    color: "Communication",
  },
  {
    id: 5,
    value: 97,
    color: "Adapt & Evolves",
  },
  {
    id: 6,
    value: 77,
    color: "Goal Oriented",
  },
  {
    id: 7,
    value: 92,
    color: "Profile Endorsement",
  },
];
const vvv = [
  {
    id: 1,
    value: 78,
    color: "Attitude",
  },
  {
    id: 2,
    value: 90,
    color: "performance",
  },
  {
    id: 3,
    value: 95,
    color: "productivity",
  },
  {
    id: 4,
    value: 78,
    color: "leadership",
  },
  {
    id: 5,
    value: 97,
    color: "integrity",
  },
  {
    id: 6,
    value: 77,
    color: "expertise",
  },
  {
    id: 7,
    value: 92,
    color: "diversity",
  },
];
const vvvv = [
  {
    id: 1,
    value: 78,
    color: "Attitude",
  },
  {
    id: 2,
    value: 90,
    color: "performance",
  },
  {
    id: 3,
    value: 95,
    color: "productivity",
  },
  {
    id: 4,
    value: 78,
    color: "leadership",
  },
  {
    id: 5,
    value: 97,
    color: "integrity",
  },
  {
    id: 6,
    value: 77,
    color: "expertise",
  },
  {
    id: 7,
    value: 92,
    color: "diversity",
  },
];

export default class Bpdf extends CommonSettingController {

  getDomainSkills = (userDataHere: any) => {
    if (
      isEmpty(userDataHere)
      // isEmpty(userDataHere.attributes) ||
      // isEmpty(userDataHere.attributes.domain_skills)
    )
      return "";
    const domainSkillsPresent = userDataHere;
    const domainSkillsStr = domainSkillsPresent.join(", ");
    // console.log("domainSkillsStr = ", domainSkillsStr);
    return domainSkillsStr;
  }


  // Customizable Area Start
  render() {
    console.log(this.state.userAccountDetails, "uuuuuu")
    const profile = this.state.userAccountDetails?.attributes?.account?.data?.attributes?.profile?.data?.attributes
    const ratings = this.state.userAccountDetails?.attributes?.account?.data?.attributes?.rating?.data?.attributes
    const pro = this.state.userAccountDetails?.attributes?.account?.data?.attributes
    return (
      <div style={{ width: "100%", minHeight: "100vh", backgroundColor: "rgba(245, 245, 245, 1)", boxSizing: "border-box", padding: '20px' }}>
        <Row style={{ width: "80%", margin: 'auto' }}>
          <Col xs={{ span: 24 }} lg={{ span: 6 }} style={{ padding: "30px 15px 10px", backgroundColor: '#F3F3F3' }}>
            <div>
              <div className="profile_pic">
                <img
                  src={this.state.userAccountDetails?.attributes?.account?.data?.attributes?.photo}
                  className="pic"
                />
              </div>

              <div style={{ textAlign: 'center' }}>
                <Text className="profile_name">
                  {profile?.company_name ? profile?.company_name : ""}
                </Text>
                <Text className="domine_expert_text">{profile?.business && profile?.business}</Text>
                <Text className="domine_expert_text">{profile?.business_industry && profile?.business_industry}</Text>
                <Text className="domine_expert_text">{profile?.ownership_type && profile?.ownership_type}</Text>
                <Text className="domine_expert_text">{profile?.established_year && profile?.established_year}</Text>
                <Text className="domine_expert_text">{profile?.founder && profile?.founder}</Text>
                <Text className="domine_expert_text">{profile?.num_of_employees && profile?.num_of_employees}</Text>
                <Text className="domine_expert_text">{profile?.location && profile?.location}</Text>
                <Text className="domine_expert_text">{profile?.website && profile?.website}</Text>
              </div>

              {/* PRODUCTS & SERVICES component start */}
              <div className="headline" >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div className="overview">
                    <PhoneFilled style={{ fontSize: 35, color: '#fff', marginTop: 7 }} />
                  </div>
                  <Text className="domine_expert_text" style={{ color: '#2f5597', marginLeft: 15, textTransform: 'uppercase' }}>PRODUCTS & SERVICES</Text>
                </div>
                <hr className="hr_line" />
                {/* <div>
                  <p className="left_head">Email : <span className="left_text">{pro?.email ? pro?.email : 'null'}</span></p>
                  <p className="left_head">Phone : <span className="left_text">{pro?.full_phone_number ? pro?.full_phone_number : 'null'}</span></p>
                </div> */}
              </div>
              {/* PRODUCTS & SERVICES component end */}

              {/* HISTORY component start */}
              <div className="headline" >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div className="overview">
                    <PhoneFilled style={{ fontSize: 35, color: '#fff', marginTop: 7 }} />
                  </div>
                  <Text className="domine_expert_text" style={{ color: '#2f5597', marginLeft: 15, textTransform: 'uppercase' }}>HISTORY</Text>
                </div>
                <hr className="hr_line" />
                {/* <div>
                  <p className="left_head">Email : <span className="left_text">{pro?.email ? pro?.email : 'null'}</span></p>
                  <p className="left_head">Phone : <span className="left_text">{pro?.full_phone_number ? pro?.full_phone_number : 'null'}</span></p>
                </div> */}
              </div>
              {/* HISTORY component end */}

              {/*  EXPANSION/ 
                 MERGERS & ACQUISITIONS component start */}
              <div className="headline" >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div className="overview">
                    <PhoneFilled style={{ fontSize: 35, color: '#fff', marginTop: 7 }} />
                  </div>
                  <Text className="domine_expert_text" style={{ color: '#2f5597', marginLeft: 15, textTransform: 'uppercase' }}>EXPANSION/<br />MERGERS & ACQUISITIONS</Text>
                </div>
                <hr className="hr_line" />
                {/* <div>
                  <p className="left_head">Email : <span className="left_text">{pro?.email ? pro?.email : 'null'}</span></p>
                  <p className="left_head">Phone : <span className="left_text">{pro?.full_phone_number ? pro?.full_phone_number : 'null'}</span></p>
                </div> */}
              </div>
              {/* EXPANSION/ 
                 MERGERS & ACQUISITIONS component end */}

              {/* CORE TEAM component start */}
              <div className="headline" >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div className="overview">
                    <PhoneFilled style={{ fontSize: 35, color: '#fff', marginTop: 7 }} />
                  </div>
                  <Text className="domine_expert_text" style={{ color: '#2f5597', marginLeft: 15, textTransform: 'uppercase' }}>CORE TEAM</Text>
                </div>
                <hr className="hr_line" />
                {/* <div>
                  <p className="left_head">Email : <span className="left_text">{pro?.email ? pro?.email : 'null'}</span></p>
                  <p className="left_head">Phone : <span className="left_text">{pro?.full_phone_number ? pro?.full_phone_number : 'null'}</span></p>
                </div> */}
              </div>
              {/* CORE TEAM component end */}

              {/* contact component start */}
              <div className="headline" >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div className="overview">
                    <PhoneFilled style={{ fontSize: 35, color: '#fff', marginTop: 7 }} />
                  </div>
                  <Text className="domine_expert_text" style={{ color: '#2f5597', marginLeft: 15, textTransform: 'uppercase' }}>Contact details</Text>
                </div>
                <hr className="hr_line" />
                <div>
                  <p className="left_head">Email : <span className="left_text">{pro?.email ? pro?.email : 'null'}</span></p>
                  <p className="left_head">Phone : <span className="left_text">{pro?.full_phone_number ? pro?.full_phone_number : 'null'}</span></p>
                </div>
              </div>
              {/* contact component end */}
            </div>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 18 }} style={{ padding: "30px 15px 10px", backgroundColor: '#FFF' }}>
            <div>
              <div className="headline" style={{ height: 80 }} >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div className="overview">
                    <ContactsOutlined
                      className="header_icon" />
                  </div>
                  <Text className="domine_expert_text" style={{ color: '#2f5597', marginLeft: 15, textTransform: 'uppercase' }}>company overview</Text>
                </div>
                <hr className="hr_line" />
                <div>
                  <span className="domine_expert_text" style={{ fontSize: 12, marginBottom: 10 }}>
                    {profile?.company_overview}
                  </span>
                </div>
              </div>
              {/* Circular Progress bar Start */}
              <div>
                <div className="rating_main">
                  {
                    ratings?.rating &&
                    Array.prototype.slice.call(ratings?.rating, 4).map((item: any, index: number) => {
                      return (
                        <div>
                          {item.map((i: any) => {
                            return <div className="rating_view_1">
                              <Box position="relative" display="inline-flex">
                                <CircularProgress
                                  variant="determinate"
                                  value={i.score}
                                  // color={'#0076de'}
                                  size={120}
                                  thickness={5}
                                  style={{
                                    color: "#2f5597",
                                    zIndex: 1,
                                    transform: "rotate(90deg)"
                                  }}
                                // className={classes.bottom}
                                />
                                <Box
                                  top={0}
                                  left={0}
                                  bottom={0}
                                  right={0}
                                  position="absolute"
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="center"
                                  style={{
                                    background: '#fff',
                                    borderRadius: '50%',
                                    textAlign: 'center'
                                  }}
                                >
                                  <div >
                                    <span className="text_inner_circle">Leadership</span>
                                    <span className="text_inner_circle">Rating</span>
                                    <p className="value_inner_circle">{i.score}%</p>
                                  </div>
                                </Box>
                              </Box>
                            </div>
                          })}
                        </div>
                      )
                    })
                  }
                  <div className="rating_view_1">
                    <Box position="relative" display="inline-flex">
                      <CircularProgress
                        variant="determinate"
                        value={98}
                        // color={'#0076de'}
                        size={120}
                        thickness={5}
                        style={{
                          color: "#2f5597",
                          zIndex: 1,
                          transform: "rotate(90deg)"
                        }}
                      // className={classes.bottom}
                      />
                      <Box
                        top={0}
                        left={0}
                        bottom={0}
                        right={0}
                        position="absolute"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        style={{
                          background: '#fff',
                          borderRadius: '50%',
                          textAlign: 'center'
                        }}
                      >
                        <div >
                          <span className="text_inner_circle">Leadership</span>
                          <span className="text_inner_circle">Rating</span>
                          <p className="value_inner_circle">98%</p>
                        </div>
                      </Box>
                    </Box>
                  </div>
                  <div className="rating_view_1">
                    <Box position="relative" display="inline-flex">
                      <CircularProgress
                        variant="determinate"
                        value={95}
                        // color={'#0076de'}
                        size={120}
                        thickness={5}
                        style={{
                          color: "#2f5597",
                          zIndex: 1,
                          transform: "rotate(90deg)"
                        }}
                      // className={classes.bottom}
                      />
                      <Box
                        top={0}
                        left={0}
                        bottom={0}
                        right={0}
                        position="absolute"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        style={{
                          background: '#fff',
                          borderRadius: '50%',
                          textAlign: 'center'
                        }}
                      >
                        <div >
                          <span className="text_inner_circle">Team</span>
                          <span className="text_inner_circle">Rating</span>
                          <p className="value_inner_circle">95%</p>
                        </div>
                      </Box>
                    </Box>
                  </div>
                  <div className="rating_view_1">
                    <Box position="relative" display="inline-flex">
                      <CircularProgress
                        variant="determinate"
                        value={92}
                        // color={'#0076de'}
                        size={120}
                        thickness={5}
                        style={{
                          color: "#2f5597",
                          zIndex: 1,
                          transform: "rotate(90deg)"
                        }}
                      // className={classes.bottom}
                      />
                      <Box
                        top={0}
                        left={0}
                        bottom={0}
                        right={0}
                        position="absolute"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        style={{
                          background: '#fff',
                          borderRadius: '50%',
                          textAlign: 'center'
                        }}
                      >
                        <div >
                          <span className="text_inner_circle">Customer</span>
                          <span className="text_inner_circle">Rating</span>
                          <p className="value_inner_circle">92%</p>
                        </div>
                      </Box>
                    </Box>
                  </div>
                  <div className="rating_view_1">
                    <Box position="relative" display="inline-flex">
                      <CircularProgress
                        variant="determinate"
                        value={92}
                        // color={'#0076de'}
                        size={120}
                        thickness={5}
                        style={{
                          color: "#2f5597",
                          zIndex: 1,
                          transform: "rotate(90deg)"
                        }}
                      // className={classes.bottom}
                      />
                      <Box
                        top={0}
                        left={0}
                        bottom={0}
                        right={0}
                        position="absolute"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        style={{
                          background: '#fff',
                          borderRadius: '50%',
                          textAlign: 'center'
                        }}
                      >
                        <div >
                          <span className="text_inner_circle">Customer</span>
                          <span className="text_inner_circle">Rating</span>
                          <p className="value_inner_circle">92%</p>
                        </div>
                      </Box>
                    </Box>
                  </div>
                </div>
              </div>
              {/* Circular Progress bar End */}
              {/* Linear Progress bar start */}
              <div>
                <div>
                  <div style={{ width: '75%', margin: 'auto' }}>
                    <div className="rating_applied_heading">
                      <span>APPLIED Rating Distribution</span>
                    </div>
                    {
                      ratings?.rating?.['General Business Rating'] ? (
                        ratings?.rating?.['General Business Rating'].slice(0, (ratings?.rating?.['General Business Rating'].length - 1)).map((item: any, index: number) => {
                          return <div className="rating_main_2" key={index}>
                            <div className="rating_applied_l1">
                              <span style={{ fontSize: 14 }}>{item.name}</span>
                            </div>
                            <div className="rating_applied_rr">
                              <Box>
                                <LinearProgress
                                  variant="determinate"
                                  value={item.score == null ? 0 : item.score}
                                  // color='blue'
                                  // size={250}
                                  // thickness={3}
                                  style={{
                                    height: 15
                                  }}
                                // className={classes.bottom}
                                />
                                <Box
                                  top={0}
                                  left={0}
                                  bottom={0}
                                  right={0}
                                  position="absolute"
                                  display="flex"
                                  alignItems="center"
                                  style={{
                                    background: `#c1efff`,
                                    left: `${item.score == null ? 0 : item.score}%`,
                                    height: 15
                                  }}
                                >
                                </Box>
                              </Box>
                            </div>
                            <span>{item.score == null ? 0 : item.score}%</span>
                          </div>
                        })
                      )
                        : null
                    }
                  </div>
                </div>
              </div>
              {/* Linear Progress bar End */}

              <div style={{ width: '75%', margin: 'auto', marginTop: 25 }}>
                <div>
                  <Row
                    gutter={12}
                  >
                    <Col className="col1" span={6}>
                      <div>
                        <div className="headerr">
                          <span>Employee View</span>
                        </div>
                        <div>
                          {
                            ratings?.rating?.Employee && (
                              ratings?.rating?.Employee.slice(0, (ratings?.rating?.Employee.length - 1)).map((item: any, index: number) => {
                                return <div style={{ display: 'flex', alignItems: 'center', marginBottom: 5 }} key={index} >
                                  <div className="rating_view_11">
                                    <Box position="relative" display="inline-flex">
                                      <CircularProgress
                                        variant="determinate"
                                        value={item.score == null ? 100 : item.score}
                                        // color={'#0076de'}
                                        size={40}
                                        thickness={4}
                                        style={{
                                          color: item.score == null ? "rgb(193, 239, 255) " : "#2f5597",
                                          zIndex: 1,
                                          transform: "rotate(90deg)"
                                        }}
                                      // className={classes.bottom}
                                      />
                                      <Box
                                        top={0}
                                        left={0}
                                        bottom={0}
                                        right={0}
                                        position="absolute"
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                        style={{
                                          background: '#fff',
                                          borderRadius: '50%',
                                          textAlign: 'center'
                                        }}
                                      >
                                        <div >
                                          <p className="percent_text">{item.score == null ? 0 : item.score}%</p>
                                        </div>
                                      </Box>
                                    </Box>
                                  </div>
                                  <span className="text_span">{item.name}</span>
                                </div>
                              })
                            )
                          }
                        </div>
                      </div>
                    </Col>
                    <Col className="col1" span={6}>
                      <div>
                        <div className="headerr">
                          <span>Supplier View</span>
                        </div>
                        <div>
                          {
                            ratings?.rating?.Supplier && (
                              ratings?.rating?.Supplier.slice(0, (ratings?.rating?.Supplier.length - 1)).map((item: any, index: number) => {
                                return <div style={{ display: 'flex', alignItems: 'center', marginBottom: 5 }} key={index} >
                                  <div className="rating_view_11">
                                    <Box position="relative" display="inline-flex">
                                      <CircularProgress
                                        variant="determinate"
                                        value={item.score == null ? 100 : item.score}
                                        // color={'#0076de'}
                                        size={40}
                                        thickness={4}
                                        style={{
                                          color: item.score == null ? "rgb(193, 239, 255) " : "#2f5597",
                                          zIndex: 1,
                                          transform: "rotate(90deg)"
                                        }}
                                      // className={classes.bottom}
                                      />
                                      <Box
                                        top={0}
                                        left={0}
                                        bottom={0}
                                        right={0}
                                        position="absolute"
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                        style={{
                                          background: '#fff',
                                          borderRadius: '50%',
                                          textAlign: 'center'
                                        }}
                                      >
                                        <div >
                                          <p className="percent_text">{item.score == null ? 0 : item.score}%</p>
                                        </div>
                                      </Box>
                                    </Box>
                                  </div>
                                  <span className="text_span">{item.name}</span>
                                </div>
                              })
                            )
                          }
                        </div>
                      </div>
                    </Col>
                    <Col className="col1" span={6}>
                      <div>
                        <div className="headerr">
                          <span>Customer View</span>
                        </div>
                        <div>
                          {
                            ratings?.rating?.['Business Customer'] && (
                              ratings?.rating?.['Business Customer'].slice(0, (ratings?.rating?.['Business Customer'].length - 1)).map((item: any, index: number) => {
                                return <div style={{ display: 'flex', alignItems: 'center', marginBottom: 5 }} key={index} >
                                  <div className="rating_view_11">
                                    <Box position="relative" display="inline-flex">
                                      <CircularProgress
                                        variant="determinate"
                                        value={item.score == null ? 100 : item.score}
                                        // color={'#0076de'}
                                        size={40}
                                        thickness={4}
                                        style={{
                                          color: item.score == null ? "rgb(193, 239, 255) " : "#2f5597",
                                          zIndex: 1,
                                          transform: "rotate(90deg)"
                                        }}
                                      // className={classes.bottom}
                                      />
                                      <Box
                                        top={0}
                                        left={0}
                                        bottom={0}
                                        right={0}
                                        position="absolute"
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                        style={{
                                          background: '#fff',
                                          borderRadius: '50%',
                                          textAlign: 'center'
                                        }}
                                      >
                                        <div >
                                          <p className="percent_text">{item.score == null ? 0 : item.score}%</p>
                                        </div>
                                      </Box>
                                    </Box>
                                  </div>
                                  <span className="text_span">{item.name}</span>
                                </div>
                              })
                            )
                          }
                        </div>
                      </div>
                    </Col>
                    <Col className="col1" span={6}>
                      <div>
                        <div className="headerr">
                          <span>Community View</span>
                        </div>
                        <div>
                          {
                            ratings?.rating?.['Business Community'] && (
                              ratings?.rating?.['Business Community'].slice(0, (ratings?.rating?.['Business Community'].length - 1)).map((item: any, index: number) => {
                                return <div style={{ display: 'flex', alignItems: 'center', marginBottom: 5 }} key={index} >
                                  <div className="rating_view_11">
                                    <Box position="relative" display="inline-flex">
                                      <CircularProgress
                                        variant="determinate"
                                        value={item.score == null ? 100 : item.score}
                                        // color={'#0076de'}
                                        size={40}
                                        thickness={4}
                                        style={{
                                          color: item.score == null ? "rgb(193, 239, 255) " : "#2f5597",
                                          zIndex: 1,
                                          transform: "rotate(90deg)"
                                        }}
                                      // className={classes.bottom}
                                      />
                                      <Box
                                        top={0}
                                        left={0}
                                        bottom={0}
                                        right={0}
                                        position="absolute"
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                        style={{
                                          background: '#fff',
                                          borderRadius: '50%',
                                          textAlign: 'center'
                                        }}
                                      >
                                        <div >
                                          <p className="percent_text">{item.score == null ? 0 : item.score}%</p>
                                        </div>
                                      </Box>
                                    </Box>
                                  </div>
                                  <span className="text_span">{item.name}</span>
                                </div>
                              })
                            )
                          }
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>

              {/* COMPANY MISSION, VISION & VALUES component start */}
              <div className="headline" >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div className="overview">
                    <ScheduleOutlined style={{ fontSize: 35, color: '#fff', marginTop: 7 }} />
                  </div>
                  <Text className="domine_expert_text" style={{ color: '#2f5597', marginLeft: 15, textTransform: 'uppercase' }}>COMPANY MISSION, VISION & VALUES</Text>
                </div>
                <hr className="hr_line" />
                <div>

                </div>
              </div>
              {/* COMPANY MISSION, VISION & VALUES component end */}

              {/* HEALTH, SAFETY & ENVIRONMENT STATEMENT component start */}
              <div className="headline" >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div className="overview">
                    <ScheduleOutlined style={{ fontSize: 35, color: '#fff', marginTop: 7 }} />
                  </div>
                  <Text className="domine_expert_text" style={{ color: '#2f5597', marginLeft: 15, textTransform: 'uppercase' }}>HEALTH, SAFETY & ENVIRONMENT STATEMENT</Text>
                </div>
                <hr className="hr_line" />
                <div>

                </div>
              </div>
              {/* HEALTH, SAFETY & ENVIRONMENT STATEMENT component end */}

              {/* PRODUCTS AND SERVICES component start */}
              <div className="headline" >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div className="overview">
                    <SchoolIcon style={{ fontSize: 35, color: '#fff', marginTop: 7 }} />
                  </div>
                  <Text className="domine_expert_text" style={{ color: '#2f5597', marginLeft: 15, textTransform: 'uppercase' }}>PRODUCTS AND SERVICES</Text>
                </div>
                <hr className="hr_line" />
                <div>

                </div>
              </div>
              {/* PRODUCTS AND SERVICES component end */}

              {/* CLIENT REFERENCES component start */}
              <div className="headline" >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div className="overview">
                    <ReadOutlined style={{ fontSize: 35, color: '#fff', marginTop: 7 }} />
                  </div>
                  <Text className="domine_expert_text" style={{ color: '#2f5597', marginLeft: 15, textTransform: 'uppercase' }}>CLIENT REFERENCES</Text>
                </div>
                <hr className="hr_line" />
                <div>

                </div>
              </div>
              {/* CLIENT REFERENCES component end */}

            </div>
          </Col>
        </Row>
      </div>
    );
  }
  // Customizable Area End
}
