Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.labelCommunityForum = "Community Forums";
exports.labelWorkAnniversaries = "Upcoming Work Anniversaries";
exports.labelBirthAnniversaries = "Upcoming Birth Anniversaries";
exports.labelKnownPeople = "People you may know"
exports.labelInvitations = "Invitations"
exports.GETMethod = "GET";
exports.POSTMethod = "POST";
exports.PUTMethod = "PUT";
exports.DELETEMethod = "DELETE";
exports.APPLICATIONJSONContentType = "application/json";
exports.MULTIPARTFormData = "multipart/form-data";
exports.exampleAPiMethod = "POST";
exports.validationApiContentType = "application/json"
exports.SearchAPIMethod = "GET";
exports.deleteCommunityApiMethod = "DELETE"
exports.commentEndPointApi ="bx_block_conversationthreading/comments/"

exports.GetProfileInfoEndPoint = "bx_block_profile/profiles";
exports.newUserSearchEndPoint = "bx_block_search/search/user_search?search=";
exports.GetCategoriesEndPoint = "bx_block_categories/categories";
exports.GetSendRegEndPoint = "bx_block_request_management/requests";
exports.postsApiEndpoint = "bx_block_posts/posts";
exports.GetCommunityPostsEndPoint =
  "bx_block_posts/posts/fetch_community_posts";
exports.commentsApiEndpoint = "bx_block_comments/comments";
exports.GetNetConnectionEndPoint = "bx_block_request_management/requests";
exports.getCommunitiesApiEndpoint = "bx_block_communityforum/community_forums";
exports.createEditCommunityApiEndPoint =
  "bx_block_communityforum/community_forums";
exports.deleteCommunityApiEndPoint = "bx_block_communityforum/community_forums";
exports.getCommunityDataApiEndpoint =
  "bx_block_communityforum/community_forums";
exports.getSetttingsFlagsApiEndpoint =
  "bx_block_communityforum/community_forums";
exports.getPostRequestsApiEndpoint = "bx_block_communityforum/community_forums";
exports.getJoinRequestsApiEndpoint = "bx_block_follow/community_joins";
exports.joinCommunityApiEndpoint = "bx_block_follow/community_joins";
exports.followCommunityApiEndpoint = "bx_block_follow/community_joins/follow";
exports.acceptRejectJoinRequestApiEndpoint = "bx_block_follow/community_joins";
exports.communityForumApiEndPoint = "bx_block_communityforum/community_forums";
exports.blockUserApiEndpoint = "bx_block_follow/community_blocks";
exports.anniversariesApiEndPoint = "account_block/accounts/anniversaries";
exports.searchAPIEndPoint = "bx_block_search/search?search=";
exports.refreshTokenApiEndPoint = "bx_block_login/refresh_tokens";
exports.notificationListingApiEndPoint =
  "bx_block_notifications/notifications?today_page=1&today_per_page=10&older_page=1&older_per_page=10&yesterday_page=1&yesterday_per_page=10"; //
exports.addDeviceTokenApiEndPoint = "account_block/accounts/add_device_token";
exports.followUnFollowEndPoint = "bx_block_follow/follows";
exports.getAnyAccountEndPoint = "account_block/accounts/";
exports.accountEndpoint = "account_block/accounts/";
exports.getNetConnectionEndPoint = "bx_block_request_management/requests";
exports.InviteByAdminEndPoint = "bx_block_follow/community_joins/admin_invite";
exports.getInvitedFriendsEndPoint =
  "bx_block_follow/community_joins/fetch_admin_invitations";
exports.getNetworkEndPoint = "bx_block_request_management/requests";
exports.manageInvitedFriendsEndPoint = "bx_block_follow/community_joins/";
exports.sendLinkEndPoint = "send_link";
exports.communityEndPoint = "bx_block_communityforum/community_forums/";
exports.postendpoint = "bx_block_posts/posts";
exports.createComment = "bx_block_comments/comments";
exports.likeDislikeApiMethod = "POST";
exports.getKnownPeopleEndPoint =
  "bx_block_request_management/requests/suggestions";
exports.getNetworkApiMethod = "GET";
// Home Feed Posts API details
exports.getPostsApiContentType = "application/json";
exports.getPostsApiEndpoint = "bx_block_posts/posts";
exports.getPostsApiMethod = "GET";
exports.getPostByIdForHidePost = "bx_block_posts/posts";
exports.getPostByIdForHidePostMethodType ="GET"

//Account Details
exports.getAccountDetailsEndPoint = "account_block/accounts";
exports.getUserAnalyticsApiCallId = "account_block/accounts/user_analytics_details"
exports.getAccountDetailsApiMethod = "GET"
exports.manageRequestApiEndPoint = "bx_block_communityforum/community_forums"
exports.communityGetMethodType = "GET";

//get pending posts and joining requests
exports.getRequestsApiContentType = "application/json";
exports.getRequestsApiMethod = "GET";

//getNetworkRelation
exports.getNetworkRelation = "bx_block_request_management/requests/relations"
exports.getFollowersApiCallIdEndPoint = "account_block/accounts/followers_list";

// get Followers
exports.getConnectionApiCallIdEndPoint = "bx_block_request_management/requests?status=accepted";

exports.getCustomerINFOEndPoint = "bx_block_payments/payments/customer_info";
exports.getCustomerINFOMethod = "GET";
exports.getAllSubscribers = "bx_block_subscriptions/account_subscriptions";
exports.getAllSubscribersMethod = "GET";
exports.postNewsSubscribeMethodType = "POST";
exports.postNewsSubscribe = "bx_block_contact_us/newsletter"
exports.postNewsUnSubscribeMethodType = "GET";
exports.postNewsUnSubscribe = "bx_block_contact_us/news_letter_unsubscribe"
// delete post 
exports.getMySavedPostsApiEndPoint ="bx_block_posts/posts/saved_posts"
exports.deletePostEndPoint = "bx_block_posts/posts";
exports.getMyHidePostsApiEndPoint = "bx_block_posts/posts/hidden_posts";
exports.updateShareCountApiEndPoint = "bx_block_posts/posts/"
// Customizable Area End
exports.getInvitedFriendsEndPoint = "bx_block_follow/community_joins/fetch_admin_invitations";
exports.manageInvitedFriendsEndPoint ='bx_block_follow/community_joins/';
exports.sendLinkEndPoint = "send_link";
exports.communityEndPoint = "bx_block_communityforum/community_forums/"
exports.labelCommunityForum = "Community Forums"
exports.labelWorkAnniversaries = "Upcoming Work Anniversaries";
exports.labelBirthAnniversaries = "Upcoming Birth Anniversaries";
exports.labelKnownPeople = "People you may know";
exports.businessKnownPeople = "Business you may know"
exports.labelInvitations = "Invitations";
exports.placeHolderWhatOnMind = "What's on your mind?";
exports.btnLearnMore = "Learn More";
exports.getUserPostsApiEndpoint = "bx_block_posts/posts"
exports.getLoginUserPostApiEndPoint = "bx_block_posts/posts/fetch_user_posts"
exports.getMyReviewsApiEndpoint = "bx_block_reviews/reviews"
exports.getFollowingsApiCallIdEndPoint = "account_block/accounts/following_list"
exports.getRatingReceivedApiEndpoint = "account_block/accounts/ratings_received"
exports.getRatingSendApiEndpoint = "account_block/accounts/ratings_sent"
exports.downloadResumeEndPoint = "bx_block_cvresumecandidatemanagement/resume"
exports.getMyHidePostsApiEndPoint = "bx_block_posts/posts/hidden_posts"
exports.postAdmininviteCommunity = "bx_block_follow/community_joins/admin_invite"
// Customizable Area End 
