import React from "react";
// Customizable Area Start
import { Row, Col, Image, Typography, Button, Spin } from "antd";
import "antd/dist/antd.css";
import { style, AppImage, AndroidImage, iosImage } from "./assets";
import ForgotController from "./forgotPasswordController.web";
import { CloseCircleFilled } from "@ant-design/icons";
const { Title, Text } = Typography;
// Customizable Area End

export default class ResetPasswordError extends ForgotController {
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    return (
      <>
        <Row>
          <Col span={14} xs={0} sm={12} md={12} lg={12} xl={14} xxl={14}>
            {!this.state.forgotPasswordBannerImage ? (
              <Spin />
            ) : (
              <Image
                preview={false}
                src={this.state.forgotPasswordBannerImage}
                wrapperStyle={{ height: "100%", width: "100%" }}
                style={{ height: "100%", width: "100%" }}
              />
            )}
          </Col>
          <Col
            span={3}
            xs={24}
            sm={0}
            md={0}
            lg={0}
            xl={0}
            xxl={0}
            className={style.top_padding}
          />
          <Col
            span={10}
            xs={24}
            sm={12}
            md={12}
            lg={12}
            xl={10}
            xxl={10}
            // className={style.top_padding}
            style={{ alignSelf: "center" }}
          >
            <Row>
              <Col span={4} />
              <Col span={16}>
                <Row className={style.loginText}>
                  <br />
                  <br />
                  <CloseCircleFilled
                    style={{
                      fontSize: "50px",
                      paddingBottom: "16px",
                      color: "#ea0c0c"
                    }}
                  />

                  <Title className={style.w_100_center} level={3}>
                    Password Reset Error
                  </Title>
                </Row>
                <Row className={style.loginText}>
                  <Text
                    type="secondary"
                    style={{ textAlign: "center", marginBottom: "16px" }}
                  >
                    Currently we are facing an error while resetting your
                    password. Please try again
                  </Text>
                  <br />
                  <br />
                  <Button
                    className={style.btn_colors}
                    type="primary"
                    shape="round"
                    size={"large"}
                    onClick={() => this.props.history.goBack()}
                    style={{ width: "100%" }}
                  >
                    Try Again
                  </Button>
                </Row>
              </Col>
              <Col span={4} />
            </Row>
          </Col>
        </Row>
        <Row style={{ backgroundColor: "#f5f5f5" }}>
          <Col span={12} xs={24} sm={24} md={12} lg={12} xl={14} xxl={14}>
            <Row
              className={style.app_alignCenter}
              style={{ justifyContent: "flex-end", width: "100%" }}
            >
              <Image
                preview={false}
                src={AppImage}
                style={{ objectFit: "fill" }}
                // wrapperStyle={{ width: "100%" }}
              />
            </Row>
          </Col>
          <Col
            span={12}
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={10}
            xxl={10}
            // className={style.app_store}
          >
            <Row
              // className={style.app_alignCenter}
              style={{
                alignItems: "center",
                height: "100%",
                paddingRight: "40px",
                paddingLeft: "40px"
              }}
            >
              <Col span={6} xs={0} sm={6} md={3} lg={3} xl={3} xxl={6} />
              <Col span={10} xs={24} sm={24} md={18} lg={18} xl={18} xxl={10}>
                <Row>
                  <Title level={3}>Available for Android and IOS</Title>
                  <Text
                    style={{
                      paddingBottom: "20px",
                      fontSize: "1.1rem",
                      lineHeight: "1.3rem"
                    }}
                    type="secondary"
                    className={style.w_100}
                  >
                    Download applied app from your Play Store or App Store
                  </Text>
                  <Col span={11}>
                    <Image preview={false} src={AndroidImage} />
                  </Col>
                  <Col span={2} />
                  <Col span={11}>
                    <Image preview={false} src={iosImage} />
                  </Col>
                </Row>
              </Col>
              <Col span={8} xs={8} sm={8} md={3} lg={3} xl={3} xxl={8} />
            </Row>
          </Col>
        </Row>
        <Row
          style={{
            justifyContent: "center",
            paddingRight: "40px",
            paddingTop: "10px",
            paddingLeft: "40px"
          }}
        >
          <p>©2021 applied. All rights reserved.</p>
          <a
            target="_blank"
            href="https://www.websitepolicies.com/policies/view/hiuS6UvI"
            style={{
              opacity: 0.8,
              color: "#798da0",
              fontWeight: 600
            }}
          >
            &nbsp;&nbsp;Privacy Policy&nbsp;&nbsp;
          </a>
          <div
            style={{
              height: "20px",
              borderLeft: "2px solid black",
              opacity: "20%"
            }}
          />
          <a
            href="https://www.websitepolicies.com/policies/view/qhOP4hOG"
            target="_blank"
            style={{
              color: "#798da0",
              opacity: 0.8,
              fontWeight: 600
            }}
          >
            &nbsp;&nbsp;Terms of use
          </a>
        </Row>
      </>
    );
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
}
// Customizable Area Start
// Customizable Area End
