import React from "react";
// Customizable Area Start
import {
  Row,
  Col,
  Image,
  Form,
  Input,
  Typography,
  Button,
  message,
  Divider,
  Spin,
  Checkbox
} from "antd";
import { LinkedinFilled } from "@ant-design/icons";
import "antd/dist/antd.css";
import "./auth.css";
import {
  iconImage,
  style,
  AppImage,
  AndroidImage,
  iosImage
} from "./assets";
import CreateAccountController from "./createAccountController.web";
import CountryCodeSelector from "../../country-code-selector/src/CountryCodeSelector.web";
import { AlertMessage } from "../../alert/src/AlertMessage.web";
import { phoneRegExp } from "./commonExported.web";
import { EyeFilled, EyeInvisibleFilled } from '@ant-design/icons';
const { Title, Text, Link } = Typography;
// const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const userRegex = /^[a-zA-Z ]*$/;
const passwrdRegex = /^(?=.*[A-Za-z])(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$/;
// const emailReg = new RegExp("\\w+");
// const emailReg = new RegExp("[^@]+[@][\\S]+[.][\\S]+");
// const emailReg = new RegExp(
//   "/A(?!.)(?!.*?..)([.w-]+)(?<!.)@((?:[-a-z0-9]+.)+[a-z]{2,})z/i"
// );
const emailReg = RegExp(
  /^([a-zA-Z0-9_\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/
);
// Customizable Area End

export default class CreateAccountWeb extends CreateAccountController {
  // Customizable Area Start
  renderLandingPageImage = () => (!this.state.landingPageImage ? <Spin /> :
    <Image
      preview={false}
      className={style.image_width_set}
      src={this.state.landingPageImage}
      alt=''
      wrapperStyle={{ height: "100%", width: "100%" }}
      style={{ height: "100%" }}
    />)

  getButtonClassName = (profileType: string) => {
    if (this.state.button === profileType) {
      return style.btn_colors;
    }
    return style.btn_transparent;
  }

  getButtonColorStyle = (userProfileType: string) => {
    if (this.state.button === userProfileType) {
      return "#fff"
    }
    return "#a9bac8";
  }

  conditionShort = (value: any) => {
   return value ? Promise.resolve() : Promise.reject(
      new Error("You have to agree with Terms & Conditions and Privacy Policy to Sign Up"))
  }

  // Customizable Area End
  render() {
    // Customizable Area Start
    return (
      <>
        {this.redirectUser()}
        <Row>
          <Col
            // className="gutter-row"
            span={14}
            xs={0}
            sm={0}
            md={12}
            lg={12}
            xl={14}
          >
            {this.renderLandingPageImage()}
          </Col>
          <Col
            // className="gutter-row"
            span={10}
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={10}
            style={{ paddingTop: "2%" }}
          >
            <Row>
              <Col span={4} />
              <Col span={16}>
                <Row>
                  <Col span={6} />
                  <Col span={12}>
                    <Image
                      preview={false}
                      className={style.logopadding}
                      src={iconImage}
                    />
                  </Col>
                  <Col span={6} />
                </Row>
                <Row className={style.loginText}>
                  <Title className={style.alignCenter} level={3} style={{ marginBottom: 0 }}>
                    Welcome!
                  </Title>
                  <Text type="secondary" className={style.w_100_center} style={{ marginBottom: 20, color: "#BFBFBF", fontSize: "16px" }}>
                    Please signup to continue
                  </Text>

                  <Text type="secondary" style={{ color: "#BFBFBF", fontSize: "16px" }}>I want to signup for</Text>
                </Row>

                <Row className={style.buttons_tab}>
                  <Col span={10} xs={24} sm={24} md={24} lg={10} xl={10}>
                    <Button
                      // type={
                      //   this.state.button === "business" ? "primary" : "default"
                      // }
                      className={this.getButtonClassName("business")}
                      data-testid="changeButtonBusiness"
                      shape="round"
                      size={"large"}
                      onClick={() => this.changeButton("business")}
                      style={{
                        color: this.getButtonColorStyle("business"),
                        fontWeight: "normal",
                        width: "100%"
                      }}
                    >
                      Business Profile
                    </Button>
                  </Col>
                  <Col span={4} xs={24} sm={4} md={2} lg={4} xl={4}>
                    <Button disabled={true} size="small" />
                  </Col>
                  <Col span={10} xs={24} sm={24} md={24} lg={10} xl={10}>
                    <Button
                      // type={
                      //   this.state.button === "personal" ? "primary" : "default"
                      // }
                      className={this.getButtonClassName("personal")}
                      data-testid="changeButtonPersonal"
                      style={{
                        color: this.getButtonColorStyle("personal"),
                        fontWeight: "normal",
                        width: "100%"
                      }}
                      shape="round"
                      size={"large"}
                      onClick={() => this.changeButton("personal")}
                    >
                      Personal Profile
                    </Button>
                  </Col>
                </Row>
                <Form
                  data-testid="test-signup"
                  initialValues={{
                    full_name: "",
                    confirm_password: "",
                    full_phone_number: "",
                    email: "",
                    password: "",
                    checked: false
                  }}
                  onFinish={this.handleSignup}
                >
                  <Form.Item
                    data-testid="Full-Name"
                    rules={[
                      { required: false, message: "Required field" },
                      {
                        min: 1,
                        max: 40,
                        message: "Max Length Should be 40"
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (value.length > 0 && value.trim().length > 0) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error("Required field"));
                        }
                      }),
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (getFieldValue("full_name").match(userRegex)) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error("Enter Only Alphabets")
                          );
                        }
                      })
                    ]}
                    name="full_name"
                  >
                    <Input
                      className={style.input_fields}
                      style={{ borderWidth: 1, color: "#a9bac8" }}
                      name="full_name"
                      placeholder="Name"
                      size="large"
                      type={"text"}
                    />
                  </Form.Item>
                  <Form.Item
                    data-testid="test-Email"
                    rules={[
                      { required: true, message: "Required field" },
                      // { type: "email", message: "Invalid Email!" },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || emailReg.test(value)) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error("Please Enter Valid Email!")
                          );
                        }
                      })
                    ]}
                    name="email"
                  >
                    <Input
                      className={style.input_fields}
                      name="email"
                      style={{ borderWidth: 1, color: "#a9bac8" }}
                      placeholder="Email"
                      size="large"
                      type={"email"}
                    />
                  </Form.Item>

                  <Form.Item
                    data-testid="PhoneNumber"
                    rules={[
                      { required: true, message: "Required field" },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (
                            !value ||
                            getFieldValue("full_phone_number").match(
                              phoneRegExp
                            )
                          ) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            { message: "Incorrect Phone Number" }
                          );
                        }
                      })
                    ]}
                    name="full_phone_number"
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between"
                      }}
                    >
                      <CountryCodeSelector
                        id={"CountryCodeSelector"}
                        name="cc"
                        allowPropChange={true}
                        placeHolder={this.state.countryPlaceholder}
                        disable={false}
                        value={this.state.countryCode}
                        navigation={this.props.navigation}
                      />
                      <Input
                        className={style.input_fields}
                        data-test-id="phonenumberField"
                        style={{ borderWidth: 1, width: "68%", color: "#a9bac8" }}
                        name="full_phone_number"
                        placeholder="Phone Number"
                        size="large"
                        onKeyPress={(e) => {
                          if (isNaN(Number(e.key)) && e.key !== 'Backspace') {
                            e.preventDefault();
                          }
                        }}
                      />
                    </div>
                  </Form.Item>
                  <Form.Item
                    data-testid="test-password"
                    rules={[
                      { required: true, message: "Required field" },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (
                            !value ||
                            getFieldValue("password").match(passwrdRegex)
                          ) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(
                              "Must Contain 6 Characters, One Uppercase, One Lowercase, One Number and One Special Character"
                            )
                          );
                        }
                      })
                    ]}
                    name="password"
                  >
                    <Input.Password
                      className={style.input_fields}
                      name="password"
                      data-testid="passwordField"
                      placeholder="Password"
                      size="large"
                      type={"password"}
                      iconRender={(visible) => (visible ? <EyeFilled style={{ color: "#CFD9E2" }} /> : <EyeInvisibleFilled style={{ color: "#CFD9E2" }} />)}
                    />
                  </Form.Item>
                  <Form.Item
                    data-testid="test-passwordConfirm"
                    style={{ marginBottom: 8 }}
                    rules={[
                      { required: true, message: "Required field" },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (
                            !value ||
                            getFieldValue("confirm_password") === (getFieldValue("password"))
                          ) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(
                              "Both Passwords Should Match"
                            )
                          );
                        }
                      })
                    ]}
                    name="confirm_password"
                  >
                    <Input.Password
                      className={style.input_fields}
                      name="confirm_password"
                      data-testid="ConfirmpasswordField"
                      placeholder="Confirm Password"
                      size="large"
                      type={"password"}
                      iconRender={(visible) => (visible ? <EyeFilled style={{ color: "#CFD9E2" }} /> : <EyeInvisibleFilled style={{ color: "#CFD9E2" }} />)}
                    />
                  </Form.Item>
                  <Form.Item
                    name="agreement"
                    data-testid="Agreement"
                    valuePropName="checked"
                    rules={[
                      ({ getFieldValue }) => ({
                        validator: (_, value) => {
                          return this.state.isAgreement
                            ? Promise.resolve()
                            : this.conditionShort(value)
                        }

                      })
                    ]}
                  >
                    <Checkbox style={{ borderColor: "#ccc" }}>
                      <Text style={{ color: "#ccc", textAlign: "right" }}>
                        I agree to{" "}
                        <a
                          style={{ color: "rgba(30, 79, 121, 1)" }}
                          href="https://www.websitepolicies.com/policies/view/qhOP4hOG"
                          target="_blank"
                        >
                          Terms & Conditions
                        </a>
                        {/* <Text style={{ color: "rgba(30, 79, 121, 1)" }}>
                          Terms & Conditions
                        </Text> */}{" "}
                        and{" "}
                        {/* <Text style={{ color: "rgba(30, 79, 121, 1)" }}>
                          Privacy Policy
                        </Text> */}
                        <a
                          href="https://www.websitepolicies.com/policies/view/hiuS6UvI"
                          target="_blank"
                          style={{ color: "rgba(30, 79, 121, 1)" }}
                        >
                          Privacy Policy
                        </a>
                      </Text>
                    </Checkbox>
                  </Form.Item>
                  {/* <Form.Item
                    name="confirm_password"
                    dependencies={["password"]}
                    // hasFeedback
                    rules={[
                      {
                        required: true,
                        message: "Please confirm your password!",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue("password") === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(
                              "The two passwords that you entered do not match!"
                            )
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password
                      className={style.input_fields}
                      name="confirm_password"
                      placeholder="Confirm Password"
                    />
                  </Form.Item> */}
                  <Spin spinning={this.state.loading}>
                    <Button
                      className={style.btn_colors}
                      type="primary"
                      htmlType="submit"
                      data-testid="submitBtn"
                      shape="round"
                      size={"large"}
                      style={{ width: "100%" }}
                      onClick={() => {
                        this.setState({ isAgreement: false })
                      }}
                    >
                      Sign Up
                    </Button>
                  </Spin>
                </Form>
                {/* <br /> */}
                {/* <Row>
                  <Col span={7} />
                  <Col span={10}>
                    <Divider>
                      <Text type="secondary" style={{ fontWeight: "lighter" }}>
                        OR
                      </Text>
                    </Divider>
                  </Col>
                  <Col span={7} />
                </Row>
                <Button
                  className={style.btn_transparent}
                  shape="round"
                  size={"large"}
                  style={{
                    color: "rgba(0, 119, 181, 1)",
                    justifyContent: "center"
                  }}
                  icon={
                    <LinkedinFilled
                      translate={{}}
                      style={{ fontSize: "24px", color: "#0e76a8" }}
                    />
                  }
                  onClick={() => message.info("Coming Soon")}
                >
                  Connect with Linkedin
                </Button> */}
                {/* <br /> */}
                <Row
                  style={{
                    justifyContent: "center",
                    paddingTop: "12px",
                    marginBottom: 18
                    // fontSize: "1.2rem",0
                  }}
                >
                  <Text type="secondary" onClick={this.handlePress}>
                    Already have an account?&nbsp;
                  </Text>
                  <Link href="/" style={{ color: "#005574" }}>
                    Log In
                  </Link>
                </Row>
              </Col>
              <Col span={4} />
            </Row>
          </Col>
        </Row>
        <Row style={{ backgroundColor: "#f5f5f5" }}>
          <Col span={12} xs={24} sm={24} md={12} lg={12} xl={14} xxl={14}>
            <Row
              className={style.app_alignCenter}
              style={{ justifyContent: "flex-end", width: "100%" }}
            >
              <Image
                preview={false}
                src={AppImage}
                style={{ objectFit: "fill" }}
              // wrapperStyle={{ width: "100%" }}
              />
            </Row>
          </Col>
          <Col
            span={12}
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={10}
            xxl={10}
          // className={style.app_store}
          >
            <Row
              // className={style.app_alignCenter}
              style={{
                height: "100%",
                alignItems: "center",
                paddingRight: "40px",
                paddingLeft: "40px"
              }}
            >
              <Col span={6} xs={0} sm={6} md={3} lg={3} xl={3} xxl={6} />
              <Col span={10} xs={24} sm={24} md={18} lg={18} xl={18} xxl={10}>
                <Row>
                  <Title level={3}>Available for Android and IOS</Title>
                  <Text
                    type="secondary"
                    className={style.w_100}
                    style={{
                      fontSize: "1.1rem",
                      lineHeight: "1.3rem",
                      paddingBottom: "20px"
                    }}
                  >
                    Download applied app from your Play Store or App Store
                  </Text>
                  <Col span={11}>
                    <Image preview={false} src={AndroidImage} />
                  </Col>
                  <Col span={2} />
                  <Col span={11}>
                    <Image preview={false} src={iosImage} />
                  </Col>
                </Row>
              </Col>
              <Col span={8} xs={8} sm={8} md={3} lg={3} xl={3} xxl={8} />
            </Row>
          </Col>
        </Row>
        <Row
          style={{
            justifyContent: "center",
            paddingTop: "10px",
            paddingLeft: "40px",
            paddingRight: "40px"
          }}
        >
          <p>©2021 applied. All rights reserved.</p>
          <a href="https://www.websitepolicies.com/policies/view/hiuS6UvI" target="_blank">
            &nbsp;&nbsp;Privacy Policy&nbsp;&nbsp;
          </a>
          <a href="https://www.websitepolicies.com/policies/view/qhOP4hOG" target="_blank">
            &nbsp;&nbsp;Terms of use
          </a>
        </Row>
        <AlertMessage
          data-testid="alertNessage"
          isModalVisible={this.state.isErrorModalShow}
          title={this.state.errorContent.title}
          body={this.state.errorContent.body}
          isError={this.state.errorContent.isError}
          onOk={() =>
            this.setState({ isErrorModalShow: false, errorContent: {} })
          }
        />
      </>
    );
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
}
// Customizable Area Start
// Customizable Area End