import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
// import confirm from "../../alert/src/alert.web";
// import AlertError from "../../alert/src/alertError.web";
import { RouterProps } from "react-router";
export const configJSON = require("./config");
import { FormInstance } from "antd/lib/form";
import { Select, message, Modal, Row, Col, Button } from "antd";
import { string } from "yup";
const { Option } = Select;
export interface Props extends RouterProps {
  navigation: any;
  id: string;
}
interface S {
  loading: boolean;
  loadLocation: boolean;
  loadSubCategory: boolean;
  isRegistration: boolean;
  bussiness_profile: boolean;
  data: any;
  data1: any;
  // industry_type: any | unknown;
  token: string | null;
  category_data: [];
  category_list: object[];
  sub_category: any[];
  sub_category_data: object[];
  children: any[];
  businessIndustryList: any;
  businessOwnership: any;
  noOfEmployee: any;
  selectedNoOfEmployee: any;
  otherBusinessIndustryVisible: boolean;
  Other_Business_Industry: any;
  profileUrl: string;
  file: any;
  prev_data: any;
  isModalVisible: any;
  modalIndex: number;
  modalString: string;
  isSuccess: boolean;
  latitude: string | null;
  longitude: string | null;
  userAddress: string | null;
  redirect: boolean;
  contactPerson: string;
  currentYear: number;
  yearList: number[];
  copyProfileUrl: string;
  openCropModal: boolean;
  backgroundImage: any;
  backgroundImageFile: any;
  address: any;
  suggestionSelected: boolean,
  location: boolean;
  cover_file:any
}
interface SS {
  id: any;
}

export default class CreateProfileBasicControllerV2 extends BlockComponent<
  Props,
  S,
  SS
> {
  getBusinessProfilecallId: any;
  postUploadProfileImageApiCallId: any;
  profile_formref = React.createRef<FormInstance>();
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.receive = this.receive.bind(this);
    this.state = {
      cover_file:"",
      bussiness_profile: true,
      loading: true,
      loadLocation: false,
      loadSubCategory: false,
      isRegistration: true,
      data: {},
      data1: {},
      // industry_type:  this.props.history.location.state? this.props?.history?.location?.state?.industry_type:'',
      token: localStorage.getItem("token") ? localStorage.getItem("token") : "",
      category_data: [],
      category_list: [],
      children: [],
      sub_category: [],
      sub_category_data: [],
      businessIndustryList: [],
      businessOwnership: [],
      noOfEmployee: [],
      selectedNoOfEmployee: "",
      otherBusinessIndustryVisible: false,
      Other_Business_Industry: "",
      profileUrl: "",
      file: {},
      prev_data: {},
      isModalVisible: false,
      modalIndex: 0,
      modalString: "",
      isSuccess: false,
      latitude: null,
      longitude: null,
      userAddress: null,
      redirect: false,
      contactPerson: "",
      currentYear: new Date().getFullYear(),
      yearList: [],
      copyProfileUrl: '',
      openCropModal: false,
      backgroundImage: '',
      backgroundImageFile: {},
      address: null,
      suggestionSelected : false,
      location: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubCategory = this.handleSubCategory.bind(this);
    this.updateSubCategories = this.updateSubCategories.bind(this);
    this.getLocation = this.getLocation.bind(this);
    this.getCordinates = this.getCordinates.bind(this);
    this.reverseGeoCodeCordinates = this.reverseGeoCodeCordinates.bind(this);
    this.handleLocationError = this.handleLocationError.bind(this);
  }

  getLocation() {
    this.setState({ loadLocation: true });
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        this.getCordinates,
        this.handleLocationError
      );
    } else {
      console.log("geo location is not supported in browser");
      this.setState({ loadLocation: false });
    }
  }

  getCordinates(position: any) {
    console.log(position.coords.latitude);
    this.setState({
      latitude: position.coords.latitude,
      longitude: position.coords.longitude,
      // userAddress
    });
    this.reverseGeoCodeCordinates();
  }

  async reverseGeoCodeCordinates() {
    let key = "AIzaSyCBH92YX2oCbI7PChqFNrgzw-PolHAiKZc";
    await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${this.state.latitude
      },${this.state.longitude}&sensor=false&key=${key}`
    )
      .then((response) => response.json())
      .then((data) => {
        let newData = data.results[0].address_components;
        // console.log(newData, 'new data');
        // let place = newData.filter(
        //   (ac: { types: string | string[] }) => ~ac.types.indexOf("sublocality_level_1")
        // )[0].long_name;
        let city = newData.filter(
          (ac: { types: string | string[] }) => ~ac.types.indexOf("locality")
        )[0].long_name;
        let country = newData.filter(
          (ac: { types: string | string[] }) => ~ac.types.indexOf("country")
        )[0].long_name;
        this.setState(
          (state) => ((state.prev_data.location = city + ", " + country), state)
        );
        setTimeout(() => {
          this.profile_formref?.current?.setFieldsValue({
            location: city + ", " + country
          });
        }, 0);
      })
      .catch((error) => {
        this.setState({ loadLocation: false });
        alert(`error while location, ${error}`);
      });
    this.setState({ loadLocation: false });
  }

  handleLocationError(error: any) {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        this.setState({ loadLocation: false });
        alert("User denied the request for Geolocation.");
        break;
      case error.POSITION_UNAVAILABLE:
        this.setState({ loadLocation: false });
        alert("Location information is unavailable.");
        break;
      case error.TIMEOUT:
        this.setState({ loadLocation: false });
        alert("The request to get user location timed out.");
        break;
      case error.UNKNOWN_ERROR:
        this.setState({ loadLocation: false });
        alert("An unknown error occurred.");
        break;
    }
  }
  async componentDidMount() {
    let startYear = 1801;
    let year = [];
    while (startYear <= this.state.currentYear) {
      year.push(startYear);
      startYear = startYear + 1;
    }
    this.setState({ yearList: year.reverse() });
    let token = localStorage.getItem("token");
    if (token && token !== null && token !== undefined) {
      this.setState({ redirect: false });
    } else {
      this.setState({ redirect: true });
    }
    this.getCategories();
    // this.setState({ loading: true });
    let details: any = this.props.history.location.state;
    console.log(details, "hello");
    if (details && details != null) {
      let newData = Object.assign(
        {},
        {
          industry_type:
            details && details.industry_type
        },
        { history: details && details.history },
        { overview: details && details.overview }
      );
      // this.setState({industry_type: details.industry_type})
      this.setState({ data: newData, })
      if (details.profile) {
        this.setState({
          prev_data: details && details.profile,
          profileUrl: details.profile.profileUrl,
          cover_file: details.profile.coverUrl,
          address: details.profile.location
        });
      }
    }
    this.setState({ loading: false });
  }

  redirectUser() {
    if (this.state.redirect) {
      return <Redirect to="/" />;
    }
  }

  handleChange(event: any) {
    console.log("@@@ e======hello", event);
    this.setState({ selectedNoOfEmployee: event });
  }

  updateSubCategories() {
    try {


      console.log("striking data====up");
      // setTimeout(() => {
      this.setState({ loadSubCategory: true });
      // }, 200);

      if (this.state.prev_data.business && this.state.category_data) {
        let checkIndustry: any = this.state.category_data.filter(
          (item: any) => item.attributes.name === this.state.prev_data.business
        );
        this.setState({
          sub_category: checkIndustry[0].attributes.sub_categories,
        });
      } else if (this.state.data.industry_type && this.state.category_data) {
        let checkIndustry: any = this.state.category_data.filter(
          (item: any) =>
            item.attributes.name === this.state.data.industry_type
        );
        // console.log(checkIndustry, "update data", this.state.category_data);
        this.setState({
          sub_category: checkIndustry[0].attributes.sub_categories,
        });
      } else {
        this.setState({ loadSubCategory: false });
        console.log("error in loading sub_category_data");
      }
      console.log('************')
      setTimeout(() => {
        this.setState({ loadSubCategory: false });
      }, 200);
      // if ( this.formref?.current?.getFieldValue("business_industry") !== null) {
      //   // console.log('information is check3ed')
      //    this.formref?.current?.resetFields();
      // }
    } catch (e) {
      this.setState({ loadSubCategory: false });
      console.log('Eorororororooror', e)
    }
  }

  handleSubCategory(data: any) {
    console.log("striking data ==");
    let checkIndustry: any = this.state.category_data.filter(
      (item: any) => item.attributes.name === data
    );
    this.setState(
      {
        sub_category: checkIndustry[0].attributes.sub_categories,
        // prev_data.business_industry: undefined
      }
    );
    // this.updateSubCategories();
    // console.log("information striking ", this.profile_formref?.current?.getFieldValue("business_industry"))
    this.profile_formref?.current?.setFieldsValue({ business_industry: undefined })
    this.setState({ otherBusinessIndustryVisible: false })
    // }
  }

  //=============@@@ Upload Profile Image==============

  getBase64(img: any, callback: any) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  async beforeUpload(file: any) {
    console.log("file==>", file);
    const isJpgOrPng = (await file.type.slice(0, 5)) == "image";
    if (!isJpgOrPng) {
      //  message.error("You can only upload Image file!");
    } else {
      console.log("isJpgOrPng===>", isJpgOrPng);
      this.getBase64(file, (imageUrl: string) => {
        console.log("imageUrl===>", imageUrl);
        this.setState(
          {
            profileUrl: imageUrl,
            copyProfileUrl: imageUrl,
            openCropModal: true,
            loading: false,
          },

        );
      });
    }
    return isJpgOrPng;
  }

  async uploadBackgroundImage(file: any) {
    const isJpgorPng = (await file.type.slice(0, 5)) == 'image';
    if (!isJpgorPng) {
      console.log(file);
    } else {
      this.getBase64(file, (imageurl: string) => {
        this.setState(
          {
            backgroundImage: imageurl,
          },
        );
      });
    }
    return isJpgorPng;
  }

  handleImageChange = (info: any) => {
    console.log("info", info);
    this.setState({
      file: info.file.originFileObj,
    });
    console.log("@@@uploade Image", this.state.file);
    this.uploadProfileImage();
  };
  handleBackgroundImageChange = (info: any) => {
    if (info?.fileList) {
      const url = URL.createObjectURL(info.file.originFileObj);
      this.setState({cover_file:url})
    }
    this.setState({
      backgroundImageFile: info.file.originFileObj,
    });
    this.uploadBackgroundImageSetup(info.file.originFileObj);
  };

  //======================@@@@ Receive Function =======================

  async receive(from: string, message: Message) {
    console.log("@@@ API Response ==========", message);
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getBusinessProfilecallId !== null &&
      this.getBusinessProfilecallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      // this.apiGetUserOtpCallId = null;

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      console.log("get all buisness data insutry", responseJson);

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      console.log("get all buisness", errorReponse);

      if (responseJson) {
        this.getbusinessProfileSuccessCallBack(responseJson);
      } else {
        this.getBusinessProfileFailureCallBack(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    } else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.postUploadProfileImageApiCallId !== null &&
      this.postUploadProfileImageApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      // this.apiGetUserOtpCallId = null;

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      console.log("get all buisness", responseJson);

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      console.log("get all buisness", errorReponse);

      if (responseJson) {
        this.setState({
          isSuccess: true,
        });
        this.postUploadImageSuccessCallBack(responseJson);
      } else {
        this.postUploadImageFailureCallBack(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }
  }

  //======================@@@ Success And Failure Call Back ======================

  getbusinessProfileSuccessCallBack = (responseJson: any) => {
    // console.log("@@@ initial success call============", responseJson);
    let dropDownDetail = responseJson && responseJson.data;
    // console.log("@@@@@@checkEmployment dropDownDetail=======", dropDownDetail);
    let checkBusinessInds = dropDownDetail.filter(
      (item: any) => item.attributes.name === "Business Industry"
    );

    let checkOwnerShip = dropDownDetail.filter(
      (item: any) => item.attributes.name === "Ownership"
    );
    console.log("@@@@@@checkEmployment=======", checkOwnerShip);

    let checkOfEmployee = dropDownDetail.filter(
      (item: any) => item.attributes.name === "No of Employees"
    );
    console.log("@@@@@@checkEmployment=======", checkOfEmployee);

    this.setState(
      {
        category_data: dropDownDetail,
        businessIndustryList: checkBusinessInds[0].attributes.sub_categories,
        businessOwnership: checkOwnerShip[0].attributes.sub_categories,
        noOfEmployee: checkOfEmployee[0].attributes.sub_categories,
      },
      () => {
        console.log(
          "@@@@@@checkEmployment=======212222222222",
          this.state.businessIndustryList,
          this.state.category_data
        );
      }
    );
    this.updateSubCategories();
  };

  getBusinessProfileFailureCallBack = (responseJson: any) => {
    console.log(
      "@@@ Get Business Profile Failure call Back ========",
      responseJson
    );
  };

  //==========@@@ Success And Failure Call Back For upload Image==================

  postUploadImageSuccessCallBack = (responseJson: any) => {
    console.log(
      "@@@ Post Upload Profile Image success call Back ========",
      responseJson
    );
    // alert(`Profile Pic Upload Successfull`);
  };
  postUploadImageFailureCallBack = (responseJson: any) => {
    console.log(
      "@@@ Post Upload Profile Image Failure call Back ========",
      responseJson
    );
    alert(`Profile Pic Not Uploaded`);
  };

  // @@@@ Handle Date of Basic Screen, navigation to next page ===================

  handleAddProfileData = (data: object | any) => {
    console.log("@@@ business profile data ======", data);
    delete data["profile_pic"];
    delete data["cover_pic"]
    delete data["coverUrl"]
    if (this.state.otherBusinessIndustryVisible) {
      data.business_industry = this.state.Other_Business_Industry;
    }
    // console.log("@@@ business profile data ======22222", data);

    Object.assign(data, {
      full_name: data.company_name,
    });

    let newData = Object.assign({}, data, {
      profileUrl: this.state.profileUrl,
      coverUrl:this.state.cover_file
      // location:
      //   this.state.userAddress != null
      //     ? this.state.userAddress
      //     : this.state.prev_data.location,
    });

    let finalData = Object.assign(
      {},
      { profile: { ...newData, location: this.state.address } },
      {
        industry_type:
          this.state.data.industry_type && this.state.data.industry_type
      },
      { overview: this.state.data && this.state.data.overview },
      { history: this.state.data && this.state.data.history }
    );
    console.log("finalData", finalData);
    this.props.history.replace({
      state: finalData,
    });
    this.props.history.push({
      pathname: "/setup/profile/business/about",
      state: finalData,
    });
  };

  //=========================@@@@ Call Get Api ============================

  getCategories = async () => {
    const header = {
      "Content-Type": "application/json",
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getBusinessProfilecallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_categories/categories"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    console.log(
      "@@@ Request ===========",
      requestMessage,
      this.getBusinessProfilecallId
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  //====================@@@ Post APi For Upload Image=======================
  uploadProfileImage = () => {
    const header = {
      // "Content-Type": "multipart/form-data",
      token: this.state.token,
    };

    console.log("this.state.profileUrl", this.state.file);

    const formData = new FormData();
    formData.append("profile_pic", this.state.file);

    console.log("@@@ upload==", formData);
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.postUploadProfileImageApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_profile/profiles/upload_profile_pic"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    console.log(
      "@@@ Request ===========",
      requestMessage,
      this.postUploadProfileImageApiCallId
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  uploadBackgroundImageSetup = (img:any) => {
    const header = {
      token: this.state.token,
    };
    const formData = new FormData();
    formData.append("cover_pic", img);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.postUploadProfileImageApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_profile/profiles/upload_cover_pic"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  // Customizable Area Start
  handleSelect = async (value: any) => {
    this.setState({ address: value, location: false });
    this.profile_formref?.current?.setFieldsValue({ location: value });
    this.setState({
      prev_data: { ...this.state.prev_data, location: value },
      suggestionSelected : true
    });

  };
  validateLocation = (_:any, value:any) => {
    if (!this.state.suggestionSelected && !this.state.prev_data.location) {
      return Promise.reject("Required field");
    }
    return Promise.resolve();
  };

  handlevalues = (suggestion: any) => {

    switch (suggestion.terms.length) {

      case 1:
        return suggestion.terms[0].value;
      case 2:
        return suggestion.terms[0].value + ", " + suggestion.terms[1].value;
      default:
        if (suggestion.terms.length >= 3) {
          return suggestion.terms[0].value + ", " + suggestion.terms[suggestion.terms.length - 2].value + ", " + suggestion.terms[suggestion.terms.length - 1].value;
        }
    }
  }
  // Customizable Area End

  // onChange(e: any) {
  //   console.log(`checked = ${e.target.checked}`);
  // }
}
