import React from "react";
// Customizable Area Start
import { horizentalTripleDot } from "../assets";
import "../profile.css";
import OtherProfileController from "../OtherProfileController.web";
import SliderView from "../CommonUI/SliderView.web";
import NPSChartView from "../CommonUI/NPS_Chart.web";
import ReviewDataView from "../CommonUI/ReviewDataView.web";
import AddReviewView from "../CommonUI/AddReviewView.web";
import { Layout, Space, Row, Col, Image, Card, Avatar, Typography, Button, Tabs, Divider, Modal, Spin, Progress, List, Empty, Menu, Dropdown, Select } from 'antd';
import SelectCategory from "../CommonUI/SelectCategory.web";
import SendRequestModal from "../CommonUI/SendRequestModal.web";
import UserPost from "../CommonUI/UserPost.web";
import { CommonDialog } from "../CommonUI/CommonDialog.web";
import { FollowUnfollowAlert } from "../CommonUI/FollowUnfollowAlert";
import RatingView from "../CommonUI/RatingView.web";
import moment from "moment";
import { message } from "antd"
import AboutAndStats from "../CommonUI/AboutAndStats.web";
import BlockUnblockAlert from "../CommonUI/BlockUnblockAlert.web";
import ReportUser from "../CommonUI/ReportUser.web";
import {
    ZoomOutOutlined,
    CloseOutlined,
    ZoomInOutlined,
    RotateLeftOutlined,
    RotateRightOutlined,
    EyeOutlined,
    StopOutlined,
    UserOutlined,
    CheckCircleFilled
} from '@ant-design/icons';
import CommunityAlert from "../../../CommunityForum/src/CommunityAlert.web";
// Customizable Area End
const { Text, Title, Paragraph } = Typography;
const { TabPane } = Tabs;

export default class OtherPersonalProfile extends OtherProfileController {
    // Customizable Area Start
    render() {
        const { profileData, ratingOption, isModal, isAddReview, isAddAppliedViewRating, isAddRatingRateAsCustomer, isRatingViewShow, ratingView, ratingViewName, ratingViewName_2, reviewData, isLoading, isSelectCategoryModalVisible, errorMessageOnOk, nextPageLoader, cover_image, ratingData } = this.state;
        const account_id = profileData?.account_id;
        const { navigation } = this.props;

        const onCancle = () => {
            return this.setState({ isSuccessfullModal: false })
        }

        return (
            <Layout>
                <div style={{ backgroundColor: "rgba(245, 245, 245, 1)", minHeight: '90vh', height: '100%' }}>
                    {isLoading ?
                        <div style={{ margin: 12 }}>
                            <Spin />
                        </div> :
                        <>
                            {!this.state.suspendedAccount ?
                                <Row>
                                    <Col xs={{ span: 24 }} lg={{ span: 18 }}>
                                        <Profile
                                            data={profileData}
                                            lastSeen={this.state.lastSeen}
                                            handleClick={this.handleOpenModal}
                                            handleReviewclick={this.handleOpenAddReviewModal}
                                            handleFollow={this.onHandleFollow}
                                            handleFollowing={this.handleFollowUnfollowOpen}
                                            handleConnect={this.onClickConnect}
                                            handleAddAppliedViewRatingOpen={this.handleAddAppliedViewRatingOpen}
                                            isLoading={isLoading}
                                            handleChat={this.handleChatPush}
                                            acceptRequest={this.acceptRequest}
                                            handleBlock={this.handleBlockOpen}
                                            handleReportOpen={this.handleReportOpen}
                                            handleRemoveConnection={this.handleRemoveConnection}
                                            handleSentConnectionsList={this.handleCancelConnectionRequest}
                                            nextPageLoader={nextPageLoader}
                                            DownloadResumeLoading={this.state.downloadResumeLoading}
                                            downloadResumewithAxios={this.downloadResumeWithAxios}
                                            widthhchange={this.state.widthhchange}
                                            handleIncreaseWidth={this.handleIncreaseWidth}
                                            handleDecreaseWidth={this.handleDecreaseWidth}
                                            displayCoverImage={this.state.displayCoverImage}
                                            handleHideCoverImage={this.handleHideCoverImage}
                                            handleShowCoverImage={this.handleShowCoverImage}
                                            imageRotationDegree={this.state.imageRotationDegree}
                                            handleRotateImageRight={this.handleRotateImageRight}
                                            handleRotateImageLeft={this.handleRotateImageLeft}
                                            handleShowPreview={this.handleShowPreview}
                                            preview={this.state.preview}
                                            accountPlan={this.state.accountPlan}
                                            handleHidePreview={this.handleHidePreview}
                                            cover_image={cover_image}
                                            RatingOption={ratingOption}
                                        />
                                        {!isLoading ? <UserPostRatingReview
                                            isPostView={this.state.isPostView}
                                            data={profileData}
                                            handleFollow={this.onHandleFollow}
                                            handleFollowing={this.handleFollowUnfollowOpen}
                                            isRatingVisible={this.state.isRatingVisible}
                                            activeTab={this.state.activeTabForPost}
                                            getPosts={this.getBusinessPosts}
                                            postDataArray={this.state.postsDataArray}
                                            isPrivate={this.state.isPrivate}
                                            accountId={this.state.account_id}
                                            handleRatingView={this.handleRatingView}
                                            reviewData={reviewData}
                                            ratingData={ratingData}
                                            isLoading={this.state.isPostLoading}
                                            noOfPostData={this.state.noOfPostData}
                                            handlePostList={this.handlePostList}
                                        /> : <Spin />}
                                    </Col>
                                    {!this.state.isPrivate &&
                                        <Col xs={{ span: 24 }} lg={{ span: 6 }}>
                                            <Experience
                                                data={profileData}
                                                isLoading={isLoading}
                                            />
                                            <Education
                                                data={profileData}
                                                isLoading={isLoading}
                                            />
                                            <Certification
                                                data={profileData}
                                                isLoading={isLoading}
                                            />
                                            <AwardsRecognition
                                                data={profileData}
                                                isLoading={isLoading}
                                            />
                                            <Patent
                                                data={profileData}
                                                isLoading={isLoading}
                                            />
                                            <Achievements
                                                data={profileData}
                                                isLoading={isLoading}
                                            />
                                            <Languages
                                                data={profileData}
                                                isLoading={isLoading}
                                            />
                                        </Col>
                                    }
                                </Row> :
                                <Row style={{ marginTop: 24, marginLeft: 18, marginRight: 18 }} gutter={[6, 6]}>
                                    <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                                        <div style={{
                                            background: "#fff",
                                            height: '100%',
                                            padding: 20,
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}>
                                            <Avatar style={{ backgroundColor: "#919191" }}
                                                size={60}
                                                src={this.state.suspendedAccountData?.photo ? this.state.suspendedAccountData?.photo :
                                                    <UserOutlined />} />
                                            <div style={{ margin: "10px" }}>
                                                <Text style={{
                                                    fontSize: 17,
                                                    fontWeight: 500,
                                                }}>{this.state.suspendedAccountData?.full_name}</Text>
                                                <div>
                                                    <span style={{ color: "#da0e2a", fontSize: 13 }}><StopOutlined /> This account is suspended by user</span>
                                                </div>

                                            </div>
                                        </div>
                                    </Col>
                                </Row>

                            }
                        </>

                    }
                </div>

                <Modal
                    width={450}
                    style={{ borderWidth: "0px", borderRadius: "4px", overflow: "hidden" }}
                    centered
                    data-testid='Accept-request-modal'
                    className="Accept-request-modal"
                    title={
                        <div style={{ width: "100%", cursor: "move", fontWeight: "bold", borderWidth: "0px", paddingRight: '24px' }}>
                            {profileData?.full_name} wants to connect with you as{' '}
                            {profileData?.received_request?.sender_relation}
                        </div>}
                    visible={this.state.isShowInvitationModal}
                    bodyStyle={{ paddingTop: "5px" }}
                    onCancel={() => this.setState({ isShowInvitationModal: false })}
                    footer={[
                        <div style={{ display: 'flex', justifyContent: 'space-between', margin: '0 10px' }}>
                            <Button data-testid="report-button" size="large" className="report-button" style={{ width: "45%" }} key="accept"
                                onClick={() => {
                                    if (this.state.changeRelationShipAccept) {
                                        this.setState({ isShowInvitationModal: false });
                                        this.acceptRequest({ type: "accepted", ...profileData.received_request })
                                    } else {
                                        this.setState({
                                            checkModalForAccept: true
                                        })
                                    }
                                }}
                            >Accept Request</Button>
                            <Button data-testid='reject-button' size="large" className="reject-button" style={{ width: "45%" }} key="reject"
                                onClick={() => {

                                    this.setState({ isShowInvitationModal: false, checkModalForAccept: false });
                                    this.acceptRequest({ type: "rejected", ...profileData.received_request })
                                }}
                            >Reject Request</Button>
                        </div>
                    ]}
                >
                    <Row gutter={12}>
                        <Col xs={24} >
                            <p>Change relationship to</p>
                            <Select
                                data-testid='changeInviteRelationship'
                                id={"select"}
                                style={{
                                    padding: "0 !important",
                                    border: "2px solid #a9bac8",
                                    borderRadius: '27px',
                                    overflow: "hidden",
                                    width: "100%"
                                }}
                                placeholder="Select Relationship"
                                bordered={true}
                                dropdownStyle={{
                                    border: "2px solid #a9bac8",
                                    borderRadius: "10px",
                                    paddingTop: "0px",
                                    paddingBottom: "0px",
                                    width: "100%"
                                }}
                                value={profileData?.received_request?.sender_relation}
                                onChange={this.changeInviteRelationship}
                            >
                                {this.state.loadInvitationData && this.state.loadInvitationData.map((item: any, id: number) => <option key={item.id}
                                    style={{
                                        borderBottom: this.state.loadInvitationData?.length - 1 > id ? "1px solid #a9bac8" : "none",
                                        padding: "10px 20px 10px 20px"
                                    }}
                                    value={item.name}>
                                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                        <div className="capital_options" style={{ color: "#a9bac8" }}>{item.name}</div>
                                    </div>
                                </option>)}
                            </Select>
                            {this.state.checkModalForAccept
                                && !this.state.changeRelationShipAccept &&
                                <Text style={{ color: 'red', fontSize: 14 }}>please change relationship </Text>
                            }
                        </Col>
                    </Row>
                </Modal>


                {isAddReview && <ModalAddReview
                    open={isAddReview}
                    handleCancel={this.handleAddReviewCancel}
                    data={account_id}
                    disableAnonymous={this.state.disableAnonymous}
                    hideAnonymous={this.state.hideAnonymous}
                    onFinish={this.onFinishAddReview}
                />}
                <ModalAbout
                    data={profileData}
                    open={isModal}
                    handleCancel={this.handleCancel}
                />
                <ModalAddAppliedViewRating
                    open={isAddAppliedViewRating}
                    handleCancel={this.handleAddAppliedViewRatingCancel}
                    handleSave={this.handleSaveAndNext}
                    generalRating={this.state.generalRating}
                    generalRatingChange={this.generalRatingChange}
                />
                <ModalAddRatingRateAsCustomer
                    open={isAddRatingRateAsCustomer}
                    handleCancel={this.handleAddRatingRateAsCustomerCancel}
                    handleBack={this.handleAddRatingBack}
                    userRateItems={this.state.userRateItems}
                    userRatingChange={this.userRatingChange}
                    submitUsersRating={this.submitUsersRating}
                />
                <ModalRatingView
                    open={isRatingViewShow}
                    ratingViewData={ratingView}
                    ratingViewName={ratingViewName}
                    handleRatingNPSChart={this.handleRatingNPSChart}
                    handleCancel={this.handleRatingViewCancel}
                />
                <ModalNPSChart
                    openNPS={ratingViewName_2}
                    handleCancel={this.handleNPSChartCancel}
                />
                <CommonDialog
                    body={"Review Submitted Successfully"}
                    isModalVisible={this.state.isCompleteReview}
                    onOk={this.handleAlertReviewOk}
                />
                <SendRequestModal
                    sendRequestRelations={this.state.sendRequestRelations}
                    handleChange={this.handleSelectChange}
                    isModalVisible={isSelectCategoryModalVisible}
                    handleOk={this.handleOnClickConnect}
                    handleCancel={this.handleSelectCategoryCancel}
                    errorMessage={errorMessageOnOk}
                    userName={profileData?.full_name}
                />
                <CommonDialog
                    body={"Already Reviewed!"}
                    isModalVisible={this.state.alertReview}
                    onOk={this.handleAlertReviewOk}
                />

                <FollowUnfollowAlert
                    handleOk={this.onHandleFollow}
                    handleCancel={this.handleFollowUnfollowCancel}
                    visible={this.state.followUnfollowAlert}
                />
                <BlockUnblockAlert
                    visible={this.state.blockAlertOpen}
                    handleUnblock={this.handleUnblock}
                    handleCancel={this.handleBlockCancel}
                    fullName={profileData?.full_name}
                    accountId={account_id}
                    blocked={profileData?.block_accounts?.[0]?.journal_id === account_id}
                />
                <ReportUser
                    visible={this.state.reportOpen}
                    handleCancel={this.handleReportClose}
                    onFinish={this.onFinishReport}
                />
                <CommunityAlert
                    modal={this.state.isSuccessModal}
                    modalStatus={this.state.isSuccessModal}
                    handleCancel={() => { this.setState({ isSuccessModal: false }) }}
                    heading={""}
                    desc={this.state.getMessage}
                />
                <CommunityAlert
                    modal={this.state.isModalOpen}
                    modalStatus={this.state.isModalOpen}
                    handleCancel={() => { this.setState({ isModalOpen: false }) }}
                    heading={"Request Sent Successfully"}
                    desc={`Your Connection Request to ${this.state.modelData?.name} as ${this.state.category_id} Sent Successfully`}
                />
            </Layout >
        );
    }
    // Customizable Area End
}

//Customizable Area Start

export const successModel = (isOpen: any, setFun: any, relation: any, userName: any) => {
    return <Modal
        width={450}
        data-testid='Acceptrequestmodal'
        style={{ borderWidth: "0px", borderRadius: "4px", overflow: "hidden" }}
        centered
        className="Accept-request-modal"
        closable={false}
        title={
            <div style={{ width: "100%", cursor: "move", fontWeight: "bold", borderWidth: "0px", alignItems: "center", display: "flex", flexDirection: "column", rowGap: "16px" }}>
                <CheckCircleFilled style={{ fontSize: '50px', color: '#005673' }} />
                Request Sent Successfully
                <span style={{ fontWeight: "normal", textAlign: "center", color: "gray" }}>Your Connection Request to {userName} as {relation} Sent Successfully</span>
            </div>}
        visible={isOpen}
        bodyStyle={{ paddingTop: "5px" }}
        onCancel={() => setFun()}
        footer={[
            <div style={{ display: 'flex', justifyContent: 'center', margin: '0 10px' }}>
                <Button data-testid="reportbutton" size="large" className="report-button" style={{ width: "90%" }} key="accept"
                    onClick={() => {
                        setFun()
                    }}
                >Okay</Button>

            </div>
        ]}
    />
}

interface MyNPSChartProps {

    openNPS?: {
        name: string,
        npsData: any
    }
    handleCancel: () => void

}

export class ModalNPSChart extends React.Component<MyNPSChartProps> {

    render() {
        return <Modal
            title={
                <Space
                    style={{
                        width: "100%",
                        cursor: "move",
                        fontWeight: "bold",
                        color: "rgba(0, 0, 0, 0.85)"
                    }}
                >
                    {this.props.openNPS?.name}
                </Space>
            }
            visible={this.props?.openNPS?.name !== ""}
            footer={null}
            bodyStyle={{
                width: "100%",
                height: "100%",
                borderRadius: "5px",
                overflowY: "auto",
                backgroundColor: "#f1f0f5"
            }}
            centered
            onCancel={() => this.props.handleCancel()}
        >
            <NPSChartView npsData={this.props.openNPS?.npsData} />
        </Modal>
    }

}

interface IUnfollow {
    visible: boolean
    handleOk: any
    handleCancel: any
}


export class ModaUnFollow extends React.Component<IUnfollow> {

    render() {
        const { visible, handleOk, handleCancel } = this.props
        return <Modal
            width={350}
            style={{ borderWidth: "0px", borderRadius: "4px", overflow: "hidden" }}
            centered
            // placement="bottomRight"
            title={
                <div
                    style={{
                        width: "100%",
                        cursor: "move",
                        fontWeight: "bold",
                        borderWidth: "0px"
                    }}
                    onFocus={() => { }}
                    onBlur={() => { }}
                // end
                >
                    Unfollow
                </div>
            }
            visible={visible}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={[
                <Row>
                    <Col span={12}>
                        <Button
                            size="large"
                            className="report-button"
                            style={{ padding: '3px 30px' }}
                            key="report"
                            onClick={handleCancel}
                        >
                            Cancel
                        </Button>
                    </Col>
                    <Col span={12}>
                        <Button
                            size="large"
                            className="follow-label"
                            style={{ borderWidth: "0px" }}
                            key="report"
                            onClick={handleOk}
                        >
                            Unfollow
                        </Button>
                    </Col>
                </Row>
            ]}
        >
            <Text style={{ textAlign: 'center' }}>Are you sure want to Unfollow?</Text>
        </Modal>
    }

}

interface AddRatingRateAsCustomerProps {

    open?: boolean
    handleCancel: () => void
    handleBack: () => void
    userRateItems: any
    userRatingChange: any
    submitUsersRating: any

}

export class ModalAddRatingRateAsCustomer extends React.Component<AddRatingRateAsCustomerProps> {

    render() {

        return <Modal
            footer={null}
            centered
            bodyStyle={{
                width: "100%",
                height: "80vh",
                overflowX: "hidden",
                borderRadius: "5px",
                paddingTop: "0px",
                overflowY: "scroll",


            }}
            title={
                <Space
                    style={{
                        width: "100%",
                        cursor: "move",
                        fontWeight: "bold",
                        color: "rgba(0, 0, 0, 0.85)"
                    }}
                >
                    Add Rating
                </Space>
            }
            visible={this.props.open}
            onCancel={() => this.props.handleCancel()}
        >
            <div
                style={{
                    display: "flex",
                    paddingRight: "20px",
                    width: "100%",
                    flexDirection: "column"
                }}
            >
                <Title
                    style={{
                        marginBottom: "1rem",
                        color: "rgba(0, 0, 0, 0.85)",
                        fontWeight: "normal",
                    }}
                    level={5}
                >
                    Rate as {this.props.userRateItems?.name}
                </Title>
                {
                    this.props.userRateItems?.array?.map((item: any, index: number) => item.name &&
                        <Space
                            direction="vertical"
                            key={item.name}
                        >
                            <SliderView
                                description={item?.description}
                                name={item.name}
                                changeHandler={(val: any) => this.props.userRatingChange({ name: item.name, value: val, index: index })}
                                value={item.value}
                            />
                        </Space>)
                }
            </div>
            <div
                style={{
                    width: "100%",
                    marginTop: "1rem",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between"
                }}
            >
                <Button
                    type="default"
                    style={{
                        width: "45%",
                        height: "2.5rem",
                        borderRadius: "25px",
                        border: "1px solid #4080c0",
                        color: "#006285"
                    }}
                    onClick={() => this.props.handleBack()}
                >
                    Back
                </Button>
                <Button
                    type="primary"
                    style={{
                        width: "45%",
                        height: "2.5rem",
                        borderRadius: "25px",
                        backgroundColor: "#006285",
                        color: "#ffffff"
                    }}
                    onClick={this.props.submitUsersRating}
                >
                    Submit
                </Button>
            </div>
        </Modal>
    }

}

interface AddRatingAppliedViewProps {

    open?: boolean
    handleCancel: () => void
    handleSave: () => void
    generalRating: any
    generalRatingChange: any

}

export class ModalAddAppliedViewRating extends React.Component<AddRatingAppliedViewProps> {

    render() {

        return <Modal
            footer={null}
            centered
            bodyStyle={{
                width: "100%",
                height: "80vh",
                borderRadius: "5px",
                overflowY: "scroll",
                overflowX: "hidden",
                paddingTop: "0px"
            }}
            title={
                <Space
                    style={{
                        width: "100%",
                        cursor: "move",
                        fontWeight: "bold",
                        color: "rgba(0, 0, 0, 0.85)",
                        margin: "0px",
                    }}
                >
                    Add Rating
                </Space>
            }
            visible={this.props.open}
            onCancel={() => this.props.handleCancel()}
        >
            <div
                style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    paddingRight: "20px",



                }}
            >
                <Title
                    style={{
                        color: "rgba(0, 0, 0, 0.85)",
                        marginBottom: "1rem",
                        fontWeight: "normal",

                    }}
                    level={5}
                >
                    Applied View Rating
                </Title>
                {
                    this.props.generalRating?.array?.map((item: any, index: any) => item.name && <Space
                        direction="vertical"
                        key={item.name}
                    >
                        <SliderView
                            description={item.description}
                            name={item.name}
                            changeHandler={(val: any) => this.props.generalRatingChange({ name: item.name, value: val, index: index })}
                            value={Math.round(item.value)}
                        />
                    </Space>
                    )
                }
            </div>
            <Button
                style={{
                    width: "100%",
                    height: "2.5rem",
                    borderRadius: "25px",
                    backgroundColor: "#006285",
                    color: "#ffffff",
                    marginTop: "1rem"
                }}
                onClick={() => this.props.handleSave()}
            >
                Save & Next
            </Button>
        </Modal>
    }

}

interface MyProps {

    data?: any
    accId?: number
    isLoading: boolean

}
interface MyModalProps {

    open?: boolean
    data?: any
    handleCancel: () => void

}
interface MyAddReviewProps {

    open?: boolean
    handleCancel: () => void
    data?: string
    disableAnonymous: boolean
    hideAnonymous: boolean
    onFinish: (value: any) => void

}

export class ModalAddReview extends React.Component<MyAddReviewProps> {

    render() {
        return <Modal
            footer={null}
            bodyStyle={{
                width: "100%",
                height: "62vh",
                borderRadius: "5px",
            }}
            title={
                <Space
                    style={{
                        width: "100%",
                        cursor: "move",
                        fontWeight: "bold",
                        color: "rgba(0, 0, 0, 0.85)"
                    }}
                >
                    Add Review
                </Space>
            }
            visible={this.props.open}
            onCancel={() => this.props.handleCancel()}
        >
            <AddReviewView
                disableAnonymous={this.props.disableAnonymous}
                hideAnonymous={this.props.hideAnonymous}
                data={this.props.data}
                onFinish={this.props.onFinish}
            />
        </Modal>
    }

}

interface RatingViewProps {

    open?: boolean
    ratingViewData?: Array<any>
    ratingViewName?: string
    handleRatingNPSChart: (name: string, data: any) => void
    handleCancel: () => void

}
export class ModalRatingView extends React.Component<RatingViewProps> {


    render() {
        return <Modal
            title={
                <Space
                    style={{
                        width: "100%",
                        cursor: "move",
                        fontWeight: "bold",
                        color: "rgba(0, 0, 0, 0.85)"
                    }}
                >
                    {this.props.ratingViewName}
                </Space>
            }
            visible={this.props.open}
            footer={null}
            bodyStyle={{
                width: "100%",
                height: "80vh",
                borderRadius: "5px",
                overflowY: "auto",
                backgroundColor: "#f1f0f5"
            }}
            centered
            onCancel={() => this.props.handleCancel()}
        >
            {
                this.props?.ratingViewData?.map((item, id) => {
                    if (id !== 7) {
                        const score = 0
                        if (item.score === null) {
                            return <div
                                key={id}
                            >
                                <Card
                                    style={{ width: "100%", height: "100%", cursor: "pointer", marginTop: "1rem" }}
                                    onClick={() => this.props.handleRatingNPSChart(item.name, item.nps_rating)}
                                >
                                    <Progress type="circle" width={110} percent={score} />
                                    <Text style={{ marginLeft: "2rem", color: "rgba(0, 0, 0, 0.85)" }}>{item.name}</Text>
                                </Card>
                            </div>
                        } else {
                            return (
                                <div
                                    key={id}
                                >
                                    <Card
                                        style={{ width: "100%", height: "100%", cursor: "pointer", marginTop: "1rem" }}
                                    >
                                        <Progress type="circle" width={110} percent={item.score} />
                                        <Text style={{ marginLeft: "2rem" }}>{item.name}</Text>
                                    </Card>
                                </div>
                            )
                        }
                    }
                })
            }
        </Modal>
    }

}

export class ModalAbout extends React.Component<MyModalProps> {

    render() {
        console.log("props ==>", this.props?.data)
        return <Modal
            title={
                <Space
                    style={{
                        width: "100%",
                        cursor: "move",
                        fontWeight: "bold",
                        color: "rgba(0, 0, 0, 0.85)"
                    }}
                >
                    About {this.props?.data?.first_name ? this.props?.data?.first_name : this.props?.data?.full_name}
                </Space>
            }
            visible={this.props?.open}
            footer={null}
            bodyStyle={{
                width: "100%",
                maxHeight: "80vh",
                borderRadius: "5px",
                overflowY: "auto",
                padding: "0px 24px 16px 24px"
            }}
            centered
            onCancel={() => this.props.handleCancel()}
        >
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <Paragraph style={{ color: "#919191" }}>{this.props?.data?.profile?.data?.attributes?.description ? this.props?.data?.profile?.data?.attributes?.description : this.props?.data?.description}</Paragraph>
            </div>
        </Modal>
    }

}

interface MyProfileProps {
    lastSeen: any
    data?: any
    handleClick: () => void
    handleReviewclick: () => void
    handleFollow: () => void
    handleFollowing: () => void
    handleConnect: () => void
    handleAddAppliedViewRatingOpen: () => void
    isLoading: boolean
    handleChat: () => void
    acceptRequest: any
    handleBlock: () => void
    handleReportOpen: () => void
    handleRemoveConnection: (id: any) => void
    handleSentConnectionsList: () => void
    downloadResumewithAxios: () => void
    DownloadResumeLoading: boolean
    nextPageLoader?: boolean
    widthhchange: any
    handleIncreaseWidth: () => void
    handleDecreaseWidth: () => void
    displayCoverImage: any
    handleHideCoverImage: () => void
    handleShowCoverImage: () => void
    imageRotationDegree: any
    handleRotateImageRight: () => void
    handleRotateImageLeft: () => void
    handleShowPreview: () => void
    preview: boolean
    handleHidePreview: () => void
    cover_image: any
    accountPlan: string;
    RatingOption: boolean
}

interface MyProfileState {
    visible: boolean
}

export class Profile extends React.Component<MyProfileProps, MyProfileState> {
    constructor(props: any) {
        super(props)

        this.state = {
            visible: false
        }
    }

    handleInvited(isInvited: any) {
        if (isInvited) {
            return "Requested"
        } else {
            return "Connect"
        }
    }
    renderButtonUi(isInvited: any) {
        return (
            this.props?.data?.is_blocked ?
                <Button
                    data-testid='blocked'
                    type="default"
                    shape="round"
                    style={{ border: "1px solid #4080c0", width: isInvited ? "7rem" : "6.5rem", color: "#4080c0" }}
                    onClick={() => this.props.handleBlock()}
                // disabled={isInvited ? true : false}
                >
                    Unblock
                </Button>
                : <Button
                    data-testid='Connect'
                    type="default"
                    shape="round"
                    style={{ border: "1px solid #4080c0", width: isInvited ? "7rem" : "6.5rem", color: "#4080c0" }}
                    onClick={() => this.props.handleConnect()}
                    disabled={isInvited ? true : false}
                >
                    {this.props.nextPageLoader ? <Spin /> : this.handleInvited(isInvited)}
                </Button>
        )
    }
    renderFollowButtonUi = (isFollowing: boolean, isBlocked: boolean) => {
        return (
            <Button
                data-testid="follow"
                shape="round"
                style={{ border: "1px solid #4080c0", width: isFollowing ? "7rem" : "6rem", color: isFollowing ? "#ffffff" : "#4080c0", backgroundColor: isFollowing ? "#4080c0" : "#ffffff" }}
                onClick={() => this.renderFollowButtonUI(isFollowing)}
                disabled={isBlocked}
            >
                {
                    isFollowing ? "Following" : "Follow"
                }
            </Button>
        )
    }
    renderFollowButtonUI(isFollowing: any) {
        if (isFollowing) {
            this.props.handleFollowing()
        } else {
            this.props.handleFollow()
        }
    }
    renderUI(props: any) {
        const { isFollowing, rating_option, isConnected, isInvited, isBlocked, isRequested, requestedObj, menu } = props
        return (
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", flexWrap: "wrap" }}>
                <div style={{ marginBottom: "5px", }}>
                    {this.renderFollowButtonUi(isFollowing, isBlocked)}
                </div>
                <div style={{ marginLeft: "10px", marginBottom: "5px", display: (isConnected || isRequested) ? "none" : "block", pointerEvents: isBlocked ? "none" : "auto" }}>
                    {
                        this.renderButtonUi(isInvited)
                    }
                </div>
                <div style={{ marginLeft: "10px", display: (isRequested) ? "block" : "none" }}>

                    <Button
                        data-testid="openAcceptModal"
                        style={{ border: "1px solid #6ccc3e", color: "#6ccc3e" }}
                        shape="round"
                        onClick={() => this.props.acceptRequest({ type: "openAcceptModal" })}
                    >
                        Accept
                    </Button>
                </div>
                <div style={{ marginLeft: "10px", display: (isRequested) ? "block" : "none" }}>
                    <Button
                        data-testid="RequestRejected"
                        shape="round"
                        danger
                        onClick={() => this.props.acceptRequest({ type: "rejected", ...requestedObj })}
                    >
                        Reject
                    </Button>
                </div>
                <div style={{ marginLeft: "10px", marginBottom: "5px", display: isConnected || rating_option ? "block" : "none" }}>
                    <Button
                        data-testid="AddAppliedViewRating"
                        style={{ backgroundColor: "#4080c0", width: "5rem", color: "#ffffff" }}
                        shape="round"
                        onClick={() => this.props.handleAddAppliedViewRatingOpen()}
                    // disabled={true}
                    >
                        Rate
                    </Button>
                </div>
                <div style={{ marginLeft: "10px", marginBottom: "5px", display: isConnected ? "block" : "none" }}>
                    <Button
                        style={{ backgroundColor: "#4080c0", width: "5.5rem", color: "#ffffff" }}
                        data-testid='Review'
                        shape="round"
                        onClick={() => this.props.handleReviewclick()}
                    >
                        Review
                    </Button>
                </div>
                <div style={{ marginLeft: "10px", marginBottom: "5px", display: isConnected ? "block" : "none" }}>
                    <Button
                        data-testid='downloadResume'
                        style={{
                            backgroundColor: "#4080c0", width: "9rem", color: "#ffffff"
                        }}
                        onClick={() => {
                            // message.info('Coming soon')
                            {
                                this.props.downloadResumewithAxios()
                            }
                        }}
                        // onClick={() => {
                        //     // message.info('Coming soon')
                        //     if (!this.props.DownloadResumeLoading) {
                        //         this.props.downloadResumewithAxios()
                        //     }
                        // }}
                        shape="round"
                    >
                        {this.props.DownloadResumeLoading ? <Spin /> : "Download Resume"}
                    </Button>
                </div>
                <div style={{ marginBottom: "5px", marginLeft: "10px", display: isConnected ? "block" : "none" }}>
                    <Button
                        onClick={this.props.handleChat}
                        style={{ backgroundColor: "#4080c0", width: "5rem", color: "#ffffff" }}
                        shape="round"
                    >
                        Chat
                    </Button>
                </div>
                <div style={{ marginBottom: "5px", marginLeft: "10px" }}>
                    <Dropdown data-testid="dropdown" overlay={menu} placement="bottomRight" trigger={["click"]}>
                        <img src={horizentalTripleDot} width="32px" />
                    </Dropdown>
                </div>
            </div>
        )
    }
    renderDomainSkillUI(domainSkills: any, lastSeen: any) {
        function formatLastSeen(userLastSeen: any, accountPlan: string) {
            if (moment(userLastSeen, moment.ISO_8601, true).isValid()) {
                return (
                    <>
                        {accountPlan !== "free" &&
                            <Text type="secondary" style={{ fontSize: "12px", display: "flex", flexDirection: 'row', flexWrap: "wrap", alignItems: 'baseline', textTransform: "capitalize" }}>
                                <span style={{ width: "6px", height: "6px", backgroundColor: "yellow", borderRadius: "50%", margin: "0px 4px 0px 0px", position: "relative", bottom: "1px" }}></span>
                                {`Last Active On: ${moment(userLastSeen).format('DD/MM/YYYY hh:mm A')}`}
                            </Text>}
                    </>
                )

            } else {
                return (
                    <>
                        {accountPlan !== "free" &&
                            <Text type="secondary" style={{ fontSize: "12px", display: "flex", flexDirection: 'row', flexWrap: "wrap", alignItems: 'baseline', textTransform: "capitalize" }}>
                                <span style={{ width: "6px", height: "6px", backgroundColor: "green", borderRadius: "50%", margin: "0px 4px 0px 0px", position: "relative", bottom: "1px" }}></span>
                                {userLastSeen}
                            </Text>}
                    </>
                )
            }
        }
        return (
            <div style={{ display: "flex", flexDirection: 'column' }}>
                <Text style={{ display: "flex", flexWrap: "wrap", flexDirection: 'row', alignItems: 'baseline' }}>
                    <span style={{ fontSize: "18px", fontWeight: 500, textTransform: "capitalize" }}>
                        {this.props.data?.full_name}
                    </span>
                    {
                        this.props.data?.designation?.title ?
                            <>
                                <span style={{ width: "6px", height: "6px", backgroundColor: "#dfe5eb", borderRadius: "50%", margin: "0px 4px 0px 7px", position: "relative", bottom: "1px" }}></span>
                                <>
                                    <span
                                        style={{ fontSize: "12px", fontWeight: 500, textTransform: "capitalize" }}
                                    >
                                        {this.props.data?.designation?.title}
                                    </span>
                                    {
                                        this.props.data?.designation?.company ?
                                            <span><span style={{ margin: "0px 3px" }}> at </span>{this.props.data?.designation?.company}</span> : null
                                    }

                                </>
                            </>
                            : null
                    }
                </Text>
                <Text type="secondary" style={{ fontSize: "12px", display: "flex", flexDirection: 'row', flexWrap: "wrap", alignItems: 'baseline', textTransform: "capitalize" }}>
                    {
                        domainSkills ?
                            <>
                                <span style={{ width: "6px", height: "6px", backgroundColor: "#dfe5eb", borderRadius: "50%", margin: "0px 4px 0px 0px", position: "relative", bottom: "1px" }}></span>
                                <>
                                    {
                                        domainSkills?.map((item: any, id: number) => {
                                            if (id + 1 < domainSkills.length) {
                                                return `${item}, `
                                            } else {
                                                return `${item}`
                                            }
                                        })
                                    }
                                </>
                            </>
                            : null
                    }
                    {
                        this.props.data?.experience_years ?
                            <>
                                <span style={{ width: "6px", height: "6px", backgroundColor: "#dfe5eb", borderRadius: "50%", margin: "0px 4px 0px 7px", position: "relative", bottom: "1px" }}></span>
                                <>
                                    {this.props.data?.experience_years}+ year of Experience
                                </>
                            </>
                            : null
                    }
                </Text>
                {formatLastSeen(lastSeen, this.props.accountPlan)}
            </div>
        )
    }
    render() {
        const isFollowing = this.props?.data?.following;
        const userLastSeen = this.props?.lastSeen;
        const isConnected = this.props?.data?.friend;
        const rating_option = this.props.RatingOption;
        const isInvited = this.props?.data?.invited;
        const isBlocked = this.props?.data?.block_accounts?.[0]?.journal_id === this.props.data.account_id;
        const isRequested = Object.entries(this.props?.data?.received_request || {}).length > 0;
        const requestedObj = this.props?.data?.received_request || {};

        const menu = (
            <Menu>
                {!this.props?.data?.is_blocked && <Menu.Item
                    onClick={() => this.props.handleBlock()}
                    key="block"
                >
                    {isBlocked ? "Unblock" : "Block"}
                </Menu.Item>}
                <Menu.Item
                    data-testid='ReportOpen'
                    onClick={() => this.props.handleReportOpen()}
                    key="report"
                >
                    Report
                </Menu.Item>
                {this.props?.data?.friend && <Menu.Item
                    onClick={() => this.props.handleRemoveConnection(this.props?.data?.request?.id)}
                    key="remove_connection"
                >
                    Remove Connection
                </Menu.Item>}
                {isInvited && this.props?.data?.connected_as === null && < Menu.Item
                    onClick={() => this.props.handleSentConnectionsList()}
                    key="cancel Request"
                >
                    Cancel Connection Request
                </Menu.Item>}
            </Menu>
        );

        const domainSkills = this.props?.data?.domain_skills;
        return (
            <div id="profile" style={{ width: "100%", display: "flex", justifyContent: "end", paddingTop: "1rem" }}>
                <div style={{ width: "96%", backgroundColor: "#ffffff", margin: "auto" }}>
                    <div
                        data-testid="preview"
                        onMouseEnter={() => this.props.handleShowPreview()}
                        onMouseLeave={() => this.props.handleHidePreview()}
                        className="cover-img">
                        <img
                            id="myImg"
                            src={this.props.cover_image ? this.props.cover_image : "https://timelinecovers.pro/facebook-cover/download/stunning-little-flowers-facebook-cover.jpg"}
                            style={{ borderTopRightRadius: "5px", borderTopLeftRadius: "5px", objectFit: "cover" }}
                            width="100%"
                            height="250px"
                        />
                        {this.props.preview && <div
                            data-testid="ShowCoverImage"
                            onClick={() => { this.props.handleShowCoverImage() }}
                            className="cover"
                            style={{
                                cursor: 'pointer',
                                position: 'absolute',
                                display: "flex",
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundColor: 'black',
                                width: '96%',
                                height: '250px',
                                opacity: 0.8,
                            }}
                        >
                            <p style={{ color: 'white' }}>
                                {<EyeOutlined />} Preview
                            </p>
                        </div>}
                        <Avatar className="avatar-position" size={64} style={{ backgroundColor: "#919191" }} src={this.props?.data?.photo ? this.props?.data?.photo : <UserOutlined />} />
                    </div>
                    <div className='block'>
                        <div id="myModal" className="modal" style={{ display: `${this.props.displayCoverImage}`, height: 'unset' }}>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    minHeight: '100vh'
                                }}>
                                <div style={{
                                    maxHeight: '100vh',
                                    overflow: 'auto',
                                    width: '100%'
                                }}>
                                    <div
                                        className='reviewedCoverImage'
                                        style={{
                                            transition: 'transform 0.2s, width 0.2s, height 0.2s',
                                            width: `${(window.innerWidth < 700 ? 100 : 40) + this.props.widthhchange}%`,
                                            position: 'relative',
                                            overflow: 'hidden',
                                            margin: 'auto'
                                        }}>
                                        <ul className="ImageHandleBar">
                                            <li data-testid="handleRotateImageLeft" onClick={() => this.props.handleRotateImageLeft()}><RotateLeftOutlined style={{ fontSize: '12px !important' }} /></li>
                                            <li data-testid="handleRotateImageRight" onClick={() => this.props.handleRotateImageRight()}><RotateRightOutlined style={{ fontSize: '12px !important' }} /></li>
                                            <li data-testid="handleDecreaseWidth" onClick={() => this.props.handleDecreaseWidth()}><ZoomOutOutlined style={{ fontSize: '12px' }} /></li>
                                            <li data-testid="handleIncreaseWidth" onClick={() => this.props.handleIncreaseWidth()}><ZoomInOutlined style={{ fontSize: '12px' }} /></li>
                                            <li data-testid="handleHideCoverImage" onClick={() => this.props.handleHideCoverImage()}><CloseOutlined style={{ fontSize: '12px' }} /></li>
                                        </ul>

                                        <img
                                            className="zoomable-image-container modal-content"
                                            style={{
                                                transform: `rotate(${this.props.imageRotationDegree}deg)`,
                                                transition: 'transform 0.2s, width 0.2s',
                                                width: `${100}%`,
                                            }}
                                            src={this.props.cover_image ? this.props.cover_image : "https://timelinecovers.pro/facebook-cover/download/stunning-little-flowers-facebook-cover.jpg"}
                                            id="img01" />
                                    </div>
                                </div>
                            </div>
                            <div id="caption"></div>
                        </div>
                    </div>
                    <div className="user-details">
                        <Row>
                            <Col style={{ padding: "0px 0px 0px 16px" }} xs={{ span: 24 }} lg={{ span: 9 }} >
                                {
                                    this.props.isLoading ? <Spin />
                                        : this.renderDomainSkillUI(domainSkills, userLastSeen)

                                }
                            </Col>
                            <Col xs={{ span: 24 }} lg={{ span: 15 }} style={{ padding: "10px 16px 0px 0px", }}>
                                {
                                    this.props.isLoading ? <Spin />
                                        : this.renderUI({ isFollowing, rating_option, isConnected, isInvited, isBlocked, isRequested, requestedObj, menu })
                                }
                            </Col>
                            <Col span={24} >
                                <AboutAndStats
                                    isLoading={this.props.isLoading}
                                    data={this.props.data}
                                    handleClick={this.props.handleClick}
                                />
                            </Col>
                        </Row>

                    </div>

                </div>
            </div>
        );
    }

}


interface MyPostRatingReviewProps {


    data?: any
    getPosts: (id: number) => any
    postDataArray?: any
    accountId: number
    isPrivate: boolean
    isPostView: boolean
    handleRatingView: (data: any, name: string) => void
    reviewData: Array<any>
    ratingData: Array<any>
    isLoading: boolean
    activeTab: any
    isRatingVisible: boolean
    handleFollow: () => void
    handleFollowing: () => void
    noOfPostData: boolean
    handlePostList: any

}

interface Istate {

    activeTab: any

}

export class UserPostRatingReview extends React.Component<MyPostRatingReviewProps, Istate> {


    constructor(props: any) {
        super(props)
        this.state = {
            activeTab: '1'
        }
    }
    componentDidMount = () => {
        //console.log(this.props.activeTab, this.props.isPostView)
        this.setState({
            activeTab: "1"
        })
    }

    changeTab = (activeKey: any) => {
        this.setState({
            activeTab: activeKey
        });
    }
    // PostDatatab = () => {
    //     return (
    //         <TabPane
    //             tab="Posts"
    //             key={this.props.isPrivate ? "1" : "2"}
    //             style={{ width: "96%", height: "100%", margin: "1rem auto" }}
    //         >
    //             {
    //                 <UserPost item={this.props.isPostView}/>
    //             }
    //         </TabPane>
    //     )
    // }
    ReviewDatatab = () => {
        return (
            <TabPane
                tab="Reviews"
                key={this.props.isPrivate ? "3" : "4"}
                style={{ width: "96%", height: "100%", margin: "1rem auto" }}
            >
                {
                    // this.props.isLoading ? <Spin />
                    this.props?.reviewData?.length ?
                        <ReviewDataView reviewData={this.props.reviewData} />
                        : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                }
            </TabPane>
        )
    }
    ratingDataTab = () => {
        return (
            <TabPane
                tab="Ratings"
                key={this.props.isPrivate ? "2" : "3"}
                style={{
                    width: "96%",
                    height: "100%",
                    margin: "1rem auto"
                }}
            >
                {
                    this.props.isLoading && !this.props.data ? <Spin /> :
                        <RatingView data={this.props.ratingData} isPrivate={this.props.isRatingVisible} />
                }
            </TabPane>
        )
    }

    render() {

        const isFollowing = this.props?.data?.following;
        const isConnected = this.props?.data?.friend;

        return (
            <div style={{ width: "100%", paddingTop: "1rem" }}>
                <Tabs
                    activeKey={this.state.activeTab}
                    defaultActiveKey={this.state.activeTab}
                    onChange={this.changeTab}
                    centered={true}
                    size="large"
                    tabBarStyle={{
                        color: "rgb(117, 117, 117)",
                        backgroundColor: "rgb(221, 230, 235)",
                        height: "3rem"
                    }}
                    style={{
                        width: "96%",
                        margin: "auto",
                        borderRadius: "3px",
                        backgroundColor: "#f1f0f5"
                    }}
                >
                    {/* {this.props.isPostView && */}
                    <TabPane
                        tab="Posts"
                        key="1"
                        style={{ width: "96%", height: "100%", margin: "1rem auto" }}
                    >
                        {/* <div onClick={()=>this.changeTab(1)}> */}
                        {
                            <div>
                                {
                                    this.props.isLoading ? <Spin /> :
                                        this.props.postDataArray?.map((item: any, index: number) => {
                                            return (
                                                <>
                                                    <UserPost item={item}
                                                        data-testid={`follow_PostRating-${index}`}
                                                        following={this.props.data?.following}
                                                        onHandleFollow={this.props.data?.following ? () => this.props.handleFollowing() : () => this.props.handleFollow()}
                                                    />
                                                    {
                                                        (this.props.postDataArray.length - 1 === index) &&
                                                        <>
                                                            {this.props.isLoading ? <Spin /> :
                                                                this.props.noOfPostData &&
                                                                <Text data-testid='getPost' style={{ fontSize: 16, color: "#0e76a8", cursor: 'pointer' }}
                                                                    onClick={() => this.props.getPosts(this.props.accountId)}
                                                                >

                                                                    Load More ...
                                                                </Text>
                                                            }
                                                        </>
                                                    }

                                                </>
                                            )
                                        })

                                }
                            </div>
                        }
                        {/* </div> */}

                    </TabPane>
                    {/* } */}


                    {this.ratingDataTab()}

                    {this.ReviewDatatab()}
                </Tabs>
            </div>
        );
    }

}


export class Experience extends React.Component<MyProps> {

    checkStartDate(item: any) {
        if (moment(item?.start_date, "DD/MM/YYYY").format("DD-MM-YYYY") !== 'Invalid date') {
            return moment(item?.start_date, "DD/MM/YYYY").format("DD-MM-YYYY")
        } else {
            return moment(item?.start_date).format("DD-MM-YYYY")
        }
    }
    renderItemDescription(item: any) {
        return (
            <div style={{ display: "flex", flexDirection: "column" }}>
                <Text style={{ fontWeight: 500, fontSize: "14px", color: "rgb(117, 117, 117)", display: item?.start_date ? "block" : "none" }}>
                    <div style={{ display: 'flex' }}>
                        <div

                            className="Education_column1"
                        >Start Date:</div>
                        <div className="Column2_otherProfile">{this.checkStartDate(item)}</div>
                    </div>
                </Text>
                <Text style={{ fontWeight: 500, fontSize: "14px", color: "rgb(117, 117, 117)", display: item?.end_date ? "block" : "none" }}>
                    <div style={{ display: 'flex' }}>
                        <div

                            className="Education_column1"
                        >End Date:</div>
                        <div className="Column2_otherProfile">{moment(item?.end_date, "DD/MM/YYYY").format("DD-MM-YYYY") !== 'Invalid date' ? moment(item?.end_date, "DD/MM/YYYY").format("DD-MM-YYYY") :
                            moment(item?.end_date).format("DD-MM-YYYY")
                        }</div>
                    </div>
                </Text>
                <Text style={{
                    fontWeight: 500, fontSize: "14px",
                    color: "rgb(117, 117, 117)", display: item?.title ? "block" : "none"
                }}>
                    <div style={{ display: 'flex' }}>
                        <div

                            className="Education_column1"
                        >Title:</div>
                        <div className="otherProfile_Experience_Title">{item?.title}</div>
                    </div>
                </Text>
                <Text style={{ fontWeight: 500, fontSize: "14px", color: "rgb(117, 117, 117)", display: item?.description ? "block" : "none" }}>
                    <div style={{ display: 'flex' }}>
                        <div

                            className="Education_column1"
                        >Description:</div>
                        <div className="Column2_otherProfile">{item?.description}</div>
                    </div>
                </Text>
            </div>
        )
    }
    renderListItem(item: any, index: any) {
        return (
            <List.Item className="listInfo" key={`${item.title}${index}`}>
                <List.Item.Meta
                    title={<div><h4 className="ant-list-item-meta-title"
                        style={{
                            textTransform: "capitalize",
                            paddingRight: 8,
                            wordBreak: 'break-word'
                        }}>{item?.company}</h4></div>}
                    description={
                        this.renderItemDescription(item)
                    }
                />
            </List.Item>
        )
    }
    render() {
        const experienceList = this.props?.data?.experiences;
        console.log(experienceList)
        return <>
            {
                experienceList?.length > 0 ?
                    <div style={{ margin: "1rem" }}>
                        <Title level={5} style={{ color: "rgba(0, 0, 0, 0.85)" }}>Experience</Title>
                        <List
                            data-testid='ExperienceList'
                            style={{ borderRadius: "5px", overflow: "hidden" }}
                            itemLayout="horizontal"
                            dataSource={
                                experienceList.sort((a: any, b: any) => {
                                    try {
                                        const letet = a.start_date?.split('/')
                                        if (letet.length > 0) {
                                            return a.start_date?.split('/').reverse()?.join().localeCompare(b.start_date.split('/')?.reverse()?.join())
                                        } else {
                                            return a.start_date?.reverse()?.join().localeCompare(b.start_date?.reverse()?.join())
                                        }
                                    } catch (e) {
                                        return null
                                    }
                                }).reverse()
                            }
                            renderItem={(item: any, index: number) => (
                                this.renderListItem(item, index)
                            )}
                        />
                    </div>
                    : null
            }
        </>
    }

}

export class Education extends React.Component<MyProps> {



    render() {
        const educationList = this.props?.data?.graduations;
        return <>
            {
                educationList?.length > 0 ?
                    <div style={{ margin: "1rem" }}>
                        <Title level={5} style={{ color: "rgba(0, 0, 0, 0.85)" }}>Education</Title>
                        <List
                            data-testid='educationList'
                            style={{ borderRadius: "5px", overflow: "hidden" }}
                            itemLayout="horizontal"
                            dataSource={
                                educationList?.sort((a: any, b: any) => {
                                    return a?.year - b?.year
                                    // try {
                                    //     return a?.year?.split('/')?.reverse()?.join()?.localeCompare(b.year.split('/')?.reverse()?.join())
                                    // } catch (e: any) {
                                    //     return null
                                    // }
                                }
                                ).reverse()

                            }
                            renderItem={(item: any) => (
                                <List.Item className="listInfo">
                                    <List.Item.Meta
                                        title={<span style={{ textTransform: "capitalize" }}>
                                            {item.type ? item.type === "senior_secondary" ? "Sr Secondary" : <span>{item?.degree}{' '}{item.stream && `(${item?.stream})`}</span> : <span>{item?.degree}{' '}{item.stream && `(${item?.stream})`}</span>}
                                        </span>}
                                        description={
                                            (item?.type && item?.stream || item?.university && item?.degree) &&
                                            < div style={{ display: "flex", flexDirection: "column" }}>
                                                <Text style={{ fontWeight: 500, fontSize: "14px", color: "rgb(117, 117, 117)", display: item?.university ? "block" : "none" }}>
                                                    <div style={{ display: 'flex' }}>
                                                        <div

                                                            className="Education_column1"
                                                        >University:</div>
                                                        <div className="Column2_otherProfile">{item?.university}</div>
                                                    </div>
                                                </Text>
                                                <Text style={{ fontWeight: 500, fontSize: "14px", color: "rgb(117, 117, 117)", display: item?.stream && item.type === "senior_secondary" ? "block" : "none" }}>
                                                    <div style={{ display: 'flex' }}>
                                                        <div

                                                            className="Education_column1"
                                                        >Stream:</div>
                                                        <div className="Column2_otherProfile">{item?.stream}</div>
                                                    </div>
                                                </Text>
                                                <Text style={{ fontWeight: 500, fontSize: "14px", color: "rgb(117, 117, 117)", display: item?.board ? "block" : "none" }}>
                                                    <div style={{ display: 'flex' }}>
                                                        <div

                                                            className="Education_column1"
                                                        >Board:</div>
                                                        <div className="Column2_otherProfile">{item?.board}</div>
                                                    </div>
                                                </Text>
                                                <Text style={{ fontWeight: 500, fontSize: "14px", color: "rgb(117, 117, 117)" }}>
                                                    <div style={{ display: 'flex' }}>
                                                        <div

                                                            className="Education_column1"
                                                        >Year:</div>
                                                        <div className="Column2_otherProfile">{item?.year}</div>
                                                    </div>
                                                </Text>
                                                <Text style={{ fontWeight: 500, fontSize: "14px", color: "rgb(117, 117, 117)" }}>
                                                    <div style={{ display: 'flex' }}>
                                                        <div

                                                            className="Education_column1"
                                                        >Score:</div>
                                                        <div className="Column2_otherProfile">{item?.score}</div>
                                                    </div>
                                                </Text>
                                            </div>
                                        }
                                    />
                                </List.Item>
                            )}
                        />
                    </div>
                    : null
            }
        </>
    }

}

export class Certification extends React.Component<MyProps> {

    render() {
        const certificationList = this.props?.data?.certifications;
        return <>
            {
                certificationList?.length > 0 ?
                    <div style={{ margin: "1rem" }}>
                        <Title level={5} style={{ color: "rgba(0, 0, 0, 0.85)" }}>Certification</Title>
                        <List
                            data-testid='certificationList'
                            style={{ borderRadius: "5px", overflow: "hidden" }}
                            itemLayout="horizontal"
                            dataSource={certificationList}
                            renderItem={(item: any) => (
                                <List.Item className="listInfo">
                                    <List.Item.Meta
                                        title={<span style={{ textTransform: "capitalize" }}>{item.name}</span>}
                                        description={item.description}
                                    />
                                </List.Item>
                            )}
                        />
                    </div>
                    : null
            }
        </>
    }

}

export class AwardsRecognition extends React.Component<MyProps> {


    render() {
        const awardsList: Array<any> = this.props?.data?.profile?.data?.attributes?.awards;
        return <>
            {
                awardsList?.length > 0 ?
                    <div style={{ margin: "1rem" }}>
                        <Title level={5} style={{ color: "rgba(0, 0, 0, 0.85)" }}>Awards & Recognition</Title>
                        <List
                            data-testid="awardsList"
                            style={{ borderRadius: "5px", overflow: "hidden" }}
                            itemLayout="horizontal"
                            dataSource={awardsList}
                            renderItem={(item: any) => (
                                <List.Item className="listInfo">
                                    <List.Item.Meta
                                        title={<span style={{ textTransform: "capitalize" }}>
                                            {item.title}</span>}
                                        description={item.description}
                                    />
                                </List.Item>
                            )}
                        />
                    </div>
                    : null
            }
        </>
    }

}

export class Patent extends React.Component<MyProps> {


    render() {
        const patentsList = this.props?.data?.patents;

        return <>
            {
                patentsList?.length > 0 ?
                    <div style={{ margin: "1rem" }}>
                        <Title level={5} style={{ color: "rgba(0, 0, 0, 0.85)" }}>Patent</Title>
                        <List
                            data-testid='patentsList'
                            style={{ borderRadius: "5px", overflow: "hidden" }}
                            itemLayout="horizontal"
                            dataSource={patentsList}
                            renderItem={(item: any) => (
                                <List.Item className="listInfo">
                                    <List.Item.Meta
                                        title={<span style={{ textTransform: "capitalize" }}>{item.name}</span>}
                                        description={item.description}
                                    />
                                </List.Item>
                            )}
                        />
                    </div>
                    : null
            }
        </>

    }

}

export class Achievements extends React.Component<MyProps> {

    render() {
        const achievementsList = this.props?.data?.achievements;

        return <>
            {
                achievementsList?.length > 0 ?
                    <div style={{ margin: "1rem" }}>
                        <Title level={5} style={{ color: "rgba(0, 0, 0, 0.85)" }}>Achievements</Title>
                        <List
                            data-testid='achievementsList'
                            style={{ borderRadius: "5px", overflow: "hidden" }}
                            itemLayout="horizontal"
                            dataSource={achievementsList}
                            renderItem={(item: any) => (
                                <List.Item className="listInfo">
                                    <List.Item.Meta
                                        description={item.description}
                                    />
                                </List.Item>
                            )}
                        />
                    </div>
                    : null
            }
        </>

    }

}

export class Languages extends React.Component<MyProps> {


    render() {
        const languagesList = this.props?.data?.languages;

        return <>
            {
                languagesList?.length > 0 ?
                    <div style={{ margin: "1rem" }}>
                        <Title level={5} style={{ color: "rgba(0, 0, 0, 0.85)" }}>Languages</Title>
                        <List
                            data-testid='languagesList'
                            style={{ borderRadius: "5px", overflow: "hidden" }}
                            itemLayout="horizontal"
                            dataSource={languagesList}
                            renderItem={(item: any) => (
                                <List.Item className="listInfo">
                                    <List.Item.Meta
                                        title={<span style={{ textTransform: "capitalize" }}>{item.language}</span>}
                                    />
                                </List.Item>
                            )}
                        />
                    </div>
                    : null
            }
        </>

    }

}

// Customizable Area End
