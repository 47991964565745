import React from 'react';
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { message } from "antd";
// Customizable Area Start
import { getStorageData, isEmpty } from "../../../framework/src/Utilities";
import axios from "axios";
import { cloneDeep } from "lodash";
import { Redirect, useParams } from "react-router-dom";
// @ts-ignore
import { baseURL } from '../../../framework/src/config.js';
// Customizable Area End
const googleAPIkey = "AIzaSyCBH92YX2oCbI7PChqFNrgzw-PolHAiKZc";
import { RouterProps } from "react-router";
import { createRef } from "react";
import { EditorState, convertToRaw } from "draft-js";


export const configJSON = require("./config");
export interface Props extends RouterProps {
  // Customizable Area Start
  navigation: any;
  id: string;
  history: any;
  match: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isCreatePostEnable: boolean;
  isMenuVisible: boolean;
  isBusinessUser: boolean;
  createPostDescription: any;
  postList: any[];
  videoArrayList: any;
  isloader: boolean;
  token: string | null;
  isCreatePostVisible: boolean;
  fullAddress: string;
  createPostImages: any;
  loading: boolean;
  likedItems: number[];
  commentLikedItem: any[];
  followedItems: number[];
  initialCount: boolean;
  userProfile: any;
  loginUser: any;
  accountDetails: any
  isReportIssueVisible: boolean;
  isShowInvitationModal: boolean;
  isShowConnectionModal: boolean;
  reportPostID: any;
  selectedFile: any;
  value: any;
  relationValue: any;
  mentionInputValue: string;
  GetConnectionArrayAcc: any;
  tagged_connection_ids: any;
  isEmojiPickerEnable: boolean;
  postComments: any;
  AllMedia: any
  knownPeople: any
  allCommunity: any
  joinnedCommunities: any
  upComingAnniversaries: any;
  birthAnniversaries: any;
  workAnniversaries: any;
  peoplemayKnow: any;
  allInvitations: any;
  businessConnection: any;
  personalConnection: any;
  invitationUserInfo: any;
  loadInvitationData: any;
  redirect: boolean;
  isMounted: boolean;
  loadcommunity: boolean;
  loadUpcoming: boolean;
  loadpeopleKnow: boolean;
  loadInvitation: boolean;
  loadLocation: boolean;
  postlocation: any;
  latitude: any,
  longitude: any,
  followersAndFollowingOpen: boolean;
  createPostOpen: boolean;
  keyValue: string;
  followers: any;
  tagsOpen: boolean;
  editPostOpen: boolean;
  editData: any;
  isCommentloader: boolean;
  isloadEditPost: boolean;
  address: any;
  location: boolean;
  loadComments: any;
  editortext: any;
  open: boolean;
  suggestions: any
  deleteExistingMedias: any;
  deleteId: any;
  getPostBYIdForHidePost: any;
  AniversaryALLData:any
  // Customizable Area End
}
interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class PostFeedController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  labelLogOut: string;
  labelProfile: string;
  labelSetting: string;
  labelApplied: string;
  labelHome: string;
  labelMyNetwork: string;
  labelCommunity: string;
  labelChat: string;
  labelAt: string;
  labelCommunityForum: string;
  labelWorkAnniversaries: string;
  labelBirthAnniversaries: string;
  labelKnownPeople: string;
  labelInvitations: string;
  placeHolderSearchContact: string;
  placeHolderWhatOnMind: string;
  btnLearnMore: string;

  isMenuVisible: boolean;
  SearchUserDataGetId: any;
  getHomeFeedPostsApiCallId: any;
  getPendingInvitationsApiCallId: any;
  getKnownPeopleApiCallId: any;
  getNetworkConnectionAppliedId: any;
  getAllCommunityApiCallId: any;
  addCommentInPost: any;
  addReplyCommentInPost: any;
  getCommentInPostApiCallID: any;
  getEditPostApiCallID: any;
  unsubscribe: any;
  getFollowersApiCallId: any;
  deletePostApiCallId: any;
  geteditFeedPostApiCallId: any;
  saveHidePostId: string;

  GetCategorySelectionByNetworkforConnection: any
  GetCategorySelectionByNetwork: any
  getPostByIdForHidePostMethodTypeCallID: any;
  // Customizable Area End
  contentRef = createRef<any>();
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      isCreatePostEnable: false,
      isMenuVisible: false,
      isBusinessUser: false,
      createPostDescription: EditorState.createEmpty(),
      postList: [],
      videoArrayList: [],
      isloader: true,
      token: localStorage.getItem("token") ? localStorage.getItem("token") : "",
      isCreatePostVisible: false,
      fullAddress: "",
      createPostImages: [],
      loading: true,
      likedItems: [],
      commentLikedItem: [],
      followedItems: [],
      initialCount: true,
      loginUser: {},
      userProfile: {},
      isReportIssueVisible: false,
      isShowInvitationModal: false,
      isShowConnectionModal: false,
      reportPostID: "",
      selectedFile: null,
      value: 'Fraude or pyramid Marketing',
      relationValue: '',
      GetConnectionArrayAcc: [],
      mentionInputValue: "",
      tagged_connection_ids: [],
      isEmojiPickerEnable: false,
      postComments: [],
      AllMedia: [],
      accountDetails: {},
      knownPeople: [],
      allCommunity: [],
      joinnedCommunities: [],
      upComingAnniversaries: {},
      peoplemayKnow: [],
      allInvitations: [],
      birthAnniversaries: [],
      workAnniversaries: [],
      businessConnection: [],
      personalConnection: [],
      invitationUserInfo: {},
      loadInvitationData: [],
      redirect: false,
      isMounted: false,
      loadcommunity: true,
      loadUpcoming: true,
      loadpeopleKnow: true,
      loadInvitation: true,
      loadLocation: false,
      latitude: null,
      longitude: null,
      postlocation: '',
      followersAndFollowingOpen: false,
      createPostOpen: false,
      keyValue: "",
      followers: [],
      tagsOpen: false,
      editPostOpen: false,
      editData: {},
      isCommentloader: false,
      isloadEditPost: false,
      address: null,
      location: false,
      loadComments: false,
      editortext: "",
      open: false,
      suggestions: [],
      deleteExistingMedias: [],
      deleteId: '',
      getPostBYIdForHidePost: [],
      AniversaryALLData:[]
    };

    this.btnLearnMore = configJSON.btnLearnMore;
    this.placeHolderWhatOnMind = configJSON.placeHolderWhatOnMind;
    this.placeHolderSearchContact = configJSON.placeHolderSearchContact;
    this.labelLogOut = configJSON.labelLogOut;
    this.labelProfile = configJSON.labelProfile;
    this.labelSetting = configJSON.labelSetting;
    this.labelApplied = configJSON.labelApplied;
    this.labelHome = configJSON.labelApplied;
    this.labelMyNetwork = configJSON.labelMyNetwork;
    this.labelCommunity = configJSON.labelCommunity;
    this.labelChat = configJSON.labelChat;
    this.labelAt = configJSON.labelAt;
    this.labelCommunityForum = configJSON.labelCommunityForum;
    this.labelWorkAnniversaries = configJSON.labelWorkAnniversaries;
    this.labelBirthAnniversaries = configJSON.labelBirthAnniversaries;
    this.labelKnownPeople = configJSON.labelKnownPeople;
    this.labelInvitations = configJSON.labelInvitations;

    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.setState({ isMounted: true });
    let token = await localStorage.getItem("token");
    if (token && token !== null && token !== undefined) {
      this.setState({ redirect: false });
    } else {
      this.setState({ redirect: true });
    }
    if (this.state.isMounted) {
      await this.getHomeFeedPosts();
      await this.getPeopleYouknow();
      await this.getInvitations();
      await this.getAllCommunity()     

      this.getNetworkConnectionApplied();
    }

    this.getUpcomingWorkAnniversariesData()
    this.getFollowers();
    let id = this.props.match.params.id
    this.getPostByIDForHidePost(id)

  }

  redirectUser() {
    if (this.state.redirect) {
      return <Redirect to="/" />;
    }
  }

  handleEditPostOpen = (id: any) => {
    this.setState({ editPostOpen: true })
    this.getFeedPost(id);
  }

  handleFollowersAndFollowingOpen = (data: string) => {
    if (data.match(/followers/gi)) {
      this.props.history.push({ pathname: "/home/followers/following", state: { keyValue: "1" } });
    } else if (data.match(/Following/gi)) {
      this.props.history.push({ pathname: "/home/followers/following", state: { keyValue: "2" } });
    } else if (data.match(/Posts/gi)) {
      // console.log(this.state.userProfile?.attributes?.profile?.data?.attributes?.role ==="business",'userProfile__')
      if (this.state.userProfile?.attributes?.profile?.data?.attributes?.role === "business") {
        return this.props.history.push({ pathname: "/MyBusiness/Profile" });
      } else {
        return this.props.history.push({ pathname: "/MyPersonalProfile/Profile" });
      }
    } else if (data.match(/Connections/gi)) {
      this.props.history.push({ pathname: "/myNetwork" });
    } else if (data.match(/Rating Received/gi)) {
      this.props.history.push({ pathname: "/Rating", state: { keyValue: "2" } });
    } else if (data.match(/Rating Sent/gi)) {
      this.props.history.push({ pathname: "/Rating", state: { keyValue: "1" } });
    }
  }
  getFeedPost = async (id: number) => {
    this.setState({
      AllMedia: [], createPostImages: [], videoArrayList: [], createPostDescription: EditorState.createEmpty(), isCreatePostEnable: false, isCreatePostVisible: false, tagged_connection_ids: [],
      isloadEditPost: true,
      deleteExistingMedias: []
    });
    const header = {
      "Content-Type": configJSON.getPostsApiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.geteditFeedPostApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      // configJSON.getUserPostsApiEndpoint
      `${configJSON.getPostsApiEndpoint}/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getPostsApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  async componentWillUnmount() {
    this.setState({ isMounted: false });
  }

  handleDataUpdateAfterEditorDelete = (post: any, str?: any) => {

    let list = this.state.postList.map((item: any) => {
      if (item?.attributes?.id != post?.attributes?.id) {

        return item
      } else {

        if (str === 'delete') {
          return
        } else {
          return post
        }
      }
    })
    console.log("dsklfajld", list)
    this.setState({
      postList: list.filter((item: any) => item !== undefined)
    })

  }

  async receive(from: String, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      //get Edit data
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.geteditFeedPostApiCallId) {
          this.setState({ editData: responseJson.data, isloadEditPost: false })
          this.setState({ createPostDescription: responseJson.data?.attributes?.body });
          this.setState({ tagged_connection_ids: responseJson.data?.attributes?.outside_taggings });
          this.setState({ AllMedia: responseJson.data?.attributes?.media });
        }
      } else {
        this.parseApiErrorResponse(responseJson);
      }
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.getHomeFeedPostsApiCallId) {
          if (responseJson?.data) {
            this.setState({
              postList: responseJson?.data,
            })
          }
          console.log(responseJson.data, 'home feed responseJson.data')
        } else if (apiRequestCallId === this.addCommentInPost) {
          this.setState({
            isloader: false,
            // loadComments: false,
            isCommentloader: false
          });
          this.getComments(responseJson.data.attributes.commentable_id);
          // window.location.reload();
        } else if (apiRequestCallId === this.addReplyCommentInPost) {
          this.setState({
            isloader: false,
            // loadComments: false
          });
          this.getHomeFeedPosts();
          // window.location.reload();
        }
        else if (apiRequestCallId === this.saveHidePostId) {
          let id = this.props.match.params.id
          this.getPostByIDForHidePost(id)
        }
        else if (apiRequestCallId === this.GetCategorySelectionByNetworkforConnection) {
          this.setState({
            isShowConnectionModal: true,
            loadInvitationData: responseJson.relations
          })

        } else if (apiRequestCallId === this.GetCategorySelectionByNetwork) {
          this.setState({
            isShowInvitationModal: true,
            loadInvitationData: responseJson.relations
          })
        }
        else if (apiRequestCallId === this.getCommentInPostApiCallID) {
          console.log("get comments: ", responseJson);
          let list = this.state.postList.map((value) =>
            value.id == responseJson.data[0]?.attributes?.commentable_id
              ? { ...value, attributes: { ...value.attributes, comments: { data: responseJson.data } } }
              : value
          );

          this.setState({
            // loadComments: false,
            postComments: responseJson.data,
            postList: list
          });
        } else if (apiRequestCallId === this.getPendingInvitationsApiCallId) {
          // console.log("network request: ", JSON.stringify(responseJson));
          this.setState({
            // postList: responseJson.data,
            isloader: false,
          });
        } else if (apiRequestCallId === this.getKnownPeopleApiCallId) {
          // console.log("konown people: ", JSON.stringify(responseJson));
          this.setState({
            knownPeople: responseJson.data,
            isloader: false,
          });
        } else if (apiRequestCallId === this.getNetworkConnectionAppliedId) {
          let receivedData = responseJson.data;
          let finalFriendsData: any = [];
          receivedData &&
            receivedData.forEach((item: any, index: any) => {
              if (item && item.attributes && item.attributes.full_name) {
                let finalItem = item;
                finalItem["name"] = item.attributes.full_name;
                finalFriendsData.push(finalItem);
              }
            });
          // console.log("finalFriendsData - ", finalFriendsData);
          this.setState(
            {
              GetConnectionArrayAcc: finalFriendsData,
            },
            () => {
              // console.log("responseJson.data rcvd = ", responseJson.data);
              if (
                this.state.GetConnectionArrayAcc &&
                this.state.GetConnectionArrayAcc.length > 0
              ) {
                // console.log("categories: ", this.state.GetConnectionArrayAcc);
                const connectedFriends = this.state.GetConnectionArrayAcc.map(
                  (item: any) => item.attributes.connected_as
                )
                  .filter((v: any, i: any, a: any) => a.indexOf(v) == i)
                  .filter((x: any) => x !== null)
                  .map((x: any) => {
                    return { value: x };
                  });

              }
            }
          );

        }

        //getPostByIdForHidepost
        if (apiRequestCallId && responseJson) {
          if (apiRequestCallId === this.getPostByIdForHidePostMethodTypeCallID) {
            // this.setState({
            //   getPostBYIdForHidePost: responseJson.data
            // })
            if (responseJson?.data) {
              this.setState({
                getPostBYIdForHidePost: responseJson.data,
                isloader: false,
              })

            }
            console.log(this.state.getPostBYIdForHidePost, "getpostbyIdForHide")
          }
        } else {
          this.parseApiErrorResponse(responseJson);
        }

        // get followers
        if (apiRequestCallId && responseJson) {
          if (apiRequestCallId === this.getFollowersApiCallId) {
            this.setState({ followers: responseJson.data });
            // console.log(this.state.followers, "folllllll")
          }
        } else {
          this.parseApiErrorResponse(responseJson);
          this.setState({
            editPostOpen: false, isloadEditPost: false
          })
        }
        if (apiRequestCallId === this.deletePostApiCallId) {
          if (responseJson) {
            const data = { attributes: { id: this.state.deleteId } }
            this.handleDataUpdateAfterEditorDelete(data, 'delete')
          }
        }
      }
      else {
        this.parseApiErrorResponse(responseJson);
        this.setState({
          editPostOpen: false, isloadEditPost: false
        })
      }
    } else if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let requesterId = message.getData(
        getName(MessageEnum.SessionRequestedBy)
      );
    }

    let data: any = await getStorageData("loginUser").then((res) => {
      // console.log(res, 'loginUser');
      if (res) {
        this.setState({
          loginUser: res,
          isBusinessUser: res?.role === "business",
        });
      }

    });
    await getStorageData("userProfile").then((res) => {
      // console.log("userProfile res-->", res);
      this.setState({ userProfile: res },
        () => console.log(this.state.userProfile, 'userProfile')
      );
    });
    await getStorageData("accountData").then((res) => {
      //   console.log("accountDetails-->", res);
      this.setState({ loading: true });
      this.setState({ accountDetails: res, loading: false }
        // , () => console.log(this.state.accountDetails,'accountDetails')
      );
      if (res?.attributes?.account?.data?.attributes?.profile_setup === false) {
        if (res?.attributes?.account?.data?.attributes?.role === "business") {
          return this.props.history.replace('/setup/profile/business');
        } else {
          return this.props.history.replace('/setup/profile/personal');
        }
      }
    });
    // Customizable Area End
  }

  // Customizable Area Start

  getDomainSkills(userDataHere: any) {
    if (
      isEmpty(userDataHere)
      // isEmpty(userDataHere.attributes) ||
      // isEmpty(userDataHere.attributes.domain_skills)
    )
      return "";
    const domainSkillsPresent = userDataHere;
    const domainSkillsStr = domainSkillsPresent.join(", ");
    // console.log("domainSkillsStr = ", domainSkillsStr);
    return domainSkillsStr;
  }

  deleteItem = (index: any) => {
    let deleteExistingMedias: any = cloneDeep(this.state.deleteExistingMedias);
    this.state.AllMedia.forEach((item: any, indexHere: any) => {
      if (index === indexHere && item.blob_id) {
        deleteExistingMedias.push(item);
      }
    });

    console.log("Deleting: ", index);
    let clonedArr = [...this.state.AllMedia].filter(
      (el: any, i: number) => index !== i
    );
    this.setState({ AllMedia: clonedArr, deleteExistingMedias });
  };


  getAllCommunity = async () => {
    this.setState({ loadcommunity: true });
    try {
      const response = await axios({
        url: `${baseURL}/bx_block_groups/community_forums/?admin=false&joined=true`,
        method: 'get',
        headers: {
          'token': this.state.token,
          'Content-Type': 'application/json'
        }
      })
      if (response?.data?.communities) {
        response.data.communities?.data?.map((c: any) => {
          if (c?.attributes?.joined && c?.attributes?.admin === false) {
            this.setState((prevState) => ({
              joinnedCommunities: [...prevState.joinnedCommunities, c],
              loadcommunity: false,
            }));
          }
        });
      }
    } catch (error) {
      console.error(error);
      this.setState({ loadcommunity: false });
    }
    this.setState({ loadcommunity: false });
  }
  //community forum
  communityForum = () => {
    this.props.history.push({ pathname: '/community/all', state: { communityData: this.state.joinnedCommunities, heading: 'Community Forum' } })
  }
  //work anniversies

  workAnniversariesRoute = () => {
    this.props.history.push({
      pathname: "/upcoming/anniversaries",
      state: {
        key: '1',
        aniversaryData: this.state.AniversaryALLData
      },
    });

  }

  //Birth anniversies

  birthAnniversariesRoute = () => {
    this.props.history.push({
      pathname: "/upcoming/anniversaries",
      state: {
        key: '2',
        aniversaryData: this.state.AniversaryALLData,
      },
    });

  } 

  getUpcomingWorkAnniversariesData = async () => {
    this.setState({ loadUpcoming: true });
    try {
      const response = await axios({
        url: `${baseURL}/account_block/accounts/anniversaries`,
        method: 'get',
        headers: {
          'token': this.state.token,
          'Content-Type': 'application/json'
        }
      })
      if (response.data) {
        // birthAnniversaries
        let birthDates: any = [];
        let workDates: any = [];
        response.data.birth?.today?.data?.map((c: any) => birthDates.push(c));
        response.data.birth?.this_month?.data?.map((c: any) => birthDates.push(c));
        response.data.birth?.next_month?.data?.map((c: any) => birthDates.push(c));
        response.data.work?.today?.data?.map((c: any) => workDates.push(c));
        response.data.work?.this_month?.data?.map((c: any) => workDates.push(c));
        response.data.work?.next_month?.data?.map((c: any) => workDates.push(c));
        return this.setState({
          birthAnniversaries: birthDates,
          workAnniversaries: workDates,
          loadUpcoming: false,
          AniversaryALLData: response?.data
        })
      }
    } catch (error) {
      this.setState({ loadUpcoming: false });
    }
  }

  // invitations

  onViewAllInvitationsRoute = () => {
    this.props.history.push('/myNetwork/invitation')

  };

  //people invite

  onViewAllInviteRoute = () => {
    this.props.history.push('/myNetwork/you_may_know')

  };

  // *** api calls ******

  getHomeFeedPosts = async () => {
    const header = {
      "Content-Type": configJSON.getPostsApiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getHomeFeedPostsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPostsApiEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getPostsApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  likePost = async (id: number) => {
    const token = this.state.token;
    const header = {
      "Content-Type": configJSON.getPostsApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getPostsApiEndpoint}/${id}/like`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.likeDislikeApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  dislikePost = async (id: number) => {
    const token = await this.state.token;
    const header = {
      "Content-Type": configJSON.getPostsApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getPostsApiEndpoint}/${id}/dislike`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.likeDislikeApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  toggleLike = (id: number, liked: boolean) => {
    if (liked) {
      this.setState({
        // postLike: false
        likedItems: this.state.likedItems.filter(function (el) {
          return el !== id;
        }),
      });
      this.dislikePost(id);
    } else {
      // console.log("like");
      // this.setState({postLike:true})
      this.setState((prevState) => ({
        likedItems: [...prevState.likedItems, id],
      }));
      this.likePost(id);
    }
  };

  toggleCommentLike = (id: number, liked: boolean) => {
    if (liked) {
      this.dislikedComment(id);
    } else {
      this.likedComment(id);
    }
  };

  handlePostList = (data: any, status: string) => {
    const postData = this.state.postList;
    if (status === "update") {
      console.log(status, data);
      const updatePostList = postData?.map((item: any) => {
        if (item.id === data.id) {
          return data
        } else {
          return item
        }
      })
      this.setState({ postList: updatePostList });
    } else if (status === "delete") {
      const updatePostList = postData?.filter((item: any) => {
        return item.id != data?.id
      })
      this.setState({ postList: updatePostList });
    }
  }

  onClickFollow = async (id: number, isFollowed: boolean) => {
    try {
      const response = await axios({
        url:
          `${baseURL}/bx_block_follow/follows?account_id=${id}`,
        method: "POST",
        headers: {
          token: await localStorage.getItem('token'),
          "Content-Type": "application/json"
        }
      });
      if (isFollowed) {
        this.setState({
          // postLike: false
          followedItems: this.state.followedItems.filter(function (el) {
            return el !== id;
          }),
        });
      } else {
        this.setState((prevState) => ({
          followedItems: [...prevState.followedItems, id],
        }));
      }
    } catch (error) {
      console.error(error, 'error while calling api follow REQUEST');
    }
  };

  addComment = (id: number, comment: any) => {
    this.setState({ isCommentloader: true });
    const token = this.state.token;
    const header = {
      "Content-Type": configJSON.getPostsApiContentType,
      token: token,
    };
    let body = {
      data: {
        attributes: {
          post_id: id,
          comment: comment,
        },
      },
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addCommentInPost = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createComment}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  addReplyComment = (id: number, comment: any) => {
    console.log(id, "vvvv", comment);
    // this.setState({ loadComments: true });
    const token = this.state.token;
    const header = {
      "Content-Type": configJSON.getPostsApiContentType,
      token: token,
    };
    let body = {
      data: {
        attributes: {
          comment_id: id,
          comment: comment,
        },
      },
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addReplyCommentInPost = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createComment}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getComments = async (id: number) => {

    // this.setState({ loadComments: true });
    const token = this.state.token;
    const header = {
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCommentInPostApiCallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createComment}?post_id=${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getPostsApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  likedComment = async (id: number) => {
    this.setState({ isloader: true });
    const token = await this.state.token;
    const header = {
      // "Content-Type": configJSON.getPostsApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createComment}/${id}/like`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.likeDislikeApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({ isloader: false });
    this.getHomeFeedPosts();
    return true;
  };
  dislikedComment = (id: number) => {
    this.setState({ isloader: true });
    const token = this.state.token;
    const header = {
      // "Content-Type": configJSON.getPostsApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createComment}/${id}/dislike`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.likeDislikeApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({ isloader: false });
    this.getHomeFeedPosts();
    return true;
  };

  getKnownPeople = () => {
    const header = {
      token: this.state.token,
    };

    // console.log("getKnownPeople");
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getKnownPeopleApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getKnownPeopleEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getNetworkApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  };

  //People you know
  getPeopleYouknow = async () => {
    this.setState({ peoplemayKnow: [], loadpeopleKnow: true });
    try {
      const response = await axios({
        url: `${baseURL}/bx_block_request_management/requests/suggestions`,
        method: 'get',
        headers: {
          'token': this.state.token,
          'Content-Type': 'application/json'
        }
      })
      // const response = await axios.get('');
      this.setState({ peoplemayKnow: response.data, loadpeopleKnow: false })
      //  console.log(response.data, 'all getPeopleYouknow');
    } catch (error) {
      console.error(error);
      this.setState({ loadpeopleKnow: false });
    }
  }

  // Invitations
  getInvitations = async () => {
    this.setState({ allInvitations: [], loadInvitation: true });
    try {
      const response = await axios({
        url: `${baseURL}/bx_block_request_management/requests?status=pending`,
        method: 'get',
        headers: {
          'token': this.state.token,
          'Content-Type': 'application/json'
        },
        // data: JSON.stringify(httpBody)
      })
      // const response = await axios.get('');
      this.setState({ allInvitations: response.data, loadInvitation: false },
        () => console.log(this.state.allInvitations, 'all Invitations')
      );
    } catch (error) {
      console.error(error);
      this.setState({ loadInvitation: false });
    }
  }

  //handle add connections
  handleConnectionModal = (data: any, role: any) => {
    this.setState({ isShowConnectionModal: true, invitationUserInfo: data, loadInvitationData: [] });
    this.getRelations("send", data?.req_id);
  }

  handleInvitationModal = (data: any, role: any) => {
    this.getRelations("accept", data?.account_id);
    this.setState({ isShowInvitationModal: true, invitationUserInfo: data, loadInvitationData: [] });
  }

  getRelations = async (type: any, account_id: any) => {
    try {
      const response = await axios({
        url: `${baseURL}/bx_block_request_management/requests/relations?account_id=${account_id}&action_type=${type}`,
        method: 'get',
        headers: {
          'token': this.state.token,
          'Content-Type': 'application/json'
        }
      })
      if (response && response?.data) {
        const reltionsArray = response?.data.relations.map((item: any) => ({ name: item, id: item }))
        this.setState({ loadInvitationData: reltionsArray });
      }
    } catch (error) {
      console.error(error);
    }
  }


  sendRequest = async (relation: any, account_id: any) => {
    try {
      const response = await axios({
        url: `${baseURL}/bx_block_request_management/requests`,
        method: 'post',
        headers: {
          'token': this.state.token,
          'Content-Type': 'application/json'
        },
        data: {
          data: {
            "sender_relation": relation,
            "account_id": account_id
          }
        }

      })
      if (response && response?.data) {
        this.setState({ isShowConnectionModal: false })
        this.getPeopleYouknow();
      }
    } catch (error) {
      console.error(error);
    }
  }

  handleMenuClick(e: any, id: any) {
    console.log("click =>", e, id);
    if (e === "save") {
      // setDropDownVisible(true);
      this.savePost(id, "save");
    }
    if (e === "hide") {
      this.savePost(id, "hide");
    }
    if (e === "report") {
      // setDropDownVisible(true);
      this.setState({ isReportIssueVisible: true, reportPostID: id });
    }
  }

  handleOk = async (value: any) => {
    let httpBody = {
      data: {
        attributes: {
          context: value
        }
      }
    }
    try {
      const response = await axios({
        url: `${baseURL}/bx_block_posts/posts/${this.state.reportPostID}/report`,
        method: 'post',
        headers: {
          'token': this.state.token,
          'Content-Type': 'application/json'
        },
        data: JSON.stringify(httpBody)
      })
      // console.log(response.data, 'report submitted');
      this.setState({ isReportIssueVisible: false });
    } catch (error) {
      console.error(error);
      this.setState({ isReportIssueVisible: false });
    }
    this.setState({ isReportIssueVisible: false });
    // setDropDownVisible(false);
  };

  handleCancel = (e: any) => {
    // console.log(e);
    this.setState({ isReportIssueVisible: false, reportPostID: "" });
  };
  onChangeValue = (e: any) => {
    // console.log("radio checked", e.target.value);
    this.setState({ value: e.target.value });
  };
  savePost = async (id: number, endString: string) => {
    // this.setState({ isloader: true });
    const token = this.state.token;
    const header = {
      "Content-Type": configJSON.getPostsApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.saveHidePostId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getPostsApiEndpoint}/${id}/${endString}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleChange = (event: any) => {
    // console.log("@@@ e======hello", event);
    this.setState({ relationValue: event });
  }

  handleInvitations = async (value: any, status: any, req_id: any, cat_id: any) => {
    let httpBody = {
      data: {
        status: status,
        sender_relation: this.state.relationValue
      }
    }
    // console.log(value,'*', status,'*', id);
    try {
      const response = await axios({
        url: `${baseURL}/bx_block_request_management/requests/${req_id}`,
        method: 'put',
        headers: {
          'token': this.state.token,
          'Content-Type': 'application/json'
        },
        data: JSON.stringify(httpBody)
      })
      if (response?.data) {
        this.getInvitations();
        this.getPeopleYouknow();
        // window.location.reload();
        return this.setState({ isShowInvitationModal: false });
      }

    } catch (error) {
      console.error(error);
      this.setState({ isShowInvitationModal: false });
    }
    this.setState({ isShowInvitationModal: false });
  }

  getNetworkConnectionApplied = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token,
      // token: 'eyJhbGciOiJIUzUxMiJ9.eyJpZCI6MzUxLCJleHAiOjE2MjIyODE3OTV9.VeU0sy4U9YiEJXyATrMzJWwFPlgaSm9KqwQVwoX7ZmLJ8SXW7oQQY3NgQ5oolFrKyhron8Bha9RZ0XCP7QNPnQ',
    };
    //   let formdata: any = new FormData();
    //   formdata.append("[token]", 'eyJhbGciOiJIUzUxMiJ9.eyJpZCI6NTI4LCJleHAiOjE2MjIxMjU0MDd9.GxLPNGdjH_WraIR5jIR4eURIPVsXogq2VKcYebGSvTfC407qLv2PKtDW6c9IuFXneT0lKsV_POuSQJepNErovA');
    let data = {
      status: "pending",
    };
    const httpBody = {
      data: data,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getNetworkConnectionAppliedId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getNetworkEndPoint + "?status=accepted"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    //   requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),httpBody);
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getNetworkApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // console.log("Getconnection--->" + requestMessage);
    return true;
  };

  redirectCommunity = (id: string) => {
    this.updateUnseenCount(id);
    return this.props.history.push({
      pathname: '/community/block',
      state: { communityId: id }
    })
  }

  // unseen post count
  updateUnseenCount = async (id: any) => {
    try {
      const response = await axios({
        url: `${baseURL}/bx_block_groups/community_forums/${id}`,
        method: 'get',
        headers: {
          'token': this.state.token,
          'Content-Type': 'application/json'
        },
      })
      console.log(response, 'updateUnseenCount');
    } catch (error) {
      console.error(error, 'error in updateUnseenCount');
    }
  }
  onItemPress = (id: string) => {
    console.log(id, 'press item');

  }

  // get post by id for hide post
  getPostByIDForHidePost = async (id: any) => {
    this.setState({ isloader: true });
    const token = this.state.token;
    const header = {
      "Content-Type": configJSON.getPostsApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getPostByIdForHidePostMethodTypeCallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getPostByIdForHidePost}/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getPostByIdForHidePostMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  // get followers
  getFollowers = async () => {
    let id = this.state.userProfile?.attributes?.profile?.data?.attributes?.account_id

    const header = {
      "Content-Type": configJSON.getPostsApiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getFollowersApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getFollowersApiCallIdEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getPostsApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  // delete post 
  deletePost = async (id: any) => {
    this.setState({
      deleteId: id
    })
    const header = {
      "Content-Type": configJSON.getPostsApiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deletePostApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deletePostEndPoint}/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.DELETEMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

}

  // Customizable Area End