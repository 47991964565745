import React, { useState } from 'react';
import { Space, Checkbox, Button, Typography, Card, Row, Col, Switch, Form, Input, Select, DatePicker } from 'antd';
import CustomRadioButton from './CustomRadioButton';
import axios from 'axios';
import moment from 'moment';
// @ts-ignore
import { baseURL } from '../../../../framework/src/config.js';
import AlertMessage from './AlertMessage.web';
const { Title, Text, Paragraph } = Typography;
const { TextArea } = Input;
import { EyeFilled, EyeInvisibleFilled } from '@ant-design/icons';


const SuspendAccount = (props: any) => {
    // Customizable Area Start
    const { onFinish, suspendMessage, handleOk } = props;
    const [form] = Form.useForm();
    const [ratingButtonValue, setRatingButtonValue] = useState<number | null>(null);
    const [isDisabled, setIsDisabled] = useState(false);
    const [empty, setEmpty] = useState<boolean>(false)
    const [passwordVerify, setPasswordVerify] = useState<boolean | null>(null);
    const [ratingShow, setRatingShow] = useState(false);
    const [validateToken, setValidateToken] = useState<string | null>(null);
    const [passwordValue, setPasswordValue] = useState<string>('');
    const [reason, setReason] = useState<string>('');

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    const handleSelectChange = (value:any) => {
        setReason(value);
    }

    const onBlur = async (e: any) => {
        let text = e
        if (text.target.value.trim().length === 0) {
            setEmpty(true)
        } else {
            setEmpty(false)
        }
        try {
            const responce = await axios({
                method: 'post',
                url: `${baseURL}/account_block/accounts/validate`,
                headers: {
                    "Content-Type": "application/json",
                    "token": localStorage.getItem("token")
                },
                data: {
                    password: e.target.value,
                    token_type: 'suspend'
                }
            })

            setPasswordVerify(true);
            setValidateToken(responce?.data?.token)
        } catch (error) {
            console.log(error);
            setPasswordVerify(false);
        }
    }

    const onChange = (e: any) => {
        setPasswordValue(e.target.value);
    }

    const handleFinish = (value: any,) => {
        // console.log(value);
        if (ratingButtonValue === null) {
            setRatingShow(true);
        } else if (validateToken) {
            let formData = {
                token: validateToken,
                suspend_until: value.suspend_until._d.toISOString().slice(0, 10),
                reason:reason ==="custom"?value?.costumReason:value?.reason,
                description: value.thoughts,
                app_rating: ratingButtonValue
            }
            setRatingShow(false);
            onFinish(formData);
        }

    }

    const handleOnClick = (value: string) => {
        if (value === "Yes, Suspend Account") {
            setIsDisabled(false);
            if (isDisabled) {
                setPasswordVerify(null);
                setPasswordValue('');
                form.resetFields();
                setRatingButtonValue(null);
            }
        } else if (value === "No, Don't Suspend") {
            setIsDisabled(true);
            setPasswordVerify(null);
            setPasswordValue('');
            form.resetFields();
            setRatingButtonValue(null);
        }
    }

    const optionList = [
        {
            id: 1,
            name: "No, Don't Suspend",
            value: "no"
        },
        {
            id: 2,
            name: "Yes, Suspend Account",
            value: "yes"
        }
    ]

    const ratingNumber = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

    const config = {
        rules: [{ type: 'object' as const, required: true, message: 'Please select date!' }],
    };

    const d = new Date();
    function disabledDate(current: any) {
        // Can not select days before today and today
        let customDate = moment().format("YYYY-MM-DD");
        return current && current < moment(customDate, "YYYY-MM-DD");
    }
    return (
        <Space direction='vertical' style={{ width: "100%" }}>
            <Row gutter={[1, 1]}>
                <Col xs={{ span: 24 }} lg={{ span: 20 }} style={{ display: "flex", flexDirection: "column" }}>
                    <Title
                        style={{}}
                        level={5}
                    >
                        Suspend Account
                    </Title>
                    <div style={{ width: "100%", backgroundColor: "#ffffff", padding: "1rem", display: "flex", flexDirection: "column" }}>
                        <Paragraph
                            style={{ fontWeight: 500, fontSize: "12px", color: "rgba(151, 151, 151, 0.6)", marginBottom: "0px" }}
                        >
                            Are you sure you want to suspend your account?
                        </Paragraph>
                        <CustomRadioButton data={optionList} handleOnClick={handleOnClick} />
                        <div>
                            <Row>
                                <Col xs={{ span: 24 }} lg={{ span: 10 }}>
                                    <div style={{ display: "flex", flexDirection: "column", marginBottom: "15px" }}>
                                        <Paragraph
                                            style={{
                                                fontWeight: 500,
                                                fontSize: "12px",
                                                color: "rgba(151, 151, 151, 0.6)",
                                                marginBottom: "5px"
                                            }}
                                        >
                                            To suspend account, you need to confirm your password.
                                        </Paragraph>
                                        <Input.Password
                                            autoComplete="new-password"
                                            placeholder="Confirm Password"
                                            onInput={onBlur}
                                            data-test-id ="password"
                                            onChange={onChange}
                                            disabled={isDisabled}
                                            className="passwordInput"
                                            style={{
                                                borderRadius: "25px",
                                                border: "1px solid rgb(0, 105, 143,0.5)",
                                                padding:"8px"
                                            }}
                                            iconRender={(visible) => (visible ? <EyeFilled style={{ color: "#CFD9E2" }} /> : <EyeInvisibleFilled style={{ color: "#CFD9E2" }} />)}
                                            value={passwordValue}
                                            onClick={() => null}
                                            onFocus={() => null}
                                        />
                                        {
                                            passwordVerify !== null &&
                                            <Text type='secondary' style={{ fontSize: "14px", color: passwordVerify ? "#52c41a" : "#f5222d" }}>
                                                {passwordVerify ? "Verified" : empty ? "Required!" : "Invalid password"}
                                            </Text>
                                        }
                                    </div>
                                </Col>
                            </Row>
                            <Form
                                form={form}
                                name="basic"
                                labelCol={{ span: 5 }}
                                wrapperCol={{ span: 10 }}
                                onFinish={handleFinish}
                                onFinishFailed={onFinishFailed}
                                initialValues={{ suspend_until: moment() }}
                            >
                                <Paragraph
                                    style={{
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(151, 151, 151, 0.6)",
                                        marginBottom: "5px"
                                    }}
                                >
                                    Select date until which your account will be suspended
                                </Paragraph>
                                <Form.Item
                                    name="suspend_until"
                                    {...config}
                                    style={{ marginBottom: "15px" }}
                                >
                                    <DatePicker

                                        // defaultValue={moment()}
                                        disabledDate={disabledDate}
                                        disabled={isDisabled === false ? !passwordVerify === true : true}
                                        style={{ width: "100%", borderRadius: "25px", border: "1px solid rgb(0, 105, 143,0.5)",padding:"8px" }}
                                        format={"DD-MM-YYYY"}
                                        picker="date"
                                    />
                                </Form.Item>

                                <Paragraph
                                    style={{ fontWeight: 500, fontSize: "12px", color: "rgba(151, 151, 151, 0.6)", marginBottom: "5px" }}
                                >
                                    Provide Feedback
                                </Paragraph>
                                <Form.Item
                                name="reason"
                                rules={[{ required: true, message: 'Please add your reason!' }]}
                                style={{ marginBottom: "15px" }}
                            >
                               
                                 <Select
                                    placeholder="Reason for leaving applied"
                                    onChange={handleSelectChange}
                                    data-test-id="Reason"
                                    style={{ borderRadius: "25px", border: "1px solid rgb(0, 105, 143,0.5)" }}
                                    disabled={isDisabled === false ? !passwordVerify === true : true}
                                >
                                    <Select.Option value="This is temporary,I'll be back">This is temporary,I'll be back</Select.Option>
                                    <Select.Option value="My account was hacked">My account was hacked</Select.Option>
                                    <Select.Option value="I have a privacy concern">I have a privacy concern</Select.Option>
                                    <Select.Option value="I don't feel safe on Facebook">I don't feel safe on Applied</Select.Option>
                                    <Select.Option value="I get too many emails, invitations and requests from Applied">I get too many emails, invitations and requests from Applied </Select.Option>
                                    <Select.Option value="I spend too much time on Applied">I spend too much time on Applied</Select.Option>
                                    <Select.Option value="I don't find Applied useful">I don't find Applied useful</Select.Option>
                                    <Select.Option value="I have another Applied account">I have another Applied account</Select.Option>
                                    <Select.Option value="I don't understand how to use Applied">I don't understand how to use Applied</Select.Option>
                                    <Select.Option value="custom">Other, please explain further</Select.Option>
                                </Select>                                
                            </Form.Item>   
                           {reason ==="custom" && <Form.Item
                                name="costumReason"
                                rules={[{ required: true, message: 'Please add your reason!' }]}
                                style={{ marginBottom: "15px" }}
                            >
                                <Input
                                    disabled={isDisabled === false ? !passwordVerify === true : true}
                                    style={{ borderRadius: "25px", border: "1px solid rgb(0, 105, 143,0.5)",padding:"8px" }}
                                    placeholder="Reason for leaving applied"
                                    
                                />
                            </Form.Item> }
                                <Form.Item
                                    name="thoughts"
                                    rules={[{ required: true, message: 'Please add your thoughts' }]}
                                    style={{ marginBottom: "15px" }}
                                    data-test-id="thoughts"
                                >
                                    <TextArea
                                        disabled={isDisabled === false ? !passwordVerify === true : true}
                                        style={{ borderRadius: "5px", border: "1px solid rgb(0, 105, 143,0.5)", }}
                                        placeholder="Add your thoughts about applied"
                                        autoSize={{ minRows: 3, maxRows: 3 }}
                                    />
                                </Form.Item>

                                <Paragraph style={{ fontWeight: 500, fontSize: "12px", color: "rgba(151, 151, 151, 0.6)", marginBottom: "7px" }}>How likely would you recommend this app to your friend?</Paragraph>
                                <div style={{ marginBottom: "15px" }}>
                                    {
                                        ratingNumber.map((item: number) => {
                                            return (
                                                <Button
                                                    key={item}
                                                    disabled={isDisabled === false ? !passwordVerify === true : true}
                                                    style={{
                                                        marginRight: "5px",
                                                        borderRadius: "5px",
                                                        width: "2rem",
                                                        border: "1px solid rgb(0, 105, 143,0.5)",
                                                        backgroundColor: ratingButtonValue === item ? "#00698f" : "#ffffff",
                                                        color: ratingButtonValue === item ? "#ffffff" : "rgb(0, 105, 143,0.5)",
                                                        fontWeight: "bold",
                                                        fontSize: "14px",
                                                        padding: "0px"
                                                    }}
                                                    onClick={() => setRatingButtonValue(item)}
                                                >
                                                    {item}
                                                </Button>
                                            )
                                        })
                                    }
                                    {
                                        ratingShow ? <Paragraph
                                            style={{
                                                fontWeight: 400,
                                                fontSize: "14px",
                                                color: "#f5222d",
                                                marginBottom: "5px"
                                            }}
                                        >
                                            Please add your rating
                                        </Paragraph>
                                            : null
                                    }
                                </div>
                                <Form.Item style={{ marginBottom: "15px" }}>
                                    <Button disabled={isDisabled === false ? !passwordVerify === true : true} style={{ width: "100%", height: "2.5rem", fontSize: "14px", backgroundColor: "#00698f", borderRadius: "18px", color: "#ffffff" }} type="primary" htmlType="submit">
                                        Suspend Account
                                    </Button>
                                </Form.Item>
                            </Form>

                        </div>
                    </div>
                </Col>
            </Row>
            <AlertMessage
                isModalVisible={suspendMessage}
                subBody="We will get back to you"
                body={suspendMessage}
                failureModal={suspendMessage === "Account successfully suspended"}
                onOk={handleOk}
            />
        </Space>
    )
    // Customizable Area End
}

export default SuspendAccount;
