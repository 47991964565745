// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import { ModalContainer } from 'react-router-modal';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';
import AlertBlock from '../../blocks/alert/src/AlertBlock.web';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import './styleSheet.css'
// import InitialLoadNotification from '../../blocks/PushNotifications/src/InitialLoadNotification.web';

// import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
// import CommunityForum from '../../blocks/CommunityForum/src/CommunityForum';
// import ContactsIntegration from '../../blocks/ContactsIntegration/src/ContactsIntegration';
// import Categoriessubcategories from '../../blocks/categoriessubcategories/src/Categoriessubcategories';
// import Payments from '../../blocks/Payments/src/Payments';
// import Notifications from '../../blocks/Notifications/src/Notifications';
// import Videos from '../../blocks/Videos/src/Videos';
// import SocialMediaAccountRegistrationScreen from '../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen';
// import BulkUploading from '../../blocks/BulkUploading/src/BulkUploading';
// import RolesPermissions from '../../blocks/RolesPermissions/src/RolesPermissions';
// import Share from '../../blocks/Share/src/Share';
// import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
// import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
// import NewPassword from "../../blocks/forgot-password/src/NewPassword";
// import VideoManagement from '../../blocks/VideoManagement/src/VideoManagement';
// import CustomisableUserSubscriptions from '../../blocks/CustomisableUserSubscriptions/src/CustomisableUserSubscriptions';
// import Download from '../../blocks/Download/src/Download';
// import CvresumeCandidateManagement from '../../blocks/CvresumeCandidateManagement/src/CvresumeCandidateManagement';
// import Groups from '../../blocks/Groups/src/Groups';
// import Contactus from '../../blocks/contactus/src/Contactus';
// import AddContactus from '../../blocks/contactus/src/AddContactus';
// import AccountScoreranking from '../../blocks/AccountScoreranking/src/AccountScoreranking';
// import Comments from '../../blocks/Comments/src/Comments';
// import TargetedFeed from '../../blocks/TargetedFeed/src/TargetedFeed';
// import EmailAccountLoginBlock from '../../blocks/email-account-login/src/EmailAccountLoginBlock';
// import PushNotifications from '../../blocks/PushNotifications/src/PushNotifications';
// import GraphicalCharts from '../../blocks/GraphicalCharts/src/GraphicalCharts';
// import QuestionBank from '../../blocks/QuestionBank/src/QuestionBank';
// import PhoneNumberInput from '../../blocks/mobile-account-registration/src/mobile-input/PhoneNumberInput';
// import AdditionalDetailForm from '../../blocks/mobile-account-registration/src/user-additional-detail-input/AdditionalDetailForm';
// import CountryCodeSelector from '../../blocks/country-code-selector/src/CountryCodeSelector';
// import SelfdestructMessaging from '../../blocks/SelfdestructMessaging/src/SelfdestructMessaging';
// import MobileAccountLoginBlock from '../../blocks/mobile-account-login/src/MobileAccountLoginBlock';
// import ConversationThreading from '../../blocks/ConversationThreading/src/ConversationThreading';
// import EmailAccountRegistration from '../../blocks/email-account-registration/src/EmailAccountRegistration';
// import AdminConsole from '../../blocks/AdminConsole/src/AdminConsole';
// import Search from '../../blocks/Search/src/Search';
// import Invitefriends from '../../blocks/invitefriends/src/Invitefriends';
// import SearchEngineOptimisationseo from '../../blocks/SearchEngineOptimisationseo/src/SearchEngineOptimisationseo';
// import ContentModeration from '../../blocks/ContentModeration/src/ContentModeration';
// import PaymentAdmin from '../../blocks/PaymentAdmin/src/PaymentAdmin';
// import CustomizableQuestionnaire from '../../blocks/CustomizableQuestionnaire/src/CustomizableQuestionnaire';
// import PhotoLibrary from '../../blocks/PhotoLibrary/src/PhotoLibrary';
// import MailingListIntegration from '../../blocks/MailingListIntegration/src/MailingListIntegration';
// import AutomaticReminders from '../../blocks/AutomaticReminders/src/AutomaticReminders';
// import PledgeTracking from '../../blocks/PledgeTracking/src/PledgeTracking';
// import OTPInputAuth from '../../blocks/otp-input-confirmation/src/OTPInputAuth';
// import PrivacySettings from '../../blocks/PrivacySettings/src/PrivacySettings';
// import AddFriends from '../../blocks/AddFriends/src/AddFriends';
// import PostCreation from '../../blocks/postcreation/src/PostCreation';
// import Posts from '../../blocks/postcreation/src/Posts';
// import PostDetails from '../../blocks/postcreation/src/PostDetails';
// import RequestManagement from '../../blocks/RequestManagement/src/RequestManagement';
// import Subscriptions from '../../blocks/Subscriptions/src/Subscriptions';
// import Mentionstagging from '../../blocks/Mentionstagging/src/Mentionstagging';
// import EmailNotifications from '../../blocks/EmailNotifications/src/EmailNotifications';
// import SocialMediaAccountLoginScreen from '../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen';
// import ApiIntegration from '../../blocks/ApiIntegration/src/ApiIntegration';
// import UserProfileBasicBlock from '../../blocks/user-profile-basic/src/UserProfileBasicBlock';
// import Reviews from '../../blocks/Reviews/src/Reviews';
// import Login from '../../blocks/social-media-account-login/src/Login';

import LoginWeb from '../../blocks/social-media-account/src/Login.web';
import CreateAccountWeb from '../../blocks/social-media-account-registration/src/createAccount.web';
import VerifyEmail from '../../blocks/social-media-account-registration/src/verifyEmail.web';
import VerifyPhone from '../../blocks/social-media-account-registration/src/verifyPhone.web';
import VerificationSuccess from '../../blocks/social-media-account-registration/src/verificationSuccess.web';
import VerificationError from '../../blocks/social-media-account-registration/src/verificationError.web';
import ForgotPasswordWeb from '../../blocks/forgot-password/src/forgotPassword.web';
import VerifyCode from '../../blocks/forgot-password/src/verifyCode.web';
import ResetPassword from '../../blocks/forgot-password/src/resetPassword.web';
import ResetPasswordSuccess from '../../blocks/forgot-password/src/passwordResetSuccess.web';
import ResetPasswordError from '../../blocks/forgot-password/src/passwordRestError.web';
import ProfileSetupBasicBusiness from '../../blocks/user-profile-basic/src/bussiness/ProfileSetupBasic.web';
import ProfileSetupOverView from '../../blocks/user-profile-basic/src/bussiness/ProfileSetupOverview.web';
import ProfileSetupBasicBusinessHistory from '../../blocks/user-profile-basic/src/bussiness/ProfileSetupHistory.web';
import SelectIndustry from '../../blocks/social-media-account-registration/src/selectIndustry.web';
import CreatePersonalProfile from '../../blocks/user-profile-basic/src/personal/personalBasicProfile.web';
import ProfileExperience from '../../blocks/user-profile-basic/src/personal/personalExperience.web';
import CreateCertificate from '../../blocks/user-profile-basic/src/personal/addCertificate.web';
import CreateEducation from '../../blocks/user-profile-basic/src/personal/addEducation.web';
import CreateLanguageInterest from '../../blocks/user-profile-basic/src/personal/addLanguage.web';
import CreateGeneralInformation from '../../blocks/user-profile-basic/src/personal/addGeneralInformation.web';
import MyNetwork from '../../blocks/invitefriends/src/network/myNetwork.web';
import MyNetworkDetail from '../../blocks/invitefriends/src/network/MyNetworkDetail.web';

import EditPersonalProfile from '../../blocks/user-profile-basic/src/personal/EditPersonalProfile.web';
import EditProfileExperience from '../../blocks/user-profile-basic/src/personal/EditPersonalExperience.web';
import EditCertificate from '../../blocks/user-profile-basic/src/personal/EditCertificate.web';
import EditEducation from '../../blocks/user-profile-basic/src/personal/EditEducation.web';
import EditLanguageInterest from '../../blocks/user-profile-basic/src/personal/EditLanguageInterest.web';
import EditGeneralInformation from '../../blocks/user-profile-basic/src/personal/EditGeneralInformation.web';

import ProfileSetupBasicBusinessV2 from '../../blocks/user-profile-basic/src/bussiness/ProfileSetupBasicV2.web';
import ProfileSetupBasicBusinessOverviewV2 from '../../blocks/user-profile-basic/src/bussiness/ProfileSetupOverviewV2.web';
import ProfileSetupBasicBusinessHistoryV2 from '../../blocks/user-profile-basic/src/bussiness/ProfileSetupHistoryV2.web';
import AllViewInvitation from '../../blocks/invitefriends/src/network/AllViewInvitation.web';
import AllViewNetwork from '../../blocks/invitefriends/src/network/AllViewNetwork.web';
import AllViewInvitePeople from '../../blocks/invitefriends/src/network/AllViewInvitepeople.web';
import PostRating from '../../blocks/CommunityForum/src/MyProfileRating/PostRating.web';
import PremiumAccount from '../../blocks/CommunityForum/src/MyProfileRating/PremiumAccount.web';
import UpgradeAccount from '../../blocks/CommunityForum/src/MyProfileRating/UpgradeAccount.web';
import Checkout from '../../blocks/CommunityForum/src/MyProfileRating/checkout.web';
import MySavedPosts from '../../blocks/CommunityForum/src/MyProfileRating/MySavedPosts.web';
import HidePosts from '../../blocks/CommunityForum/src/MyProfileRating/HidePosts.web';
import AnniversariesRating from '../../blocks/CommunityForum/src/MyProfileRating/AnniversariesRating.web';
import RatingReview from '../../blocks/CommunityForum/src/MyProfileRating/RatingReview.web';
import RatingSentRecivedView from '../../blocks/CommunityForum/src/MyProfileRating/RatingSentRecivedView.web';
import FollowersFollowing from '../../blocks/CommunityForum/src/MyProfileRating/FollowersFollowing.web';
import UserAnalytics from '../../blocks/CommunityForum/src/MyProfileRating/UserAnalytics.web.tsx'
import MyBusinessProfile from '../../blocks/CommunityForum/src/MyProfileRating/MyBusinessProfile.web';
import MyPersonalProfile from '../../blocks/CommunityForum/src/MyProfileRating/MyPersonalProfile.web';
import RatingSent from '../../blocks/CommunityForum/src/MyProfileRating/RatingSent.web';

import EditBusinessProfileBasic from '../../blocks/user-profile-basic/src/bussiness/EditBusinessProfileBasic.web';
import EditBusinessProfileOverview from '../../blocks/user-profile-basic/src/bussiness/EditBusinessProfileOverview.web';
import EditBusinessProfileHistory from '../../blocks/user-profile-basic/src/bussiness/EditBusinessProfileHistory.web';

import CommunityManegeRequest from '../../blocks/CommunityForum/src/CommunityManageRequest.web';
import CommunityBlock from '../../blocks/CommunityForum/src/CommunityBlock.web';
import CommunityBlockForum from '../../blocks/CommunityForum/src/CommunityBlockForum.web';
import UpcomingWorkAnniversaries from '../../blocks/CommunityForum/src/MyProfileRating/WorkAnniversaries.web';
import UpcomingBirthAnniversaries from '../../blocks/CommunityForum/src/MyProfileRating/BirthAnniverseries.web';
import CommunitySettings from '../../blocks/CommunityForum/src/admin/CommunitySettings.web';
import CommunitySetting from '../../blocks/CommunityForum/src/CommunitySetting.web';
import AllCommunity from "../../blocks/Groups/src/AllCommunity.web"
import HomeBlock from '../../blocks/CommunityForum/src/HomeBlock.web';
import SearchBlockWeb from '../../blocks/Search/src/SearchBlock.web';
import NotificationsWeb from '../../blocks/Notifications/src/Notification.web';
import QuestionnaireTabsWeb from 'blocks/CommunityForum/src/MyProfileRating/QuestionnaireTabs.web';
// import NotificationsWeb from '../../blocks/not';

import OtherBusinessProfile from '../../blocks/user-profile-basic/src/bussiness/OtherBusinessProfile.web';
import Anniversaries from '../../blocks/AutomaticReminders/src/Anniversaries.web';
import OtherPersonalProfile from '../../blocks/user-profile-basic/src/personal/OtherPersonalProfile.web';
import PrivacySettings from '../../blocks/PrivacySettings/src/Setting/PrivacySettings.web';
import TermsandConditions from '../../blocks/PrivacySettings/src/Setting/TermsandConditions.web';
import PrivacyPolicy from '../../blocks/PrivacySettings/src/Setting/PrivacyPolicy.web';
import PublicTermsandConditionsWeb from '../../blocks/PrivacySettings/src/Setting/TermsandConditions.web';
import DMCAPolicy from '../../blocks/PrivacySettings/src/Setting/DMCAPolicy.web';
import Disclaimer from '../../blocks/PrivacySettings/src/Setting/Disclaimer.web';
import CookiePolicy from '../../blocks/PrivacySettings/src/Setting/CookiePolicy.web';
import AcceptableUsePolicy from '../../blocks/PrivacySettings/src/Setting/AcceptableUsePolicy.web';
import Pdf from '../../blocks/PrivacySettings/src/Setting/Pdf.web';
import Bpdf from '../../blocks/PrivacySettings/src/Setting/Bpdf.web';

import viewPersonalPost from '../../blocks/CommunityForum/src/MyProfileRating/viewPersonalPost.web';
import PostFeedBlock from '../../blocks/CommunityForum/src/PostFeedBlock.web';
import FollowersAndFollowing from 'blocks/user-profile-basic/src/CommonUI/FollowersAndFollowing.web';
// chat
import ChatPage from 'blocks/SelfdestructMessaging/src/ChatScreen.web';
//about
import AboutPageWeb from '../../blocks/info-page/src/AboutPage.web.tsx';

const routeMap = {
    // CommunityForum: {
    //   component: CommunityForum,
    //   path: '/CommunityForum'
    // },
    // ContactsIntegration: {
    //   component: ContactsIntegration,
    //   path: '/ContactsIntegration'
    // },
    // Categoriessubcategories: {
    //   component: Categoriessubcategories,
    //   path: '/Categoriessubcategories'
    // },
    // Payments: {
    //   component: Payments,
    //   path: '/Payments'
    // },
    // Notifications: {
    //   component: Notifications,
    //   path: '/Notifications'
    // },
    // Videos: {
    //   component: Videos,
    //   path: '/Videos'
    // },
    // SocialMediaAccountRegistrationScreen: {
    //   component: SocialMediaAccountRegistrationScreen,
    //   path: '/SocialMediaAccountRegistrationScreen'
    // },
    // BulkUploading: {
    //   component: BulkUploading,
    //   path: '/BulkUploading'
    // },
    // RolesPermissions: {
    //   component: RolesPermissions,
    //   path: '/RolesPermissions'
    // },
    // Share: {
    //   component: Share,
    //   path: '/Share'
    // },
    // ForgotPassword:{
    //  component:ForgotPassword,
    // path:"/ForgotPassword"},
    // ForgotPasswordOTP:{
    //  component:ForgotPasswordOTP,
    // path:"/ForgotPasswordOTP"},
    // NewPassword:{
    //  component:NewPassword,
    // path:"/NewPassword"},
    // VideoManagement: {
    //   component: VideoManagement,
    //   path: '/VideoManagement'
    // },
    // CustomisableUserSubscriptions: {
    //   component: CustomisableUserSubscriptions,
    //   path: '/CustomisableUserSubscriptions'
    // },
    // Download: {
    //   component: Download,
    //   path: '/Download'
    // },
    // CvresumeCandidateManagement: {
    //   component: CvresumeCandidateManagement,
    //   path: '/CvresumeCandidateManagement'
    // },
    // Groups: {
    //   component: Groups,
    //   path: '/Groups'
    // },
    // Contactus: {
    //   component: Contactus,
    //   path: '/Contactus'
    // },
    // AddContactus: {
    //   component: AddContactus,
    //   path: '/AddContactus'
    // },
    // AccountScoreranking: {
    //   component: AccountScoreranking,
    //   path: '/AccountScoreranking'
    // },
    // Comments: {
    //   component: Comments,
    //   path: '/Comments'
    // },
    // TargetedFeed: {
    //   component: TargetedFeed,
    //   path: '/TargetedFeed'
    // },
    // EmailAccountLoginBlock: {
    //   component: EmailAccountLoginBlock,
    //   path: '/EmailAccountLoginBlock'
    // },
    // PushNotifications: {
    //   component: PushNotifications,
    //   path: '/PushNotifications'
    // },
    // GraphicalCharts: {
    //   component: GraphicalCharts,
    //   path: '/GraphicalCharts'
    // },
    // QuestionBank: {
    //   component: QuestionBank,
    //   path: '/QuestionBank'
    // },
    // PhoneNumberInput: {
    //   component: PhoneNumberInput,
    //   path: '/PhoneNumberInput'
    // },
    // AdditionalDetailForm: {
    //   component: AdditionalDetailForm,
    //   path: '/AdditionalDetailForm'
    // },
    // CountryCodeSelector: {
    //   component: CountryCodeSelector,
    //   path: '/CountryCodeSelector'
    // },
    // SelfdestructMessaging: {
    //   component: SelfdestructMessaging,
    //   path: '/SelfdestructMessaging'
    // },
    // MobileAccountLoginBlock: {
    //   component: MobileAccountLoginBlock,
    //   path: '/MobileAccountLoginBlock'
    // },
    // ConversationThreading: {
    //   component: ConversationThreading,
    //   path: '/ConversationThreading'
    // },
    // EmailAccountRegistration: {
    //   component: EmailAccountRegistration,
    //   path: '/EmailAccountRegistration'
    // },
    // AdminConsole: {
    //   component: AdminConsole,
    //   path: '/AdminConsole'
    // },
    // Search: {
    //   component: Search,
    //   path: '/Search'
    // },
    // Invitefriends: {
    //   component: Invitefriends,
    //   path: '/Invitefriends'
    // },
    // SearchEngineOptimisationseo: {
    //   component: SearchEngineOptimisationseo,
    //   path: '/SearchEngineOptimisationseo'
    // },
    // ContentModeration: {
    //   component: ContentModeration,
    //   path: '/ContentModeration'
    // },
    // PaymentAdmin: {
    //   component: PaymentAdmin,
    //   path: '/PaymentAdmin'
    // },
    // CustomizableQuestionnaire: {
    //   component: CustomizableQuestionnaire,
    //   path: '/CustomizableQuestionnaire'
    // },
    // PhotoLibrary: {
    //   component: PhotoLibrary,
    //   path: '/PhotoLibrary'
    // },
    // MailingListIntegration: {
    //   component: MailingListIntegration,
    //   path: '/MailingListIntegration'
    // },
    // AutomaticReminders: {
    //   component: AutomaticReminders,
    //   path: '/AutomaticReminders'
    // },
    // PledgeTracking: {
    //   component: PledgeTracking,
    //   path: '/PledgeTracking'
    // },
    // OTPInputAuth: {
    //   component: OTPInputAuth,
    //   path: '/OTPInputAuth'
    // },
    // PrivacySettings: {
    //   component: PrivacySettings,
    //   path: '/PrivacySettings'
    // },
    // AddFriends: {
    //   component: AddFriends,
    //   path: '/AddFriends'
    // },
    // PostCreation: {
    //   component: PostCreation,
    //   path: '/PostCreation'
    // },
    // Posts: {
    //   component: Posts,
    //   path: '/Posts'
    // },
    // PostDetails: {
    //   component: PostDetails,
    //   path: '/PostDetails'
    // },
    // RequestManagement: {
    //   component: RequestManagement,
    //   path: '/RequestManagement'
    // },
    // Subscriptions: {
    //   component: Subscriptions,
    //   path: '/Subscriptions'
    // },
    // Mentionstagging: {
    //   component: Mentionstagging,
    //   path: '/Mentionstagging'
    // },
    // EmailNotifications: {
    //   component: EmailNotifications,
    //   path: '/EmailNotifications'
    // },
    // SocialMediaAccountLoginScreen: {
    //   component: SocialMediaAccountLoginScreen,
    //   path: '/SocialMediaAccountLoginScreen'
    // },
    // ApiIntegration: {
    //   component: ApiIntegration,
    //   path: '/ApiIntegration'
    // },
    // UserProfileBasicBlock: {
    //   component: UserProfileBasicBlock,
    //   path: '/UserProfileBasicBlock'
    // },
    // Reviews: {
    //   component: Reviews,
    //   path: '/Reviews'
    // },

    // Home: {
    //   component: SocialMediaAccountRegistrationScreen,
    //   path: '/',
    //   exact: true
    // },
    // InfoPage: {
    //   component: InfoPage,
    //   path: '/InfoPage'
    // },
    LoginWeb: {
        component: LoginWeb,
        // path: '/login'
        path: '/',
        exact: true,
        type: 'Auth'
    },
    CreateAccountWeb: {
        component: CreateAccountWeb,
        path: '/signup',
        type: 'Auth'
    },
    SelectIndustry: {
        component: SelectIndustry,
        path: '/select/industry',
        type: 'Auth'
    },
    VerifyEmail: {
        component: VerifyEmail,
        path: '/email/varification',
        type: 'Auth'
    },
    VerifyPhone: {
        component: VerifyPhone,
        path: '/phone/varification',
        type: 'Auth'
    },
    VerificationSuccess: {
        component: VerificationSuccess,
        path: '/varification/success',
        type: 'Auth'
    },
    VerificationError: {
        component: VerificationError,
        path: '/varification/error',
        type: 'Auth'
    },
    ForgotPasswordWeb: {
        component: ForgotPasswordWeb,
        path: '/forgot/password',
        type: 'Auth'
    },
    VerifyCode: {
        component: VerifyCode,
        path: '/verify/password/otp',
        type: 'Auth'
    },
    ResetPassword: {
        component: ResetPassword,
        path: '/reset/password',
        exact: true,
        type: 'Auth'
    },
    ResetPasswordSuccess: {
        component: ResetPasswordSuccess,
        path: '/reset/password/success',
        type: 'Auth'
    },
    ResetPasswordError: {
        component: ResetPasswordError,
        path: '/reset/password/error',
        type: 'Auth'
    },
    ProfileSetupBasicBusiness: {
        component: ProfileSetupBasicBusiness,
        path: '/add_profile'
    },
    ProfileSetupOverView: {
        component: ProfileSetupOverView,
        path: '/profile_overview'
    },
    ProfileSetupBasicBusinessHistory: {
        component: ProfileSetupBasicBusinessHistory,
        path: '/profile_history'
    },
    ProfileSetupBasicBusinessV2: {
        component: ProfileSetupBasicBusinessV2,
        path: '/setup/profile/business',
        exact: true,
        type: 'Auth'
    },
    ProfileSetupBasicBusinessOverviewV2: {
        component: ProfileSetupBasicBusinessOverviewV2,
        path: '/setup/profile/business/about',
        type: 'Auth'
    },
    ProfileSetupBasicBusinessHistoryV2: {
        component: ProfileSetupBasicBusinessHistoryV2,
        path: '/setup/profile/business/overview',
        type: 'Auth'
    },
    CreatePersonalProfile: {
        component: CreatePersonalProfile,
        path: '/setup/profile/personal',
        exact: true,
        type: 'Auth'
    },
    ProfileExperience: {
        component: ProfileExperience,
        path: '/setup/profile/personal/experience',
        type: 'Auth'
    },
    CreateCertificate: {
        component: CreateCertificate,
        path: '/setup/profile/personal/achievements',
        type: 'Auth'
    },
    CreateEducation: {
        component: CreateEducation,
        path: '/setup/profile/personal/education',
        type: 'Auth'
    },
    CreateLanguageInterest: {
        component: CreateLanguageInterest,
        path: '/setup/profile/personal/about',
        type: 'Auth'
    },
    CreateGeneralInformation: {
        component: CreateGeneralInformation,
        path: '/setup/profile/personal/generalinformation',
        type: 'Auth'
    },
    MyNetwork: {
        component: MyNetwork,
        path: '/myNetwork',
        exact: true
    },
    MyNetworkDetail: {
        component: MyNetworkDetail,
        path: '/myNetwork/:name'
    },
    AllViewInvitation: {
        component: AllViewInvitation,
        path: '/AllView/Invitation'
    },
    AllViewInvitePeople: {
        component: AllViewInvitePeople,
        path: '/AllView/InvitePeople'
    },
    AllViewNetwork: {
        component: AllViewNetwork,
        path: '/AllView/Network'
    },
    AlertWeb: {
        component: AlertBlock,
        path: '*/AlertWeb',
        modal: true
    },
    WorkAnniversaries: {
        component: UpcomingWorkAnniversaries,
        path: '/upcoming/anniversaries'
    },
    CommunityForum: {
        component: CommunityBlockForum,
        path: '/community/block/Forum',
        exact: true
    },
    BirthAnniversaries: {
        component: UpcomingBirthAnniversaries,
        path: '/upcoming/BirthAnniversaries'
    },
    home: {
        component: HomeBlock,
        path: '/home',
        exact: true
    },
    UpdateQuestionnaire: {
        component: QuestionnaireTabsWeb,
        path: '/myProfile/updateQuestionnaire'
    },
    // UserFeed: {
    //   component: UserFeedBlock,
    //   path: '/userfeed/block'
    // },
    postRating: {
        component: PostRating,
        path: '/Post/Rating'
    },
    PremiumAccount: {
        component: PremiumAccount,
        path: '/Premium/Account'
    },
    UpgradeAccount: {
        component: UpgradeAccount,
        path: '/UpgradeAccount'
    },
    Checkout: {
        component: Checkout,
        path: '/checkout'
    },
    MySavedPosts: {
        component: MySavedPosts,
        path: '/MySaved/Posts'
    },
    HidePosts: {
        component: HidePosts,
        path: '/hide/Posts'
    },
    AnniversariesRating: {
        component: AnniversariesRating,
        path: '/Anniversaries_Rating'
    },
    RatingReview: {
        component: RatingReview,
        path: '/Rating',
        exact: true
    },
    RatingSentRecivedView: {
        component: RatingSentRecivedView,
        path: '/Rating/View'
    },

    FollowersFollowing: {
        component: FollowersFollowing,
        path: '/Followers/Following'
    },
    UserAnalytics : {
        component: UserAnalytics,
        path: '/UserAnalytics'
    },
    MyBusinessProfile: {
        component: MyBusinessProfile,
        path: '/MyBusiness/Profile'
    },
    OtherBusinessProfile: {
        component: OtherBusinessProfile,
        path: '/other/business/profile'
    },
    OtherPersonalProfile: {
        component: OtherPersonalProfile,
        path: '/other/personal/profile'
    },
    FollowersAndFollowing: {
        component: FollowersAndFollowing,
        path: '/home/followers/following'
    },
    ViewPersonalPost: {
        component: viewPersonalPost,
        path: `/viewPost/:id`
    },
    PostFeedBlock: {
        component: PostFeedBlock,
        path: `/post/:id`
    },
    PrivacySettings: {
        component: PrivacySettings,
        path: `/settings/tab/:tabId`
    },
    TermsandConditions: {
        component: TermsandConditions,
        path: '/terms&conditions',
        type: 'Auth',
        exact: true,
    },
    PrivacyPolicy: {
        component: PrivacyPolicy,
        path: '/privacypolicy'
    },
    DMCAPolicy: {
        component: DMCAPolicy,
        path: '/dmcapolicy'
    },
    Disclaimer: {
        component: Disclaimer,
        path: '/disclaimer'
    },
    CookiePolicy: {
        component: CookiePolicy,
        path: '/cookiepolicy'
    },
    AcceptableUsePolicy: {
        component: AcceptableUsePolicy,
        path: '/acceptableusepolicy'
    },
    Pdf: {
        component: Pdf,
        path: '/Pdf'
    },
    Bpdf: {
        component: Bpdf,
        path: '/Bpdf'
    },
    EditBusinessProfileBasic: {
        component: EditBusinessProfileBasic,
        path: '/edit/profile/business'
    },
    EditBusinessProfileOverview: {
        component: EditBusinessProfileOverview,
        path: '/edit/BusinessProfile/about'
    },
    EditBusinessProfileHistory: {
        component: EditBusinessProfileHistory,
        path: '/edit/BusinessProfile/Overview'
    },
    EditPersonalProfile: {
        component: EditPersonalProfile,
        path: '/edit/profile/personal',
        exact: true
    },
    EditProfileExperience: {
        component: EditProfileExperience,
        path: '/edit/profile/personal/experience'
    },
    EditCertificate: {
        component: EditCertificate,
        path: '/edit/profile/personal/achievements'
    },
    EditEducation: {
        component: EditEducation,
        path: '/edit/profile/personal/education'
    },
    EditLanguageInterest: {
        component: EditLanguageInterest,
        path: '/edit/profile/personal/about'
    },
    EditGeneralInformation: {
        component: EditGeneralInformation,
        path: '/edit/profile/personal/generalinformation'
    },
    CommunityManegeRequest: {
        component: CommunityManegeRequest,
        path: '/Community/ManegeRequest'
    },
    SearchResults: {
        component: SearchBlockWeb,
        path: '/search'
    },
    // Dashboard: {
    //   component: HomeFeedBlock,
    //   path: '/dashboard'
    // },
    Community: {
        component: CommunityBlock,
        path: '/community/block',
        exact: true
    },
    CommunitySettingsPage: {
        component: CommunitySettings,
        path: '/community/admin-settings'
    },
    CommunityViewAllPage: {
        component: AllCommunity,
        path: '/community/all'
    },
    Communitytest: {
        component: CommunitySetting,
        path: '/community/test'
    },
    Notifications: {
        component: NotificationsWeb,
        path: '/notifications',
        type: 'Auth'
    },
    // Home: {
    //   component: SocialMediaAccountRegistrationScreen,
    //   path: '/',
    //   exact: true
    // },
    // InfoPage: {
    //   component: InfoPage,
    //   path: '/InfoPage'
    // },
    // postRating: {
    //     component: PostRating,
    //     path: '/Post/Rating'
    // },
    // PremiunAccount: {
    //     component: PremiunAccount,
    //     path: '/Premiun/Account'
    // },
    // MySavedPosts: {
    //     component: MySavedPosts,
    //     path: '/MySaved/Posts'
    // },
    // AnniversariesRating: {
    //     component: AnniversariesRating,
    //     path: '/Anniversaries_Rating'
    // },
    // RatingReview: {
    //     component: RatingReview,
    //     path: '/Rating'
    // },
    // MyBusinessProfile: {
    //     component: MyBusinessProfile,
    //     path: '/MyBusiness/Profile'
    // },
    MyPersonalProfile: {
        component: MyPersonalProfile,
        path: '/MyPersonalProfile/Profile'
    },
    RatingSent: {
        component: RatingSent,
        path: '/MyPersonalProfile/RatingSent'
    },
    ChatPage: {
        component: ChatPage,
        path: '/chat'
    },
    PublicTermsandConditionsWeb: {
        component: PublicTermsandConditionsWeb,
        path: '/terms-and-conditions',
        exact: true,
        type: 'Auth'
    },
    AboutPageWeb: {
        component: AboutPageWeb,
        path: '/about',
        exact: true,
        type: 'Auth'
    },
    PrivacyPolicy: {
        component: PrivacyPolicy,
        path: '/privacy-policy',
        exact: true,
        type: 'Auth'
    },
    // EditBusinessProfileBasic: {
    //     component: EditBusinessProfileBasic,
    //     path: '/edit/profile/business'
    // },
    // EditBusinessProfileOverview: {
    //     component: EditBusinessProfileOverview,
    //     path: '/edit/BusinessProfile/about'
    // },
    // EditBusinessProfileHistory: {
    //     component: EditBusinessProfileHistory,
    //     path: '/edit/BusinessProfile/Overview'
    // },
    // EditPersonalProfile: {
    //     component: EditPersonalProfile,
    //     path: '/edit/profile/personal',
    //     exact: true
    // },
    // EditProfileExperience: {
    //     component: EditProfileExperience,
    //     path: '/edit/profile/personal/experience'
    // },
    // EditCertificate: {
    //     component: EditCertificate,
    //     path: '/edit/profile/personal/achievements'
    // },
    // EditEducation: {
    //     component: EditEducation,
    //     path: '/edit/profile/personal/education'
    // },
    // EditLanguageInterest: {
    //     component: EditLanguageInterest,
    //     path: '/edit/profile/personal/about'
    // },
    // EditGeneralInformation: {
    //     component: EditGeneralInformation,
    //     path: '/edit/profile/personal/generalinformation'
    //     // path: '/EditBusiness_Profile_Overview'
    // },
    // EditBusinessProfileHistory: {
    //     component: EditBusinessProfileHistory,
    //     path: '/EditBusiness_Profile_History'
    // },
    // CommunityManegeRequest: {
    //     component: CommunityManegeRequest,
    //     path: '/Community/ManegeRequest'
    // },
    // SearchResults: {
    //     component: SearchBlockWeb,
    //     path: '/search'
    // },
    // Dashboard: {
    //   component: HomeFeedBlock,
    //   path: '/dashboard'
    // },
    // Community: {
    //     component: CommunityBlock,
    //     path: '/community/block',
    //     exact: true
    // },
    // CommunitySettingsPage: {
    //     component: CommunitySettings,
    //     path: '/community/admin-settings'
    // },
    // CommunityViewAllPage: {
    //     component: AllCommunityBlock,
    //     path: '/community/all'
    // },
    // Communitytest: {
    //     component: CommunitySetting,
    //     path: '/community/test'
    // },
    // Notifications: {
    //     component: NotificationsWeb,
    //     path: '/notifications'
    // }
    Anniversaries: {
        component: Anniversaries,
        path: '/myProfile/anniversaries'
    }
};

// const firebaseAPI = firebase.initializeApp({
//   apiKey: "AIzaSyD5znbK_bxSVQ1IDW4HGePhmU-UBY0I5go",
//   authDomain: "thermal-talon-316017.firebaseapp.com",
//   projectId: "thermal-talon-316017",
//   storageBucket: "thermal-talon-316017.appspot.com",
//   messagingSenderId: "1347984169",
//   appId: "1:1347984169:web:bcb2baf3d77690deef7538",
//   measurementId: "G-3Y65XC3Q2N"
// });

// ******************firebase messging************
// const messaging = firebase.messaging();

// const { REACT_APP_VAPID_KEY } = process.env;
// const publicKey = REACT_APP_VAPID_KEY;

// export const getToken = async (setTokenFound) => {
//   let currentToken = "";

//   try {
//     currentToken = await messaging.getToken({ vapidKey: publicKey });
//     if (currentToken) {
//       setTokenFound(true);
//     } else {
//       setTokenFound(false);
//     }
//   } catch (error) {
//     console.log("An error occurred while retrieving token. ", error);
//   }

//   return currentToken;
// };

// export const onMessageListener = () =>
//   new Promise((resolve) => {
//     messaging.onMessage((payload) => {
//       resolve(payload);
//     });
//   });

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            title: '',
            body: ''
        };
    }

    // openNotification = () => {
    //   notification.open({
    //     message: this.state?.title ? this.state?.title : "Some title",
    //     description: this.state?.body ? this.state?.body : "Some body",
    //     onClick: () => {
    //       console.log("Notification Clicked!");
    //     }
    //   });
    // };

    render() {
        // const defaultAnalytics = firebaseAPI.analytics();
        // defaultAnalytics.logEvent('APP_Loaded');
        // const customerInfo = JSON.parse(localStorage.getItem('customer_info'));
        // const StripePubKey = customerInfo?.publishableKey
        //     ? customerInfo?.publishableKey
        //     : JSON.parse(localStorage.getItem('customer_info'))?.publishableKey;
        // const stripePromise = loadStripe(StripePubKey);
        // onMessageListener().then((payload) => {
        //   this.setState({ show: true });
        //   this.setState({
        //     title: payload?.notification?.title,
        //     body: payload?.notification?.body,
        //   });
        //   console.log(payload);
        // }).catch((err) => console.log("failed: ", err));
        return (
            <View>
                {/* {this.state?.show && this.openNotification()}
        <InitialLoadNotification /> */}
                {/* <TopNav /> */}
                {/* <Elements stripe={stripePromise}> */}
                    {WebRoutesGenerator({ routeMap: routeMap })}
                {/* </Elements> */}
                {/* <ModalContainer /> */}
            </View>
        );
    }
}

export default App;
