import React, { useState, useEffect } from 'react'
import { Row, Col, Modal, Button } from 'antd';
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import ProfileRatingViewsPercentage from './ProfileRatingViewsPercentage.web';
import ProfileRatingAppliedProgressBar from './profileRatingAplliedProgressBar.web';
import { AlertMessage } from "../../../alert/src/AlertMessage.web";
import NPSChartView from './NPS_Chart.web';
import {
    Typography,
    CircularProgress,
    Box
} from "@material-ui/core";
// Customizable Area Start
import { Progress } from 'antd';
import SliderView from './SliderView.web';
//Customizable Area End

const arrayData = (data: any) => {
   
    const array = [

        {
            id: 3,
            title: data?.attributes?.role === "business" ? "Employees View" : "Customer View",
            ratingName: data?.attributes?.role === "business" ? "Employees Rating" : "Customer View",
            subTitle: data?.attributes?.role === "business" ? "Employees View" : "Customer View",
            value: data?.attributes?.role === "business" ? Math.round(data?.attributes?.rating['Employee'][7].score) : Math.round(data?.attributes?.rating['Customer'][7].score),
            size: 108,
            data: data?.attributes?.role === "business" ? data?.attributes?.rating['Employee'] : data?.attributes?.rating['Customer']
        },
        {
            id: 4,
            title: "Community View",
            ratingName: data?.attributes?.role === "business" ? "Community Rating" : "Community View",
            subTitle: "Community View",
            value: Math.round(data?.attributes?.rating['Community'][7].score),
            size: 108,
            data: data?.attributes?.rating['Community']
        }
    ]

    return array
}

const array = (data: any) => {
    const progressCircleBarData = [
        {
            id: 1,
            title: data?.attributes?.role === "business" ? "Customer View" : "Leadership View",
            ratingName: data?.attributes?.role === "business" ? "Customer Rating" : "Leadership View",
            subTitle: data?.attributes?.role === "business" ? "Customer View" : "Leadership View",
            value: data?.attributes?.role === "business" ? Math.round(data?.attributes?.rating['Customer'][7].score) : Math.round( data?.attributes?.rating['Leader'][7].score),
            size: 108,
            data: data?.attributes?.role=== "business" ? data?.attributes?.rating['Customer'] : data?.attributes?.rating['Leader']
        },
        {
            id: 2,
            title: data?.attributes?.role === "business" ? "Supplier View" : "Team View",
            ratingName: data?.attributes?.role === "business" ? "Supplier Rating" : "Team View",
            subTitle: data?.attributes?.role === "business" ? "Supplier View" : "Team View",
            value: data?.attributes?.role === "business" ? Math.round(data?.attributes?.rating['Supplier'][7].score) : Math.round(data?.attributes?.rating['Team'][7].score),
            size: 108,
            data: data?.attributes?.role=== "business" ? data?.attributes?.rating['Supplier'] : data?.attributes?.rating['Team']
        },
    ];

    const arrayNew = arrayData(data)
    const mergedArray = [...progressCircleBarData, ...arrayNew]


    return mergedArray
}
const RatingView = (props: any) => {
    // Customizable Area Start
   
    let initialRating = {
        detractors: 0,
        passives: 0,
        promoters: 0,
        noValue: 1,
        score: '12'
    }
    const [modal, setModal] = useState(false)
    const [activeTab, setActiveTab] = useState<any>({})
    const [npsData, setNpsData] = useState<any>({})
    const { data, isPrivate } = props;
   
   
    const progressLinearBarData = data?.attributes?.rating['Applied View Rating'];
   

    const progressCircleBarData = array(data)

    useEffect(() => {
        setNpsData(initialRating)
    }, [])

    const handleActiveTab = (item: any) => {
        setActiveTab(item)
    }

    return (
        <>
            <div
                style={{
                    width: "100%",
                    height: "100%",
                    marginBottom: "1rem"
                }}
            >
                <Row justify="center" style={{ width: "100%", flexDirection: "column", gap: "12px" }}>

                </Row>
                <br />
                <div style={{ height: '100%' }}>
                    <Row gutter={16}>
                        <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 22 }} xl={{ span: 22 }}>
                            <Row style={{ gap: "15px" }}>
                                {
                                    progressCircleBarData.map((item) => {
                                        return (
                                            <Col xs={24} md={12} xl={5} lg={6} key={item.id}>
                                                <div
                                                    style={{
                                                        background: activeTab.title === item.title ? '#dee5eb' : '#fff',
                                                        cursor: "pointer",
                                                        border: '1px solid rgb(0,0,0,0.05)'
                                                    }}
                                                    key={item.id}
                                                    onClick={() => {
                                                        handleActiveTab(item)
                                                        if (isPrivate) {

                                                            setModal(true)
                                                            // AlertError({
                                                            //     title:''
                                                            // })
                                                        }
                                                        else {
                                                            console.log("hello")

                                                        }
                                                    }}
                                                // onClick={() => handleRatingView(item.data, item.ratingName)}
                                                >

                                                    <ProfileRatingViewsPercentage
                                                        title={item.title}
                                                        value={item.value}
                                                        subTitle={item.subTitle}
                                                        color={item.value < 59 ? "#b24040" : item.value < 79 ? "#3a87a8" : "#3c9b83"}
                                                        size={item.size}
                                                    />

                                                </div>
                                            </Col>
                                        )
                                    })
                                }
                            </Row>
                        </Col>

                    </Row>
                </div>
                <div style={{ height: '100%', width: "100%", marginTop: '24px' }} >
                    <Row style={{}}>
                        {/* <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 22 }} xl={{ span: 22 }}> */}
                        <Col
                            xs={{ span: 24 }}
                            lg={{ span: 12 }}

                        >
                            <div
                                style={{ width: "90%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", gap: "10px", marginLeft: "12px" }}
                            >
                                <h4 style={{ color: "rgba(0, 0, 0)", fontWeight: 600, fontSize: "15px" }}>Applied View</h4>
                                <Typography variant='body2' style={{ opacity: 0.9, fontSize: "14px" }}>
                                    Applied Score: <span style={{ color: "#3a87a8", fontWeight: "bold" }}>
                                        {
                                            data?.attributes?.role === "business" ?
                                                Math.round(data?.attributes?.rating['Applied View Rating'][7]?.score)
                                                : Math.round(data?.attributes?.rating['Applied View Rating'][data?.attributes?.rating['Applied View Rating'].length-1]?.score)
                                        }%</span>
                                </Typography>
                            </div>
                            <div
                                style={{
                                    width: "100%",
                                    borderRadius: "5px",
                                    overflow: "hidden",
                                    padding: "25px 30px 13px 30px",
                                    backgroundColor: "rgba(255, 255, 255, 1)"
                                }}
                            >
                                {

                                    progressLinearBarData?.map((item: any, id: number) => {
                                        if (item.name) {
                                            return (
                                                <div
                                                    className="other_Rating"
                                                    key={id}
                                                    onClick={() => {
                                                        // console.log(item.score, "asdgadghal llasdla")
                                                        if (item.score) {
                                                            setNpsData({ ...item.nps_rating[0], score: item.score })
                                                        } else {
                                                            setNpsData(initialRating)
                                                        }
                                                    }}
                                                >
                                                    <ProfileRatingAppliedProgressBar
                                                        key={id}
                                                        description={item.description}
                                                        title={item.name}
                                                        value={item.score === null ? 0 : Math.round(item.score)}
                                                        color={item.score < 59 ? "#b24040" : item.score < 79 ? "#3a87a8" : "#3c9b83"}
                                                    />
                                                </div>
                                            )
                                        } else {

                                            return null
                                        }
                                    })
                                }
                            </div>
                        </Col>
                        <Col
                            xs={{ span: 24 }}
                            lg={{ span: 12 }}

                        >

                            <NPSChartView
                                npsData={npsData}
                            />
                        </Col>
                        {data?.attributes?.friend && activeTab?.data ?
                            <Col xs={24} md={24} xl={16} lg={22} >
                                <div style={{ background: '#fff', height: '100%', width: '99%', zIndex: 100 }}>
                                    <div style={{ background: "rgb(245, 245, 245)", padding: '8px 24px' }}>
                                        <h3 style={{ color: "#1890ff", fontSize: 14, }}>{activeTab.ratingName}</h3>
                                    </div>

                                    {activeTab?.data?.slice(0, activeTab?.data?.length - 1).map((item: any) => {
                                        return (
                                            <div style={{ padding: '0px 24px', display: 'flex', alignItems: 'center', marginTop: 16 }}>

                                                <Box position="relative" display="inline-flex"
                                                    onClick={() => {
                                                        // console.log(item.nps_rating[0])
                                                        // console.log(item.score, "asdgadghal llasdla")
                                                        if (item.score) {
                                                            setNpsData({ ...item.nps_rating[0], score: item.score })
                                                        } else {
                                                            setNpsData(initialRating)
                                                        }
                                                    }}
                                                >

                                                    <Progress
                                                        type="circle"
                                                        strokeColor={item.score < 59 ? "#b24040" : item.score < 79 ? "#3a87a8" : "#3c9b83"}
                                                        strokeWidth={9}
                                                        width={50}

                                                        className="rating-progress"
                                                        percent={Math.round(item.score)}
                                                        style={{
                                                            cursor: 'pointer',
                                                            fontSize: 11,
                                                            fontWeight: 600
                                                        }}
                                                    />
                                                    <Box style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                                                    <SliderView
                                                        description={item?.description}
                                                    />
                                                    </Box>

                                                </Box>
                                                <h3 style={{ marginLeft: '20px', color: "rgba(0, 0, 0, 0.6)", fontSize: 14, fontWeight: 'bold' }}>{item?.name}</h3>

                                            </div >

                                        )
                                    })}
                                    <div style={{ marginBottom: '16px' }}></div>


                                </div >


                            </Col >
                            : <Modal
                                width={375}
                                style={{
                                    borderRadius: "4px",
                                }}

                                onCancel={() => { setModal(false) }}
                                footer={null}
                                // placement="bottomRight"
                                visible={modal}

                                bodyStyle={{
                                    marginTop: '12px',
                                    textAlign: 'center',
                                    paddingBottom: '16px',
                                }}
                            >
                                <>
                                    <CloseCircleFilled
                                        style={{
                                            fontSize: '44px',
                                            paddingBottom: '16px',
                                            color: '#ea0c0c',
                                        }}
                                    />
                                    <br />
                                    <Typography style={{ fontSize: 18, fontWeight: 'bold' }}>
                                        Establish Permission to view ratings
                                    </Typography>

                                    <Typography style={{ color: 'grey', opacity: 0.6, fontSize: 14, padding: '0px 10px' }}>
                                        You don't have permission to view ratings.
                                        Please establish connection to view ratings.
                                    </Typography>
                                </>
                            </Modal>

                        }
                    </Row >
                </div >


            </div >
            {/* <Modal
                width={375}
                style={{
                    borderRadius: "4px",
                }}

                onCancel={() => { setModal(false) }}
                footer={null}
                // placement="bottomRight"
                visible={modal}

                bodyStyle={{
                    marginTop: '12px',
                    textAlign: 'center',
                    paddingBottom: '16px',
                }}
            >
                <>
                    <CloseCircleFilled
                        style={{
                            fontSize: '44px',
                            paddingBottom: '16px',
                            color: '#ea0c0c',
                        }}
                    />
                    <br />
                    <Typography style={{ fontSize: 18, fontWeight: 'bold' }}>
                        Establish Permission to view ratings
                    </Typography>

                    <Typography style={{ color: 'grey', opacity: 0.6, fontSize: 14, padding: '0px 10px' }}>
                        You don't have permission to view ratings.
                        Please establish connection to view ratings.
                    </Typography>
                </>
            </Modal> */}

        </>
    )
    // Customizable Area End
}

export default RatingView;