import React, { Component } from "react";
import { runEngine } from "../../../../framework/src/RunEngine";
import { Message } from "../../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { IBlock } from "../../../../framework/src/IBlock";
import { RouterProps } from "react-router";
import { Redirect } from "react-router-dom";
import { FormInstance } from "antd/lib/form";
//@ts-ignore
import { gapi } from "gapi-script";
export const configJSON = require("../config");
import { Select } from "antd";
const axios = require("axios");
const { Option } = Select;
import {
  Row,
  Col,
  Button,
  Modal,
  message
} from "antd";
import { isEmpty } from "lodash";
import { removeStorageData, setStorageData, getStorageData } from "../../../../framework/src/Utilities";
export interface Props extends RouterProps {
  navigation: any;
  id: string;
}
// Customizable Area Start
import { appId, api_Token } from "../../../SelfdestructMessaging/src/ChatScreenController.web";
import Mixpanel from "../../../../components/src/Mixpanel";
// Customizable Area End
export interface AllData {
  // Customizable Area Start
  contact: [
    {
      name: string;
      number: string;

    },
  ];
  information: string;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  loading: boolean;
  isRegistration: boolean;
  bussiness_profile: boolean;
  data: any;
  contact: any[];
  general: string;
  token: string | null;
  pre_data: any;
  all_data: AllData;
  msg_id: string;
  isImportContactVisible: boolean;
  employment_type: string | null;
  isErrorModalShow: boolean;
  errorContent: any;
  isModalVisible: any;
  modalIndex: number;
  modalString: string;
  prev_data: any;
  isSuccess: boolean;
  redirect: boolean;
  googleContacts: any;
  isLoadProfile: boolean;
  contactNumberError: any[];
  contactNameError: any[];
  editNum: boolean;
  disableNextButton: boolean
  // Customizable Area End
}
interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}
const contact_props = ["name", "email", "tel", "address", "icon"];
const opts = { multiple: true };
var clientId =
  "649592030497-gp3moqh0k2src5r2u5qfaorii1dqkvdc.apps.googleusercontent.com"; // clientId received from API
var apiKey = "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4"; // client secret key from API
var scopes = "https://www.google.com/m8/feeds"; // enables read/write access to contacts
const gapiClientId =
  "480277687056-0hj34so227lmagot48vc6o6gk1a1re37.apps.googleusercontent.com";
const gapiApiKey = "UiF1Vp3RJXh6ZuVAjJeMHNrQ";
var SCOPES = "https://www.googleapis.com/auth/contacts.readonly";
export default class CreateGeneralController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  createAccountApiCallId: any;
  contact_formref = React.createRef<FormInstance>();
  general_formref = React.createRef<FormInstance>();
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.receive = this.receive.bind(this);
    this.state = {
      contactNameError: [],
      contactNumberError: [],
      bussiness_profile: true,
      loading: true,
      isRegistration: true,
      data: {},
      token: localStorage.getItem("token") ? localStorage.getItem("token") : "",
      pre_data: localStorage.getItem("data")
        ? localStorage.getItem("data")
        : [],
      contact: [],
      general: "",
      msg_id: "",
      isImportContactVisible: false,
      employment_type: localStorage.getItem("employment_type")
        ? localStorage.getItem("employment_type")
        : "",
      isErrorModalShow: false,
      errorContent: {},
      isModalVisible: false,
      modalIndex: 0,
      modalString: '',
      prev_data: {},
      isSuccess: false,
      all_data: {
        contact: [
          {
            name: '',
            number: "",
          },
        ],
        information: ''
      },
      redirect: false,
      googleContacts: [],
      isLoadProfile: false,
      editNum: false,
      disableNextButton: false
    };
    this.alertModal = this.alertModal.bind(this);
  }
  async componentDidMount() {
    let token = await localStorage.getItem("token");
    if (token && token !== null && token !== undefined) {
      this.setState({ redirect: false });
    } else {
      this.setState({ redirect: true });
    }
    // console.log("test ========== ", this.props.history.location.state);
    let contactDetails: any = this.props.history.location.state;
    if (contactDetails && contactDetails != null) {
      let newData = Object.assign(
        {},
        { experience: contactDetails && contactDetails.experience },
        { profile: contactDetails && contactDetails.profile },
        { graduation: contactDetails && contactDetails.graduation },
        { language: contactDetails && contactDetails.language },
        { certificate: contactDetails && contactDetails.certificate }
      );
      this.setState({
        prev_data: newData,
      });
      if (contactDetails && contactDetails.contact) {
        // console.log(contactDetails.contact,'jhdbbwefubuwebfubwubehujebwfuhb')
        this.setState({
          all_data: contactDetails && contactDetails.contact,
        });
      }
    }
    this.setState({ loading: false });
  }

  redirectUser() {
    if (this.state.redirect) {
      return <Redirect to="/" />;
    }
  }

  // =================== @@handleChange =========================
  handleInputChange = (e: any, index: any, data: string) => {
    const { name, value } = e.target;
    if (data === 'information') {
      const list: any = [...this.state.all_data.contact];
      list[index][name] = value;
      this.setState(list);
    } else {
      console.log('error while handiling data');
    }
  };

  handleAddContact = (values: any) => {
    let all_data = this.state.all_data;
    if (all_data.contact) {
      all_data.contact.push(values);
    } else {
      Object.assign(all_data, { contact: [values] });
    }
    console.log(
      '@@@ contact data=====',
      values,
      '@@@ contact data=====',
      all_data
    ),
      this.setState({ all_data: all_data }, () => {
        this.contact_formref.current!.resetFields();
      });
  };
  handleGeneralInfo = (data: any) => {
    // console.log('working =>', data)
    this.setState((state) => ((state.all_data.information = data.information), state), () => {
      // console.log('call back state', this.state.all_data)
      let newData = Object.assign(
        {},
        { experience: this.state.prev_data && this.state.prev_data.experience },
        { profile: this.state.prev_data && this.state.prev_data.profile },
        { graduation: this.state.prev_data && this.state.prev_data.graduation },
        { language: this.state.prev_data && this.state.prev_data.language },
        { certificate: this.state.prev_data && this.state.prev_data.certificate },
        { contact: this.state.all_data }
      );
      // console.log('push data', newData);
    });

  };

  //=================== @@show modal =========================
  showModal = (index: number, data: string) => {
    this.setState({
      modalIndex: index,
      modalString: data,
      isModalVisible: true,
    });
  };
  // ====================@@delete all_data values=======================
  deleteData = (value: any) => {
    if (
      this.state.all_data.contact != null &&
      this.state.modalString === 'Reference'
    ) {
      const list: any = [...this.state.all_data.contact];
      list.splice(value, 1);
      this.setState((state) => ((state.all_data.contact = list), state));
    } else {
      console.log('error while deleting');
    }
    this.setState({
      isModalVisible: false,
    });
  };


  validateOnSubmit = () => {
    const awardError = this.state.contactNameError?.filter(item => item?.error?.length > 10)
    const acheivementError = this.state.contactNumberError?.filter(item => item?.error?.length > 10)
    if (awardError?.length > 0 || acheivementError?.length > 0) {
      message.error("Please Enter valid details of contact name and number", 1.4)
      this.setState({
        disableNextButton: true
      })
      this.setState({
        disableNextButton: false
      })
    } else {
      if (this.state.all_data.information.length === 0) {
        this.handleSubmit()
      } else {
        if (this.state.all_data.information.length >= 15) {
          this.handleSubmit()
        } else {
          // alert("General Information take minimum 15 character")
        }
      }
    }

  }
  handleSubmit = async () => {
    let all_data = this.state.all_data;

    let newData = Object.assign(
      {},
      { profile: this.state.prev_data && this.state.prev_data.profile },
      { experience: this.state.prev_data && this.state.prev_data.experience },
      { language: this.state.prev_data && this.state.prev_data.language },
      { graduation: this.state.prev_data && this.state.prev_data.graduation },
      { certificate: this.state.prev_data && this.state.prev_data.certificate },
      { contact: all_data }
    );
    const dob = newData.profile.date_of_birth;
    const currentAge = this.calculateAge(dob);
    Mixpanel.track("UserAge", { "Age": currentAge })
    
    this.props.history.replace({
      state: newData,
    });
    this.addPersonalProfileData();
  };

  calculateAge = (dob: string) => {
    const dobDate = new Date(dob);
    const today = new Date();

    let age = today.getFullYear() - dobDate.getFullYear();
    const monthDifference = today.getMonth() - dobDate.getMonth();
    const dayDifference = today.getDate() - dobDate.getDate();

    if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
      age--;
    }

    return age;
  }

  handlePrevious = () => {
    // if (this.general_formref && this.general_formref?.current?.getFieldValue("information") !== '') {
    //   console.log('information is checked')
    //   this.general_formref && this.general_formref.current?.submit();
    // } else {
    let all_data = this.state.all_data;
    let newData = Object.assign(
      {},
      { profile: this.state.prev_data && this.state.prev_data.profile },
      { experience: this.state.prev_data && this.state.prev_data.experience },
      { graduation: this.state.prev_data && this.state.prev_data.graduation },
      { certificate: this.state.prev_data && this.state.prev_data.certificate },
      { language: this.state.prev_data && this.state.prev_data.language },
      { contact: this.state.prev_data?.contact ? this.state.prev_data.contact : all_data }
    );
    this.props.history.replace({
      state: newData,
    });
    this.props.history.push({
      pathname: "/setup/profile/personal/about",
      state: newData,
    });
    // }
  }
  readContacts() {
    //@ts-ignore
    var api = navigator.contacts || navigator.mozContacts;

    if (api && !!api.select) {
      // new Chrome API
      api
        .select(["name", "email"], { multiple: true })
        .then(function (contacts: any) {
          // console.log("Found " + contacts.length + " contacts.");
          if (contacts.length) {
            console.log(
              "First contact: " +
              contacts[0].name +
              " (" +
              contacts[0].email +
              ")"
            );
          }
        })
        .catch(function (err: any) {
          console.log("Fetching contacts failed: " + err.name);
        });
    } else if (api && !!api.find) {
      // old Firefox OS API
      var criteria = {
        sortBy: "familyName",
        sortOrder: "ascending",
      };

      api
        .find(criteria)
        .then(function (contacts: any) {
          console.log("Found " + contacts.length + " contacts.");
          if (contacts.length) {
            console.log(
              "First contact: " +
              contacts[0].givenName[0] +
              " " +
              contacts[0].familyName[0]
            );
          }
        })
        .catch(function (err: any) {
          console.log("Fetching contacts failed: " + err.name);
        });
    } else {
      console.log("Contacts API not supported.");
    }
  }

  async getContacts() {
    //@ts-ignore
    if ("contacts" in navigator && "select" in navigator.contacts) {
      try {
        //@ts-ignore
        const contacts = await navigator.contacts.select(["name", "tel"], {
          multiple: true,
        });

        console.log(
          "Your first contact: " + contacts[0].name + " " + contacts[0].tel
        );
      } catch (e) {
        console.log("Unexpected error happened in Contact Picker API", e);
      }
    } else {
      console.log("Your browser doesn't support Contact Picker API");
    }
  }
  contactsTemp() {
    gapi.client.setApiKey(gapiApiKey); //sets apiKey for application
    window.stop();
    this.checkAuth();
  }
  checkAuth() {
    gapi.auth.authorize(
      {
        //authorizes the client using api key fields and calls handleAuthResult method
        client_id: gapiClientId,
        scope: scopes,
        immediate: false,
      },
      this.handleAuthResult
    );
  }

  handleAuthResult(authResult: any) {
    window.stop();
    if (authResult && !authResult.error) {
      //if no authorization error, then it will show the login popup
      axios.get(
        "https://www.google.com/m8/feeds/contacts/default/full?alt=json&access_token=" +
        authResult.access_token +
        "&max-results=700&v=3.0",
        function (response: any) {
          //Handle Response as per requirement
          window.stop();
        }
      );
    }
  }

  addPersonalProfileData = () => {
    this.setState({ isLoadProfile: true })
    const header = {
      "Content-Type": "application/json",
      token: this.state.token,
    };

    // console.log("data", this.state.prev_data);
    let allGraduations = this.state.prev_data?.graduation && [
      ...this.state.prev_data?.graduation?.post,
      ...this.state.prev_data?.graduation?.graduation,
      ...this.state.prev_data?.graduation?.srSecondary,
    ];
    let filterGraduationData = allGraduations && allGraduations.filter((item: any) => {
      if (item.year || item.score || item.degree || item.university || item.stream || item.board) {
        return item
      }
    })
    // console.log(filterGraduationData)
    // console.log(allGraduations);
    let updated_data = Object.assign({},
      // {information:this.state.all_data.information},
      { contact_references: this.state?.all_data?.contact },
      { description: this.state.all_data?.information },
      this.state.prev_data?.profile,
      { experiences: this.state.prev_data?.experience },
      { graduations: filterGraduationData ? filterGraduationData : null },
      {
        certifications: this.state.prev_data?.certificate?.certificate?.filter((item: any) =>
          item.name !== null &&
          item.name !== "" &&
          item.name !== undefined &&
          item.description !== null &&
          item.description !== "" &&
          item.description !== undefined
        )
      },
      { awards: this.state.prev_data?.certificate?.award },
      { achievements: this.state.prev_data?.certificate?.acheivement },
      {
        patents: this.state.prev_data?.language?.patents?.filter((item: any) => item.name !== null &&
          item.name !== "" &&
          item.name !== undefined &&
          item.description !== null &&
          item.description !== "" &&
          item.description !== undefined)
      },
      {
        interests: this.state.prev_data?.language?.other?.filter((item: any) => item.name !== null &&
          item.name !== "" &&
          item.name !== undefined)
      },
      { languages: this.state.prev_data?.language?.language === undefined ? null : this.state.prev_data?.language?.language });
    delete updated_data["profileUrl"];
    delete updated_data["primary_email"];

    // validation
    if (!updated_data.contact_references) {
      delete updated_data?.contact_references;
    } else if (!updated_data?.contact_references[0]?.name) {
      delete updated_data?.contact_references;
    }
    if (!updated_data?.description) {
      delete updated_data?.description;
    }
    if (!updated_data?.experiences) {
      delete updated_data["experiences"];
    } else if (!updated_data?.experiences[0].company) {
      delete updated_data["experiences"];
    }
    if (!updated_data?.certifications) {
      delete updated_data?.certifications
    } else if (!updated_data?.certifications[0]?.name) {
      delete updated_data?.certifications
    }
    if (!updated_data?.awards) {
      delete updated_data?.awards;
    } else if (!updated_data?.awards[0]?.title) {
      delete updated_data?.awards;
    }
    if (!updated_data?.achievements) {
      delete updated_data?.achievements;
    } else if (!updated_data?.achievements[0]?.description) {
      delete updated_data?.achievements;
    }
    if (!updated_data?.patents) {
      delete updated_data?.patents;
    } else if (!updated_data?.patents[0]?.name) {
      delete updated_data?.patents;
    }
    if (!updated_data?.interests) {
      delete updated_data?.interests;
    } else if (!updated_data?.interests[0]?.name) {
      delete updated_data?.interests;
    }

    const json_data = {
      data: { attributes: updated_data },
    };

    const httpBody = json_data;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addPersonalProfileAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({
      msg_id: requestMessage.id,
    });
  };
  addUserInChatBot = async (UpdateuserId?: string, nickname?: string, profile_url?: string, designation?: string) => {
    const formData = new FormData();
    if (UpdateuserId) {
      formData.append('user_id', UpdateuserId);
    }
    if (nickname) {
      formData.append('nickname', nickname);
    }
    if (profile_url) {
      formData.append('profile_url', profile_url);
    }
    await fetch("https://api-" + appId + ".sendbird.com/v3/users",
      {
        method: 'POST',
        body: formData,
        headers: {
          "Accept": "application/json",
          "Api-Token": api_Token,
          Authorization:
            "Bearer " +
            api_Token
        }
      },
    ).then((data) => data.json()).then((friendRes) => {
      console.log(friendRes)
    }).catch(error => { })
    await axios(
      {
        url: "https://api-" + appId + ".sendbird.com/v3/users/" + UpdateuserId + "/metadata",
        method: 'POST',
        data: {
          "metadata": { "designation": designation }
        },
        headers: {
          "Accept": "application/json",
          "Api-Token": api_Token,
          Authorization:
            "Bearer " +
            api_Token
        }
      }
    ).then((data: any) => console.log(data)).catch((error: any) => { })
  }
  async receive(from: string, message: Message) {
    console.log("receive");
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId && responseJson) {
        // console.log(responseJson);
        if (apiRequestCallId == this.createAccountApiCallId) {
          // this.setState({ isLoadProfile: false })
          if (responseJson && responseJson?.data?.type !== 'error' && !responseJson.errors && !responseJson.error) {
            // console.log(responseJson)
            const loginUser = await getStorageData('loginUser')
            console.log(responseJson.data)
            const setData = {
              ...loginUser, profile_pic: responseJson?.data?.attributes?.photo,
              name: responseJson?.data?.attributes?.full_name
            }
            const designation = responseJson?.data?.attributes?.domain_skills.join(",")
            await setStorageData("loginUser", setData)
            this.setState({
              isLoadProfile: false,
              isErrorModalShow: true,
              errorContent: {
                title: "Success",
                isError: false,
                body: "Personal Profile Created",
              },
            });
            await this.addUserInChatBot(setData.id, setData.name, setData.profile_pic, designation)
            await removeStorageData("signUpUserResponse");
            await setStorageData("signUpUserID", { id: responseJson?.data?.attributes?.account_id });
            // Customizable Area Start
            await setStorageData("userProfile-1", responseJson);
            // Customizable Area End
            // this.props.history.push({pathname:'/home'})
          } else
            if (responseJson && responseJson.errors) {
              if (responseJson && responseJson.errors[0].profile) {
                this.setState({
                  isLoadProfile: false,
                  isErrorModalShow: true,
                  errorContent: {
                    title: "Error",
                    isError: true,
                    body: responseJson.errors[0].profile,
                  },
                });
              }
            } else
              if (responseJson.data && responseJson.data?.attributes && responseJson.data?.attributes?.errors) {
                if (responseJson.data?.attributes?.errors.name) {
                  this.setState({
                    isLoadProfile: false,
                    isErrorModalShow: true,
                    errorContent: {
                      title: "Error",
                      isError: true,
                      body: responseJson.data?.attributes?.errors.name,
                    },
                  });
                } else
                  if (responseJson.data?.attributes?.errors.end_date) {
                    this.setState({
                      isLoadProfile: false,
                      isErrorModalShow: true,
                      errorContent: {
                        title: "Error",
                        isError: true,
                        body: responseJson.data?.attributes?.errors.end_date,
                      },
                    });
                  } else
                    if (responseJson.data?.attributes?.errors.experiences) {
                      this.setState({
                        isLoadProfile: false,
                        isErrorModalShow: true,
                        errorContent: {
                          title: "Error",
                          isError: true,
                          body: responseJson.data?.attributes?.errors.experiences,
                        },
                      });
                      // console.log(responseJson.data?.attributes?.errors.experiences)
                    } else
                      if (responseJson.data?.attributes?.errors.date) {
                        this.setState({
                          isLoadProfile: false,
                          isErrorModalShow: true,
                          errorContent: {
                            title: "Error",
                            isError: true,
                            body: responseJson.data?.attributes?.errors.date,
                          },
                        });
                      }
                      else {
                        // responseJson.data?.attributes?.errors?.description?.map((item: string) => {
                        //   return this.setState({
                        //     isLoadProfile: false,
                        //     isErrorModalShow: true,
                        //     errorContent: {
                        //       title: "Error",
                        //       isError: true,
                        //       body: item,
                        //     },
                        //   });
                        // })

                        this.setState({
                          isLoadProfile: false,
                          isErrorModalShow: true,
                          errorContent: {
                            title: "Error",
                            isError: true,
                            body: responseJson.data?.attributes?.errors?.description[0],
                          },
                        });

                      }
              } else {
                console.log("something went wrong");
              }
        }
      }
      // else {
      //   console.log("hello");
      // }
    }
  }

  //////////////////alert/////////
  alertModal = () => {
    return (
      <Modal
        title="Personal Profile"
        visible={this.state.isSuccess}
        onCancel={() =>
          this.setState({
            isSuccess: false,
          })
        }
        footer={null}
        style={{ borderRadius: "0.5rem" }}
      >
        {/* <p>{this.state.modalIndex}</p> */}
        <p>Personal Profile Created</p>
        <Row
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            paddingTop: "0.5rem",
            paddingBottom: "1rem",
          }}
        >
          <Col>
            <Button
              type="primary"
              shape="round"
              size={"large"}
              style={{
                backgroundImage: "linear-gradient(#00536c, #007491)",
                borderColor: "#005574 !important",
                width: "200px",
              }}
              onClick={() => {
                this.setState({
                  isSuccess: false,
                });
                this.props.history.push({
                  pathname: "/home",
                });
              }}
            >
              Ok
            </Button>
          </Col>
        </Row>
      </Modal>
    );
  };

  // BEGIN-NOSCAN
  // fetch google contacts 
  getUserContacts = async (data: any) => {
    const host = "https://people.googleapis.com";
    const endPoint =
      "/v1/people/me/connections?personFields=names,phoneNumbers";

    console.log("loading contact");
    try {
      const request = await fetch(`${host}${endPoint}`, {
        method: 'get',
        headers: {
          Authorization: `${data.token_type} ${data.access_token}`,
          Accept: 'application/json',
        }
      }
      ).then(function (response) {
        return response.json();
      }).then((data) => {
        this.setState({
          isImportContactVisible: false
        })
        if (data?.connections?.length > 0) {
          let newValues: any = []
          data?.connections?.map((c: any) => {
            let name;
            let phoneNum;
            if (c?.names != undefined && c?.names[0]) {
              name = c?.names ? c.names[0].displayName : 'Unknown'
              if (c?.phoneNumbers != undefined && c.phoneNumbers[0]) {
                if (c?.phoneNumbers[0]?.canonicalForm) {
                  phoneNum = c.phoneNumbers[0].canonicalForm
                } else if (c.phoneNumbers[0].value) {
                  phoneNum = c.phoneNumbers[0].value
                } else {
                  phoneNum = ""
                }
              }
              newValues.push({
                name: name,
                number: phoneNum
              })
            }
          });
          this.setState({
            all_data: { ...this.state.all_data, contact: newValues }
          })

        } else {
          message.error("contacts are unavailable")
        }
      });
      console.log("Loading completed");
    } catch (error) {
      console.log(error)
      message.error("something went wrong")
      this.setState({
        isImportContactVisible: false
      })
    }
  }
  // END-NOSCAN

  // google auth 
  onLoginSuccess = (res: any) => {
    console.log('Login Success:', res.profileObj);
    console.log('Login Success:', res);

    if (typeof res?.profileObj?.email === typeof 'test') {
      this.getUserContacts(res.tokenObj);
    }
    this.setState({
      isImportContactVisible: false
    })
  };


  onLoginFailure = (res: any) => {
    console.log('Login Failed:', res);
    this.setState({
      isImportContactVisible: false
    })
  };
  // Customizable Area End
}
