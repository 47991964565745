import React, { Component } from 'react';
import { runEngine } from '../../../../framework/src/RunEngine';
import { Message } from '../../../../framework/src/Message';
import MessageEnum, {
  getName,
} from '../../../../framework/src/Messages/MessageEnum';
import { BlockComponent } from '../../../../framework/src/BlockComponent';
import { IBlock } from '../../../../framework/src/IBlock';
import confirm from '../../../alert/src/alert.web';
import AlertError from '../../../alert/src/alertError.web';
import moment from 'moment';
import { RouterProps } from 'react-router';
import { Redirect } from "react-router-dom";
import { FormInstance } from 'antd/lib/form';
export const configJSON = require('../config');
import { Select } from 'antd';
const { Option } = Select;
export interface Props extends RouterProps {
  navigation: any;
  id: string;
}
export interface AllData {
  post: [
    {
      type: string,
      degree: string | null;
      university: string | null;
      year: string | null;
      score: string | null;
    }
  ];
  graduation: [
    {
      type: string,
      degree: string | null;
      university: string | null;
      year: string | null;
      score: string | null;
    }
  ];
  srSecondary: [
    {
      type: string,
      stream: string | null;
      board: string | null;
      year: string | null;
      score: string | null;
    }
  ];
}
interface S {
  loading: boolean;
  category_data: any[];
  selectUniversityDataAdd:any;
  isRegistration: boolean;
  bussiness_profile: boolean;
  data: any;
  all_data: AllData;
  post_graduation: any[];
  graduation: any[];
  srSecondary: any[];
  pre_data: any;
  token: string | null;
  employment_type: string | null;
  isModalVisible: any;
  modalIndex: number;
  modalString: string;
  prev_data: any;
  redirect: boolean;
  currentYear: number;
  yearList: number[];
  typeNumber: boolean;
  error: any
}
interface SS {
  id: any;
}

export default class CreateEducationController extends BlockComponent<
  Props,
  S,
  SS
> {
  getBusinessProfilecallId: any;
  createAccountApiCallId: any;
  post_formref = React.createRef<FormInstance>();
  graduation_formref = React.createRef<FormInstance>();
  secondary_formref = React.createRef<FormInstance>();
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.receive = this.receive.bind(this);
    this.state = {
      bussiness_profile: true,
      category_data: [],
      selectUniversityDataAdd:[],
      loading: true,
      isRegistration: true,
      data: {},
      prev_data: {},
      token: localStorage.getItem('token') ? localStorage.getItem('token') : '',
      post_graduation: [],
      graduation: [],
      srSecondary: [],
      all_data: {
        post: [
          {
            type: "post_graduations",
            degree: null,
            university: null,
            year: null,
            score: null,
          },
        ],
        graduation: [
          {
            type: "graduations",
            degree: null,
            university: null,
            year: null,
            score: null,
          },
        ],
        srSecondary: [
          {
            type: "senior_secondary",
            stream: null,
            board: null,
            year: null,
            score: null,
          },
        ],
      },
      pre_data: localStorage.getItem('data')
        ? localStorage.getItem('data')
        : [],
      employment_type: localStorage.getItem('employment_type')
        ? localStorage.getItem('employment_type')
        : '',
      isModalVisible: false,
      modalIndex: 0,
      modalString: '',
      redirect: false,
      currentYear: new Date().getFullYear(),
      yearList: [],
      typeNumber: false,
      error: { postGraduationYear: "", graduationYear: "", graduationDegreeError: "", postDegreeError: "", streamError: "" }
    };
  }
  async componentDidMount() {
    let token = await localStorage.getItem("token");
    if (token && token !== null && token !== undefined) {
      this.setState({ redirect: false });
    } else {
      this.setState({ redirect: true });
    }
    this.getCategories();
    this.handleYear();
    // console.log(
    //   'test graduation========== ',
    //   this.props.history.location.state
    // );
    let educationDetails: any = this.props.history.location.state;
    if (educationDetails && educationDetails != null) {
      let newData = Object.assign(
        {},
        { experience: educationDetails && educationDetails.experience },
        { profile: educationDetails && educationDetails.profile },
        { certificate: educationDetails && educationDetails.certificate },
        { language: educationDetails && educationDetails.language },
        { contact: educationDetails && educationDetails.contact }
      );
      this.setState({ prev_data: newData });
      if (educationDetails && educationDetails.graduation) {
        this.setState({
          all_data: educationDetails && educationDetails.graduation,
        });
      }
    }
    this.setState({ loading: false });
  }

  redirectUser() {
    if (this.state.redirect) {
      return <Redirect to="/" />;
    }
  }

  handleYear = () => {
    let startYear = 1975;
    let year = [];
    while (startYear <= this.state.currentYear) {
      year.push(startYear);
      startYear = startYear + 1;
    }
    this.setState({ yearList: year.reverse() });
  }

  handlePostGraduation = (values: any) => {
    let newData = {
      type: "post_graduations",
      degree: null,
      university: null,
      year: null,
      score: null,
    }
    let all_data = this.state.all_data;
    if (all_data.post) {
      all_data.post.push(newData);
    } else {
      Object.assign(all_data, { post: [values] });
    }

    // console.log(
    //   '@@@ postgraduation data====',
    //   values,
    //   '@@@ postgraduation data====',
    //   all_data
    // ),
    this.setState({ all_data: all_data }, () => {
      this.post_formref.current!.resetFields();
    });
  };
  handleGraduation = (values: any) => {
    let newData = {
      type: "graduations",
      degree: null,
      university: null,
      year: null,
      score: null,
    }
    let all_data = this.state.all_data;
    if (all_data.graduation) {
      all_data.graduation.push(newData);
    } else {
      Object.assign(all_data, { graduation: [values] });
    }
    // console.log(
    //   '@@@ graduation data====',
    //   values,
    //   '@@@ graduation data====',
    //   all_data
    // ),
    this.setState({ all_data: all_data }, () => {
      this.graduation_formref.current!.resetFields();
    });
  };
  handleSrSecondary = (values: any) => {
    let newData = {
      type: "senior_secondary",
      stream: null,
      board: null,
      year: null,
      score: null,
    }
    let all_data = this.state.all_data;
    if (all_data.srSecondary) {
      all_data.srSecondary.push(newData);
    } else {
      Object.assign(all_data, { srSecondary: [values] });
    }
    // console.log(
    //   '@@@ srSecondary data====',
    //   values,
    //   '@@@ srSecondary data====',
    //   all_data
    // ),
    this.setState({ all_data: all_data }, () => {
      this.secondary_formref.current!.resetFields();
    });
  };
  // =================== @@handleChange =========================
  handleInputChange = (e: any, index: any, data: string) => {
    const { name, value } = e.target;
    if (data === 'Post') {
      const list: any = [...this.state.all_data.post];
      list[index][name] = value;
      this.setState(list);
    } else if (data === 'Graduation') {
      const list: any = [...this.state.all_data.graduation];
      list[index][name] = value;
      this.setState(list);
    } else if (data === 'Secondary') {
      const list: any = [...this.state.all_data.srSecondary];
      list[index][name] = value;
      this.setState(list);
    } else {
      console.log('error while handiling data');
    }
  };
  handleYearChange = (value: any, index: any, data: string) => {
    if (data === 'Post') {
      const list: any = [...this.state.all_data.post];
      list[index]["year"] = value;
      this.setState(list);
    } else if (data === 'Graduation') {
      const list: any = [...this.state.all_data.graduation];
      list[index]["year"] = value;
      this.setState(list);
    } else if (data === 'Secondary') {
      const list: any = [...this.state.all_data.srSecondary];
      list[index]["year"] = value;
      this.setState(list);
    } else {
      console.log('error while handiling data');
    }
  };
  handleUniversity = (value: any, index: any, data: string) => {
    // console.log(e, index, data);
    if (data === 'Post') {
      const list: any = [...this.state.all_data.post];
      list[index]['university'] = value;
      this.setState(list);
    } else if (data === 'Graduation') {
      const list: any = [...this.state.all_data.graduation];
      list[index]['university'] = value;
      this.setState(list);
    } else if (data === 'Secondary') {
      const list: any = [...this.state.all_data.srSecondary];
      list[index]['board'] = value;
      this.setState(list);
    } else {
      console.log('error while handiling data');
    }
  };
  //=================== @@show modal =========================
  showModal = (index: number, data: string) => {
    this.setState({
      modalIndex: index,
      modalString: data,
      isModalVisible: true,
    });
  };
  // ====================@@delete all_data values=======================
  deleteData = (value: any) => {
    if (this.state.all_data.post != null && this.state.modalString === 'Post Graduation') {
      const list: any = [...this.state.all_data.post];
      list.splice(value, 1);
      this.setState((state) => ((state.all_data.post = list), state));
    } else if (
      this.state.all_data.graduation != null &&
      this.state.modalString === 'Graduation'
    ) {
      const list: any = [...this.state.all_data.graduation];
      list.splice(value, 1);
      this.setState((state) => ((state.all_data.graduation = list), state));
    } else if (
      this.state.all_data.srSecondary != null &&
      this.state.modalString === 'Sr Secondary'
    ) {
      const list: any = [...this.state.all_data.srSecondary];
      list.splice(value, 1);
      this.setState((state) => ((state.all_data.srSecondary = list), state));
    } else {
      console.log('error while deleting');
    }
    this.setState({
      isModalVisible: false,
    });
  };
  skipProfile = () => {
    let all_data = this.state.all_data;
    let newData = Object.assign(
      {},
      { experience: this.state.prev_data && this.state.prev_data.experience },
      { certificate: this.state.prev_data && this.state.prev_data.certificate },
      { language: this.state.prev_data && this.state.prev_data.language },
      { contact: this.state.prev_data && this.state.prev_data.contact },
      { education: this.state.prev_data && this.state.prev_data },
      { profile: this.state.prev_data && this.state.prev_data.profile },
      { graduation: this.state.prev_data.graduation ? this.state.prev_data.graduation : all_data }
    );
    // console.log(newData);
    this.props.history.replace({
      state: newData,
    });
    this.props.history.push({
      pathname: '/setup/profile/personal/generalinformation',
      state: newData,
    });
  };

  handlePrevious = () => {
    let newData = Object.assign(
      {},
      { experience: this.state.prev_data && this.state.prev_data.experience },
      { certificate: this.state.prev_data && this.state.prev_data.certificate },
      { language: this.state.prev_data && this.state.prev_data.language },
      { contact: this.state.prev_data && this.state.prev_data.contact },
      { profile: this.state.prev_data && this.state.prev_data.profile },
      { graduation: this.state.all_data }
    );
    this.props.history.replace({
      state: newData,
    });
    this.props.history.push({
      pathname: '/setup/profile/personal/achievements',
      state: newData,
    });
  };
 

  validatePostGraduationYear = () => {
   const { post, graduation, srSecondary } = this?.state?.all_data;
    if ((post && post?.[0]?.year || '') <= (graduation && graduation?.[0]?.year || '') || (post && post?.[0]?.year || '') <= (srSecondary && srSecondary?.[0]?.year || '')) {
      this.setState({ error: { ...this.state.error, postGraduationYear: "Please enter valid year" } });
      return false;
    }
    return true;
  }

  validateGraduationYear = () => {
    const { graduation, srSecondary } = this?.state?.all_data;
    if (graduation && graduation?.[0]?.year && (graduation && graduation?.[0]?.year || '') <= (srSecondary && srSecondary?.[0]?.year || '')) {
      this.setState({ error: { ...this.state.error, graduationYear: "Please enter valid year" } });
      return false;
    }
    return true;
  }

 

  clearErrors = () => {
    this.setState({ error: { postGraduationYear: "", graduationYear: "", graduationDegreeError: "", postDegreeError: "", streamError: "" } });
  }

  handleNext = async () => {
    const isPostGraduationYearValid = this.validatePostGraduationYear();
    const isGraduationYearValid = this.validateGraduationYear();
    if (isPostGraduationYearValid && isGraduationYearValid ) {
      this.clearErrors();
      this.onNext();
    }
  }



  onNext = () => {
    let all_data = this.state.all_data;

    let newData = Object.assign(
      {},
      { profile: this.state.prev_data && this.state.prev_data.profile },
      { experience: this.state.prev_data && this.state.prev_data.experience },
      { language: this.state.prev_data && this.state.prev_data.language },
      { contact: this.state.prev_data && this.state.prev_data.contact },
      { certificate: this.state.prev_data && this.state.prev_data.certificate },
      { graduation: all_data }
    );
    // console.log(newData);
    this.props.history.replace({
      state: newData,
    });
    this.props.history.push({
      pathname: '/setup/profile/personal/about',
      state: newData,
    });

    // console.log("data test", this.state.data.allData);
    // console.log('data test', newData);
  };

  //=========================@@@@ Call Get Api ============================

  getCategories = async () => {
    const header = {
      'Content-Type': 'application/json',
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getBusinessProfilecallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_categories/categories'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    // console.log(
    //   '@@@ Request ===========',
    //   requestMessage,
    //   this.getBusinessProfilecallId
    // );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  //======================@@@@ Receive Function =======================

  async receive(from: string, message: Message) {
    // console.log('@@@ API Response ==========', message);
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getBusinessProfilecallId !== null &&
      this.getBusinessProfilecallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      // this.apiGetUserOtpCallId = null;

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      // console.log('get all buisness', responseJson);

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      // console.log('get all buisness', errorReponse);

      if (responseJson) {
        this.getbusinessProfileSuccessCallBack(responseJson);
      } else {
        this.getBusinessProfileFailureCallBack(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }
  }

  //======================@@@ Success And Failure Call Back ======================
  handleUniversitySearchAdd = (SearchText: any) => {
    const options = this.state.category_data.map((item) => ({ value: item.name }));
    const data = options.filter((item) =>
      item.value.toLowerCase().includes(SearchText.toLowerCase())
    );
    this.setState({ selectUniversityDataAdd: data });
  }
  getbusinessProfileSuccessCallBack = (responseJson: any) => {
    // console.log('@@@ initial success call============', responseJson);
    let dropDownDetail = responseJson && responseJson.data;
    // console.log('@@@@@@checkEmployment dropDownDetail=======', dropDownDetail);
    let checkAllUniversity: any = dropDownDetail.filter(
      (item: any) => item.attributes.name === 'University'
    );
    // let all_subItems =

    this.setState(
      {
        category_data: checkAllUniversity[0].attributes.sub_categories,
      }
     
    );
    const options = this.state.category_data.map((item: any) => ({ value: item.name }));
    this.setState({ selectUniversityDataAdd: options });
  };

  getBusinessProfileFailureCallBack = (responseJson: any) => {
    console.log(
      '@@@ Get Business Profile Failure call Back ========',
      responseJson
    );
  };

  // handleChange(event: any) {
  //   console.log('@@@ e======', event);
  //   this.setState({ : event });
  // }
}
