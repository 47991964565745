import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../../framework/src/Messages/MessageEnum";
import moment from "moment";
import { Redirect } from "react-router-dom";
// Customizable Area Start
import React from "react";
import { RouterProps } from "react-router";
import { DeleteImgPng, settingIcon, blockIcon, eyehiddenIcon } from "../assets"
import {
    Row,
    Col,
    Button, Modal, Typography
} from "antd";
const { Text } = Typography;
// Customizable Area End

export const configJSON = require("../config");
export interface Props extends RouterProps {
    // Customizable Area Start
    navigation: any;
    id: string;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    initialActiveNav: string | number;
    isUnblockVisible: boolean;
    allCommunity: any;
    token: string | null;
    redirect: boolean;
    isMounted: boolean;
    communityData: any;
    hiddenPosts: any;
    blockedAccounts: any;
    manageRequests: {
        post_request: boolean,
        join_request: boolean
    };
    isloading: boolean;
    updateBlockUser: boolean;
    isModel:boolean
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class CommunitySettingsController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    unBlockCommunityId: string = "";
    manageRequestId: string = "";
    getBlockedAccountId: string = "";
    getHiddenPostsId: string = "";
    addToCommunityId: string = "";
    editManageRequestId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];

        this.state = {
            initialActiveNav: 1,
            isUnblockVisible: false,
            allCommunity: '',
            token: localStorage.getItem('token') ? localStorage.getItem('token') : null,
            redirect: false,
            isMounted: false,
            communityData: "",
            hiddenPosts: [],
            blockedAccounts: [],
            manageRequests: {
                post_request: false,
                join_request: false
            },
            isloading: false,
            updateBlockUser: false,
            isModel:false
        };

        // Customizable Area End
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }

    async componentDidMount() {
        super.componentDidMount();
        this.setState({ isMounted: true });
        let token = this.state.token;
        if (token && token !== null && token !== undefined) {
            this.setState({ redirect: false });
        } else {
            this.setState({ redirect: true });
            this.redirectUser();
        }
        let communityInfo: any = this.props.history?.location?.state;
        this.setState(({ communityData: communityInfo, initialActiveNav: communityInfo.initialActiveNav }));
        if (communityInfo) {
            await this.getManageRequest(communityInfo?.id);
            await this.getBlockedAccounts(communityInfo?.id);
            await this.getHiddenPosts(communityInfo?.id);
        }
    }

    redirectUser() {
        if (this.state.redirect) {
            return <Redirect to="/" />;
        }
    }

    data = [
        {
            id: 1,
            icon: settingIcon,
            title: 'Manage requests',
        },
        {
            id: 2,
            icon: blockIcon,
            title: 'Blocked Accounts',
        },
        {
            id: 3,
            icon: eyehiddenIcon,
            title: 'Hidden Posts',
        },
        {
            id: 4,
            icon: DeleteImgPng,
            title: 'Delete Community',
        },
    ];


    renderUnBlockModal = () => {

        return (
            <Modal
                width={320}
                style={{ borderWidth: "0px", borderRadius: "4px", overflow: "hidden" }}
                centered
                data-testid="unblock-confirmation"
                title={
                    <div
                        style={{
                            width: "100%",
                            cursor: "move",
                            fontWeight: "bold",
                            borderWidth: "0px"
                        }}
                    >
                        Unblock Confirmation
                    </div>
                }
                visible={this.state.isUnblockVisible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                footer={[
                    <Row style={{ padding: '0px 10px 10px 10px' }}>
                        <Col span={12}>
                            <Button
                                size="large"
                                className="report-button"
                                key="report"
                                data-testid="no-unblock-button"
                                onClick={this.handleCancel}
                            >
                                No,Don't unblock
                            </Button>
                        </Col>
                        <Col span={12}>
                            <Button
                                size="large"
                                className="follow-label"
                                style={{ borderWidth: "0px" }}
                                key="report"
                                data-testid="yes-unblock-button"
                                onClick={this.handleOk}
                            >
                                Yes, unblock
                            </Button>
                        </Col>
                    </Row>
                ]}
            >
                <Text>{`Are you sure want to unblock ${this.state.allCommunity} ?`}</Text>
            </Modal>
        );
    };

    async componentWillUnmount() {
        this.setState({ isMounted: false });
    }

    handleSwitchChange = (value: boolean, type: string) => {
        if (type === "post_request") {
            this.setState({ manageRequests: { ...this.state.manageRequests, post_request: value } })
        } else if (type === "join_request") {
            this.setState({ manageRequests: { ...this.state.manageRequests, join_request: value } })
        }
    }

    handleCommunity = () => {
        this.props.history.push({ pathname: '/community/block', state: { communityId: this.state.communityData?.id } })
    }

    handleOk = () => {
        this.setState({ isUnblockVisible: false });
        this.unBlockCommunity(this.state.communityData.id, this.state.updateBlockUser);
    };

    handleCancel = () => {
        this.setState({ isUnblockVisible: false });
    };


    getDifference = (created_at: string) => {
        let differenceStr = "";
        let difference = 0;
        const dateForDifference = moment(created_at);
        difference = moment().diff(dateForDifference, "days");
        if (difference === 0) {
            let hours = moment().diff(dateForDifference, "hours");
            if (hours === 0) {
                const minutes = moment().diff(dateForDifference, "minutes");
                differenceStr = minutes + " min ago";
            } else {
                differenceStr = hours + " hr ago";
            }
        } else {
            if (difference === 1) {
                differenceStr = "1 day ago";
            }
            else {
                differenceStr = difference + " days ago";
            }
        }
        return <span style={{ color: 'gray' }}>{differenceStr}</span>;
    }

    // *************** api calls *******************************

    async receive(from: String, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiRequestCallId && responseJson) {
                if (apiRequestCallId === this.unBlockCommunityId) {
                    this.getBlockedAccounts(this.state.communityData.id);
                } else if (apiRequestCallId === this.manageRequestId) {
                    this.setState({
                        manageRequests: responseJson,
                        isloading: false
                    })
                } else if (apiRequestCallId === this.getBlockedAccountId) {
                    this.setState({
                        blockedAccounts: responseJson?.blocked_accounts.data
                    })
                } else if (apiRequestCallId === this.getHiddenPostsId) {
                    this.setState({
                        hiddenPosts: responseJson?.hidden_posts.data
                    })
                } else if (apiRequestCallId === this.addToCommunityId) {
                    window.location.reload();
                }else if (apiRequestCallId === this.editManageRequestId){
                    responseJson.data&&this.setState({isModel:true})
                }
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start

    onItemPress = (id: string) => {
        delete this.props.history?.location?.state;
        this.props.history.replace({
            pathname: '/community/admin-settings', state: {
                id: this.state.communityData?.id,
                name: this.state.communityData?.name,
                initialActiveNav: id
            }
        });

        this.setState({ initialActiveNav: id });
    }

    unBlockCommunity = (communityId: any, userId: any) => {
        let formdata = new FormData();
        formdata.append("community_forum_id", communityId);
        formdata.append("account_id", userId);
        const header = {
            // "Content-Type": configJSON.apiContentType,
            token: this.state.token,
        };
        let body = formdata;
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.unBlockCommunityId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.blockUserApiEndpoint}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            header
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.POSTMethod
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            body
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    getManageRequest = (id: number | string) => {
        this.setState({ isloading: true })
        const header = {
            "Content-Type": configJSON.getPostsApiContentType,
            token: this.state.token,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.manageRequestId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.manageRequestApiEndPoint}/${id}/settings?manage_requests=true`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            header
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.communityGetMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    getBlockedAccounts = (id: number | string) => {
        const header = {
            "Content-Type": configJSON.getPostsApiContentType,
            token: this.state.token,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getBlockedAccountId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.manageRequestApiEndPoint}/${id}/settings?blocked_accounts=true`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            header
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.communityGetMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    getHiddenPosts = (id: number | string) => {
        const header = {
            "Content-Type": configJSON.getPostsApiContentType,
            token: this.state.token,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getHiddenPostsId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.manageRequestApiEndPoint}/${id}/settings?hidden_posts=true`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            header
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.communityGetMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    add_to_community = (id: number | string) => {
        const header = {
            "Content-Type": configJSON.getPostsApiContentType,
            token: this.state.token,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.addToCommunityId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getUserPostsApiEndpoint}/${id}/add_to_community`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            header
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.POSTMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    editManageRequestFlags = async (id: number | string) => {
        const header = {
            "Content-Type": configJSON.getPostsApiContentType,
            token: this.state.token,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.editManageRequestId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.manageRequestApiEndPoint}/${id}?join_request=${this.state.manageRequests.join_request}&post_request=${this.state.manageRequests.post_request}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            header
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.PUTMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    // Customizable Area End
}
