import React from "react";
// Customizable Area Start
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { ReplieDataType, ProfileAttributesDataType } from "./Interface";
export const configJSON = require("./config");
// Customizable Area End
export interface Props {
    // Customizable Area Start
    item: ReplieDataType
    handleUserRequest: (value: ProfileAttributesDataType) => void
    onCommentLike: (commentId: number | string, liked: boolean) => Promise<void>
    handleSubmitReplyComment: (commentId: number, message: string ,editID:number) => void
    commentId: number
    isEdit:boolean;
    handleEdit:() =>void
    logInUser:string
    handleDelete:(commentId: number)=> void
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    rplyPostComment: string;
    userReplyCommentData: Array<ReplieDataType>;
    displayComment: boolean;
    emoji: boolean;
    onSendReplyComment: boolean;
    handleReplyComments: number;
    // Customizable Area End
}
interface SS {
    // Customizable Area Start
    // Customizable Area End
}
export default class RenderReplyCommentController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    commentLikeStatusApi: string = ""
    commentReplycalledApi: string = ''
    // Customizable Area End
    constructor(props: Props) {
        // Customizable Area Start
        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage)
        ];
        this.state = {
            rplyPostComment: "",
            userReplyCommentData: [],
            displayComment: false,
            emoji: false,
            onSendReplyComment: false,
            handleReplyComments: 1
        }
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area End
    }
    // Customizable Area Start
    handleOnSubmitCommentReply = (commentId: number, message: string) => {
        this.props.handleSubmitReplyComment(this.props.commentId, message ,commentId)
        this.setState({
            onSendReplyComment: true,
            rplyPostComment: '',
            displayComment: false
        })
    }
    onRplyChange = (value: string) => {
        this.setState({
            rplyPostComment: value
        })
    };

    handleEmoji = () => {
        this.setState({
            emoji: !this.state.emoji
        })
    }
    
    onEdit =(item:any) =>{        
        this.props.handleEdit()
        this.setState({
            displayComment: !this.state.displayComment,
            rplyPostComment: item.attributes.comment
        })      
    }

    onReply = () => {
        this.setState({
            displayComment: !this.state.displayComment,
            rplyPostComment: ""
        })
    }
    onReplyEmojiClick = (event: {}, emojiObject: { emoji: string; }) => {
        this.setState({
            emoji: !this.state.emoji,
            rplyPostComment: this.state.rplyPostComment.concat(emojiObject.emoji)
        })
    };
    // Customizable Area End
}