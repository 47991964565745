import React, { Component } from "react";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { message } from "antd";
export const configJSON = require("./config");
import { RouterProps } from "react-router";
export interface Props extends RouterProps {
  navigation: any;
  id: string;
}
interface S {
  verificationData: any;
  isSuccessModalShow: boolean
  errorContent: any;
  verifySuccessBannerImageId: string | null;
  verifySuccessPageBannerImage: string;
}
interface SS {
  id: any;
}

export default class SuccessController extends BlockComponent<Props, S, SS> {
  createAccountApiCallId: any;
  otpResendApiCallId: any;
  formref = React.createRef();
  timer: any;
  getVerifySuccessPageBannerImageApiCallId = '';
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.receive = this.receive.bind(this);
    this.state = {
      verificationData: {},
      isSuccessModalShow: false,
      errorContent: {},
      verifySuccessBannerImageId: '',
      verifySuccessPageBannerImage: ''
    };
  }
  async componentDidMount() {
    this.getVerifySuccessPageBannerImage()
    let history: any = (await this.props.history)
      ? this.props.history.location.state
      : "";
    console.log(history, 'history***')
    if (history) {
      this.setState({ verificationData: history });
    }
    // console.log("history", history);
  }
  onContinueClick = async () => {
    if (this.state.verificationData?.role_id == 2) {
      this.props.history.push("/select/industry");
    } else {
      let data = {
        name: this.state.verificationData.name,
        email: this.state.verificationData.email
      }
      this.props.history.push({
        pathname: "/setup/profile/personal",
        state: data,
      });
      // this.props.history.push("/add_personal_profile");
    }
    // message.info("Coming soon!");
  };
  async receive(from: string, message: Message) {
    console.log("receive");
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId == this.getVerifySuccessPageBannerImageApiCallId) {
          this.updateVerifySuccessPageBannerImageState(responseJson);
        }
      } else {
        console.log("hello");
      }
    }
  }

  getVerifySuccessPageBannerImage = () => {
    const header = {
      'Content-Type': 'application/json',
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getVerifySuccessPageBannerImageApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getLandingPageImageAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    this.setState({
      verifySuccessBannerImageId: requestMessage.id,
    });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  updateVerifySuccessPageBannerImageState = (verifySuccessPageBannerImageData: any) => {
    if (verifySuccessPageBannerImageData.data) {
      this.setState({
        verifySuccessPageBannerImage: verifySuccessPageBannerImageData.data[0].attributes.image,
      });
    } else {
      this.setState({
        isSuccessModalShow: true,
        errorContent: {
          title: "Error",
          isError: true,
          body: "Unable to load verify Success banner image!"
        },
      });
    }
  };
}
