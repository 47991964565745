import React, { Component } from 'react';
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { BlockComponent } from '../../../framework/src/BlockComponent';
import { IBlock } from "../../../framework/src/IBlock";
import confirm from "../../alert/src/alert.web";
import AlertError from "../../alert/src/alertError.web";
import { RouterProps } from "react-router";
import { runInThisContext } from 'vm';
import { Redirect } from "react-router-dom";
import { FormInstance } from 'antd/lib/form';
import {
  Row,
  Col,
  Typography,
  Button,
  Modal,
} from 'antd';
import { removeStorageData, setStorageData, getStorageData } from '../../../framework/src/Utilities';
import { appId, api_Token } from '../../SelfdestructMessaging/src/ChatScreenController.web';
// @ts-ignore
import { baseURL } from "../../../framework/src/config.js";
import axios from "axios"
import { AlertMessage } from '../../alert/src/AlertMessage.web';
const { Title, Text } = Typography;
export const configJSON = require('./config');

export interface Props extends RouterProps {
  navigation: any;
  id: string;
}
export interface AllData {
  history: [
    {
      year: string;
      description: string;
    }
  ];
  expansion: [
    {
      year: string;
      description: string;
    }
  ];
  core_team: [
    {
      name: string;
      designation: string;
    }
  ];
  client_references: [
    {
      reference: string;
    }
  ];
}
interface S {
  isSubmitting: boolean;
  loading: boolean;
  isRegistration: boolean;
  bussiness_profile: boolean;
  data: any;
  all_data: AllData;
  prevData: any;
  profileLoading: boolean;
  token: string | null;
  historyYears: any;
  historyDescription: any;
  expansionYears: any;
  expansionDescription: any;
  teamMemberName: any;
  teamMemberDesignation: any;
  ClientReference: any;
  prev_state: any;
  isModalVisible: any;
  modalIndex: number;
  modalString: string;
  isSuccess: boolean;
  isError: boolean;
  redirect: boolean;
  errorData: any;
  profileId: any;
  yearList: number[];
  currentYear: number;
}
interface SS {
  id: any;
}

export default class EditBusinessHistoryController extends BlockComponent<Props, S, SS>{
  businessProfileHistoryApiCallId: any;
  postUploadProfileImageApiCallId: any;
  postUploadBackgroundImageCallId: any;
  GetProfielInformationId: any;
  // formref = React.createRef();
  year_formref = React.createRef<FormInstance>();
  Expansion_formref = React.createRef<FormInstance>();
  Core_Teams_formref = React.createRef<FormInstance>();
  References_formref = React.createRef<FormInstance>();

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.receive = this.receive.bind(this);
    this.state = {
      bussiness_profile: true,
      loading: true,
      isRegistration: true,
      // data: this.props.history ? this.props.history.location.state : '',
      data: [],
      yearList: [],
      currentYear: new Date().getFullYear(),
      prevData: {},
      profileLoading: true,
      all_data: {
        history: [
          {
            year: '',
            description: '',
          },
        ],
        expansion: [
          {
            year: '',
            description: '',
          },
        ],
        core_team: [
          {
            name: '',
            designation: '',
          },
        ],
        client_references: [
          {
            reference: '',
          },
        ],
      },
      token: localStorage.getItem('token'),
      historyYears: '',
      historyDescription: '',
      expansionYears: '',
      expansionDescription: '',
      teamMemberName: '',
      teamMemberDesignation: '',
      ClientReference: '',
      prev_state: '',
      isModalVisible: false,
      modalIndex: 0,
      modalString: '',
      isSuccess: false,
      isError: false,
      redirect: false,
      errorData: {},
      profileId: '',
      isSubmitting: false,
    };
    this.alertModal = this.alertModal.bind(this);
    // this.handlePrevious = this.handlePrevious.bind(this);
  }

  async componentDidMount() {
    let token = await localStorage.getItem("token");
    if (token && token !== null && token !== undefined) {
      this.setState({ redirect: false });
    } else {
      this.setState({ redirect: true });
    }
    console.log("Profile OverView========== ", this.props.history?.location?.state);
    let details: any = this.props.history?.location?.state;
    let newData: AllData = Object.assign(
      {},
      {
        history: details?.oldData?.history?.length > 0 ? details?.oldData?.history : [{
          year: '',
          description: '',
        }],
      },
      {
        expansion: details?.oldData?.expansion?.length > 0 ? details?.oldData?.expansion : [{
          year: '',
          description: '',
        }],
      },
      {
        core_team: details?.oldData?.core_team?.length > 0 ? details?.oldData?.core_team : [{
          name: '',
          designation: '',
        }],
      },
      {
        client_references: details?.oldData?.client_references?.length > 0 ? details?.oldData?.client_references : [{
          reference: '',
        }],
      },
    );
    this.setState({
      prevData: details?.oldData,
      all_data: newData,
      data: details?.data,
      profileLoading: false
    });

    const profileInfo = await getStorageData('userProfile');
    if (profileInfo) {
      this.setState({ profileId: profileInfo?.attributes?.profile.data?.attributes?.account_id });
    } else {
      const profileInfo = await getStorageData('loginUser');
      if (profileInfo) {
        this.setState({ profileId: profileInfo?.id });
      }
    }
    this.setState({ loading: false });
    let startYear = 1801;
    let year = [];
    while (startYear <= this.state.currentYear) {
      year.push(startYear);
      startYear = startYear + 1;
    }
    this.setState({ yearList: year.reverse() });
  }

  redirectUser() {
    if (this.state.redirect) {
      return <Redirect to="/" />;
    }
  }

  //=================== @@show modal =========================
  showModal = (index: number, data: string) => {
    this.setState({
      modalIndex: index,
      modalString: data,
      isModalVisible: true,
    });
  };

  // =================== @@handleChange =========================
  handleInputChange = (e: any, index: any, data: string) => {
    const { name, value } = e.target ? e.target : e;
    if (data === 'History') {
      if (name === "description") {
        const list: any = [...this.state.all_data.history];
        list[index][name] = value;
        this.setState(list);
      } else {
        const list: any = [...this.state.all_data.history];
        list[index]["year"] = e;
        this.setState(list);
      }
    } else if (data === 'Expansion') {
      if (name === "description") {
        const list: any = [...this.state.all_data.expansion];
        list[index][name] = value;
        this.setState(list);
      } else {
        const list: any = [...this.state.all_data.expansion];
        list[index]["year"] = e;
        this.setState(list);
      }
    } else if (data === 'Core Team') {
      const list: any = [...this.state.all_data.core_team];
      list[index][name] = value;
      this.setState(list);
    } else if (data === 'Client References') {
      const list: any = [...this.state.all_data.client_references];
      list[index][name] = value;
      this.setState(list);
    } else {
      console.log('error while handiling data');
    }
  };

  // ====================@@delete all_data values=======================
  deleteData = (value: any) => {
    if (
      this.state.all_data.history != null &&
      this.state.modalString === 'History'
    ) {
      const list: any = [...this.state.all_data.history];
      list.splice(value, 1);
      this.setState(
        (state) => ((state.all_data.history = list), state),
        () => {
          // this.handleLocalStorage();
        }
      );
    } else if (
      this.state.all_data.expansion != null &&
      this.state.modalString === 'Expansion'
    ) {
      const list: any = [...this.state.all_data.expansion];
      list.splice(value, 1);
      this.setState(
        (state) => ((state.all_data.expansion = list), state),
        () => {
          // this.handleLocalStorage();
        }
      );
    } else if (
      this.state.all_data.core_team != null &&
      this.state.modalString === 'Core Team'
    ) {
      const list: any = [...this.state.all_data.core_team];
      list.splice(value, 1);
      this.setState(
        (state) => ((state.all_data.core_team = list), state),
        () => {
          // this.handleLocalStorage();
        }
      );
    } else if (
      this.state.all_data.client_references != null &&
      this.state.modalString === 'Client References'
    ) {
      const list: any = [...this.state.all_data.client_references];
      list.splice(value, 1);
      this.setState(
        (state) => ((state.all_data.client_references = list), state),
        () => {
          // this.handleLocalStorage();
        }
      );
    } else {
      console.log('error while deleting');
    }
    this.setState({
      isModalVisible: false,
    });
  };

  //==================== @@@ SET LOCAL STORAGE===============
  // handleLocalStorage() {
  //   localStorage.setItem(
  //     'businessHistory',
  //     JSON.stringify(this.state.all_data)
  //   );
  // }

  //==================== @@@ Handle Data of History Screen===============
  // Edit User for chat module
  getProfileUser = async () => {
    try {
      const response = await axios({
        url:
          `${baseURL}/bx_block_profile/profiles`,
        method: "get",
        headers: {
          token: this.state.token,
          "Content-Type": "application/json"
        }
      });
      // console.log(response)
      if (response) {

        await setStorageData("userProfile", response?.data?.data);

      }
      // this.setState({ communityList: response.data.communities });
    } catch (error) {
      console.error(error, 'error while calling all community');
    }
  }
  EditUser = async (UpdateuserId?: string, nickname?: string, profile_url?: string, designation?: string) => {
    const formData = new FormData();
    if (nickname) {
      formData.append('nickname', nickname);
    }
    if (profile_url) {
      formData.append('profile_url', profile_url);
    }
    await fetch("https://api-" + appId + ".sendbird.com/v3/users/" + UpdateuserId,
      {
        method: 'PUT',
        body: formData,
        headers: {
          "Accept": "application/json",
          "Api-Token": api_Token,
          Authorization:
            "Bearer " +
            api_Token
        }
      },
    ).then((data) => data.json()).then((friendRes) => {
      // console.log(friendRes)
    })
    await axios(
      {
        url: "https://api-" + appId + ".sendbird.com/v3/users/" + UpdateuserId + "/metadata",
        method: 'PUT',
        data: {
          "metadata": { "designation": designation }
        },
        headers: {
          "Accept": "application/json",
          "Api-Token": api_Token,
          Authorization:
            "Bearer " +
            api_Token
        }
      }
    ).then((data: any) => console.log(data)).catch(error => {

    })
  }

  handleHistoryData = (values: any) => {
    let all_data = this.state.all_data;
    if (all_data.history) {
      all_data.history.push({ year: '', description: '' });
    } else {
      Object.assign(all_data, { history: [values] });
    }

    console.log(
      '@@@ history data====',
      values,
      '@@@ history data====',
      all_data
    ),
      // this.handleLocalStorage();
      this.setState({ all_data: all_data }, () => {
        // this.year_formref.current!.resetFields();
      });
  };

  handleExpansionData = (values: any) => {
    let all_data = this.state.all_data;
    if (all_data.expansion) {
      all_data.expansion.push({ year: '', description: '' });
    } else {
      Object.assign(all_data, { expansion: [values] });
    }
    console.log(
      '@@@ expansion data=====',
      values,
      '@@@ expansion data=====',
      all_data
    ),
      // this.handleLocalStorage();
      this.setState({ all_data: all_data }, () => {
        // this.Expansion_formref.current!.resetFields();
      });
  };

  handleCoreTeamData = (values: any) => {
    console.log(values);
    let all_data = this.state.all_data;
    if (all_data.core_team) {
      all_data.core_team.push(values);
    } else {
      Object.assign(all_data, { core_team: [values] });
    }
    console.log(
      '@@@ core team data======',
      values,
      '@@@ core team data======',
      all_data
    ),
      // this.handleLocalStorage();
      this.setState({ all_data: all_data }, () => {
        this.Core_Teams_formref.current!.resetFields();
      });
  };

  handleClientRefData = (values: any) => {
    let all_data = this.state.all_data;
    if (all_data.client_references) {
      all_data.client_references.push(values);
    } else {
      Object.assign(all_data, { client_references: [values] });
    }
    console.log(
      '@@@ client ref. data=====',
      values,
      '@@@ client ref. data=====',
      all_data
    ),
      // this.handleLocalStorage();
      this.setState({ all_data: all_data }, () => {
        this.References_formref.current!.resetFields();
      });
  };

  //==================== @@@ Receive Function =========================

  async receive(from: string, message: Message) {
    // console.log('@@@ API Response ==========', message);
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.businessProfileHistoryApiCallId !== null &&
      this.businessProfileHistoryApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      // this.apiGetUserOtpCallId = null;

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      console.log('@@@ API Response ==========', responseJson);
      if (responseJson?.data?.attributes?.errors) {
        // console.log('error 1', responseJson?.data)
        this.setState({
          isError: true,
          errorData: responseJson?.data?.attributes?.errors?.company_name ? 'Company Name is already taken' : 'Try again',
          isSubmitting: false
        });
        // this.setState({ isSuccess: true });
      } else if (responseJson?.errors) {
        // console.log('errorsssss 2',responseJson.errors)
        this.setState({ isError: true, errorData: responseJson?.errors[0]?.profile, isSubmitting: false });
        // this.businessProfileHistoryFailureCallBack(responseJson);
      } else if (responseJson.error) {
        this.setState({ isError: true, errorData: 'Try again', isSubmitting: false });
      } else {
        let data: any = await getStorageData("loginUser")
        // Customizable Area Start
        await this.GetProfileInformation();
        await this.businessProfileHistorySuccessCallBack(responseJson);
        this.setState({ isSuccess: true, isSubmitting: false });
        // Customizable Area End
      }
      // this.parseApiCatchErrorResponse(errorReponse);
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.GetProfielInformationId) {
          console.log(responseJson.data)
          setStorageData("userProfile", responseJson.data);
        }
      }
    }
  }

  //========================= @@@  Success And Failure  Call Back ====================

  businessProfileHistorySuccessCallBack = async (responseJson: any) => {
    // Customizable Area start
    console.log(
      '@@@ business Profile History Success call Back ========',
      responseJson
    );
    // signUpUserResponse
    let data: any = await getStorageData("loginUser")
    let updateData = {
      ...data, profile_pic: responseJson?.data?.attributes?.photo,
      company_name: responseJson?.data?.attributes?.company_name
    }
    const designation = responseJson?.data?.attributes?.business + "," + responseJson?.data?.attributes?.business_industry
    console.log(designation, "designation", updateData, "updateData")
    await this.EditUser((updateData.id).toString(), updateData?.company_name, updateData?.profile_pic, designation)
    await setStorageData("signUpUserID", { id: responseJson?.data?.attributes?.account_id });
    console.log(responseJson, 'responseJson__')
    await setStorageData('updatedProfile', responseJson?.data?.attributes)
    await setStorageData('loginUser', updateData)
    await setStorageData("accountData", responseJson?.data);
    await this.getProfileUser()
    await removeStorageData("signUpUserResponse");
    localStorage.removeItem('businessAbout');
    localStorage.removeItem('businessProfile');
    localStorage.removeItem('businessprofileUrl');
    localStorage.removeItem('industry_type');
    localStorage.removeItem('businessHistory');
    this.alertModal();
    // this.renderCloseModal();
    // alert('Business Profile Created')
    // Customizable Area End
  };

  businessProfileHistoryFailureCallBack = (responseJson: any) => {
    console.log(
      '@@@ business Profile History Failure call Back ========',
      responseJson
    );
    // alert('Business Profile Not Created');
    this.errorAlertModal();
  };

  //======================== @@@ Call Post Api ==============================
  // test = () => {
  //   let newData = Object.assign({}, this.state.all_data, this.state.data.industry_type, this.state.data.profile, this.state.data.overview)

  //   console.log({"data":{"attributes":newData}})
  // };
  handleAddProfileData = async () => {
    const updated_data = { ...this.state.data, ...this.state.all_data }
    console.log(updated_data, 'final data===='
    );
    this.setState({ isSubmitting: true });
    if (updated_data?.profile_pic?.picFile) {
      await this.uploadProfileImage(updated_data?.profile_pic?.picFile)
    }
    if (updated_data.cover_pic.backgroundImageFile) {
      await this.uploadBackgroundImage(updated_data.cover_pic.backgroundImageFile)
    }

    if (this.state.all_data && Object.keys(this.state.all_data).length > 0) {
      const header = {
        'Content-Type': 'application/json',
        token: this.state.token,
      };

      delete updated_data["profileUrl"];
      // updated_data.photo = updated_data.profile_pic;
      delete updated_data["profile_pic"];
      delete updated_data['backgroundImage']
      delete updated_data['cover_pic'];

      if (updated_data?.client_references[0]?.reference == '') {
        delete updated_data['client_references'];
      }
      if (updated_data?.core_team[0]?.name == '' && updated_data?.core_team[0]?.designation == '') {
        delete updated_data['core_team'];
      }
      if (updated_data?.expansion[0]?.year == '' && updated_data?.expansion[0]?.description == '') {
        delete updated_data['expansion'];
      }
      if (updated_data?.history[0]?.year == '' && updated_data?.history[0]?.description == '') {
        delete updated_data['history'];
      }


      // let updated_data;
      // console.log('@@@@updated_data', updated_data);
      const json_data = {
        data: { attributes: updated_data },
      };
      // console.log('@@@json data==', json_data);
      const httpBody = json_data;
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.businessProfileHistoryApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_profile/business_profiles/${this.state.profileId}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        'put'
      );
      // console.log(
      //   '@@@ Request ===========',
      //   requestMessage,
      //   this.businessProfileHistoryApiCallId
      // );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  };

  errorAlertModal = () => {
    return (
      <div data-testid="errorAlertModal">
        <AlertMessage
          data-testid="errorModal1"
          isModalVisible={this.state.isError}
          title='Business Profile is Not Updated'
          body={JSON.stringify(this.state.errorData)}
          isError={true}
          onOk={() => {
            this.setState({
              isError: false,
            });
          }}
        />
      </div>
    );
  };

  alertModal = () => {
    return (
      <div data-testid="alertModal">
        <AlertMessage
          data-testid="alertModal1"
          isModalVisible={this.state.isSuccess}
          title='Business Profile'
          body="Business Profile Updated"
          isError={false}
          onOk={() => {
            this.setState({
              isSuccess: false,
            });
            this.props.history.push({
              pathname: '/MyBusiness/Profile',
            });
          }}
        />
      </div>
    );
  };

  handlePrevious = () => {
    this.props.history.push({
      pathname: '/edit/BusinessProfile/about',
      state: { data: this.state.data, oldData: this.state.prevData },
    });
  }

  // ====================@@@ Post APi For Upload Image=======================
  uploadProfileImage = async (img: any) => {
    const header = {
      // "Content-Type": "multipart/form-data",
      token: this.state.token,
    };

    // console.log("this.state.profileUrl", this.state.file);

    const formData = new FormData();
    formData.append("profile_pic", img);

    console.log("@@@ upload==", formData);
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.postUploadProfileImageApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_profile/profiles/upload_profile_pic"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    console.log(
      "@@@ Request ===========",
      requestMessage,
      this.postUploadProfileImageApiCallId
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  uploadBackgroundImage = async (img: any) => {
    const headerData = {
      token: this.state.token,
    };

    const formData = new FormData();
    formData.append("cover_pic", img);
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.postUploadBackgroundImageCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_profile/profiles/upload_cover_pic"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerData)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  async GetProfileInformation() {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.GetProfielInformationId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.GetProfileInfoEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.SearchAPIMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // console.log("GetProfileInfo--->" + JSON.stringify(requestMessage));
    return true;
  }

}
