import React, { Component, MouseEvent } from "react";
// Customizable Area Start
import {
  PageHeader,
  Card,
  Row,
  Col,
  Form,
  Input,
  Typography,
  Button,
  Tooltip,
  Tag,
  Steps,
  Progress,
  Rate,
  Space,
  Select,
  Avatar,
  Upload,
  message,
  Modal, AutoComplete
} from "antd";
const { Title, Text } = Typography;
const style = require(`../../../social-media-account/src/login.module.css`);
import "../profile.css";
import { ExclamationCircleOutlined, CloseOutlined } from "@ant-design/icons";
import EditLanguageInterestController from "./EditLanguageInterestController.web";
import { AlertMessage } from "../../../alert/src/AlertMessage.web";
import { Item } from "native-base";
const { Step } = Steps;
// Customizable Area End
export default class EditLanguageInterest extends EditLanguageInterestController {
  // Customizable Area Start
  preventDefault = (e: MouseEvent) => {
    e.preventDefault();
    console.log("Clicked! But prevent default.");
  };
  rating = (data: any) => {
    console.log("Xxxxxxx", data);
    return (
      <Button shape="circle" size="small" style={{ backgroundColor: "red" }}>
        {" "}
      </Button>
    );
  };
  renderCloseModal = () => {
    return (
      <Modal
        className="delete_popup"
        title="Personal Profile"
        visible={this.state.isModalVisible}
        onOk={() => {
          this.deleteData(this.state.modalIndex);
        }}
        footer={null}
        onCancel={() =>
          this.setState({
            isModalVisible: false,
          })
        }
      >
        <p>
          Want to Delete {this.state.modalString} {this.state.modalIndex + 1} ?
        </p>
        <Row
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            paddingTop: "0.5rem",
            paddingBottom: "1rem",
          }}
        >
          <Col lg={12} md={12} sm={12} xs={12}>
            <Button
              className={style.btn_colors2}
              type="primary"
              shape="round"
              size={"large"}
              style={{ width: "200px" }}
              onClick={() => {
                this.setState({
                  isModalVisible: false,
                });
              }}
            >
              Cancel
            </Button>
          </Col>
          <Col lg={12} md={12} sm={12} xs={12}>
            <Button
              className={style.btn_danger}
              type="primary"
              shape="round"
              size={"large"}
              danger
              style={{ width: "200px" }}
              onClick={() => {
                this.deleteData(this.state.modalIndex);
              }}
            >
              Delete
            </Button>
          </Col>
        </Row>
      </Modal>
    );
  };
  showRow = () => {
    const { all_data } = this.state;
    return (
      <Row
        style={{
          // minHeight: "90vh",
          alignItems: "center",
          padding: "10% 0",
          backgroundColor: "#eff8fe",
        }}
      >
        <Col span={7} xs={2} sm={2} md={3} lg={7} xl={7} xxl={7} />
        <Col span={10} xs={20} sm={20} md={18} lg={10} xl={10} xxl={10}>
          <Title level={2} style={{ textAlign: "center" }}>
            Edit Your Personal Profile
          </Title>
          <Steps
            current={this.state.employment_type == "Student" ? 2 : 3}
            style={{ padding: "15px" }}
          >
            <Step />
            <Step />
            <Step />
            <Step />
            {/* <Step /> */}
            {this.state.employment_type != "Student" && <Step />}
          </Steps>
        </Col>
        <Col span={7} xs={2} sm={2} md={3} lg={7} xl={7} xxl={7} />
        <Col span={6} xs={2} sm={2} md={3} lg={3} xl={6} xxl={6} />
        <Col
          span={12}
          xs={20}
          sm={20}
          md={18}
          lg={18}
          xl={12}
          xxl={12}
          style={{ height: "100%" }}
          className="basic_profile_card"
        >
          {/* <Row className="experince-card-heading">Patent 1</Row> */}
          <Card className="card-body-certificate">
            {all_data.patents && all_data.patents.length > 0
              ? all_data.patents.map((item: any, index: any) => {
                return (
                  <div key={index}>
                    {/* <Row className="experince-card-heading"> */}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        backgroundColor: "#e0eaf4",
                        minHeight: "35px",
                      }}
                    >
                      <Title
                        level={4}
                        style={{
                          marginLeft: "15px",
                          marginTop: "10px",
                          color: "steelblue",
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        Patent {index + 1}
                      </Title>
                      <div
                        style={{
                          display: index === 0 ? "none" : "block",
                          cursor: "pointer",
                          padding: "1rem",
                        }}
                        onClick={() => this.showModal(index, "Patent")}
                      >
                        <CloseOutlined />
                      </div>
                    </div>
                    {/* </Row> */}
                    <Form
                      className="form-with-padding"
                      // initialValues={{
                      //   name: "",
                      // }}
                      onFinish={this.handlePatent}
                      ref={this.patent_formref}
                    >
                      <Row gutter={15}>
                        <Col
                          span={12}
                          xs={24}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          xxl={12}
                        >
                          <Form.Item
                            rules={[
                              { required: true, message: "Required field" },
                              {
                                min: 1,
                                message: "min character limit to 1",
                              },
                              {
                                max: 40,
                                message: "max character limit to 40",
                              },
                            ]}
                          // name="name"
                          >
                            <Input
                              className={style.input_fields}
                              name="name"
                              placeholder="Name"
                              value={item.name ? item.name : ''}
                              style={{ borderWidth: 1, color: "#a9bac8" }}
                              size="large"
                              onChange={async (e) => {
                                let pattern = /^[a-zA-Z\s]*$/
                                this.handleInputChange(e, index, "Patent")
                                if (item?.name.trim().length > 0 && !item.description) {
                                  console.log(item.description, "asd;adg", item.name)
                                  let error: any = [...this.state.patentsError]
                                  error[index] = { desc: error[index]?.desc, patent: "patent description should not be empty" }
                                  await this.setState({
                                    patentsError: error
                                  })
                                } else {
                                  // console.log(item.description, "adskjfahs", item.name, this.state.patentsError)
                                  let error: any = [...this.state.patentsError]
                                  error[index] = { desc: error[index]?.desc, patent: "" }
                                  await this.setState({
                                    patentsError: error
                                  })

                                }

                                let result = pattern.test(item?.name)
                                if (!result && item?.name?.trim()?.length > 0 || (item.description && !item.name)) {
                                  let error: any = [...this.state.patentsError]
                                  error[index] = { patent: "Enter only alphabets", desc: error[index]?.desc }
                                  this.setState({
                                    patentsError: error
                                  })
                                } else {
                                  // console.log(item.description, "adskjfahs asja", item.name, this.state.patentsError)
                                  let error: any = [...this.state.patentsError]
                                  error[index] = { patent: "", desc: error[index]?.desc }
                                  await this.setState({
                                    patentsError: error
                                  })
                                }

                              }

                              }
                            />
                            <Text style={{ color: 'red', fontSize: '12px', marginLeft: '15px' }}>{this.state.patentsError[index]?.patent}</Text>
                          </Form.Item>
                        </Col>
                        <Col
                          span={12}
                          xs={24}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          xxl={12}
                        >
                          <Form.Item
                            rules={[
                              { required: true, message: "Required field" },
                              {
                                min: 15,
                                message: "min character limit to 15",
                              },
                            ]}
                          // name="description"
                          >
                            <Input
                              className={style.input_fields}
                              name="description"
                              placeholder="Description"
                              value={item?.description ? item?.description : ''}
                              style={{ borderWidth: 1, color: "#a9bac8" }}
                              size="large"
                              onChange={async (e) => {
                                let pattern1 = /^[a-zA-Z\s]*$/
                                this.handleInputChange(e, index, "Patent")
                                let result = pattern1.test(item?.description)
                                if (!item.description && item.name) {
                                  let error: any = [...this.state.patentsError]
                                  error[index] = { desc: "patent description should not be empty", patent: error[index]?.patent }
                                  await this.setState({
                                    patentsError: error
                                  })
                                } else {
                                  let error: any = [...this.state.patentsError]
                                  error[index] = { desc: "", patent: error[index]?.patent }
                                  await this.setState({
                                    patentsError: error
                                  })
                                }
                                if (!result && item?.description?.trim().length > 0 || !item.description && item.name) {
                                  let error: any = [...this.state.patentsError]
                                  error[index] = { patent: error[index]?.patent, desc: "Enter only alphabets" }
                                  await this.setState({
                                    patentsError: error
                                  })
                                } else {
                                  let error: any = [...this.state.patentsError]
                                  error[index] = { patent: error[index]?.patent, desc: '' }
                                  await this.setState({
                                    patentsError: error
                                  })
                                }

                              }
                              }
                            />
                            <Text style={{ color: 'red', fontSize: '12px', marginLeft: '15px' }}>{this.state.patentsError[index]?.desc}</Text>
                          </Form.Item>
                        </Col>
                        <Row style={{ width: "100%" }}>
                          <Col
                            span={12}
                            xs={24}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            xxl={12}
                            style={{ paddingRight: 10, paddingLeft: 10 }}
                          >
                            <Button
                              className={style.experience_add_btn}
                              type="primary"
                              htmlType="submit"
                              shape="round"
                              size={"large"}
                              style={{
                                display:
                                  index === all_data.patents.length - 1
                                    ? "block"
                                    : "none",
                                width: "100%",
                                backgroundColor: "white",
                                borderColor: "#d9d9d9",
                              }}
                            >
                              Add Patent
                            </Button>
                          </Col>
                          <Col
                            span={12}
                            xs={0}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            xxl={12}
                          />
                        </Row>
                      </Row>
                    </Form>
                  </div>
                );
              })
              : ""}
            {all_data.other && all_data.other.length > 0
              ? all_data.other.map((item: any, index: any) => {
                return (
                  <div key={index}>
                    {/* <Row className="experince-card-heading"> */}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        backgroundColor: "#e0eaf4",
                        minHeight: "35px",
                      }}
                    >
                      <Title
                        level={4}
                        style={{
                          marginLeft: "15px",
                          marginTop: "10px",
                          color: "steelblue",
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        Interest {index + 1}
                      </Title>
                      <div
                        style={{
                          display: index === 0 ? "none" : "block",
                          cursor: "pointer",
                          padding: "1rem",
                        }}
                        onClick={() => this.showModal(index, "Interest")}
                      >
                        <CloseOutlined />
                      </div>
                    </div>
                    {/* </Row> */}
                    <Form
                      className="form-with-padding"
                      // initialValues={{
                      //   name: "",
                      // }}
                      onFinish={this.handleOtherInterest}
                      ref={this.other_formref}
                    >
                      <Row gutter={15}>
                        <Col
                          span={12}
                          xs={24}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          xxl={12}
                        >
                          <Form.Item
                            rules={[
                              { required: true, message: "Required field" },
                              {
                                min: 1,
                                message: "min character limit to 1",
                              },
                              {
                                max: 40,
                                message: "max character limit to 40",
                              },
                            ]}
                          // name="name"
                          >
                            <Input
                              className={style.input_fields}
                              name="name"
                              placeholder="Category"
                              value={item.name}
                              style={{ borderWidth: 1, color: "#a9bac8" }}
                              size="large"
                              onChange={(e) => {
                                let pattern = /^[a-zA-z][a-zA-z\s]*$/
                                this.handleInputChange(e, index, "Other")
                                let result = pattern.test(e.target.value)
                                if (!result && e.target?.value?.trim()?.length > 0) {
                                  let error: any = [...this.state.interestError]
                                  error[index] = "interest should not contain any type of number"
                                  this.setState({
                                    interestError: error
                                  })
                                } else {
                                  let error: any = [...this.state.interestError]
                                  error[index] = ""
                                  this.setState({
                                    interestError: error
                                  })
                                }
                              }
                              }
                            />
                            <Text style={{ color: 'red', fontSize: '12px' }}>{this.state.interestError[index]}</Text>
                          </Form.Item>
                        </Col>
                        <Row style={{ width: "100%" }}>
                          <Col
                            span={12}
                            xs={24}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            xxl={12}
                            style={{ paddingRight: 10, paddingLeft: 10 }}
                          >
                            <Button
                              className={style.experience_add_btn}
                              type="primary"
                              htmlType="submit"
                              shape="round"
                              size={"large"}
                              style={{
                                display:
                                  index === all_data.other.length - 1
                                    ? "block"
                                    : "none",
                                width: "100%",
                                backgroundColor: "white",
                                borderColor: "#d9d9d9",
                              }}
                            >

                              Add Other Interest
                            </Button>
                          </Col>
                          <Col
                            span={12}
                            xs={0}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            xxl={12}
                          />
                        </Row>
                      </Row>
                    </Form>
                  </div>
                );
              })
              : ""}
            {/* {all_data.language && all_data.language.length > 0
            ? all_data.language.map((item: any, index: any) => {
                return ( */}
            {/* <div key={index}> */}
            {/* <Row className="experince-card-heading"> */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                backgroundColor: "#e0eaf4",
                minHeight: "35px",
              }}
            >
              <Title
                level={4}
                style={{
                  marginLeft: "15px",
                  marginTop: "10px",
                  color: "steelblue",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Languages
              </Title>
              <div
                style={{
                  // display: index === 0 ? "none" : "block",
                  cursor: "pointer",
                  padding: "1rem",
                }}
              // onClick={() => this.showModal(index, "Language")}
              >
                {/* <CloseOutlined translate={{}} /> */}
              </div>
            </div>
            {/* <Row style={{ padding: "24px 0px 24px 24px" }}>
              <Col
                xs={24}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                xxl={12}
              >
                <Select
                  value={"Add Language"}
                  removeIcon={false}
                  className={style.input_fields_select}
                  placeholder="Add Languages"
                  bordered={true}
                  dropdownStyle={{
                    borderRadius: "15px",
                    border: "1px solid #a9bac8",
                    paddingTop: "0px",
                    paddingBottom: "0px",
                  }}
                  size="large"
                  style={{
                    padding: "0 !important",
                    borderWidth: 1,
                    borderRadius: "17px",
                    border: '1px solid #a9bac8',
                    color: "#a9bac8",
                    overflow: "hidden",
                  }}
                  onChange={this.changeLanguage}
                >
                  {this.state.children}
                </Select>
              </Col>
            </Row> */}
            <Row style={{ padding: "24px 0px 24px 24px" }}>
              <Col
                xs={24}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                xxl={12}
              >
                <AutoComplete
                  placeholder="Add Language"
                  options={this.state.editselectedLanguageData}
                  onSearch={this.editHandleSearch}
                  onSelect={this.changeLanguage}
               
                  status="warning"
                  className={style.input_fields_select}
                  bordered={true}
                  dropdownStyle={{
                    borderRadius: "15px",
                    border: "1px solid #a9bac8",
                    paddingTop: "0px",
                    paddingBottom: "0px",
                  }}
                  size="large"
                  style={{
                    padding: "0 !important",
                    borderWidth: 1,
                    borderRadius: "17px",
                    border: '1px solid #a9bac8',
                    color: "#a9bac8",
                    overflow: "hidden",
                  }}
                />

                {/* {this.state.children} */}
              </Col>
            </Row>
            <Row style={{ padding: "0px 24px", display: "flex", flexDirection: "row", flexWrap: "wrap", alignItems: "center", justifyContent: "space-between" }}>
              {this.state.selectedLanguages.map((data: any, index: number) => {
                return (
                  <Col
                    xs={24}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    xxl={12}
                  >
                    <Tag
                      style={{
                        backgroundColor: "#fff",
                        marginBottom: "20px",
                      }}
                      key={index}
                    >
                      <Row
                        style={{
                          flexDirection: "row",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <p
                          style={{
                            color: "#0e76a8",
                          }}
                        >
                          {data.language}
                          <Tooltip
                            placement="bottomLeft"
                            title={`${data.language}`}
                            color="#DEE4EA"
                            overlayInnerStyle={{
                              color: "black",
                            }}
                          >
                            <ExclamationCircleOutlined
                              style={{
                                marginLeft: "5px",
                                color: "#ccc",
                              }}
                            />
                          </Tooltip>
                        </p>
                        <Button
                          onClick={() => this.removeLanguage(index)}
                          type="link"
                          icon={<CloseOutlined />}
                          size="small"
                        />
                      </Row>
                      <Row
                        style={{
                          flexDirection: "row",
                          justifyContent: "space-between",
                          width: "100%",
                          // paddingVertical: 10,
                        }}
                      >
                        <Text
                          style={{
                            color: "#0e76a8",
                            fontSize: 15,
                            // fontWeight: "700",
                          }}
                        >
                          M
                        </Text>
                        <Text
                          style={{
                            color: "#0e76a8",
                            fontSize: 15,
                            marginRight: "10px",
                          }}
                        >
                          F
                        </Text>
                      </Row>
                      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item) => {
                        return (
                          <Button
                            shape="round"
                            size="small"
                            style={{
                              backgroundColor:
                                item <= data.fluency ? "#0e76a8" : "#ccc",
                              marginRight: "5px",
                              height: "18px",
                              padding: " 0px 8px",
                              borderRadius: "9px",
                            }}
                            onClick={() => {
                              this.changeProficiency(item, index);
                            }}
                          >
                            {" "}
                          </Button>
                        );
                      })}
                    </Tag>
                  </Col>
                );
              })}
            </Row>
          </Card>
          <Row style={{ background: "#f8f8f8" }}>
            <Col lg={12} xl={12} md={12} sm={12} xs={24}
              className={style.responsive_bottom_action_button} style={{ padding: "15px", }}>
              <Button
                onClick={this.skipProfile}
                className={style.skip_add_btn}
                type="primary"
                disabled={this.state.disableNextButton}
                htmlType="submit"
                shape="round"
                size={"large"}
                style={{ width: "100%" }}
              >
                Skip Profile Setup
              </Button>
            </Col>
            <Col
              lg={12} xl={12} md={12} sm={12} xs={24}
              className={style.responsive_bottom_action_button}
              style={{
                padding: "15px 0px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "end"
              }}
            >
              <Button
                className={style.previous_btn}
                type="primary"
                shape="round"
                onClick={() => this.handlePrevious()}
                size={"large"}
              >
                Previous
              </Button>
              <Button
                className={style.btn_colors}
                type="primary"
                disabled={this.state.disableNextButton}
                onClick={this.handleNext}
                onDoubleClick={() => { }}
                shape="round"
                size={"large"}
              >
                Next
              </Button>
            </Col>
          </Row>
        </Col>
        <Col span={6} xs={2} sm={2} md={3} lg={3} xl={6} xxl={6} />
      </Row>
    )
  }
  render() {
    const { all_data } = this.state;
    return (
      <div
        style={{
          backgroundColor: "#eff8fe",
          height: "100%",
        }}
      >
        {this.renderCloseModal()}
        {this.redirectUser()}

        {/* <PageHeader
          className="site-page-header"
          title={
            <span style={{ color: "white" }}>
              <img style={{ height: "32px", marginRight: "8px" }} src={logo} />
              applied
            </span>
          }
          style={{
            backgroundImage: "linear-gradient(#005574, #7aacd6)",
            color: "white",
          }}
        /> */}
        {this.showRow()}
        <AlertMessage
          isModalVisible={this.state.isErrorModalShow}
          title={this.state.errorContent.title}
          body={this.state.errorContent.body}
          isError={this.state.errorContent.isError}
          onOk={() =>
            this.setState({ isErrorModalShow: false, errorContent: {} })
          }
        />
      </div>
    );
  }
  // Customizable Area End
}
// Customizable Area Start
// Customizable Area End 