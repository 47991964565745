import React, { Component } from 'react';
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { BlockComponent } from '../../../framework/src/BlockComponent';
import { IBlock } from "../../../framework/src/IBlock";
import confirm from "../../alert/src/alert.web";
import AlertError from "../../alert/src/alertError.web";
import { RouterProps } from "react-router";
export const configJSON = require("./config");
export interface Props extends RouterProps {
    navigation: any;
    id: string;
}
interface S {
    loading: boolean;
    prevData: any;
    data: any,
    companyOverView: any,
    companyMission: any,
    companyVision: any,
    companyCoreValues: any,
    healthSafetyEnviroment: any,
    productServices: any,
    contactPhone: any,
    contactEmail: any,
    profileLoading: boolean;
}
interface SS {
    id: any;
}

export default class EditBusinessOverviewController extends BlockComponent<Props, S, SS> {
    postbusinessOverviewApiCallId: any;

    formref = React.createRef();
    constructor(props: Props) {
        super(props)
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
        ];
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        this.receive = this.receive.bind(this);
        this.state = {
            loading: true,
            prevData: {},
            data: {},
            companyOverView: '',
            companyMission: '',
            companyVision: '',
            companyCoreValues: '',
            healthSafetyEnviroment: '',
            productServices: '',
            contactPhone: '',
            contactEmail: '',
            profileLoading: true
        }
    };


    // @@@ Receive Data of Profile Basic Screen==============
    async componentDidMount() {
        console.log("Profile OverView========== ", this.props.history?.location?.state);
        let details: any = this.props.history?.location?.state;
        if (details) {
            this.setState({
                prevData: details?.oldData,
                data: details?.data,
                profileLoading: false
            })
        } else {
            this.setState({
                profileLoading: false
            })
        }
    };

    // @@@ Handle Data of Profile Overview Screen==============

    onHistoryScreen = () => {
        // let updated_data = {...this.state?.data, ...data};
        console.log("updated_data21", this.state.data, this.state?.prevData)
        this.props.history.push({
            pathname: "/edit/BusinessProfile/Overview",
            state: { data: this.state?.data, oldData: this.state?.prevData },
        })
    };

    handleAddProfileOverviewData = (data: object) => {
        // console.log('@@@ business overview====',data)
        let updated_data = { ...this.state.data, ...data };
        console.log(updated_data)
        this.props.history.push({
            pathname: "/edit/BusinessProfile/Overview",
            state: { data: updated_data, oldData: this.state.prevData },
        })
    };

    // Customizable Area End
}