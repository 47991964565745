import React, { Component } from "react";
// Customizable Area Start
import {
  PageHeader,
  Card,
  Row,
  Col,
  Form,
  Input,
  Typography,
  Button,
  Steps,
  Modal,
  message,
  Alert,
  Spin
} from "antd";
const { Title, Text } = Typography;
const style = require(`../../../social-media-account/src/login.module.css`);
import { gmail, linkedIn, phoneBook, } from "../assets";
import "../profile.css";
import {
  MailOutlined,
  LinkedinOutlined,
  ContactsOutlined
} from "@ant-design/icons";
import GoogleContacts from "react-google-contacts";
import { GoogleLogin } from 'react-google-login';
import EditGeneralInformationControllers from "./EditGeneralInformationControllers.web";
const { TextArea } = Input;
const { Step } = Steps;
import { AlertMessage } from "../../../alert/src/AlertMessage.web";
import CloseOutlined from "@ant-design/icons/lib/icons/CloseOutlined";
import { Item } from "native-base";
const userRegex = /^[a-zA-Z ]*$/;
// var clientId =
//   "649592030497-gp3moqh0k2src5r2u5qfaorii1dqkvdc.apps.googleusercontent.com"; // clientId received from API
const clientId = "1347984169-jc6ua33msljth2c13ld03ceili389dkc.apps.googleusercontent.com";
// Customizable Area End
export default class EditGeneralInformation extends EditGeneralInformationControllers {
  // Customizable Area Start
  responseCallback = (response: any) => {
    console.log(response);
  };
  renderCloseModal = () => {
    return (
      <Modal
        className="delete_popup"
        title="Personal Profile"
        visible={this.state.isModalVisible}
        onOk={() => {
          this.deleteData(this.state.modalIndex);
        }}
        footer={null}
        onCancel={() =>
          this.setState({
            isModalVisible: false
          })
        }
        style={{
          padding: 18
        }}
      >
        {/* <p>{this.state.modalIndex}</p> */}
        <p>
          Want to Delete {this.state.modalString} {this.state.modalIndex + 1} ?
        </p>
        <Row
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            paddingTop: "0.3rem",
            paddingBottom: "1rem"
          }}
        >
          <Col lg={12} md={12} sm={12} xs={12}>
            <Button
              className={style.btn_colors2}
              type="primary"
              shape="round"
              size={"large"}
              style={{ width: "200px" }}
              onClick={() => {
                this.setState({
                  isModalVisible: false
                });
              }}
            >
              Cancel
            </Button>
          </Col>
          <Col lg={12} md={12} sm={12} xs={12}>
            <Button
              className={style.btn_danger}
              type="primary"
              shape="round"
              size={"large"}
              danger
              style={{ width: "200px" }}
              onClick={() => {
                this.deleteData(this.state.modalIndex);
              }}
            >
              Delete
            </Button>
          </Col>
        </Row>
      </Modal>
    );
  };
  gmailContact = () => {
    return (
      <Col className="col-container">
        <GoogleLogin
          scope="https://www.googleapis.com/auth/contacts.readonly"
          clientId={clientId}
          render={(renderProps: any) => (
            <Button
              size="large"
              className="button-circle"
              onClick={() => renderProps.onClick()}
              // icon={<><MailOutlined /></>} 
              icon={<img className="button-icon" src={gmail} alt="Gmail icon" />}


            />
          )}
          buttonText="Import"
          onSuccess={this.onLoginSuccess}
          onFailure={this.onLoginFailure}
          cookiePolicy={'single_host_origin'}
        // isSignedIn={true}
        />
        <br />
        <Text>Gmail</Text>
      </Col>
    )
  }
  renderContactModal = () => {
    return (
      <Modal
        width={320}
        visible={this.state.isImportContactVisible}
        title="Import Contact"
        onOk={() =>
          this.setState({
            isImportContactVisible: false
          })
        }
        onCancel={() =>
          this.setState({
            isImportContactVisible: false
          })
        }
        footer={null}
      >
        <Card
          style={{
            alignItems: "center",
            justifyContent: "center",
            borderWidth: "0px"
          }}
        >
          <Row style={{ display: "flex", justifyContent: "center" }}>
            {/* <Col span={1} /> */}
            {/* <Col span={20} style={{ textAlign: "center" }}> */}

            {this.gmailContact()}

            {/* <Col className="col-container">
        <Button
            size="large"
          className="button-circle"
              // onClick={() => renderProps.onClick()}
                  icon={<img className="button-icon" src={linkedIn} alt="linkedIn icon" />}
            />
             <br />
                <Text className="text">Linkedin</Text>
              </Col> */}
            {/* <Col className="col-container">
        <Button
            size="large"
          className="button-circle"
              // onClick={() => renderProps.onClick()}
                  icon={<img className="button-icon" src={phoneBook} alt="Phonebook icon" />}
            />
             <br />
                <Text className="text">Phonebook</Text>
              </Col> */}
          </Row>
        </Card>
      </Modal>
    );
  };
  isNumberKey = (evt: any) => {
    // debugger
    var charCode = (evt?.which) ? evt?.which : evt?.keyCode
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      this.setState({
        editNum: false
      })
      return false;
    }
    this.setState({
      editNum: true
    })
    return true;
  }
  alertMessage = () => {
    return (
      <AlertMessage
        isModalVisible={this.state.isErrorModalShow}
        title={this.state.errorContent.title}
        body={this.state.errorContent.body}
        isError={this.state.errorContent.isError}
        onOk={() => {
          this.setState({
            isErrorModalShow: false,
            errorContent: {}
          });
          if (!this.state.errorContent.isError) {
            // this.props.history.push('/MyPersonalProfile/Profile')
            this.props.history.push("/home");
          }
        }}
      />
    )
  }

  render() {
    const { all_data } = this.state;
    return (
      <div
        style={{
          backgroundColor: "#eff8fe",
          height: "100%"
        }}
      >
        {this.renderCloseModal()}
        {/* {this.alertModal()} */}
        {this.redirectUser()}

        <Row
          style={{
            // minHeight: "90vh",
            alignItems: "center",
            padding: "10% 0",
            backgroundColor: "#eff8fe"
          }}
        >
          <Col span={7} xs={2} sm={2} md={3} lg={7} xl={7} xxl={7} />
          <Col span={10} xs={20} sm={20} md={18} lg={10} xl={10} xxl={10}>
            <Title level={2} style={{ textAlign: "center" }}>
              Edit Your Personal Profile
            </Title>
            <Steps
              current={this.state.employment_type == "Student" ? 3 : 4}
              style={{ padding: "15px" }}
            >
              <Step />
              <Step />
              <Step />
              <Step />
              {this.state.employment_type != "Student" && <Step />}
            </Steps>
          </Col>
          <Col span={7} xs={2} sm={2} md={3} lg={7} xl={7} xxl={7} />
          <Col span={6} xs={2} sm={2} md={3} lg={3} xl={6} xxl={6} />
          <Col
            span={12}
            xs={20}
            sm={20}
            md={18}
            lg={18}
            xl={12}
            xxl={12}
            style={{ height: "100%" }}
            className="basic_profile_card"
          >
            {/* <Row className="experince-card-heading">Reference 1</Row> */}
            <Card className="card-body-certificate">
              {all_data.contact && all_data.contact.length > 0
                ? all_data.contact.map((item: any, index: any) => {
                  return (
                    <div key={index}>
                      {/* <Row className="experince-card-heading"> */}
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          backgroundColor: "#e0eaf4",
                          minHeight: "35px"
                        }}
                      >
                        <Title
                          level={4}
                          style={{
                            marginLeft: "15px",
                            marginTop: "10px",
                            color: "steelblue",
                            height: "100%",
                            display: "flex",
                            alignItems: "center"
                          }}
                        >
                          Reference {index + 1}
                        </Title>
                        <div
                          style={{
                            display: index === 0 ? "none" : "block",
                            cursor: "pointer",
                            padding: "1rem"
                          }}
                          onClick={() => this.showModal(index, "Reference")}
                        >
                          <CloseOutlined />
                        </div>
                      </div>
                      {/* </Row> */}
                      <Form
                        className="form-with-padding"
                        // initialValues={{
                        //   name: "",
                        // }}
                        onFinish={this.handleAddContact}
                        ref={this.contact_formref}
                      >
                        <Row gutter={15}>
                          <Col
                            span={12}
                            xs={24}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            xxl={12}
                          >
                            <Form.Item
                              rules={[
                                {
                                  required: true,
                                  message: "Required field"
                                },
                                {
                                  min: 1,
                                  max: 40,
                                  message: "Max length shold be 40"
                                },
                                // ({ getFieldValue }) => ({
                                //   validator(_, value) {
                                //     if (
                                //       !value ||
                                //       getFieldValue("name").match(userRegex)
                                //     ) {
                                //       return Promise.resolve();
                                //     }
                                //     return Promise.reject(
                                //       new Error("can enter only alphabets")
                                //     );
                                //   }
                                // })
                              ]}
                            // name="name"
                            >
                              <Input
                                className={style.input_fields}
                                name="name"
                                placeholder="Name"
                                value={item?.name && item?.name}
                                style={{ borderWidth: 1 }}
                                onChange={e => {
                                  let pattern = /^[a-zA-z][a-zA-z\s]*$/
                                  this.handleInputChange(
                                    e,
                                    index,
                                    "information"
                                  )
                                  if (item?.name.trim().length > 0 && !item.number) {
                                    let error: any = [...this.state.contactNumberError]
                                    error[index] = { error: "Required Field!" }
                                    this.setState({
                                      contactNameError: error
                                    })
                                  } else {
                                    let error: any = [...this.state.contactNumberError]
                                    error[index] = { error: "" }
                                    this.setState({
                                      contactNameError: error
                                    })
                                  }
                                  let result = pattern.test(e.target.value)
                                  if (!result && e.target.value.trim().length > 0 || item.number && !item.name) {
                                    let error: any = [...this.state.contactNameError]
                                    error[index] = { error: "Enter only alphabets" }
                                    this.setState({
                                      contactNameError: error
                                    })
                                  } else {
                                    let error: any = [...this.state.contactNameError]
                                    error[index] = { error: "" }
                                    this.setState({
                                      contactNameError: error
                                    })
                                  }
                                }
                                }
                              />
                              <Text style={{ color: 'red', fontSize: '12px', paddingLeft: "16px" }}>{this.state.contactNameError[index]?.error}</Text>
                            </Form.Item>
                          </Col>
                          <Col
                            span={12}
                            xs={24}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            xxl={12}
                          >
                            <Form.Item
                              rules={[
                                {
                                  required: true,
                                  message: "Required field"
                                },
                                // ({ getFieldValue }) => ({

                                //   validator(_, value) {
                                //     if (
                                //       !value ||
                                //       getFieldValue("contact").match(
                                //         phoneRegExp
                                //       )
                                //     ) {

                                //       return Promise.resolve();
                                //     }
                                //     return Promise.reject(
                                //       new Error("Incorrect Phone Number")
                                //     );
                                //   }
                                // })
                              ]}
                            // name="contact"
                            >
                              <Input
                                className={style.input_fields}
                                name="number"
                                placeholder="Contact"
                                style={{ borderWidth: 1, color: "#a9bac8" }}
                                size="large"
                                type="text"
                                onKeyPress={(event: any) => {
                                  console.log(this.state.all_data)
                                  return this.isNumberKey(event)
                                }}
                                value={item.number}
                                onChange={e => {
                                  let pattern = /^[3-9]\d*$/;
                                  this.handleInputChange(
                                    e,
                                    index,
                                    "information"
                                  )
                                  if (item?.number.trim().length > 0 && !item.name) {
                                    let error: any = [...this.state.contactNumberError]
                                    error[index] = { error: "Required Field!" }
                                    this.setState({
                                      contactNumberError: error
                                    })
                                    console.log("name errro =>", this.state.contactNumberError)
                                  } else {
                                    let error: any = [...this.state.contactNumberError]
                                    error[index] = { error: "" }
                                    this.setState({
                                      contactNumberError: error
                                    })
                                  }
                                  let result = pattern.test(e.target.value)
                                  console.log("result ==>", result)
                                  if (item.name.trim().length === 0) {
                                    let error: any = [...this.state.contactNumberError]
                                    error[index] = { error: "Required Field!" }
                                    this.setState({
                                      contactNameError: error
                                    })
                                  }
                                  if (!result && e.target.value.trim().length > 0) {
                                    let error: any = [...this.state.contactNumberError]
                                    error[index] = { error: "Please enter valid number!" }
                                    this.setState({
                                      contactNumberError: error
                                    })
                                  } else {
                                    let error: any = [...this.state.contactNumberError]
                                    error[index] = { error: "" }
                                    this.setState({
                                      contactNumberError: error
                                    })
                                  }
                                }}



                              />
                              <Text style={{ color: 'red', fontSize: '12px', paddingLeft: "16px" }}>{this.state.contactNumberError[index]?.error}</Text>
                            </Form.Item>
                            {this.renderContactModal()}
                          </Col>
                        </Row>
                        <Row gutter={15}>
                          <Col
                            span={12}
                            xs={24}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            xxl={12}
                          >
                            <Button
                              className={style.experience_add_btn}
                              type="primary"
                              htmlType="submit"
                              shape="round"
                              size={"large"}
                              style={{
                                display:
                                  index === all_data.contact.length - 1
                                    ? "block"
                                    : "none",
                                width: "100%",
                                backgroundColor: "white",
                                borderColor: "#d9d9d9"
                              }}
                            >
                              Add Contact
                            </Button>
                          </Col>
                          <Col
                            span={12}
                            xs={24}
                            sm={0}
                            md={0}
                            lg={0}
                            xl={0}
                            xxl={0}
                          >
                            <Button disabled={true} size="small" />
                          </Col>
                          <Col
                            span={12}
                            xs={24}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            xxl={12}
                          >
                            <Button
                              className={style.experience_add_btn}
                              type="primary"
                              shape="round"
                              size={"large"}
                              onClick={
                                () =>
                                  this.setState({
                                    isImportContactVisible: true
                                  })
                                // message.info('Coming soon')
                              }
                              style={{
                                display:
                                  index === all_data.contact.length - 1
                                    ? "block"
                                    : "none",
                                width: "100%",
                                backgroundColor: "white",
                                borderColor: "#d9d9d9"
                              }}
                            >
                              Import Contact
                            </Button>
                            {this.renderContactModal()}
                          </Col>
                        </Row>
                      </Form>
                    </div>
                  );
                })
                : ""}
              <div>
                {/* <Row className="experince-card-heading"> */}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    backgroundColor: "#e0eaf4",
                    minHeight: "35px"
                  }}
                >
                  <Title
                    level={4}
                    style={{
                      marginLeft: "15px",
                      marginTop: "10px",
                      color: "steelblue",
                      height: "100%",
                      display: "flex",
                      alignItems: "center"
                    }}
                  >
                    General Information
                  </Title>
                </div>
              </div>
              {/* <Row className="experince-card-heading">
                General Information 1
              </Row> */}
              {this.state.loading ? (
                <Spin />
              ) : (
                <Form
                  className="form-with-padding-2"
                  initialValues={{
                    information: all_data.information
                      ? all_data.information
                      : ""
                  }}
                  style={{ paddingBottom: "0px" }}
                  onFinish={this.handleGeneralInfo}
                  ref={this.general_formref}
                >
                  <Row gutter={15}>
                    <Col
                      span={12}
                      xs={24}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      xxl={12}
                    >
                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: "Required field"
                          },
                          {
                            min: 15,
                            message: "Please Enter more than 15 Characters"

                          }
                        ]}
                        name="information"
                      >
                        <TextArea
                          autoSize={{ minRows: 4, maxRows: 4 }}
                          className={style.input_fields_TextArea}
                          placeholder="Description"
                          style={{ borderWidth: 1 }}
                          onChange={e =>
                            this.setState(
                              state => (
                                (state.all_data.information = e.target.value),
                                state
                              )
                            )
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col
                      span={12}
                      xs={24}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      xxl={12}
                    />
                  </Row>
                </Form>
              )}
            </Card>
            <Row style={{ background: "#f8f8f8" }}>
              <Col lg={12} xl={12} md={12} sm={12} xs={24}
                className={style.responsive_bottom_action_button} style={{ padding: "15px", }}>
                {/* <Button
                  onClick={this.skipProfile}
                  className={style.skip_add_btn}
                  type="primary"
                  htmlType="submit"
                  shape="round"
                  size={"large"}
                  style={{ width: "100%" }}
                >
                  Skip Profile Setup
                </Button> */}
              </Col>
              <Col
                lg={12} xl={12} md={12} sm={12} xs={24}
                className={style.responsive_bottom_action_button}
                style={{
                  padding: "15px 0px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "end"
                }}
              >
                <Button
                  className={style.previous_btn}
                  type="primary"
                  shape="round"
                  onClick={() => this.handlePrevious()}
                  size={"large"}
                >
                  Previous
                </Button>
                <Button
                  className={style.btn_colors}
                  type="primary"
                  loading={this.state.isSubmitting}
                  htmlType="submit"
                  disabled={this.state.disableNextButton}
                  onClick={this.validateOnSubmit}
                  shape="round"
                  size={"large"}
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </Col>
          <Col span={6} xs={2} sm={2} md={3} lg={3} xl={6} xxl={6} />
        </Row>
        {this.alertMessage()}
      </div>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End 