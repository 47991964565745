import React, { Component } from "react";
import { runEngine } from "../../../../framework/src/RunEngine";
import { Message } from "../../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { IBlock } from "../../../../framework/src/IBlock";
import { RouterProps } from "react-router";
import { Redirect } from "react-router-dom";
import { FormInstance } from "antd/lib/form";
export const configJSON = require("../config");
import { Checkbox, Select, message } from "antd";


const { Option } = Select;
export interface Props extends RouterProps {
  navigation: any;
  id: string;
}
export interface AllData {
  patents: [
    {
      name: string;
      description: string;
    }
  ];
  other: [
    {
      name: string;
    }
  ];
  language: [
    {
      name: string;
      fluency: string;
    }
  ];

}
interface S {
  loading: boolean;
  isRegistration: boolean;
  bussiness_profile: boolean;
  data: any;
  patents: any[];
  others: any[];
  categories_id: string | null;
  category_data: [];
  interests: object[];
  children: any[];
  languagesData: object[];
  pre_data: any;
  all_data: AllData;
  languages: [];
  selectedLanguages: any;
  token: string | null;
  employment_type: string | null;
  isErrorModalShow: boolean;
  errorContent: any;
  isModalVisible: any;
  modalIndex: number;
  modalString: string;
  prev_data: any;
  redirect: boolean;
  languageValue: string;
  interestError: any[];
  patentsError: any[];
  disableNextButton: boolean,
  editselectedLanguageData: any
}
interface SS {
  id: any;
}

export default class EditLanguageInterestController extends BlockComponent<
  Props,
  S,
  SS
> {
 // Customizable Area Start
  createAccountApiCallId: any;
  patent_formref = React.createRef<FormInstance>();
  other_formref = React.createRef<FormInstance>();
  language_formref = React.createRef<FormInstance>();
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.receive = this.receive.bind(this);
    this.state = {
      interestError: [],
      patentsError: [],
      bussiness_profile: true,
      loading: true,
      isRegistration: true,
      data: {},
      prev_data: {},
      token: localStorage.getItem("token") ? localStorage.getItem("token") : "",
      pre_data: localStorage.getItem("data")
        ? localStorage.getItem("data")
        : [],
      patents: [],
      children: [],
      others: [],
      languages: [],
      interests: [],
      languagesData: [],
      selectedLanguages: [],
      categories_id: "",
      category_data: [],
      employment_type: localStorage.getItem("employment_type")
        ? localStorage.getItem("employment_type")
        : "",
      isErrorModalShow: false,
      errorContent: {},
      all_data: {
        patents: [
          {
            name: '',
            description: '',
          },
        ],
        other: [
          {
            name: '',

          },
        ],
        language: [
          {
            name: '',
            fluency: ''
          },
        ],

      },
      isModalVisible: false,
      modalIndex: 0,
      modalString: '',
      redirect: false,
      languageValue: "",
      disableNextButton: false,
      editselectedLanguageData: []
    };

  }
  async componentDidMount() {
    let token = await localStorage.getItem("token");
    if (token && token !== null && token !== undefined) {
      this.setState({ redirect: false });
    } else {
      this.setState({ redirect: true });
    }
    this.getCategories();
    // console.log("hello ========== ", this.props.history.location.state);
    let LanguageDetails: any = this.props.history.location.state;
    if (LanguageDetails && LanguageDetails != null) {
      let newData = Object.assign(
        {},
        { experience: LanguageDetails && LanguageDetails.experience },
        { profile: LanguageDetails && LanguageDetails.profile },
        { graduation: LanguageDetails && LanguageDetails.graduation },
        { certificate: LanguageDetails && LanguageDetails.certificate },
        { contact: LanguageDetails && LanguageDetails.contact }
      );
      this.setState({
        prev_data: newData,
      });
      console.log(LanguageDetails, "asdkjald")
      let patentErrorList = LanguageDetails?.language?.patents?.map((item: any, index: number) => {
        let pattern = /^[a-zA-z][a-zA-z\s]*$/
        let result = item?.name?.trim()?.length === 0 ? true : pattern.test(item.name)
        if (!result) {
          return { patent: 'patents should not be contains any type of number', desc: '' }
        }
        return { patent: '', desc: '' }
      })
      let interestErrorList = LanguageDetails?.language?.other?.map((item: any, index: number) => {
        let pattern = /^[a-zA-z][a-zA-z\s]*$/
        let result = item?.name?.trim()?.length === 0 ? true : pattern.test(item.name)
        if (!result) {
          return 'instersts should not be contains any type of number'
        }
        return ' '
      })
      if (LanguageDetails && LanguageDetails.language) {
        this.setState({
          all_data: LanguageDetails && LanguageDetails.language,
          selectedLanguages: LanguageDetails?.language?.language,
          patentsError: patentErrorList,
          interestError: interestErrorList
        });
      }
    }
    this.setState({ loading: false });

  }

  redirectUser() {
    if (this.state.redirect) {
      return <Redirect to="/" />;
    }
  }
  handlePatent = (values: any) => {
    // let append_data = [...this.state.patents, data];
    // this.setState(
    //   {
    //     patents: append_data,
    //   },
    //   () => this.patent_formref.current!.resetFields()
    // );
    let all_data = this.state.all_data;
    if (all_data.patents) {
      all_data.patents.push(values);
    } else {
      Object.assign(all_data, { patents: [values] });
    }

    console.log(
      '@@@ patents data====',
      values,
      '@@@ patents data====',
      all_data
    ),
      this.setState({ all_data: all_data }, () => {
        this.patent_formref.current!.resetFields();
      });
  };
  handleOtherInterest = (values: any) => {
    // let append_data = [...this.state.others, data];
    // this.setState(
    //   {
    //     others: append_data,
    //   },
    //   () => this.other_formref.current!.resetFields()
    // );
    let all_data = this.state.all_data;
    if (all_data.other) {
      all_data.other.push(values);
    } else {
      Object.assign(all_data, { other: [values] });
    }

    // console.log(
    //   '@@@ other data====',
    //   values,
    //   '@@@ other data====',
    //   all_data
    // ),
    this.setState({ all_data: all_data }, () => {
      this.other_formref.current!.resetFields();
    });
  };
  changeLanguage = async (data: any) => {
    let index = this.state.selectedLanguages.findIndex((value: any) => value.language == data);

    if (index == -1) {
      const lang = {
        language: data,
        fluency: 0,
      };
      this.setState({
        selectedLanguages: [...this.state.selectedLanguages, lang],
      });
    } else {
      const lang = {
        language: data,
        fluency: 0,
      };
      const language = this.state.selectedLanguages;
      language.splice(index, 1, lang);

      this.setState({
        selectedLanguages: language,
      });


    }
  };
  removeLanguage = (index: any) => {
    const lang = this.state.selectedLanguages;
    lang.splice(index, 1);
    this.setState({
      selectedLanguages: lang,
    });
  };
  changeProficiency = (data: any, index: any) => {
    const lan = this.state.selectedLanguages;
    lan[index].fluency = data;
    this.setState({
      selectedLanguages: lan,
    });
    console.log(lan, 'language')
  };
  handleLanguages = (values: any) => {
    // this.setState({
    //   languages: data,
    // });
    // let all_data = this.state.all_data;
    // if (all_data.language) {
    //   all_data.language.push(values);
    // } else {
    //   Object.assign(all_data, { language: [values] });
    // }

    // console.log(
    //   '@@@ language data==== value',
    //   values,
    //   '@@@ language data==== all_data',
    //   all_data
    // ),
    //   this.setState({ all_data: all_data }, () => {
    //     this.language_formref.current!.resetFields();
    //   });
    this.setState((state) => ((state.all_data.language = this.state.selectedLanguages), state));
  };
  skipProfile = async () => {
    let m = await this.state.all_data?.patents.filter((item: any, index: number) => {
      if (item?.name !== undefined && item?.name !== '' && (item?.description === '' || item?.description === undefined)) {
        console.log(item?.name, (item?.name !== '' || item?.name !== undefined))
        let error: any = [...this.state.patentsError]
        error[index] = { patent: error[index]?.patent, desc: 'Required field' }
        this.setState({
          patentsError: error
        })
        return item
      } else if (item?.description !== undefined && item?.description !== '' && (item?.name === '' || item?.name === undefined)) {
        let error: any = [...this.state.patentsError]
        error[index] = { patent: "patent is Required here !", desc: error[index]?.desc }
        this.setState({
          patentsError: error
        })
        return item
      }
    })

    if (m.length > 0) {
      debugger
      message.error("please enter valid details", 1.4)
      this.disabledPage()

    } else {
      this.handleSkipProfile()
    }

  }

  disabledPage = () => {
    this.setState({
      disableNextButton: true
    })
    setTimeout(() => {
      this.setState({
        disableNextButton: false
      })
    }, 1450)

  }

  handleSkipProfile = async () => {
    let all_data = this.state.all_data;
    let newData = await Object.assign(
      {},
      { profile: this.state.prev_data && this.state.prev_data.profile },
      { experience: this.state.prev_data && this.state.prev_data.experience },
      { graduation: this.state.prev_data && this.state.prev_data.graduation },
      { certificate: this.state.prev_data && this.state.prev_data.certificate },
      { contact: this.state.prev_data && this.state.prev_data.contact },
      { language: this.state.prev_data.language ? this.state.prev_data.language : all_data }
    );
    let m = await this.state.patentsError.filter((item: any) => item.length > 10)
    let inter = await this.state.interestError.filter((item: any) => item.length > 10)
    if (m.length > 0 || inter.length > 0) {
      if (m.length > 0) {
        message.error("patent name can't be invalid", 1.4)
        this.disabledPage()
      }
      if (inter.length > 0) {
        message.error("interest can't be invalid", 1.4)
        this.disabledPage()
      }
    } else {
      this.props.history.replace({
        state: newData,
      });
      this.props.history.push({
        pathname: "/edit/profile/personal/generalinformation",
        state: newData,
      });

    };
  }


  //====================previous================

  handlePrevious = () => {
    let newData = Object.assign(
      {},
      { profile: this.state.prev_data && this.state.prev_data.profile },
      { experience: this.state.prev_data && this.state.prev_data.experience },
      { graduation: this.state.prev_data && this.state.prev_data.graduation },
      { certificate: this.state.prev_data && this.state.prev_data.certificate },
      { contact: this.state.prev_data && this.state.prev_data.contact },
      { language: this.state.all_data && this.state.all_data }
    );
    this.props.history.replace({
      state: newData,
    });
    this.props.history.push({
      pathname: "/edit/profile/personal/education",
      state: newData,
    });

  }

  handleError = async () => {
    let m = await this.state.patentsError.filter((item: any) => item?.patent?.length > 10)
    let inter = await this.state.interestError.filter((item: any) => item.length > 10)

    if (m.length > 0 || inter.length > 0) {
      if (m.length > 0) {
        message.error("patent name can't be invalid", 1.4)
        this.disabledPage()
      }
      if (inter.length > 0) {
        message.error("interest can't be invalid", 1.4)
        this.disabledPage()
      }
    } else {
      this.onNext()
    }

  }
  handleNext = async () => {
    let m = this.state.all_data?.patents.filter((item: any, index: number) => {
      if (item?.name !== undefined && item?.name !== '' && item?.name !== null && (item?.description === '' || item?.description === undefined || item?.description === null)) {
        let error: any = [...this.state.patentsError]
        error[index] = { patent: error[index]?.patent, desc: 'Required field' }
        this.setState({
          patentsError: error
        })
        return item
      } else if (item?.description !== undefined && item?.description !== '' && item?.description !== null && (item?.name === '' || item?.name === undefined || item?.name === null)) {
        let error: any = [...this.state.patentsError]
        error[index] = { patent: "patent is Required here !", desc: error[index]?.desc }
        this.setState({
          patentsError: error
        })
        return item
      }
    })

    if (m.length > 0) {
      message.error("please enter valid details", 1.4)
      this.disabledPage()
    } else {
      this.handleError()
    }
  };
  onNext = async () => {
    let all_data = {
      ...this.state.all_data,
      // other: this.state.all_data.other?.filter((item) => item.name !== null &&
      //   item.name !== "" &&
      //   item.name !== undefined),

      // patents: this.state.all_data.patents?.filter(item => item.name !== null &&
      //   item.name !== "" &&
      //   item.name !== undefined &&
      //   item.description !== null &&
      //   item.description !== "" &&
      //   item.description !== undefined),

      language: this.state.selectedLanguages
    };
    console.log(all_data, "adsajghajkhsg ajsd;")
    let newData = await Object.assign(
      {},
      { profile: this.state.prev_data && this.state.prev_data.profile },
      { experience: this.state.prev_data && this.state.prev_data.experience },
      { graduation: this.state.prev_data && this.state.prev_data.graduation },
      { certificate: this.state.prev_data && this.state.prev_data.certificate },
      { contact: this.state.prev_data && this.state.prev_data.contact },
      { language: all_data }
    );
    this.props.history.replace({
      state: newData,
    });
    this.props.history.push({
      pathname: "/edit/profile/personal/generalinformation",
      state: newData,
    });
    // console.log("data hello", newData);
  }
 editHandleSearch = (SearchText: any) => {
    const editData = this.state.editselectedLanguageData?.filter((item: any) =>
      item.value.toLowerCase().includes(SearchText.toLowerCase()));
    this.setState({ editselectedLanguageData: editData })
  }

  handleCategory = () => {
    let interests = [...this.state.interests];
    let language = [...this.state.languagesData];
    const children = [...this.state.children];
    this.state.category_data?.map((data: any) => {
      if (data.attributes.name === "Language") {
        language = data.attributes.sub_categories;
      }
      const addOptions = language.map((item: any) => ({ value: item.name }));
      this.setState({ editselectedLanguageData: addOptions });
  });
    language.forEach((data: any, index) => {
      children.push(
        // <Option value={data.name} key={index}>
        //   {data.name}
        // </Option>
        <option
          style={{
            borderBottom: "1px solid #7aacd6",
            padding: "10px 20px 10px 20px",
          }}
          value={data.name}
          key={index}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              // paddingTop: "0.7rem",
            }}
          >
            <div>{data.name}</div>
            {/* <div>
             <Checkbox
               name="checkbox"
               value={data.name}
               onChange={this.onChange}
             />
           </div> */}
          </div>
        </option>
      );
    });
    this.setState({
      children,

    });
    console.log(this.state.data, "lanh hello")
  };
  onChange(e: any) {
    console.log(`checked = ${e.target.checked}`);
  }
  getCategories = () => {
    const header = {
      "Content-Type": "application/json",
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCategoryAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    this.setState({
      categories_id: requestMessage.id,
    });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  async receive(from: string, message: Message) {
    console.log("receive");
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson) {
        // console.table(responseJson)
        if (responseJson.data) {
          this.setState(
            {
              category_data: responseJson.data,
            },
            this.handleCategory
          );

        } else if (responseJson.errors) {
          console.log(responseJson.errors[0].token);
          this.setState({
            isErrorModalShow: true,
            errorContent: {
              title: "Error",
              isError: true,
              body: responseJson.errors[0]?.token,
            },
          });
        } else {
          console.log("something went wrong");
        }
      } else {
        console.log("hello");
      }
    }
  }

  //=================== @@show modal =========================
  showModal = (index: number, data: string) => {
    this.setState({
      modalIndex: index,
      modalString: data,
      isModalVisible: true,
    });
  };
  // ====================@@delete all_data values=======================
  deleteData = (value: any) => {
    if (
      this.state.all_data.patents != null &&
      this.state.modalString === 'Patent'
    ) {
      const list: any = [...this.state.all_data.patents];
      list.splice(value, 1);
      this.setState((state) => ((state.all_data.patents = list), state));
    } else if (
      this.state.all_data.other != null &&
      this.state.modalString === 'Interest'
    ) {
      const list: any = [...this.state.all_data.other];
      list.splice(value, 1);
      this.setState((state) => ((state.all_data.other = list), state));
    } else if (
      this.state.all_data.language != null &&
      this.state.modalString === 'Language'
    ) {
      const list: any = [...this.state.all_data.language];
      list.splice(value, 1);
      this.setState((state) => ((state.all_data.language = list), state));
    } else {
      console.log('error while deleting');
    }
    this.setState({
      isModalVisible: false,
    });
  };

  // =================== @@handleChange =========================
  handleInputChange = (e: any, index: any, data: string) => {
    const { name, value } = e.target;
    if (data === 'Patent') {
      const list: any = [...this.state.all_data.patents];
      list[index][name] = value;
      this.setState(list);
    } else if (data === 'Other') {
      const list: any = [...this.state.all_data.other];
      list[index][name] = value;
      this.setState(list);
    } else if (data === 'Language') {
      const list: any = [...this.state.all_data.language];
      list[index][name] = value;
      this.setState(list);
    } else {
      console.log('error while handiling data');
    }
  };
  // Customizable Area End
}
