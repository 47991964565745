import React from "react";
import CommonSettingController from "./CommonSettingsController.web";
import "./settings.css";
import { List, Row, Col, Typography, Menu, Space } from 'antd';
const { Title } = Typography;
import AccountSettings from "./AccountSettings.web";
import DeleteAccount from "./DeleteAccount.web";
import AppliedPolicy from "./AppliedPolicy.web";
import ContactUs from "./ContactUs.web";
import SuspendAccount from "./SuspendAccount.web";
import AboutApplied from "./AboutApplied.web";
import Logout from "./Logout.web";
import { deleteIcon, suspendIcon, logoutIcon, aboutIcon, appliedIcon, contactUsIcon, settingIcon } from '../assets';


export default class PrivacySettings extends CommonSettingController {

  // Customizable Area Start
  render() {
    const { chkBxProfileViewPrivate,chkBxProfileViewFriends, chkBxProfileViewPublic,chkBxProfileViewFriendsOfFriends , chkBxPostViewFriends, chkBxPostViewPublic,chkBxPostViewFriendsOfFriends, chkBxRatingFriends, chkBxRatingFriendsOfFriends, chkBxRatingPublic, push_notifications, email_notifications, paramsData, policiesData, blockedAccountList, suspendMessage, purchaseHistoryData } = this.state;
    const { navigation } = this.props;

    const menuList = [
      {
        id: 1,
        icon: settingIcon,
        label: "Account Settings",
        pathName: "account-setting"
      },
      {
        id: 2,
        icon: appliedIcon,
        label: "Applied Policy",
        pathName: "applied-policy"
      },
      {
        id: 3,
        icon: aboutIcon,
        label: "About Applied",
        pathName: "about-applied"
      },
      {
        id: 4,
        icon: contactUsIcon,
        label: "Contact Us",
        pathName: "contact-us"
      },
      {
        id: 5,
        icon: suspendIcon,
        label: "Suspend Account",
        pathName: "suspend-account"
      },
      {
        id: 6,
        icon: deleteIcon,
        label: "Delete Account",
        pathName: "delete-account"
      },
      {
        id: 7,
        icon: logoutIcon,
        label: "Logout",
        pathName: "logout"
      },
    ]


    return (
      <div style={{ width: "100%", minHeight: "86vh", backgroundColor: "rgba(245, 245, 245, 1)", boxSizing: "border-box", }}>
        <Row style={{ width: "100%" }}>
          <Col xs={{ span: 24 }} lg={{ span: 6 }} style={{ padding: "1rem" }}>
            <Title
              style={{fontWeight:500,fontSize:16}}
              level={5}
            >
              Settings
            </Title>
            <Menu
              theme="light"
              selectedKeys={[this.state.menuKey ? this.state.menuKey : "1"]}
              style={{
                width: "100%",
                color: "rgba(151, 151, 151, 1)",
                borderRadius: "5px",
                overflow: "hidden"
              }}
              mode="inline"
            >
              {
                menuList.map((item: any, id: number) => {
                  return (
                    <Menu.Item key={item.id} className="customclass" style={{ width: "100%", height: "3rem", borderBottom: id + 1 < menuList?.length ? "1px solid #ededed" : "none", display: "flex", flexDirection: "row", alignItems: "center" }} onClick={() => this.handleOnClickOnListItem(item.label, item.id)}>
                      <img src={item.icon} style={{ width: "20px", height:'20px', marginRight: "10px" }} />
                      <span style={{fontWeight:500,fontSize:15}}>{item.label}</span>
                    </Menu.Item>
                  )
                })
              }
            </Menu>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 18 }} style={{ padding: "1rem" }}>
            {
              paramsData === "account-setting" ?
                <AccountSettings
                  chkBxProfileViewPrivate={chkBxProfileViewPrivate}
                  chkBxProfileViewFriends={chkBxProfileViewFriends}
                  chkBxProfileViewFriendsOfFriends={chkBxProfileViewFriendsOfFriends}
                  chkBxProfileViewPublic={chkBxProfileViewPublic}
                  chkBxPostViewFriends={chkBxPostViewFriends}
                  chkBxPostViewFriendsOfFriends={chkBxPostViewFriendsOfFriends}
                  chkBxPostViewPublic={chkBxPostViewPublic}
                  chkBxRatingFriends={chkBxRatingFriends}
                  chkBxRatingFriendsOfFriends={chkBxRatingFriendsOfFriends}
                  chkBxRatingPublic={chkBxRatingPublic}
                  push_notifications={push_notifications}
                  email_notifications={email_notifications}
                  handleChange={this.handleChange}
                  handleSwitchChange={this.handleSwitchChange}
                  onClickOnSave={this.updateSettings}
                  blockedAccountList={blockedAccountList}
                  purchaseHistoryData={purchaseHistoryData}
                  handleUnblock={this.handleUnblock}
                  isloader={this.state.isloader}
                />
                : paramsData === "applied-policy" ?
                  <AppliedPolicy
                    policiesData={policiesData}
                    isloader={this.state.isloader}
                  />
                  : paramsData === "about-applied" ?
                    <AboutApplied />
                    : paramsData === "contact-us" ?
                      <ContactUs
                        onFinish={this.handleOnFinish}
                        onOk={this.handleOnOk}
                        isModalVisible={this.state.isOkayContactUs}
                        failureModal={this.state.failureModal}
                        body={this.state.modalBody}
                      />
                      : paramsData === "delete-account" ?
                        <DeleteAccount
                          onFinish={this.handleOnfinishSuspendAccount}
                          suspendMessage={suspendMessage}
                          handleOk={this.handleLogoutOk}
                        />
                        : paramsData === "suspend-account" ?
                          <SuspendAccount
                            onFinish={this.handleOnfinishSuspendAccount}
                            suspendMessage={suspendMessage}
                            handleOk={this.handleLogoutOk}
                          />
                          : null
            }
          </Col>
        </Row>
        <Logout
          visible={this.state.isLogoutModal}
          handleCancel={this.handleLogoutCancel}
          handleOk={this.handleLogoutOk}
        />
      </div>
    );
  }
  // Customizable Area End
}
